import React, { useState } from 'react';
import {
  PostSearch,
} from 'types/postSearch/PostSearch';
import { useFinancialInformations } from 'network/api/FinancialQueries';
import BankForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/signatureAndRental/BankForm';
import { Button, IconButton } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import { FinancialInformation, financialInformationKindEnum } from 'types/postSearch/FinancialInformation';
import { useDropzone } from 'react-dropzone';
import DocumentUtils from 'services/DocumentUtils';
import { NotificationService } from 'services/notification';
import { Delete } from '@material-ui/icons';

type Props = {
  postSearch: PostSearch,
  financialInformations?: FinancialInformation[],
};

export default function FinancialInformationForm(
  {
    postSearch,
    financialInformations,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  const financialQueries = useFinancialInformations();
  const {
    createFinancialInformation,
    uploadFinancialFile,
    deleteFinancialInformation,
  } = financialQueries;

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,application/pdf',
    maxFiles: 1,
    disabled: submitting,
    onDrop: async (dropedFiles: File[]) => {
      const filesResult = await Promise.all(dropedFiles.map(async (file) => {
        const fileBase64 = await DocumentUtils.getBase64(file);
        return {
          filename: file.name,
          fileBase64,
          fileType: file.type,
        };
      }));
      setSubmitting(true);
      uploadFinancialFile.mutateAsync({
        postSearchId: postSearch.id,
        field: 'file_url',
        data: filesResult[0],
      }).catch(() => NotificationService.notifyError(Messages.t('toast.hunter.error')))
        .finally(() => setSubmitting(false));
    },
  });

  const createRibFinancialInformation = () => {
    setSubmitting(true);
    createFinancialInformation.mutateAsync({
      postSearchId: postSearch.id,
      data: {
        post_search: postSearch.id,
        kind: financialInformationKindEnum.RIB,
      },
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const deleteFinancial = async (financialInformationId: string) => {
    await deleteFinancialInformation.mutateAsync({
      postSearchId: postSearch.id,
      financialInformationId,
    }).catch(() => NotificationService.notifyError(Messages.t('toast.hunter.error')));
  };

  return (
    <div>
      {
        financialInformations && financialInformations
          .filter((financialInformation) => financialInformation.kind
            === financialInformationKindEnum.RIB)
          .map((financialInformation) => (
            <BankForm
              key={financialInformation.id}
              financialInformation={financialInformation}
              postSearch={postSearch}
            />
          ))
      }
      {
        financialInformations && financialInformations
          .filter((financialInformation) => financialInformation.kind
            === financialInformationKindEnum.PROOF_OF_PAYMENT)
          .map((financialInformation) => (
            <div key={financialInformation.id}>
              {
                financialInformation.file_url && (
                  <div>
                    <a
                      rel="noreferrer"
                      href={financialInformation.file_url}
                      target="_blank"
                    >
                      {financialInformation.file_url.split('/').slice(-1)[0]}
                    </a>
                    <IconButton
                      onClick={() => deleteFinancial(financialInformation.id || '')}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                )
              }
            </div>
          ))
      }
      <Button
        disabled={submitting}
        onClick={createRibFinancialInformation}
      >
        {Messages.t('formButton.addRib')}
      </Button>
      <div className="file-upload-button-container">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button disabled={submitting}>
            {Messages.t('formButton.uploadProof')}
          </Button>
        </div>
      </div>
    </div>
  );
}
