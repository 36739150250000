import React, { useState } from 'react';
import { useOpinion } from 'network/api/CandidateQueries';
import { Opinion } from 'types/Opinion';
import { IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import { FetchError } from 'network/Errors';

type Props = {
  opinion: Opinion,
  refetch: () => void
};

export default function OpinionForm(
  {
    opinion,
    refetch,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  const { deleteOpinion } = useOpinion();

  const handleDeleteOpinion = () => {
    setSubmitting(true);
    deleteOpinion.mutate(
      {
        opinionId: opinion.id,
      },
      {
        onSuccess: () => {
          NotificationService.notifySuccess(Messages.t('toast.hunter.opinionDeleted'));
          refetch();
          setSubmitting(false);
        },
        onError: (error: FetchError) => {
          const { status } = error;
          if (status === 500) {
            NotificationService.notifyError(Messages.t('toast.hunter.error'));
          }
          setSubmitting(false);
        },
      },
    );
  };

  return (
    <div className="opinion-tag outlined">
      <span>
        {opinion.text}
      </span>
      <IconButton disabled={submitting} onClick={handleDeleteOpinion}>
        <CancelIcon />
      </IconButton>
    </div>
  );
}
