import { Property, PropertySearch } from 'types/property';
import { PropertyType, Research } from 'types/research';
import { WorkflowStep } from 'types/WorkflowStep';
import { ActivityType } from 'types/Activity';

export interface ActivityShort {
  task_type?: string,
  activity_type: ActivityType,
  task_done: boolean,
  date: string,
}

export interface Candidate {
  client_identifier: number;
  created_at: string;
  discarded_comment: string;
  id: string;
  is_favorite: boolean;
  match_comment: string;
  hm_url: string;
  client_url: string;
  property: Property;
  property_type: PropertyType;
  research: string;
  shortlist_con_comment: string;
  shortlist_other_comment: string;
  shortlist_pro_comment: string;
  lease_start_date: string;
  updated_at: string;
  viewed: boolean;
  manually_added: boolean;
  viewed_by_client: boolean
  workflow_step: WorkflowStep;
  workflow_step_changed_date: string;
  acceptation_viewed: boolean;
  added_by_client: boolean
  activities?: ActivityShort[]
  lease_type?:LeaseType
  agent_present?: boolean
  monthly_rent_no_charges?: number
  rent_control?:boolean,
  no_control_justification?: string
  monthly_charges?: number
  deposit_amount?: number
  agency_fee?: number
  signed_by_client?: boolean,
  signed_by_agency?: boolean,
  move_in_notation?: number,
  done_well_review?: string,
  improvement_review?: string,
  post_search?: string,
  agreement_notation?: number,
  agreement_done_well_review?: string,
  agreement_improvement_review?: string,
  client_notation?: number,
}

export type LeaseType = 'LAW_89_LEASE' | 'CIVIL_CODE_LEASE';

type LeaseTypeEnum = { [key in LeaseType]: key };

export const leaseType: LeaseTypeEnum = {
  LAW_89_LEASE: 'LAW_89_LEASE',
  CIVIL_CODE_LEASE: 'CIVIL_CODE_LEASE',
};

export interface CandidatePartial extends Partial<Candidate> {
}

export interface CandidateResearch {
  client_identifier: string;
  created_at: string;
  discarded_comment: string;
  id: number;
  match_comment: string;
  property: PropertySearch;
  research: Research;
  shortlist_con_comment: string;
  shortlist_other_comment: string;
  shortlist_pro_comment: string;
  updated_at: string;
  viewed: boolean;
  viewed_by_client: boolean
  workflow_step: WorkflowStep;
  workflow_step_changed_date: string;
}
