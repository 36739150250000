import React from 'react';
import { PropertyAttribute } from 'services/propertyAttribute/PropertyAttribute';
import EditablePropertyAttributePopover
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/EditablePropertyAttributePopover';
import { Candidate } from 'types/candidate';
import { propertyType } from 'types/research';

type Props = {
  candidate: Candidate
};

export default function EditablePropertyAttributes({ candidate }: Props) {
  return (
    <>
      <div className="pictos-grid-feature">
        <EditablePropertyAttributePopover
          picto={PropertyAttribute.ROOMS.picto}
          attribute={PropertyAttribute.ROOMS}
          candidate={candidate}
        />
        <EditablePropertyAttributePopover
          picto={PropertyAttribute.BEDROOMS.picto}
          attribute={PropertyAttribute.BEDROOMS}
          candidate={candidate}
        />
        <EditablePropertyAttributePopover
          picto={PropertyAttribute.AREA_RANGE.picto}
          attribute={PropertyAttribute.AREA_RANGE}
          candidate={candidate}
        />
        <EditablePropertyAttributePopover
          attribute={PropertyAttribute.PRICE_RANGE}
          candidate={candidate}
          plainText
        />
        <EditablePropertyAttributePopover
          picto={candidate.property.elevator === false ? (
            PropertyAttribute.ELEVATOR.extraPicto
          ) : PropertyAttribute.ELEVATOR.picto}
          attribute={PropertyAttribute.ELEVATOR}
          candidate={candidate}
        />
        <EditablePropertyAttributePopover
          picto={PropertyAttribute.FLOOR_RANGE.picto}
          attribute={PropertyAttribute.FLOOR_RANGE}
          candidate={candidate}
        />
        <EditablePropertyAttributePopover
          picto={PropertyAttribute.FURNISHED.picto}
          attribute={PropertyAttribute.FURNISHED}
          candidate={candidate}
        />
        <EditablePropertyAttributePopover
          picto={
            candidate.property.property_type
              .toLowerCase() === propertyType.APARTMENT.toLowerCase() ? (
                PropertyAttribute.PROPERTY_TYPE.picto
              ) : PropertyAttribute.PROPERTY_TYPE.extraPicto
          }
          attribute={PropertyAttribute.PROPERTY_TYPE}
          candidate={candidate}
        />
      </div>
    </>
  );
}
