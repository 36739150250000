import React from 'react';
// May be a bug in the library. It doesnt work wihout disabling the eslint rule
// eslint-disable-next-line import/named
import { useViewingAppointment } from 'network/api/CandidateQueries';
import { Candidate } from 'types/candidate';
import ViewingBookForm from 'features/hunter/pages/research/researchDetails/workflowStepTransition/forms/ViewingBookForm';
import { usePropertiesContact } from 'network/api/PropertyQueries';

type Props = {
  candidate?: Candidate,
  formSubmited?: () => void,
  setDirty?: (val: boolean) => void;
};

export default function ViewingBookFormWrapper({ candidate, formSubmited, setDirty }: Props) {
  const viewingAppointmentQueries = useViewingAppointment();
  const { data: viewingAppointments } = viewingAppointmentQueries
    .getViewingAppointment(candidate?.id, !!candidate);
  const propertiesContactQueries = usePropertiesContact();

  const { isLoading: isLoadingContact, data: propertiesContact, refetch } = propertiesContactQueries
    .getPropertiesContact(candidate?.property.id, !!candidate);

  const viewingAppointmentList = viewingAppointments
  && viewingAppointments.length > 0 ? viewingAppointments : [null];

  if (!candidate || isLoadingContact) {
    return null;
  }
  return (
    <>
      {
        viewingAppointmentList.map((viewingAppointment, idx) => (
          <ViewingBookForm
            key={viewingAppointment?.id || idx}
            formSubmited={formSubmited}
            candidate={candidate}
            viewingAppointment={viewingAppointment}
            contacts={propertiesContact || []}
            refetchContacts={() => refetch()}
            setDirty={setDirty}
          />
        ))
      }
    </>
  );
}
