import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { CloudinaryContext } from 'cloudinary-react';
import { FirebaseAppProvider } from 'reactfire';
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './scss/app.scss'; // app.scss must be imported last
import { fr } from 'date-fns/locale';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from 'react-query';
import settings from 'services/hunterSettings';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBhKZDJKZXIxjqEVBpWbeowCCtrMjydT8g',
  authDomain: 'settlesweet-hunter.firebaseapp.com',
  databaseURL: 'https://settlesweet-hunter.firebaseio.com',
  projectId: 'settlesweet-hunter',
  storageBucket: 'settlesweet-hunter.appspot.com',
  messagingSenderId: '434059086365',
  appId: '1:434059086365:web:96ba0cb62839327f7954f4',
  measurementId: 'G-Q0N0MJLD47',
};

if (settings.env !== 'LOCAL') {
  Sentry.init({
    environment: settings.env,
    dsn: settings.sentryDsn,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'console') {
        return null;
      }
      return breadcrumb;
    },
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Router
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
          <QueryClientProvider client={queryClient}>
            <CloudinaryContext cloudName="settlesweet">
              <ToastContainer />
              <App />
            </CloudinaryContext>
          </QueryClientProvider>
        </FirebaseAppProvider>
      </LocalizationProvider>
      {
        settings.env === 'PREPROD' && (
          <div className="preprod">THIS IS A PREPRODUCTION ENVIRONNEMENT</div>
        )
      }
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
