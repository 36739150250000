import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Messages from 'services/i18n/Messages';

type Props = {
  onEditClick: () => void,
  onDeleteClick: () => void,
};

export default function CommentMenu(
  {
    onEditClick,
    onDeleteClick,
  }: Props,
) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={onEditClick}>
          {Messages.t('formButton.edit')}
        </MenuItem>
        <MenuItem onClick={onDeleteClick}>
          {Messages.t('formButton.delete')}
        </MenuItem>
      </Menu>
    </div>
  );
}
