export type NotificationType = 'CLIENT_REQUEST' | 'COMMENT' | 'REMINDERS' | 'OTHER';

type NotificationTypeEnum = {
  [key in NotificationType]: NotificationType;
};

export const notificationType: NotificationTypeEnum = {
  CLIENT_REQUEST: 'CLIENT_REQUEST',
  COMMENT: 'COMMENT',
  REMINDERS: 'REMINDERS',
  OTHER: 'OTHER',
};

export type ClientNotificationType = 'NEW_HOME' | 'COMMENT' | 'VIEWING' | 'APPLICATION';

type ClientNotificationTypeEnum = {
  [key in ClientNotificationType]: ClientNotificationType;
};

export const clientNotificationType: ClientNotificationTypeEnum = {
  NEW_HOME: 'NEW_HOME',
  VIEWING: 'VIEWING',
  APPLICATION: 'APPLICATION',
  COMMENT: 'COMMENT',
};
