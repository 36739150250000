import researchFilterService, { ResearchFilterService } from 'services/ResearchFilterService';
import { useObservable } from 'micro-observables';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Divider,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FilterResearchStatus, researchStatus } from 'types/research';
import { useUser } from 'reactfire';
import StringUtils from 'services/StringUtils';
import { Routes } from 'routes/Routes';
import Messages from 'services/i18n/Messages';

const USER_RESEARCH = 'user-research';
const STATUS = 'status';

type Props = {
  homematcherList: string[]
};

export default function ResearchFilter({ homematcherList }: Props) {
  const filter = useObservable(researchFilterService.getFilters());
  const { data: user } = useUser();
  const location = useLocation();
  const history = useHistory();

  const homeMatcherListLower = Object.keys(homematcherList.reduce((acc, mail) => {
    acc[mail.toLowerCase()] = mail.toLowerCase();
    return acc;
  }, {}));

  const [anchorElement, setAnchorElement] = useState(null);
  const openMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorElement(null);
  };

  const selectUser = (homematcherMail: string) => {
    closeMenu();
    history.push(Routes.updatePathWithQuery(
      location,
      [{ label: USER_RESEARCH, value: homematcherMail }],
    ));
  };

  const selectStatus = (status: string) => {
    history.push(Routes.updatePathWithQuery(
      location,
      [{ label: STATUS, value: status }],
    ));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userResearch = queryParams.get(USER_RESEARCH);
    const status = queryParams.get(STATUS);

    if (userResearch) {
      researchFilterService.setCurrentUser(userResearch);
    }
    if (status) {
      researchFilterService.updateStatus(status as FilterResearchStatus);
    }
  }, [location]);

  return (
    <div>
      <div>
        {Messages.t('research.owner')}
        <div>
          <button
            type="button"
            className="button-filter-radio checked"
            onClick={openMenu}
          >
            {StringUtils.prettyDisplayMail(filter.current_user_research)}
          </button>
          <Menu
            anchorEl={anchorElement}
            open={!!anchorElement}
            onClose={closeMenu}
            PaperProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            <MenuItem
              selected={
                !filter.current_user_research
                || filter.current_user_research === ResearchFilterService.EVERYONE
              }
              onClick={
                () => selectUser(ResearchFilterService.EVERYONE)
              }
            >
              {StringUtils.prettyDisplayMail(ResearchFilterService.EVERYONE)}
            </MenuItem>
            <MenuItem
              selected={filter.current_user_research === ResearchFilterService.NONE}
              onClick={
                () => selectUser(ResearchFilterService.NONE)
              }
            >
              {StringUtils.prettyDisplayMail(ResearchFilterService.NONE)}
            </MenuItem>
            {
              user.email && (
                <MenuItem
                  selected={filter.current_user_research === user.email}
                  onClick={() => selectUser(user.email || '')}
                >
                  {StringUtils.prettyDisplayMail(user.email)}
                </MenuItem>
              )
            }
            <Divider />
            {
              homeMatcherListLower
                .filter((homematcherMail) => user.email && homematcherMail !== user.email)
                .map((homematcherMail) => (
                  <MenuItem
                    selected={filter.current_user_research === homematcherMail}
                    key={homematcherMail}
                    onClick={() => selectUser(homematcherMail)}
                  >
                    {StringUtils.prettyDisplayMail(homematcherMail)}
                  </MenuItem>
                ))
            }
          </Menu>
        </div>
      </div>
      <div>
        {Messages.t('research.list.status')}
        <div>
          <button
            type="button"
            className={`button-filter-radio ${filter.status === 'ALL' ? 'checked' : ''}`}
            onClick={() => selectStatus('ALL')}
          >
            {Messages.t('generics.all')}
          </button>
          {
            Object.values(researchStatus).map((value) => (
              <button
                type="button"
                key={value}
                className={`button-filter-radio ${filter.status === value ? 'checked' : ''}`}
                onClick={() => selectStatus(value)}
              >
                {Messages.t(`research.status.${value}`)}
              </button>
            ))
          }
        </div>
      </div>
      <div className="research-text-filter">
        <TextField
          fullWidth
          type="text"
          variant="standard"
          placeholder="John Doe"
          label="Client name"
          value={filter.searchText}
          onChange={(
            (event) => researchFilterService
              .updateFilterText(event.target.value))}
        />
      </div>
    </div>
  );
}
