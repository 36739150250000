import Messages from 'services/i18n/Messages';

export interface CommentForm {
  comment?: string,
  clientNotation?: string | null,
}

export interface NotationDetail {
  emoji: string,
  text: () => string,
}

export interface ClientNotationType {
  [note: string]: NotationDetail,
}

export const clientNotationExtraInfo: ClientNotationType = {
  1: {
    emoji: '😐',
    text: () => Messages.t('candidate.notation.fallback'),
  },
  2: {
    emoji: '🤔',
    text: () => Messages.t('candidate.notation.why_not'),
  },
  3: {
    emoji: '🙂',
    text: () => Messages.t('candidate.notation.nice'),
  },
  4: {
    emoji: '😃',
    text: () => Messages.t('candidate.notation.awesome'),
  },
  5: {
    emoji: '😍',
    text: () => Messages.t('candidate.notation.crush'),
  },
};

export const getCurrentNotation = (key?: string): (NotationDetail | undefined) => (
  key ? clientNotationExtraInfo[key] : undefined
);
