import React, { useState, useMemo, useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SpinButton from 'theme/hunter-utils/SpinButton';
import DateTimePickerWrapper from 'lib/form/DateTimePickerWrapper';
import { ViewingBookFormType } from 'types/forms/ViewingBookForm';
import { useViewingAppointment } from 'network/api/CandidateQueries';
import { Candidate } from 'types/candidate';
import { mailRegex, validatePhoneNumber } from 'lib/form/FormUtils';
import PhoneNumberInputWrapper from 'lib/form/PhoneNumberInputWrapper';
import AddressAutocompleteWrapper from 'lib/form/AddressAutocompleteWrapper';
import { remoteType, viewingType } from 'types/ViewingAppointment';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/core';
import { ContactType } from 'types/forms/ContactInfoForm';
import SelectWrapper from 'lib/form/SelectWrapper';
import ContactInfoForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/forms/ContactInfoForm';
import { PropertyContact } from 'types/propertyContact';
import Modal from 'lib/Modal';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import { FetchError } from 'network/Errors';
import ConfirmationModal from 'features/hunter/common/ConfirmationModal';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';

type Props = {
  candidate: Candidate,
  viewingAppointment?: ViewingBookFormType,
  formSubmited?: () => void,
  contacts: PropertyContact[],
  refetchContacts?: () => void
  setDirty?: (val: boolean) => void;
};

export default function ViewingBookForm(
  {
    viewingAppointment,
    candidate,
    formSubmited,
    contacts,
    refetchContacts,
    setDirty,
  }: Props,
) {
  const radios = [
    { name: Messages.t('viewing.type.classic'), value: viewingType.CLASSIC },
    { name: Messages.t('viewing.type.accompanied'), value: viewingType.ACCOMPANIED },
    { name: Messages.t('viewing.type.remote'), value: viewingType.REMOTE },
  ];

  const [submitting, setSubmitting] = useState(false);
  const [modalIsDirty, setModalIsDirty] = useState(false);
  const [apiErrors, setApiError] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationCallback, setConfirmationCallback] = useState<() => void>(() => {
  });

  const viewingAppointmentQueries = useViewingAppointment();
  const { createViewingAppointment, updateViewingAppointment } = viewingAppointmentQueries;

  const contactsValues = useMemo(() => contacts
    .map((obj) => (
      {
        key: obj.id.toString(),
        label: `${
          obj.contact_type === ContactType.AGENCY
            ? `${obj.agency_name} `
            : ''
        }${obj.first_name} ${obj.last_name}`,
      }
    )), [contacts]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    setValue,
  } = useForm<ViewingBookFormType>({
    defaultValues: {
      viewing_type: viewingType.CLASSIC,
      property_address: candidate.property.address,
      ...viewingAppointment,
    },
  });
  const [differentMeetingAddress, setDifferentMeetingAddress] = useState(
    !!viewingAppointment?.address,
  );

  useEffect(() => {
    if (setDirty && isDirty) {
      setDirty(true);
    }
  }, [setDirty, isDirty]);

  const formField = watch(); // when pass nothing as argument, you are watching everything
  const onSubmit: SubmitHandler<ViewingBookFormType> = (formData: ViewingBookFormType) => {
    setApiError({});
    setSubmitting(true);

    if (viewingAppointment) {
      updateViewingAppointment.mutate(
        {
          viewingAppointmentId: viewingAppointment.id,
          data: formData,
          reserachId: candidate.research,
        },
        {
          onSuccess: () => {
            NotificationService.notifySuccess(Messages.t('toast.hunter.viewingUpdated'));
            if (formSubmited) {
              formSubmited();
            }
            setSubmitting(false);
          },
          onError: (error: FetchError) => {
            const { status } = error;
            if (status === 500) {
              NotificationService.notifyError(Messages.t('toast.hunter.error'));
            }
            setApiError(error.json_response);
            setSubmitting(false);
          },
        },
      );
    } else {
      createViewingAppointment.mutate(
        {
          candidateId: candidate.id,
          data: formData,
          reserachId: candidate.research,
        },
        {
          onSuccess: () => {
            NotificationService.notifySuccess(Messages.t('toast.hunter.viewingCreated'));
            if (formSubmited) {
              formSubmited();
            }
            setSubmitting(false);
          },
          onError: (error: FetchError) => {
            const { status } = error;
            if (status === 500) {
              NotificationService.notifyError(Messages.t('toast.hunter.error'));
            }
            setApiError(error.json_response);
            setSubmitting(false);
          },
        },
      );
    }
  };

  return (
    <div>
      <ConfirmationModal
        isOpen={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onConfirm={() => {
          setConfirmationModal(false);
          confirmationCallback();
        }}
        title={Messages.t('search.warning')}
        text={Messages.t('search.loseChange')}
      />
      {
        openModal && (
          <Modal
            isOpen={openModal}
            onClose={() => {
              if (modalIsDirty) {
                setConfirmationModal(true);
                setConfirmationCallback(() => (() => {
                  setOpenModal(false);
                  setModalIsDirty(false);
                }));
              } else {
                setOpenModal(false);
              }
              setValue('selected_contact', undefined);
            }}
            header={
              formField.selected_contact ? (
                <h4 className="mt-4">{Messages.t('contacts.editOrConfirm')}</h4>
              ) : (
                <h4 className="mt-4">{Messages.t('contacts.new')}</h4>
              )
            }
          >
            <div className="contact-info-modal">
              <ContactInfoForm
                canConfirm
                candidate={candidate}
                property={candidate.property}
                propertyId={candidate.property.id}
                updateIsDirty={setModalIsDirty}
                contact={
                  contacts
                    .filter((contact) => contact.id.toString() === formField.selected_contact)[0]
                }
                isFirstPropertyContact={contacts.length === 0}
                fieldRequierd
                hideTitle
                onValidate={(contactId) => {
                  if (refetchContacts) {
                    refetchContacts();
                  }
                  setValue('selected_contact', contactId.toString());
                  setOpenModal(false);
                }}
              />
            </div>
          </Modal>
        )
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="centered-group-form">
          <h6>{Messages.t('viewing.selectType')}</h6>
          <Controller
            name="viewing_type"
            control={control}
            rules={{ required: true }}
            render={(controller) => (
              <ToggleButtonGroup
                {...controller.field}
                color="primary"
                exclusive
              >
                {
                  radios.map((radio) => (
                    <ToggleButton
                      key={radio.value}
                      value={radio.value}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))
                }
              </ToggleButtonGroup>
            )}
          />
        </div>
        {
          viewingAppointment && (
            <div className="row-form">
              <Controller
                name="is_canceled"
                control={control}
                render={(controller) => (
                  <CheckboxWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('form.field.is_canceled')}
                  />
                )}
              />
            </div>
          )
        }
        <div className="row-form">
          <Controller
            name="selected_contact"
            control={control}
            rules={{ required: true }}
            render={(controller) => (
              <SelectWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('form.field.contactInfo')}
                values={contactsValues}
                onClick={() => setOpenModal(true)}
                action={() => {
                  setOpenModal(true);
                  setValue('selected_contact', undefined);
                }}
                actionLabel={Messages.t('contacts.addNew')}
              />
            )}
          />
        </div>
        <Controller
          name="date"
          control={control}
          rules={{ required: true }}
          render={(controller) => (
            <DateTimePickerWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.date')}
            />
          )}
        />
        <Controller
          name="property_address"
          control={control}
          rules={{ required: true }}
          render={(controller) => (
            <AddressAutocompleteWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.propertyAddress')}
            />
          )}
        />
        {
          differentMeetingAddress
            ? (
              <Controller
                name="address"
                control={control}
                render={(controller) => (
                  <AddressAutocompleteWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('form.field.viewingAddress')}
                  />
                )}
              />
            )
            : (
              <button
                type="button"
                className="form-inline-button"
                onClick={() => setDifferentMeetingAddress(true)}
              >
                {Messages.t('viewing.differentAddress')}
              </button>
            )
        }

        {
          formField.viewing_type === viewingType.ACCOMPANIED && (
            <>
              <br />
              <br />
              <h5>{Messages.t('viewing.groundAgentContact')}</h5>
              <div className="row-form">
                <Controller
                  name="ground_agent_first_name"
                  control={control}
                  rules={{ required: true }}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      label={Messages.t('form.field.groundAgentFirstname')}
                    />
                  )}
                />
                <Controller
                  name="ground_agent_last_name"
                  control={control}
                  rules={{ required: true }}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      label={Messages.t('form.field.groundAgentLastname')}
                    />
                  )}
                />
              </div>
              <Controller
                name="ground_agent_phone_number"
                control={control}
                rules={{
                  required: true,
                  validate: (v) => validatePhoneNumber(v)
                    || Messages.t('form.error.phoneNumber'),
                }}
                render={(controller) => (
                  <PhoneNumberInputWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('form.field.groundAgentPhone')}
                  />
                )}
              />
              <Controller
                name="ground_agent_email"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: mailRegex,
                    message: Messages.t('form.error.mail'),
                  },
                }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('form.field.groundAgentEmail')}
                  />
                )}
              />
            </>
          )
        }
        {
          formField.viewing_type === viewingType.REMOTE && (
            <>
              <Controller
                name="remote_type"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <SelectWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('form.field.remote_type')}
                    values={Object.keys(remoteType).map((key) => ({
                      key, label: Messages.t(`viewing.remoteType.${key}`),
                    }))}
                  />
                )}
              />
              {
                formField.remote_type === remoteType.GROUND_AGENT && (
                  <Controller
                    name="ground_agent_email"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: mailRegex,
                        message: Messages.t('form.error.mail'),
                      },
                    }}
                    render={(controller) => (
                      <TextFieldWrapper
                        apiErrors={apiErrors}
                        error={errors}
                        control={controller}
                        label={Messages.t('form.field.groundAgentEmail')}
                      />
                    )}
                  />
                )
              }
              <Controller
                name="remoteComments"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    type="textarea"
                    rows={5}
                    control={controller}
                    placeholder={Messages.t('form.field.remoteCommentPlaceHolder')}
                    label={Messages.t('form.field.remoteComment')}
                  />
                )}
              />
            </>
          )
        }
        <Controller
          name="remark"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="textarea"
              rows={5}
              control={controller}
              label={Messages.t('form.field.remark')}
            />
          )}
        />
        <SpinButton
          editing={!!viewingAppointment}
          spin={submitting}
          variant="primary"
          className="mb-2"
        />
      </form>
    </div>
  );
}
