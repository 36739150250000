import React from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, UseFormTrigger } from 'react-hook-form';
import {
  PostSearch,
  PostSearchForm,
} from 'types/postSearch/PostSearch';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import TimelineWrapper from 'lib/form/TimelineWrapper';
import { stringToBoolean } from 'lib/form/FormUtils';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import { gasStatusTypeEnum } from 'types/postSearch/Gas';

type Props = {
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  errors: FieldErrors
  trigger: UseFormTrigger<PostSearchForm>
  formField: PostSearchForm,
  handleSubmit: (data: Partial<PostSearch>) => void
};

export default function GasForm(
  {
    control,
    trigger,
    errors,
    formField,
    apiErrors,
    handleSubmit,
  }: Props,
) {
  return (
    <div>
      <Controller
        name="use_gas"
        control={control}
        render={(controller) => (
          <SelectWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            onChange={(value) => handleSubmit({
              use_gas: stringToBoolean(value),
            })}
            label={Messages.t('form.field.use_gas')}
            values={[
              { key: 'true', label: Messages.t('generics.YES') },
              { key: 'false', label: Messages.t('generics.NO') },
            ]}
          />
        )}
      />
      {
        formField.use_gas === 'true' && (
          <>
            <Controller
              name="gas_need_boiler_contract"
              control={control}
              render={(controller) => (
                <CheckboxWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  onChange={(value) => handleSubmit({ gas_need_boiler_contract: value })}
                  label={Messages.t('form.field.gas_need_boiler_contract')}
                />
              )}
            />
            <Controller
              name="gas_provider"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({
                    gas_provider: value,
                  })}
                  label={Messages.t('form.field.gas_provider')}
                />
              )}
            />
            <Controller
              name="gas_status"
              control={control}
              render={(controller) => (
                <TimelineWrapper
                  control={controller}
                  onChange={(value) => handleSubmit({ gas_status: value })}
                  values={
                    Object.values(gasStatusTypeEnum)
                      .map((type) => ({ key: type, label: Messages.t(`gas.status.${type}`) }))
                  }
                />
              )}
            />
            <Controller
              name="gas_notes"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  type="textarea"
                  rows={5}
                  autoSaveSubmit={(value) => handleSubmit({
                    gas_notes: value,
                  })}
                  label={Messages.t('form.field.notes')}
                />
              )}
            />
          </>
        )
      }
    </div>
  );
}
