import React, { useState } from 'react';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PropertyImage from 'theme/PropertyImage';
import { Property } from 'types/property';
import CarrouselModal from 'features/client/common/CarrouselModal';
import ImageUtils from 'services/ImageUtils';
import Messages from 'services/i18n/Messages';
import { Candidate } from 'types/candidate';
import ClientNotation from 'features/common/ClientNotation';
import { showNotationDependingWorkflowStep } from 'services/hunter/WorkflowStep';

type Props = {
  property: Property
  candidate?: Candidate
};

export default function ImageGallery({ property, candidate }: Props) {
  const [initialImage, setInitalImage] = useState<number | undefined>(undefined);
  const [fileredImage, setFilteredImage] = useState<string[]>([]);
  const [deployed, setDeployed] = useState(false);
  const images = ImageUtils.getImageArray(property, fileredImage);

  if (images.length > 0) {
    return (
      <div className="image-gallery">
        {
          images.slice(0, 5).map((image, index) => (
            <div
              key={image.image}
              role="presentation"
              className="image-container"
              onClick={() => setInitalImage(index)}
            >
              {index === 0
                && candidate
                && (
                  <div
                    className={`client-notation-wrapper ${showNotationDependingWorkflowStep(candidate) ? '' : 'hidden'}`}
                  >
                    <ClientNotation
                      candidate={candidate}
                      editable
                      deployed={deployed}
                      setDeployed={setDeployed}
                    />
                  </div>
                )}
              <PropertyImage
                className="image-container"
                onError={() => setFilteredImage((prevState) => ([...prevState, image.image]))}
                image={image.image}
                isCloudinaryImage={image.isCloudinaryImage}
              />
            </div>
          ))
        }
        {
          initialImage !== undefined && (
            <CarrouselModal
              onClose={() => setInitalImage(undefined)}
              isOpen
              intitialSelectedImage={initialImage}
              property={property}
            />
          )
        }
      </div>
    );
  }

  return (
    <div>
      <div className="">
        <br />
        <p className="text-center mt-5 mb-5 text-secondary">
          {Messages.t('property.image.noImage')} <PhotoCameraIcon />
        </p>
        <hr className="mb-3" />
      </div>
    </div>
  );
}
