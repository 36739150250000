/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import {
  IRemoteNotification, useNotification,
} from '@magicbell/magicbell-react';
import DateUtils from 'services/DateUtils';
import Messages from 'services/i18n/Messages';
import { TemplateIcon } from '@heroicons/react/solid';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { getCurrentNotation } from 'types/forms/CommentForm';

type Props = {
  onClose: () => void
  notification: IRemoteNotification,
};

type CustomAttributes = {
  imageUrl: string,
  clientIdentifier: string,
  price: string,
  rooms: string,
  area: string,
  location: string,
  clientNotation: number,
};

export default function NotificationItem({ notification, onClose }: Props) {
  const notificationHook = useNotification(notification);
  const [imageError, setImageError] = useState(false);
  const customAttributes = notification.customAttributes as CustomAttributes;

  return (
    <a
      href={notification.actionUrl || ''}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
        notificationHook.markAsRead();
      }}
      className={`notification ${notification.readAt ? 'read-notification' : ''}`}
    >
      <div className="notification-title">
        {notification.title}
      </div>
      <div className="notification-container">
        <div>
          {
            customAttributes && (
              <div className="emoji-wrapper">
                {getCurrentNotation(customAttributes.clientNotation?.toString())?.emoji}
              </div>
            )
          }
          <div className="notification-image">
            {
              !imageError && customAttributes && customAttributes.imageUrl ? (
                <img
                  onError={() => setImageError(true)}
                  src={customAttributes.imageUrl}
                  alt="notification"
                />
              ) : (
                <PhotoCameraIcon />
              )
            }
            {
              customAttributes && customAttributes.clientIdentifier && (
                <div className="client_identifier-container">
                  #{
                  customAttributes.clientIdentifier
                }
                </div>
              )
            }
          </div>

        </div>
        <div className="notification-body">
          <div className="notification-content">
            {
              customAttributes && customAttributes.price ? (
                <>
                  <div className="candidate-card-price">
                    {customAttributes.price} &euro;
                    <span className="price-month">{Messages.t('candidate.card.byMonth')}</span>
                  </div>
                  <div className="property-area-details">
                    <TemplateIcon />
                    {customAttributes.rooms}
                    <ArrowsExpandIcon />
                    {customAttributes.area} m2
                  </div>
                  <div>{customAttributes.location}</div>
                </>
              ) : (
                <div>{notification.content}</div>
              )
            }

          </div>
          <div className="date-container">
            <div>
              {DateUtils.getPrettyDateDeltaFromNow(
                notification.sentAt ? notification.sentAt * 1000 : undefined,
              )}
            </div>
          </div>
        </div>
        {
          !notification.readAt && (
            <div className="unread-marker" />
          )
        }
      </div>
    </a>
  );
}
