import React from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, UseFormTrigger } from 'react-hook-form';
import {
  PostSearch,
  PostSearchForm,
} from 'types/postSearch/PostSearch';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import TimelineWrapper from 'lib/form/TimelineWrapper';
import { stringToNumber } from 'lib/form/FormUtils';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import { electricityStatusTypeEnum } from 'types/postSearch/Electricity';

type Props = {
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  errors: FieldErrors
  trigger: UseFormTrigger<PostSearchForm>
  handleSubmit: (data: Partial<PostSearch>) => void
};

export default function ElectricityForm(
  {
    control,
    trigger,
    errors,
    apiErrors,
    handleSubmit,
  }: Props,
) {
  return (
    <div>
      <h5>
        {Messages.t('form.field.appliances')}
      </h5>
      <div className="row-form">
        <Controller
          name="eletricity_fridge"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ eletricity_fridge: value })}
              label={Messages.t('form.field.eletricity_fridge')}
            />
          )}
        />
        <Controller
          name="eletricity_heaters"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ eletricity_heaters: value })}
              label={Messages.t('form.field.eletricity_heaters')}
            />
          )}
        />
      </div>
      <div className="row-form">
        <Controller
          name="eletricity_hoven"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ eletricity_hoven: value })}
              label={Messages.t('form.field.eletricity_hoven')}
            />
          )}
        />
        <Controller
          name="eletricity_washing_machine"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ eletricity_washing_machine: value })}
              label={Messages.t('form.field.eletricity_washing_machine')}
            />
          )}
        />
      </div>
      <div className="row-form">
        <Controller
          name="eletricity_plates"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ eletricity_plates: value })}
              label={Messages.t('form.field.eletricity_plates')}
            />
          )}
        />
        <Controller
          name="eletricity_dryer"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ eletricity_dryer: value })}
              label={Messages.t('form.field.eletricity_dryer')}
            />
          )}
        />
      </div>
      <div className="row-form">
        <Controller
          name="eletricity_microwave"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ eletricity_microwave: value })}
              label={Messages.t('form.field.eletricity_microwave')}
            />
          )}
        />
        <Controller
          name="eletricity_tv"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ eletricity_tv: value })}
              label={Messages.t('form.field.eletricity_tv')}
            />
          )}
        />
      </div>
      <h5>
        {Messages.t('form.field.howManyPeople')}
      </h5>
      <Controller
        name="eletricity_people_number"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            type="number"
            control={controller}
            trigger={trigger}
            autoSaveSubmit={(value) => handleSubmit({
              eletricity_people_number: stringToNumber(value),
            })}
            label={Messages.t('form.field.eletricity_people_number')}
          />
        )}
      />
      <Controller
        name="eletricity_people_number_with_kid"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            type="number"
            control={controller}
            trigger={trigger}
            autoSaveSubmit={(value) => handleSubmit({
              eletricity_people_number_with_kid: stringToNumber(value),
            })}
            label={Messages.t('form.field.eletricity_people_number_with_kid')}
          />
        )}
      />
      <Controller
        name="eletricity_provider"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            trigger={trigger}
            autoSaveSubmit={(value) => handleSubmit({
              eletricity_provider: value,
            })}
            label={Messages.t('form.field.eletricity_provider')}
          />
        )}
      />
      <Controller
        name="eletricity_status"
        control={control}
        render={(controller) => (
          <TimelineWrapper
            control={controller}
            onChange={(value) => handleSubmit({ eletricity_status: value })}
            values={
              Object.values(electricityStatusTypeEnum)
                .map((type) => ({ key: type, label: Messages.t(`electricity.status.${type}`) }))
            }
          />
        )}
      />
      <Controller
        name="eletricity_notes"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            trigger={trigger}
            type="textarea"
            rows={5}
            autoSaveSubmit={(value) => handleSubmit({
              eletricity_notes: value,
            })}
            label={Messages.t('form.field.notes')}
          />
        )}
      />
    </div>
  );
}
