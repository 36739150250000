import Cookies from 'universal-cookie';

const SHARED_DATA_ENDPOINT = '/token';

const INSTALL_POPUP = 'hideInstall';

export class ClientSearchService {
  private cookies = new Cookies();

  getHideInstall(): boolean {
    return (this.cookies.get(INSTALL_POPUP) || 'false') === 'true';
  }

  setHideInstall() {
    this.cookies.set(INSTALL_POPUP, 'true');
  }

  static getSearch() {
    return fetch(SHARED_DATA_ENDPOINT).then((response) => response.json());
  }

  static setSearch(searchId: string) {
    fetch(SHARED_DATA_ENDPOINT, { method: 'POST', body: JSON.stringify({ token: searchId }) });
  }
}

const clientSearchService = new ClientSearchService();
export default clientSearchService;
