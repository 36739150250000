import District from 'services/isochrone/District';
import settings from 'services/hunterSettings';

export default class DistrictService {
  private fetchAdapter;

  constructor(fetchAdapter) {
    this.fetchAdapter = fetchAdapter;
  }

  districts_geojson() {
    return this.fetchAdapter
      .fetchJson(`${settings.publicUrl}/geojson/quartier_paris.geojson`)
      .then((geojson) => {
        geojson.features.forEach((element, index) => {
          element.id = index;
        });
        return geojson;
      });
  }

  available_districts(districts_geojson) {
    return districts_geojson.features.map(
      (feature) => new District(feature.id, feature),
    );
  }
}
