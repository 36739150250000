import React, { useState, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import SpinButton from 'theme/hunter-utils/SpinButton';
import { useProperty } from 'network/api/PropertyQueries';
import { Candidate, LeaseType, leaseType } from 'types/candidate';
import AddressAutocompleteWrapper from 'lib/form/AddressAutocompleteWrapper';
import Messages from 'services/i18n/Messages';
import { stringToBoolean, validateAddress } from 'lib/form/FormUtils';
import { useDropzone } from 'react-dropzone';
import { Button } from '@material-ui/core';
import { FetchError } from 'network/Errors';
import SelectWrapper from 'lib/form/SelectWrapper';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { differenceInDays, endOfMonth, getDaysInMonth } from 'date-fns';
import { ReadyToSignTransition } from 'types/forms/ReadyToSignTransition';
import DatePickerWrapper from 'lib/form/DatePickerWrapper';
import { useCandidates } from 'network/api/CandidateQueries';
import LoaderRing from 'theme/hunter-utils/LoaderRing';
import { workflowStep } from 'types/WorkflowStep';

type Props = {
  onSubmited: (datas: {
    lease_type: LeaseType
    agent_present: boolean
    monthly_rent_no_charges: number
    rent_control?: boolean,
    no_control_justification?: string
    monthly_charges: number
    deposit_amount: number
    agency_fee?: number
    lease_draft?: string | null
    lease_start_date: string
  }) => void,
  candidate: Candidate,
  researchId: string,
  setDirty?: (val: boolean) => void,
};

export default function ReadyToSignTransitionForm(
  {
    onSubmited,
    candidate,
    researchId,
    setDirty,
  }: Props,
) {
  const { updateProperty } = useProperty();
  const candidatesQueries = useCandidates();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading, data: countPerSteps } = candidatesQueries.getCountPerStep(researchId);
  const [apiErrors, setApiError] = useState({});
  const [files, setFiles] = useState<(File & { preview: string, submiting: boolean })[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [noLease, setNoLease] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: submitting,
    maxFiles: 1,
    onDrop: (dropedFiles: File[]) => {
      setFiles(dropedFiles.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
        submiting: false,
      })));
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<ReadyToSignTransition>({
    defaultValues: {
      address: candidate.property.address,
    },
  });
  const formField = watch();

  const submitForm = (formData: ReadyToSignTransition, lease: string | undefined) => {
    updateProperty.mutate(
      {
        candidate,
        data: {
          address: formData.address,
          latitude: formData.latitude,
          longitude: formData.longitude,
          location_type: formData.location_type,
        },
        propertyId: candidate.property.id,
      },
      {
        onSuccess: async () => {
          await onSubmited({
            lease_type: formData.lease_type,
            agent_present: stringToBoolean(formData.agent_present) || false,
            monthly_rent_no_charges: Number.parseInt(formData.monthly_rent_no_charges, 10),
            rent_control: stringToBoolean(formData.rent_control) || undefined,
            no_control_justification: formData.no_control_justification,
            monthly_charges: Number.parseInt(formData.monthly_charges, 10),
            deposit_amount: formData.deposit_amount,
            agency_fee: formData.agency_fee,
            lease_draft: lease,
            lease_start_date: formData.leaseStartDate,
          });
        },
        onError: (error: FetchError) => {
          setSubmitting(false);
          setApiError(error.json_response);
        },
      },
    );
  };

  const onSubmit: SubmitHandler<ReadyToSignTransition> = (formData: ReadyToSignTransition) => {
    setSubmitting(true);
    setApiError({});
    if (files.length === 0) {
      submitForm(formData, undefined);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      submitForm(formData, reader.result?.toString());
    };
    reader.onerror = (error) => {
      // eslint-disable-next-line no-console
      console.log('Error: ', error);
    };
  };

  const date = formField.leaseStartDate ? new Date(formField.leaseStartDate) : null;
  let monthlyCharge: number | null = null;
  if (date && formField.monthly_rent_no_charges && formField.monthly_charges) {
    const endMonth = endOfMonth(date);
    const dayDelta = differenceInDays(endMonth, date) + 1; // + 1 to include the first day
    const dayNumber = getDaysInMonth(date);
    const dayPrice = (Number.parseInt(formField.monthly_rent_no_charges, 10)
        + Number.parseInt(formField.monthly_charges, 10))
      / dayNumber;
    monthlyCharge = dayPrice * dayDelta;
    monthlyCharge = Math.round((monthlyCharge + Number.EPSILON) * 100) / 100;
  }

  useEffect(() => {
    if (setDirty && isDirty) {
      setDirty(true);
    }
  }, [setDirty, isDirty]);

  if (isLoading) {
    return <LoaderRing visible />;
  }

  const sum = countPerSteps?.filter((stepCount) => [
    workflowStep.NOT_SURE,
    workflowStep.SENT,
    workflowStep.VIEWING_REQUESTED,
    workflowStep.BOOKED,
    workflowStep.VIEWED,
    workflowStep.APPLIED,
    workflowStep.TO_APPLY,
    workflowStep.APPLICATION_ACCEPTED,
    workflowStep.AGREEMENT,
    workflowStep.TRASH,
  ].includes(stepCount.workflow_step)).reduce((acc, value) => acc + value.count, 0) || 0;
  if (sum < 3) {
    return (
      <div className="signed-transition">
        {Messages.t('transition.missingCandidate')}
      </div>
    );
  }
  return (
    <div className="signed-transition">
      {
        files && files.length > 0 && files[0].preview && (
          <iframe src={files[0].preview} className="signed-document" title="signed-document" />
        )
      }
      <form className="signed-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="upload-button-container">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button variant="contained">{Messages.t('form.field.updload_lease_draft')}</Button>
          </div>
          <Button onClick={() => setNoLease(true)}>{Messages.t('form.field.no_lease')}</Button>
        </div>
        {
          ((files && files.length > 0 && files[0].preview) || noLease) && (
            <>
              <Controller
                name="lease_type"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <SelectWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    requierd
                    label={Messages.t('form.field.lease_type')}
                    values={Object.values(leaseType)
                      .map((key) => (
                        { key, label: Messages.t(`candidate.lease_type.${key}`) }
                      ))}
                  />
                )}
              />
              <Controller
                name="agent_present"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <SelectWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('form.field.agent_present')}
                    values={[
                      { key: 'true', label: Messages.t('generics.YES') },
                      { key: 'false', label: Messages.t('generics.NO') },
                    ]}
                  />
                )}
              />
              <Controller
                name="address"
                control={control}
                rules={{
                  required: true,
                  validate: (v) => validateAddress(v)
                    || 'Wrong address format: it must be "PLACE, ZIPCODE CITY, COUNTRY"',
                }}
                render={(controller) => (
                  <AddressAutocompleteWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('form.field.address')}
                    placeholder=""
                    onCoordinateChanged={(value) => {
                      setValue('latitude', value.latitude);
                      setValue('longitude', value.longitude);
                      setValue('location_type', value.location_type);
                    }}
                    onPostalCodeChanged={() => {
                    }}
                  />
                )}
              />
              <Controller
                name="leaseStartDate"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <DatePickerWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('form.field.leaseStartDate')}
                  />
                )}
              />
              <Controller
                name="monthly_rent_no_charges"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    suffix="€"
                    type="number"
                    control={controller}
                    label={Messages.t('form.field.monthly_rent_no_charges')}
                  />
                )}
              />
              <Controller
                name="rent_control"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <SelectWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('form.field.rent_control')}
                    values={[
                      { key: 'all', label: Messages.t('generics.nonAppliable') },
                      { key: 'true', label: Messages.t('generics.YES') },
                      { key: 'false', label: Messages.t('generics.NO') },
                    ]}
                  />
                )}
              />
              {
                formField.rent_control === 'false' && (
                  <Controller
                    name="no_control_justification"
                    control={control}
                    rules={{ required: true }}
                    render={(controller) => (
                      <TextFieldWrapper
                        apiErrors={apiErrors}
                        error={errors}
                        control={controller}
                        label={Messages.t('form.field.no_control_justification')}
                      />
                    )}
                  />
                )
              }
              <Controller
                name="monthly_charges"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    suffix="€"
                    type="number"
                    control={controller}
                    label={Messages.t('form.field.monthly_charges')}
                  />
                )}
              />
              <Controller
                name="prorated_rent"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    suffix="€"
                    type="number"
                    value={monthlyCharge?.toString()}
                    disabled
                    control={controller}
                    label={Messages.t('form.field.prorated_rent')}
                  />
                )}
              />
              <Controller
                name="deposit_amount"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    suffix="€"
                    type="number"
                    control={controller}
                    label={Messages.t('form.field.deposit_amount')}
                  />
                )}
              />
              {
                formField.agent_present === 'true' && (
                  <Controller
                    name="agency_fee"
                    control={control}
                    rules={{ required: true }}
                    render={(controller) => (
                      <TextFieldWrapper
                        apiErrors={apiErrors}
                        error={errors}
                        suffix="€"
                        type="number"
                        control={controller}
                        label={Messages.t('form.field.agency_fee')}
                      />
                    )}
                  />
                )
              }
              <SpinButton
                editing
                spin={submitting}
                variant="primary"
                title={Messages.t('formButton.confirm')}
              />
            </>
          )
        }
      </form>
    </div>
  );
}
