import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Messages from 'services/i18n/Messages';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import SelectWrapper from 'lib/form/SelectWrapper';
import candidateFilterService, {
  CandidateFilter,
  possibleFilter,
  possibleOperand,
} from 'services/CandidateFilterService';
import { editorType } from 'types/Criteria';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Research } from 'types/research';
import DatePickerWrapper from 'lib/form/DatePickerWrapper';
import CancelIcon from '@material-ui/icons/Cancel';
import { WorkflowStep } from 'types/WorkflowStep';
import { useZipCodeBackend } from 'network/api/ZipCodesQueries';
import { Info } from '@material-ui/icons';

type Props = {
  onClose: () => void,
  research: Research,
  currentWorkflowStep: WorkflowStep,
};

export default function Fitler({ onClose, research, currentWorkflowStep }: Props) {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CandidateFilter>({
    defaultValues: {
      ...candidateFilterService.getFilters(currentWorkflowStep),
    },
  });

  const { getWithZipcode } = useZipCodeBackend();
  const { data } = getWithZipcode(research.zip_codes.join(','));
  const formField = watch();
  candidateFilterService.updateFilter(formField, currentWorkflowStep);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criterias',
  });
  useEffect(() => {
    const handleKeyBoardEvent = (event: KeyboardEvent) => {
      if (event.keyCode === 27 || event.keyCode === 13) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyBoardEvent);
    return () => {
      document.removeEventListener('keydown', handleKeyBoardEvent);
    };
  }, []);

  return (
    <>
      <div role="presentation" className="background" onClick={onClose} />
      <div className="filters">
        <div>
          {Messages.t('filter.title')}
        </div>
        <div>
          {fields.map((item, index) => (
            <div className="row-form" key={item.id}>
              <div className="filter-select">
                <Controller
                  name={`criterias.${index}.field` as 'criterias.0.field'}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={formField.criterias?.[index]?.field}
                  render={(controller) => (
                    <SelectWrapper
                      error={errors}
                      control={controller}
                      onChange={(value) => {
                        setValue(`criterias.${index}.operand`, possibleFilter[value].defaultOperand);
                        setValue(`criterias.${index}.value`, undefined);
                      }}
                      requierd
                      label={Messages.t('form.field.field')}
                      values={Object.keys(possibleFilter)
                        .map((key) => (
                          { key, label: Messages.t(possibleFilter[key].label) }
                        ))}
                    />
                  )}
                />
                <Controller
                  name={`criterias.${index}.operand` as 'criterias.0.operand'}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={formField.criterias?.[index]?.operand}
                  render={(controller) => (
                    <div className="filter-operator">
                      <SelectWrapper
                        error={errors}
                        control={controller}
                        variant="filled"
                        disableFullwidth
                        values={(possibleFilter[formField.criterias?.[index]?.field || '']?.possibleOperand || [])
                          .concat(possibleOperand.IS_EMPTY)
                          .map((filter) => (
                            {
                              key: filter,
                              label: Messages.t(`filter.${possibleFilter[formField.criterias?.[index]?.field || '']?.isString ? 'string.' : ''}${filter}`),
                            }
                          ))}
                      />
                    </div>
                  )}
                />
                <div className="info-container">
                  <Controller
                    name={`criterias.${index}.value` as 'criterias.0.value'}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={formField.criterias?.[index]?.value}
                    render={(controller) => {
                      // Accessing default property of an array seems to be buggy
                      const filter = possibleFilter[formField.criterias?.[index]?.field || ''];
                      if (filter && filter.isZipCode && data) {
                        return (
                          <SelectWrapper
                            error={errors}
                            control={controller}
                            multiple={filter.defaultOperand === possibleOperand.IN}
                            label={Messages.t('form.field.value')}
                            values={
                              research.zip_codes.map((zipCode) => ({
                                key: zipCode,
                                label: `${data[zipCode]?.map((zips) => zips.city)[0]} (${zipCode})` || zipCode,
                              })) || []
                            }
                          />
                        );
                      }
                      if (filter
                        && filter.editor === editorType.SELECT
                        && filter.getPossibleValues) {
                        return (
                          <SelectWrapper
                            error={errors}
                            control={controller}
                            multiple={filter.defaultOperand === possibleOperand.IN}
                            label={Messages.t('form.field.value')}
                            values={filter.getPossibleValues(research)}
                          />
                        );
                      }
                      if (filter && filter.editor === editorType.DATE) {
                        return (
                          <DatePickerWrapper
                            error={errors}
                            control={controller}
                            label={Messages.t('form.field.date')}
                          />
                        );
                      }
                      return (
                        <TextFieldWrapper
                          error={errors}
                          control={controller}
                          requierd
                          label={Messages.t('form.field.value')}
                        />
                      );
                    }}
                  />
                  {
                    possibleFilter[formField.criterias?.[index]?.field || '']?.tooltip && (
                      <div className="info-icon">
                        <Tooltip
                          placement="top-end"
                          title={Messages.t(possibleFilter[formField.criterias?.[index]?.field || '']?.tooltip || '')}
                        >
                          <Info />
                        </Tooltip>
                      </div>
                    )
                  }
                </div>
              </div>
              <IconButton onClick={() => remove(index)}>
                <CancelIcon />
              </IconButton>
            </div>
          ))}
        </div>
        <div className="action-container">
          <Button
            variant="contained"
            disabled={
              fields.length !== 0
              && (!formField.criterias?.[fields.length - 1]?.value
                || !formField.criterias?.[fields.length - 1]?.field
                || !formField.criterias?.[fields.length - 1]?.operand)
            }
            onClick={() => append({})}
          >
            {Messages.t('formButton.add')}
          </Button>
          <Button
            variant="contained"
            onClick={onClose}
          >
            {Messages.t('formButton.ok')}
          </Button>
        </div>
      </div>
    </>
  );
}
