import { useBackend } from 'network/BackendFetchAdapter';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export interface User {
  user_mail: string,
  research_last_count: number,
  research: string,
}

export function useUserBackend() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const GetUser = (userMail, enabled = true) => useQuery<{ [key: string]: User }>(
    ['getUser', userMail],
    async () => {
      const response: User[] = await backend.fetchJson(
        `/api/users?user_mail=${userMail}`,
      );
      return response.reduce((acc, value) => {
        if (value.research) {
          acc[value.research] = value;
        }
        return acc;
      }, {}) as { [key: string]: User };
    },
    { enabled },
  );

  const UpdateUser = useMutation<any, any, { userMail: string, researchId: string }>(
    async ({ userMail, researchId }) => {
      const response = await backend.post(
        '/api/users/refresh/',
        {
          research_id: researchId,
          user_mail: userMail,
        },
      );
      return response;
    }, {
      onSuccess: (_, variables) => {
        queryClient.refetchQueries(['getUser', variables.userMail]);
      },
    },
  );

  return {
    getUser: GetUser,
    updateUser: UpdateUser,
  };
}
