import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  CLIENT_DISCOVER,
  CLIENT_RESEARCH_TAB,
  CLIENT_TAB_ID,
  clientTab,
  clientTabDetail, ClientTabIcons,
  ClientTabTypeLower,
} from 'routes/ClientRoutes';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import {
  CollectionIcon,
  PlusCircleIcon,
} from '@heroicons/react/solid';
import {
  SearchIcon,
} from '@heroicons/react/outline';
import { workFlowStepIcon } from 'services/hunter/WorkflowStep';
import { workflowStep } from 'types/WorkflowStep';
import Messages from 'services/i18n/Messages';

type Params = {
  researchId: string,
  clientTabId: ClientTabTypeLower
};

export const clientTabIcon: ClientTabIcons = {
  DISCOVER: {
    icon: (<SearchIcon />),
  },
  TRACK: {
    icon: (<CollectionIcon />),
  },
  SHORTLIST: {
    icon: (workFlowStepIcon[workflowStep.NOT_SURE]),
  },
  ADD: {
    icon: (<PlusCircleIcon />),
  },
};

export default function NavBar() {
  const { researchId, clientTabId } = useParams<Params>();
  return (
    <nav>
      <div className="nav-content">
        <Link to={Routes.withPath(
          CLIENT_DISCOVER,
          [
            { label: RESEARCH_ID, value: researchId },
          ],
        )}
        >
          <h1>{Messages.t('client.navbar.title')}</h1>
        </Link>
        {
          Object.values(clientTab).map((key) => (
            <div
              key={key}
              className={`tab-${key.toLowerCase()} footer-tab ${clientTabId === key.toLowerCase() ? 'selected' : ''}`}
            >
              <Link
                className="footer-tab-link"
                to={Routes.withPath(CLIENT_RESEARCH_TAB,
                  [
                    { label: RESEARCH_ID, value: researchId },
                    { label: CLIENT_TAB_ID, value: key.toLowerCase() },
                  ])}
              >
                <>
                  {clientTabIcon[key].icon}
                  <div>
                    {
                      clientTabDetail[key].getLabel()
                    }
                  </div>
                </>
              </Link>
            </div>
          ))
        }
      </div>
    </nav>
  );
}
