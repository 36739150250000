import { WorkflowStep } from 'types/WorkflowStep';
import { CommentType } from 'types/Comment';

export interface Activity {
  activity_type: ActivityType
  candidate: string
  client_notation: number | null,
  created_at: string
  id: number
  message_content: string | null,
  message_title: string
  updated_at: string
  workflow_step: WorkflowStep | null,
  date: string,
  task_done: boolean
  remind_date?: string,
  task_type: string
  property_title?: string,
  read: boolean,
}

export interface ActivityTaskForm {
  id?: number
  activity_type: ActivityType
  candidate?: string
  message_content: string | null,
  date?: string,
  remind_date?: string,
  task_done?: boolean
  task_type?: string
  task_reminder?: TaskRemind,
  message_title: string
}

export interface ActivityComment {
  activity?: Activity,
  comment?: CommentType,
  createdAt: string,
}

export interface MarkAsReadActivityType {
  candidate: string,
  idList: number[],
}

export interface ActivityParial extends Partial<Activity>{
  id: number
}

export type ActivityType = 'WORKFLOW' | 'MESSAGE' | 'TASK' | 'CLIENT';

type ActivityTypeEnum = {
  [key in ActivityType]: ActivityType;
};

export const activityType: ActivityTypeEnum = {
  WORKFLOW: 'WORKFLOW',
  MESSAGE: 'MESSAGE',
  TASK: 'TASK',
  CLIENT: 'CLIENT',
};

export type TaskRemind = 'DONE' | 'REMIND';

type TaskRemindEnum = {
  [key in TaskRemind]: TaskRemind;
};
export const taskRemind: TaskRemindEnum = {
  DONE: 'DONE',
  REMIND: 'REMIND',
};
