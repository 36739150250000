import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Routes } from 'routes/Routes';
import { MAP_QUERY_STEP } from 'routes/ClientRoutes';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import { WorkflowStep } from 'types/WorkflowStep';
import Messages from 'services/i18n/Messages';

type Props = {
  steps: WorkflowStep[],
};

export default function MapButton({ steps }: Props) {
  const location = useLocation();
  return (
    <div className="map-button-container">
      <Link
        className="map-button"
        to={Routes.updatePathWithQuery(
          location,
          [
            {
              label: MAP_QUERY_STEP,
              value: steps.join(','),
            },
          ],
        )}
      >
        <LocationMarkerIcon />
        {Messages.t('map.title')}
      </Link>
    </div>
  );
}
