import Messages from 'services/i18n/Messages';

export default class DateUtils {
  public static getPrettyDateDeltaFromNow(date: string | undefined | number): string {
    if (!date) {
      return Messages.t('dates.publishedDate.unknown');
    }
    const deltaInSeconds = Math.round((Date.now() - (new Date(date)).getTime()) / 1000);
    if (deltaInSeconds < 60) {
      return Messages.t('dates.publishedDate.now');
    }
    const deltaInMinutes = deltaInSeconds / 60;
    if (deltaInMinutes < 60) {
      return Messages.t('dates.publishedDate.minutes', { deltaInMinutes: Math.round(deltaInMinutes) });
    }
    const deltaInHours = deltaInMinutes / 60;
    if (deltaInHours < 24) {
      return Messages.t('dates.publishedDate.hours', { deltaInHours: Math.round(deltaInHours) });
    }
    const deltaInDays = deltaInHours / 24;
    if (deltaInDays < 30) {
      return Messages.t('dates.publishedDate.days', { deltaInDays: Math.round(deltaInDays) });
    }
    const deltaInMonths = deltaInDays / 30;
    if (deltaInMonths < 12) {
      return Messages.t('dates.publishedDate.months', { deltaInMonths: Math.round(deltaInMonths) });
    }
    return Messages.t('dates.publishedDate.years', { deltaInYears: Math.round(deltaInMonths / 12) });
  }

  public static dateIsNullOtooOld(date: string): boolean {
    if (!date) {
      return true;
    }
    const deltaInSeconds = Math.round((Date.now() - (new Date(date)).getTime()) / 1000);
    const deltaInYear = deltaInSeconds / (60 * 60 * 24 * 30 * 12);
    return deltaInYear > 10;
  }

  public static getPositiveDaysDelta(firstDate?: Date, lastDate?: Date): number {
    if (!firstDate || !lastDate) {
      return 0;
    }
    const deltaInSeconds = Math.round((lastDate.getTime() - firstDate.getTime()) / 1000);
    const deltaInDays = deltaInSeconds / (60 * 60 * 24);
    if (deltaInDays < 0) {
      return 0;
    }
    return Math.trunc(deltaInDays);
  }

  public static getDaysDelta(firstDate?: Date, lastDate?: Date): number {
    if (!firstDate || !lastDate) {
      return 0;
    }
    const deltaInSeconds = Math.round((lastDate.getTime() - firstDate.getTime()) / 1000);
    const deltaInDays = deltaInSeconds / (60 * 60 * 24);
    return Math.trunc(deltaInDays);
  }
}
