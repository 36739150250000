import React from 'react';
// Import auth directly because most components need it
// Other Firebase libraries can be lazy-loaded as-needed
import 'firebase/auth';
import { AuthCheck, useUser } from 'reactfire';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import LogInForm from 'features/hunter/authentification/LogInForm';
import MainRouter from 'features/hunter/MainRouter';
import Layout from 'features/hunter/layout/Layout';
import { MagicBellProvider } from '@magicbell/magicbell-react';
import { notificationType } from 'types/Notifications';
import { useTaskType } from 'network/api/TaskTypeQueries';
import { ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

const API_KEY = '6640474927e1e3fd75d168de360b1e420be6f06c';

export default function HunterRoot() {
  const { data: user } = useUser();
  const { getTaskType } = useTaskType();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: taskTypes } = getTaskType();
  const theme = createMuiTheme({
    palette: {
      secondary: {
        light: '#828282',
        main: '#727272',
        dark: '#575757',
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div id="app">
        <AuthCheck fallback={<LogInForm />}>
          {
            user && user.email && (
              <MagicBellProvider
                apiKey={API_KEY}
                userEmail={user.email}
                stores={
                  [
                    ...Object.keys(notificationType)
                      .map((key) => ({
                        id: key,
                        defaultQueryParams: { categories: [key] },
                      })),
                    { id: 'ALL', defaultQueryParams: { all: true } },
                  ]
                }
              >
                <Router>
                  <Layout>
                    <MainRouter />
                  </Layout>
                </Router>
              </MagicBellProvider>
            )
          }
        </AuthCheck>
        {process.env.NODE_ENV === 'development' ? (
          <ReactQueryDevtools initialIsOpen={false} />
        ) : null}
      </div>
    </ThemeProvider>
  );
}
