import React, { useCallback, useState } from 'react';
import { AiOutlineVideoCameraAdd } from 'react-icons/ai';
import { useUploadedImage } from 'network/api/PropertyQueries';
import { NotificationService } from 'services/notification';
import { Candidate } from 'types/candidate';
import Messages from 'services/i18n/Messages';
import { useDropzone } from 'react-dropzone';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SpinButton from 'theme/hunter-utils/SpinButton';

type Props = {
  candidate: Candidate,
};

export default function AddPropertyPhotoForm({ candidate }: Props) {
  const { createUploadedImage } = useUploadedImage();
  const [files, setFiles] = useState<(File & { preview: string, submiting: boolean })[]>([]);
  const [submitting, setSubmitting] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    disabled: submitting,
    onDrop: (dropedFiles: File[]) => {
      setFiles(dropedFiles.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
        submiting: false,
      })));
    },
  });

  const [show, setShow] = useState(false);

  const handleShow = useCallback(() => setShow(true), []);
  const handleClose = useCallback(() => {
    if (submitting) {
      return;
    }
    setShow(false);
  }, [submitting]);

  const handleSendImages = useCallback(async () => {
    setSubmitting(true);
    setFiles((prevState) => (prevState.map((file) => ({ ...file, submiting: true }))));
    for (const file of files) {
      // eslint-disable-next-line no-await-in-loop
      await createUploadedImage.mutateAsync({
        candidate,
        image: file,
      }, {
        onSuccess: () => {
          setFiles((prevState) => (prevState.map((fileFitler) => ({
            ...fileFitler,
            submiting: fileFitler.name === file.name ? false : fileFitler.submiting,
          }))));
          NotificationService.notifySuccess(Messages.t('toast.hunter.imageUploaded'));
        },
      });
    }
    setSubmitting(false);
    setFiles([]);
    setShow(false);
  }, [candidate, createUploadedImage, files]);

  return (
    <>
      <Button
        startIcon={<AiOutlineVideoCameraAdd />}
        variant="contained"
        style={{ minWidth: '100px' }}
        onClick={handleShow}
      >
        {Messages.t('property.image.add')}
      </Button>
      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>{Messages.t('property.image.addPhoto')}</DialogTitle>
        <DialogContent>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {
              files.length > 0 ? (
                files.map((file) => (
                  <div className="thumbnail-container" key={file.name}>
                    <img
                      alt={file.name}
                      src={file.preview}
                    />
                    {
                      file.submiting && (
                        <div className="spinner-container">
                          <CircularProgress
                            color="inherit"
                          />
                        </div>
                      )
                    }
                    <IconButton
                      disabled={submitting}
                      className="delete-button"
                      onClick={(e) => {
                        setFiles(
                          (prevState) => prevState
                            .filter((fileFilter) => file.name !== fileFilter.name),
                        );
                        e.stopPropagation();
                      }}
                    >
                      <Close />
                    </IconButton>
                  </div>
                ))
              ) : (
                <div className="input-placeholder">{Messages.t('dragnDrop.dropHere')}</div>
              )
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {Messages.t('formButton.cancel')}
          </Button>
          <SpinButton
            title={Messages.t('formButton.add')}
            editing={false}
            spin={submitting}
            disabled={files.length === 0}
            onClick={handleSendImages}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
