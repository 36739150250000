import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Stringify from 'theme/hunter-utils/Stringify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DETAIL_ID, RESEARCH_CANDIDATE_DETAIL, TAB_ID } from 'routes/HmRoutes';
import EditablePropertyAttributes
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/EditablePropertyAttributes';
import PropertyAddressDescriptionUrlForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/forms/PropertyAddressDescriptionUrlForm';
import ActivityTimeline
  from 'features/hunter/pages/research/researchDetails/candidateDetails/activities/ActivityTimeline';
import { CANDIDATE_ID, RESEARCH_ID, Routes } from 'routes/Routes';
import PropertyContactInfoTab
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/PropertyContactInfoTab';
import { Candidate } from 'types/candidate';
import Messages from 'services/i18n/Messages';
import { useActivities } from 'network/api/ActivityQueries';
import { useComments } from 'network/api/CommentQueries';
import LoaderRing from 'theme/hunter-utils/LoaderRing';
import { ActivityComment } from 'types/Activity';
import NotesTimeline from 'features/hunter/pages/research/researchDetails/candidateDetails/notes/NotesTimeline';
import { useNote } from 'network/api/PropertyQueries';

export const CanditateTab = {
  DETAILS: 'details',
  CONTACT_INFOS: 'contact-infos',
  ACTIVITES: 'activities',
  COMMENTS: 'comments',
  NOTES: 'notes',
  PARTNER: 'partner',
  RAWJSON: 'rawjson',
  SIGN: 'sign',
  GIFT: 'gift',
  UTILITIES: 'utilities',
};

type Props = {
  candidate: Candidate,
};

type Param = {
  detailId: string,
  researchId: string,
  tabId: string,
  candidateId: string,
};

export default function CandidateTabs({ candidate }: Props) {
  const {
    detailId,
    researchId,
    tabId,
    candidateId,
  } = useParams<Param>();
  const history = useHistory();
  const location = useLocation();

  const selectStep = (step) => {
    const queryParams = new URLSearchParams(location.search);
    history.push(Routes.updateUrlWithQuery(Routes.withPath(
      RESEARCH_CANDIDATE_DETAIL,
      [
        { label: RESEARCH_ID, value: researchId },
        { label: TAB_ID, value: tabId.toLowerCase() },
        { label: CANDIDATE_ID, value: candidateId },
        { label: DETAIL_ID, value: step.toLowerCase() },
      ],
    ), Array.from(queryParams.entries()).map((entry) => ({ label: entry[0], value: entry[1] }))));
  };

  const noteQueries = useNote();
  const { data: notes } = noteQueries.getNotes(
    candidate.property.id,
  );

  const activitiesQuery = useActivities();
  const commentsQueries = useComments();

  const { isLoading, data: activities } = activitiesQuery.getActivities(
    candidate.id,
  );
  const { isLoading: isLoadingComments, data: comments } = commentsQueries.getComments(
    candidate.id,
  );

  let commentsAndActivities: ActivityComment[] = [];

  if (comments && activities) {
    commentsAndActivities = [
      ...activities.map((activity) => ({ activity, createdAt: activity.created_at })),
      ...comments.map((comment) => ({ comment, createdAt: comment.created_at })),
    ]
      .sort((a, b) => (b.createdAt.localeCompare(a.createdAt)));
  }

  return (
    <>
      <Tabs activeKey={detailId} onSelect={selectStep}>
        <Tab eventKey={CanditateTab.DETAILS} title={Messages.t('candidate.tab.features')}>
          <EditablePropertyAttributes candidate={candidate} />
          <PropertyAddressDescriptionUrlForm candidate={candidate} />
        </Tab>

        <Tab
          eventKey={CanditateTab.NOTES}
          title={(
            <div className="tab-notes">
              {Messages.t('candidate.tab.notes')}
              {notes && notes.length > 0 && <div className="notification-badge" />}
            </div>
          )}
        >
          <NotesTimeline candidate={candidate} notes={notes} />
        </Tab>

        <Tab eventKey={CanditateTab.CONTACT_INFOS} title={Messages.t('candidate.tab.contactInfos')}>
          <PropertyContactInfoTab candidate={candidate} isSelected={detailId === 'contact-infos'} />
        </Tab>

        <Tab
          eventKey={CanditateTab.ACTIVITES}
          title={(
            <div className="tab-with-notification-badge">
              {Messages.t('candidate.tab.activities')}
              {
                commentsAndActivities.some(
                  (commentOrActivity) => (
                    (commentOrActivity.comment?.read === false)
                    || (commentOrActivity.activity?.read === false)
                  ),
                )
                && (
                  <div className="notification-badge" />
                )
              }
            </div>
          )}
        >
          {
            detailId === 'activities' && !isLoading && !isLoadingComments && activities && comments ? (
              <ActivityTimeline
                candidate={candidate}
                commentsAndActivities={commentsAndActivities}
              />
            ) : (
              <LoaderRing visible />
            )
          }
        </Tab>

        <Tab eventKey={CanditateTab.RAWJSON} title={Messages.t('candidate.tab.json')}>
          <Stringify obj={candidate} />
        </Tab>

      </Tabs>
    </>
  );
}
