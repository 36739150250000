import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Talk from 'talkjs';
import { Tooltip, IconButton } from '@material-ui/core';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { onMount } from 'lib/alias/useEffectAlias';
import settings from 'services/hunterSettings';
import Messages from 'services/i18n/Messages';
import { RESEARCH_ID, Routes } from 'routes/Routes';

type Props = {
  research?: {
    id: string,
    name: string,
    email: string,
    photoUrl?: string | null | undefined,
    role: string,
    welcomeMessage?: string | null,
  },
  currentUser: {
    id: string,
    name: string,
    email: string,
    photoUrl?: string | undefined,
    role: string,
    welcomeMessage?: string | null,
  },
  isInbox?: boolean,
  isToggle?: boolean,
  isPopup?: boolean,
  isChatbox?: boolean,
  showCloseInHeader?: boolean,
  hasCustomClose?: boolean,
  hasCustomRedirect?: undefined | {
    type: string,
    route: string | null | undefined,
    params: any[] | null | undefined,
  },
};

type Params = {
  researchId: string,
};

export default function Chat({
  currentUser, research, isPopup, isInbox, isChatbox, isToggle,
  showCloseInHeader, hasCustomClose, hasCustomRedirect,
}: Props) {
  const { researchId } = useParams<Params>();
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const [toggle, setToggle] = useState(false);
  const [otherUser, setOtherUser] = useState<any>();
  const [user, setUser] = useState<any>();
  const [chatBoxEl, setChatBoxEl] = useState<HTMLElement>(chatBoxRef && chatBoxRef.current);
  const [talkLoaded, markTalkLoaded] = useState(false);
  const history = useHistory();
  onMount(() => {
    const chatContainer: HTMLElement = document.getElementById('chat-wrapper');
    if (chatContainer) {
      setChatBoxEl(chatContainer);
    }
    // wait for TalkJS to load
    Talk.ready.then(() => markTalkLoaded(true));
  });
  useEffect(() => {
    if (chatBoxEl && toggle) {
      chatBoxEl.classList.add('--none');
      setToggle(!toggle);
    }
  }, [researchId]);
  useEffect(() => {
    if (currentUser) {
      setUser(new Talk.User(currentUser));
    }
  }, [currentUser]);
  useEffect(() => {
    if (research) {
      setOtherUser(new Talk.User(research));
    }
  }, [research]);
  useEffect(() => {
    if ((isToggle ? toggle : true) && talkLoaded) {
      const session = new Talk.Session({
        appId: settings.talkJsId,
        me: user,
      });
      const conversation = session.getOrCreateConversation(researchId
        || Talk.oneOnOneId(user, otherUser));
      if (otherUser) {
        conversation.setParticipant(user);
        conversation.setParticipant(otherUser);
      }
      let chatbox;
      if (isInbox) {
        chatbox = session.createInbox();
      } else if (isChatbox) {
        chatbox = session.createChatbox();
      } else {
        chatbox = session.createPopup({ showCloseInHeader });
      }
      if (hasCustomClose) {
        chatbox.onCustomConversationAction('closeConversation', () => {
          handleClick();
        });
      }
      if (hasCustomRedirect) {
        chatbox.onCustomConversationAction('redirectSearch', (data) => {
          history.push(Routes[hasCustomRedirect.type](hasCustomRedirect.route,
            [{
              label: RESEARCH_ID,
              value: data && data.conversation && data.conversation.id as string,
            }]
              .concat(hasCustomRedirect.params || [])));
        });
      }
      chatbox.select(conversation);
      if (isToggle) {
        chatbox.mount(chatBoxEl);
      } else if (isPopup) {
        chatbox.mount({ show: false });
      } else {
        chatbox.mount(chatBoxRef.current);
      }
      return () => chatbox.destroy();
    }
    return null;
  }, [isPopup, isInbox, isChatbox, isToggle, toggle, talkLoaded]);

  const handleClick = () => {
    if (toggle) {
      chatBoxEl.classList.add('--none');
    } else {
      chatBoxEl.classList.remove('--none');
    }
    setToggle(!toggle);
  };

  return (
    <>
      { isToggle
        ? (
          <Tooltip
            title={Messages.t('chat.tooltip')}
          >
            <IconButton edge="start" onClick={handleClick}>
              <QuestionAnswerIcon />
            </IconButton>
          </Tooltip>
        ) : <div ref={chatBoxRef} /> }
    </>
  );
}
