import React, { useState } from 'react';
import { Candidate } from 'types/candidate';
import { HomeIcon } from '@heroicons/react/solid';
import GoogleMap, { Coordinates } from 'features/googleMap/GoogleMap';
import GoogleCircle from 'features/googleMap/GoogleCircle';
import GoogleDrawOverlay from 'features/googleMap/GoogleDrawOverlay';
import { LocationType, locationType } from 'types/property';
import GoogleMarker from 'features/googleMap/GoogleMarker';

type Props = {
  candidate: Candidate
};

export default function CandidateMap({ candidate }: Props) {
  const [position] = useState<Coordinates>({
    lat: candidate.property.latitude || candidate.property.fluximmo_raw_advert?.latitude || 0,
    lng: candidate.property.longitude || candidate.property.fluximmo_raw_advert?.longitude || 0,
  });
  const [address] = useState<string>(candidate.property.address);

  const [prorpertyLocationType] = useState<LocationType | undefined>(
    candidate.property.location_type,
  );
  const hasValideCoordinates = position.lat !== 0 && position.lng !== 0;

  if (!hasValideCoordinates) {
    return null;
  }
  return (
    <GoogleMap center={position} zoom={15}>
      {
        prorpertyLocationType !== locationType.ROOFTOP ? (
          <>
            <GoogleCircle
              option={{
                strokeWeight: 0,
                fillColor: '#ED8936',
                fillOpacity: 0.4,
              }}
              coordinates={position}
              radius={100}
            />
            <GoogleDrawOverlay center={position}>
              <div className="home-icon-container"><HomeIcon /></div>
            </GoogleDrawOverlay>
          </>
        ) : (
          <GoogleMarker
            showMarkerIcon
            className="marker-address"
            text={address}
            id={candidate.property.id.toString()}
            coordinates={position}
          />
        )
      }
    </GoogleMap>
  );
}
