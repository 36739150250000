import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Button from 'features/client/common/Button';
import Characteristics from 'features/client/pages/candidateDetails/Characteristics';
import Opinion from 'features/client/pages/candidateDetails/Opinion';
import Location from 'features/client/pages/candidateDetails/Location';
import Description from 'features/client/pages/candidateDetails/Description';
import Comments from 'features/client/pages/candidateDetails/comments/Comments';
import ViewingDetails from 'features/client/pages/candidateDetails/ViewingDetails';
import ClientPageModal from 'features/client/common/ClientPageModal';
import { Research } from 'types/research';
import CandidateModal from 'features/client/pages/candidateDetails/CandidateModal';
import { WorkflowStep } from 'types/WorkflowStep';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Candidate } from 'types/candidate';
import { ViewingAppointment } from 'types/ViewingAppointment';
import { ClientAction, workflowStepMetaData } from 'services/hunter/WorkflowStep';
import { useCandidate } from 'network/api/CandidateQueries';
import Links from 'features/client/pages/candidateDetails/Links';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import { NotificationService } from 'services/notification';
import ActivitiesTimeline from 'features/client/pages/candidateDetails/ActivitiesTimeline';
import Messages from 'services/i18n/Messages';
import { activityType } from 'types/Activity';
import CandidateCard from 'features/client/CandidateCard';
import { WarningAmberOutlined } from '@material-ui/icons';

type Props = {
  candidate?: Candidate,
  viewingAppointement?: ViewingAppointment[],
  research?: Research
};
type Params = {
  researchId: string,
};

export default function CandidateDetailsMobile(
  {
    candidate,
    viewingAppointement,
    research,
  }: Props,
) {
  const history = useHistory();
  const location = useLocation();
  const { updateCandidateStep } = useCandidate();
  const { researchId } = useParams<Params>();

  const [openWorkflowStepModal, setOpenWorkflowStepModal] = useState<WorkflowStep | null>(null);

  const selectRef = useCallback((node: HTMLDivElement) => {
    if (location.hash.includes('comment')) {
      node.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scroll(0, 0);
    }
  }, [candidate?.id]);

  const updateCandidate = (clientAction: ClientAction) => {
    updateCandidateStep.mutate(
      // @ts-ignore
      {
        candidate,
        data: {
          is_client_update: true,
          workflow_step: clientAction.targetWorkflowStep,
          workflow_step_changed_date: new Date(),
          activity_type: activityType.CLIENT,
          message_title: 'Client comment',
          message_content: 'The customer did not leave a comment',
          client_notation: 0,
        },
      },
      {
        onSuccess: () => {
          NotificationService.notifySuccess(Messages.t('toast.client.movedListing', { stepDisplayName: workflowStepMetaData(clientAction.targetWorkflowStep).getClientDisplayName() }));
          history.push(
            Routes.withPath(
              clientAction.clientRedirection,
              [{ label: RESEARCH_ID, value: researchId }],
            ),
          );
        },
      },
    );
  };

  return (
    <ClientPageModal
      title={candidate ? (workflowStepMetaData(candidate.workflow_step).getClientDisplayName() || '') : ''}
      footer={(
        <>
          {
            candidate && workflowStepMetaData(candidate.workflow_step)
              .clientActions?.map((clientAction) => (
                <Button
                  key={clientAction.targetWorkflowStep}
                  onClick={
                    clientAction.showModal
                      ? () => setOpenWorkflowStepModal(clientAction.targetWorkflowStep)
                      : () => updateCandidate(clientAction)
                  }
                  variant={clientAction.buttonType}
                  className="rounded-button"
                >
                  {clientAction.getDisplayName()}
                </Button>
              ))
          }
        </>
      )}
    >
      {
        candidate && research && (
          <div className="candidate-detail" ref={ref}>
            <CandidateCard
              research={research}
              onArchive={() => history.push(location.pathname)}
              candidate={candidate}
              showViewing={false}
              enableFullScreenCarousel
              showNotation
            />
            {
              candidate.property.gli_asked && (
                <div className="gli-requested">
                  <WarningAmberOutlined />{Messages.t('candidate.detail.gli')}
                </div>
              )
            }
            {
              candidate.property.available === false && (
                <div className="unavailable-property">
                  {Messages.t('candidate.detail.linkExpired')}
                </div>
              )
            }
            {
              viewingAppointement && viewingAppointement.length > 0 && (
                <ViewingDetails
                  viewingAppointement={viewingAppointement[0]}
                  candidate={candidate}
                />
              )
            }
            <Opinion candidate={candidate} research={research} />
            <Characteristics candidate={candidate} research={research} />
            <Links candidate={candidate} />
            <div ref={selectRef}>
              {
                research && (
                  <Comments research={research} candidate={candidate} />
                )
              }
            </div>
            <Location candidate={candidate} />
            <ActivitiesTimeline candidate={candidate} />
            <Description candidate={candidate} />
            {!!openWorkflowStepModal && (
              <CandidateModal
                workflowStepMetadata={
                  openWorkflowStepModal
                    ? workflowStepMetaData(openWorkflowStepModal)
                    : null
                }
                candidate={candidate}
                targetWorkflowStep={openWorkflowStepModal}
                onClose={() => setOpenWorkflowStepModal(null)}
                isOpen={!!openWorkflowStepModal}
              />
            )}
          </div>
        )
      }
    </ClientPageModal>
  );
}
