import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useBackend } from 'network/BackendFetchAdapter';
import { Research, ResearchPartialType } from 'types/research';

export function useResearches() {
  const backend = useBackend();

  return useQuery<Research[]>('getAllResearches', async () => {
    const response = await backend.fetchJson('/api/researches/');
    return response;
  });
}

export function useResearch() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const GetResearch = (research_id, enabled = true) => useQuery<Research>(
    ['getResearch', research_id],
    async () => {
      const response = await backend.fetchJson(
        `/api/researches/${research_id}/`,
      );
      return response;
    },
    { enabled, refetchOnMount: false },
  );

  const UpdateSearchSort = useMutation<any, any, { id: string, sort_order: number }[]>(
    async (data) => {
      const response = await backend.post('/api/researches/sort/', data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAllResearches']);
      },
    },
  );

  const GetHomeMatcherResearch = (
    homeMatcherMail,
    onSuccess,
    enabled = true,
  ) => useQuery<Research[]>(
    ['getHmResearch', homeMatcherMail],
    async () => {
      const response = await backend.fetchJson(
        `/api/researches?homematcher_email=${homeMatcherMail}&status__in=IN_PROGRESS,POST_SEARCH`,
      );
      return response;
    },
    { enabled, refetchOnMount: false, onSuccess },
  );

  const CalculateHmac = (research_id, enabled = true) => useQuery(
    ['calculateHmac', research_id],
    async () => {
      const response = await backend.fetchJson(
        `/api/researches/${research_id}/calculate_hmac/`,
      );
      return response;
    },
    { enabled, refetchOnMount: false },
  );

  const CreateResearch = useMutation<any, any, ResearchPartialType>(
    async (data) => {
      const response = await backend.post('/api/researches/', data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAllResearches']);
      },
    },
  );

  const UpdateResearch = useMutation<any, any, { research_id: string, data: ResearchPartialType }>(
    async ({ research_id, data }) => {
      const response = await backend.patch(
        `/api/researches/${research_id}/`,
        data,
      );
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(['getCandidatesForStep'], { active: true });
        queryClient.invalidateQueries(['getAllResearches']);
        queryClient.refetchQueries(['getResearch', data.id]);
        queryClient.refetchQueries(['getHmResearch', data.homematcher_email]);
      },
    },
  );

  const DeleteResearch = useMutation<any, any, string>(
    async (research_id) => {
      const response = await backend.delete(`/api/researches/${research_id}/`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getAllResearches');
      },
    },
  );

  return {
    getResearch: GetResearch,
    updateResearch: UpdateResearch,
    createResearch: CreateResearch,
    deleteResearch: DeleteResearch,
    calculateHmac: CalculateHmac,
    getHomeMatcherResearch: GetHomeMatcherResearch,
    updateSearchSort: UpdateSearchSort,
  };
}
