import React from 'react';
import { Candidate } from 'types/candidate';
import { useComments } from 'network/api/CommentQueries';
import AddCommentForm from 'features/client/pages/candidateDetails/comments/AddCommentForm';
import CommentCard from 'features/client/pages/candidateDetails/comments/CommentCard';
import { Research } from 'types/research';
import Messages from 'services/i18n/Messages';

type Props = {
  candidate: Candidate,
  research: Research
};

export default function Comments({ candidate, research }: Props) {
  const commentsQueries = useComments();
  const { data: comments } = commentsQueries.getComments(
    candidate.id,
  );

  return (
    <div className="candidate-section">
      <div className="comments">
        <div className="comment-container">
          <h4>{Messages.t('candidate.comments.title')}</h4>
          <div>
            {
              comments
              && comments.sort((a, b) => a.created_at.localeCompare(b.created_at))
                .map((comment) => (
                  <CommentCard
                    key={comment.id}
                    research={research}
                    candidate={candidate}
                    comment={comment}
                  />
                ))
            }
          </div>
        </div>
        <AddCommentForm candidate={candidate} author="CLIENT" />
      </div>
    </div>
  );
}
