import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, UseFormTrigger } from 'react-hook-form';
import {
  PostSearch,
  PostSearchForm,
} from 'types/postSearch/PostSearch';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import {
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/core';
import {
  meetingTypeEnum,
  proxyStatusTypeEnum,
} from 'types/postSearch/Signature';
import DatePickerWrapper from 'lib/form/DatePickerWrapper';
import TimelineWrapper from 'lib/form/TimelineWrapper';
import DateTimePickerWrapper from 'lib/form/DateTimePickerWrapper';
import { Property } from 'types/property';
import { rentalTypeEnum } from 'types/postSearch/Rental';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useDropzone } from 'react-dropzone';
import DocumentUtils from 'services/DocumentUtils';
import { usePostSearch } from 'network/api/PostSearchQueries';
import { NotificationService } from 'services/notification';
import { Delete } from '@material-ui/icons';

type Props = {
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  formField: PostSearchForm,
  errors: FieldErrors
  postSearch: PostSearch,
  trigger: UseFormTrigger<PostSearchForm>
  handleSubmit: (data: Partial<PostSearch>) => void
  handleSubmitProperty: (data: Partial<Property>) => void
  setValue: UseFormSetValue<PostSearchForm>,
  candidateId: string
};

export default function RentalForm(
  {
    candidateId,
    control,
    trigger,
    formField,
    errors,
    apiErrors,
    handleSubmit,
    setValue,
    postSearch,
    handleSubmitProperty,
  }: Props,
) {
  const { uploadPostSearchFile, createRentalPostSearch } = usePostSearch();
  const [submitting, setSubmitting] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,application/pdf',
    maxFiles: 1,
    disabled: submitting,
    onDrop: async (dropedFiles: File[]) => {
      const filesResult = await Promise.all(dropedFiles.map(async (file) => {
        const fileBase64 = await DocumentUtils.getBase64(file);
        return {
          filename: file.name,
          fileBase64,
          fileType: file.type,
        };
      }));
      setSubmitting(true);
      uploadPostSearchFile.mutateAsync({
        postSearchId: postSearch.id,
        field: 'inspection_proxy_letter_url',
        data: filesResult[0],
      }).catch(() => NotificationService.notifyError(Messages.t('toast.hunter.error')))
        .finally(() => setSubmitting(false));
    },
  });

  return (
    <div>
      <h5>{Messages.t('form.field.rentalKind')}</h5>
      <Controller
        name="inspection_kind"
        control={control}
        render={(controller) => (
          <ToggleButtonGroup
            {...controller.field}
            color="primary"
            exclusive
            onChange={
              (_, val) => {
                controller.field.onChange(val);
                handleSubmit({ inspection_kind: val });
              }
            }
          >
            {
              Object.values(rentalTypeEnum).map((type) => (
                <ToggleButton
                  key={type}
                  value={type}
                >
                  {Messages.t(`signature.organisationKind.${type}`)}
                </ToggleButton>
              ))
            }
          </ToggleButtonGroup>
        )}
      />
      <Controller
        name="inspection_common_with_sign"
        control={control}
        render={(controller) => (
          <CheckboxWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            onChange={(value) => {
              const submitData: Partial<PostSearch> = {
                inspection_common_with_sign: value,
              };
              if (value) {
                if (!formField.inspection_ga_name) {
                  setValue('inspection_ga_name', formField.ga_name);
                  submitData.inspection_ga_name = formField.ga_name;
                }
                if (!formField.inspection_ga_mail) {
                  setValue('inspection_ga_mail', formField.ga_mail);
                  submitData.inspection_ga_mail = formField.ga_mail;
                }
                if (!formField.inspection_ga_phone) {
                  setValue('inspection_ga_phone', formField.ga_phone);
                  submitData.inspection_ga_phone = formField.ga_phone;
                }
                if (!formField.inspection_proxy_mail) {
                  setValue('inspection_proxy_mail', formField.proxy_mail);
                  submitData.inspection_proxy_mail = formField.proxy_mail;
                }
                if (!formField.inspection_proxy_name) {
                  setValue('inspection_proxy_name', formField.proxy_name);
                  submitData.inspection_proxy_name = formField.proxy_name;
                }
                if (!formField.inspection_proxy_phone) {
                  setValue('inspection_proxy_phone', formField.proxy_phone);
                  submitData.inspection_proxy_phone = formField.proxy_phone;
                }
                if (!formField.inspection_proxy_birthdate) {
                  setValue('inspection_proxy_birthdate', formField.proxy_birthdate);
                  submitData.inspection_proxy_birthdate = formField.proxy_birthdate;
                }
                if (!formField.inspection_proxy_birthplace) {
                  setValue('inspection_proxy_birthplace', formField.proxy_birthplace);
                  submitData.inspection_proxy_birthplace = formField.proxy_birthplace;
                }
                if (!formField.inspection_proxy_status) {
                  setValue('inspection_proxy_status', formField.proxy_status);
                  submitData.inspection_proxy_status = formField.proxy_status;
                }
                if (!formField.inspection_proxy_letter_url) {
                  setValue('inspection_proxy_letter_url', formField.proxy_letter_url);
                  submitData.inspection_proxy_letter_url = formField.proxy_letter_url;
                }
                if (!formField.inspection_meeting_address) {
                  setValue('inspection_meeting_address', formField.meeting_address);
                  submitData.inspection_meeting_address = formField.meeting_address;
                }
                if (!formField.inspection_meeting_address_kind) {
                  setValue('inspection_meeting_address_kind', formField.meeting_address_kind);
                  submitData.inspection_meeting_address_kind = formField.meeting_address_kind;
                }
                if (!formField.inspection_meeting_date) {
                  setValue('inspection_meeting_date', formField.meeting_date);
                  submitData.inspection_meeting_date = formField.meeting_date;
                }
              }
              handleSubmit(submitData);
            }}
            label={Messages.t('form.field.inspection_common_with_sign')}
          />
        )}
      />
      {
        formField.inspection_kind === rentalTypeEnum.ACCOMPAGNIED && (
          <div className="row-form">
            <Controller
              name="inspection_ga_name"
              control={control}
              rules={{ required: true }}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ inspection_ga_name: value })}
                  label={Messages.t('form.field.ga_name')}
                />
              )}
            />
            <Controller
              name="inspection_ga_mail"
              control={control}
              rules={{ required: true }}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ inspection_ga_mail: value })}
                  label={Messages.t('form.field.ga_mail')}
                />
              )}
            />
            <Controller
              name="inspection_ga_phone"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ inspection_ga_phone: value })}
                  label={Messages.t('form.field.ga_phone')}
                />
              )}
            />
          </div>
        )
      }
      {
        formField.inspection_kind === rentalTypeEnum.ON_BEHALF && (
          <>
            <div className="row-form">
              <Controller
                name="inspection_proxy_name"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ inspection_proxy_name: value })}
                    label={Messages.t('form.field.proxy_name')}
                  />
                )}
              />
              <Controller
                name="inspection_proxy_mail"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ inspection_proxy_mail: value })}
                    label={Messages.t('form.field.proxy_mail')}
                  />
                )}
              />
              <Controller
                name="inspection_proxy_phone"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ inspection_proxy_phone: value })}
                    label={Messages.t('form.field.proxy_phone')}
                  />
                )}
              />
            </div>
            <div className="row-form">
              <Controller
                name="inspection_proxy_birthdate"
                control={control}
                render={(controller) => (
                  <DatePickerWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({
                      inspection_proxy_birthdate: value?.toISOString().split('T')[0],
                    })}
                    label={Messages.t('form.field.proxy_birthdate')}
                  />
                )}
              />
              <Controller
                name="inspection_proxy_birthplace"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ inspection_proxy_birthplace: value })}
                    label={Messages.t('form.field.proxy_birthplace')}
                  />
                )}
              />
            </div>
            <Controller
              name="inspection_proxy_status"
              control={control}
              render={(controller) => (
                <TimelineWrapper
                  control={controller}
                  onChange={(value) => handleSubmit({ inspection_proxy_status: value })}
                  values={
                    Object.values(proxyStatusTypeEnum)
                      .map((type) => ({ key: type, label: Messages.t(`proxy.status.${type}`) }))
                  }
                />
              )}
            />
            <div className="file-upload-button-container">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button disabled={submitting}>
                  {Messages.t('formButton.uploadLetter')}
                </Button>
              </div>
              {
                postSearch.inspection_proxy_letter_url && (
                  <div>
                    <a
                      rel="noreferrer"
                      href={postSearch.inspection_proxy_letter_url}
                      target="_blank"
                    >
                      {postSearch.inspection_proxy_letter_url.split('/').slice(-1)[0]}
                    </a>
                    <IconButton
                      onClick={() => handleSubmit({ inspection_proxy_letter_url: '' })}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                )
              }
            </div>
            <h5>{Messages.t('form.field.keyHandling')}</h5>
            <div className="row-form">
              <Controller
                name="locker_service_name"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ locker_service_name: value })}
                    label={Messages.t('form.field.locker_service_name')}
                  />
                )}
              />
              <Controller
                name="locker_address"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ locker_address: value })}
                    label={Messages.t('form.field.locker_address')}
                  />
                )}
              />
            </div>
            <div className="row-form">
              <Controller
                name="locker_opening_hours"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ locker_opening_hours: value })}
                    label={Messages.t('form.field.locker_opening_hours')}
                  />
                )}
              />
              <Controller
                name="locker_code"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ locker_code: value })}
                    label={Messages.t('form.field.locker_code')}
                  />
                )}
              />
            </div>
            <Controller
              name="locker_notes"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  type="textarea"
                  rows={5}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ locker_notes: value })}
                  label={Messages.t('form.field.notes')}
                />
              )}
            />
          </>
        )
      }

      <h5>{Messages.t('form.field.meeting_address')}</h5>
      <Controller
        name="meeting_address_kind"
        control={control}
        render={(controller) => (
          <ToggleButtonGroup
            {...controller.field}
            color="primary"
            exclusive
            onChange={
              (_, val) => {
                controller.field.onChange(val);
                handleSubmit({ meeting_address_kind: val });
              }
            }
          >
            {
              Object.values(meetingTypeEnum).map((type) => (
                <ToggleButton
                  key={type}
                  value={type}
                >
                  {Messages.t(`meeting_address.kind.${type}`)}
                </ToggleButton>
              ))
            }
          </ToggleButtonGroup>
        )}
      />
      <div className="row-form">
        {
          formField.meeting_address_kind === meetingTypeEnum.PROPERTY ? (
            <Controller
              name="inspection_meeting_address"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ inspection_meeting_address: value })}
                  label={Messages.t('form.field.meeting_address')}
                />
              )}
            />
          ) : (
            <Controller
              name="property_address"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmitProperty({ address: value })}
                  label={Messages.t('form.field.address')}
                />
              )}
            />
          )
        }
        <Controller
          name="inspection_meeting_date"
          control={control}
          rules={{ required: true }}
          render={(controller) => (
            <DateTimePickerWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({
                inspection_meeting_date: value?.toISOString(),
              })}
              label={Messages.t('form.field.meeting_date')}
            />
          )}
        />
      </div>
      <Button onClick={async () => {
        const res = await trigger([
          'inspection_meeting_date',
          'inspection_proxy_mail',
          'inspection_proxy_name',
          'inspection_ga_name',
          'inspection_ga_mail',
        ]);
        if (res) {
          setValue('rental_checked', true);
          handleSubmit({ rental_checked: true });
          createRentalPostSearch.mutateAsync({ candidateId });
        }
      }}
      >
        {Messages.t('formButton.sendInvitation')}
      </Button>
    </div>
  );
}
