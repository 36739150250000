import React, { useState } from 'react';
import PropertyPhotosCarousel
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/PropertyPhotosCarousel';
import PropertySourceUrl
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/PropertySourceUrl';
import CandidateTabs from 'features/hunter/pages/research/researchDetails/candidateDetails/CandidateTabs';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { workflowStep } from 'types/WorkflowStep';
import { Candidate } from 'types/candidate';
import Messages from 'services/i18n/Messages';
import { LongDateTimeFormat } from 'services/utils';
import { Research, researchStatus } from 'types/research';
import PostSearchActions
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/PostSearchActions';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { useForm, Controller } from 'react-hook-form';
import { PropertyPartial } from 'types/property';
import { NotificationService } from 'services/notification';
import { FetchError } from 'network/Errors';
import { useProperty } from 'network/api/PropertyQueries';
import CopyLinkToClipboard from 'features/hunter/common/CopyLinkToClipboard';
import { Link as LinkIcon } from '@material-ui/icons';
import PropertySummaryIcons from 'features/common/PropertySummaryIcons';

type Props = {
  research: Research,
  candidate: Candidate
};

export default function CandidateDetails({ candidate, research }: Props) {
  const [apiErrors, setApiError] = useState({});
  const { property } = candidate;
  const { updateProperty } = useProperty();

  const {
    control,
    trigger,
    formState: { errors },
  } = useForm<PropertyPartial>({
    defaultValues: property,
  });

  const handleSubmit = async (data: PropertyPartial) => {
    setApiError({});
    await updateProperty.mutateAsync({
      propertyId: candidate.property.id,
      data,
      candidate,
    }, {
      onSuccess: () => {
        NotificationService.notifySuccess(Messages.t('toast.hunter.propertyUpdated'));
      },
      onError: (error: FetchError) => {
        setApiError(error.json_response);
      },
    });
  };

  return (
    <>
      {
        research.status === researchStatus.POST_SEARCH && (
          <PostSearchActions research={research} candidate={candidate} />
        )
      }
      <div className="candidate-title">
        <form>
          {
            ![workflowStep.REVIEW, workflowStep.SHORTLIST].includes(candidate.workflow_step)
            && (
              <CopyLinkToClipboard link={candidate.client_url}>
                <LinkIcon />
              </CopyLinkToClipboard>
            )
          }
          <Controller
            name="title"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                variant="standard"
                trigger={trigger}
                autoSaveSubmit={(value) => handleSubmit({ title: value })}
                label={Messages.t('form.field.title')}
                type="textarea"
              />
            )}
          />
        </form>
        <div className="property-info-summary">
          {candidate.client_identifier && (
            <div className="identifier">
              #{candidate.client_identifier}
            </div>
          )}
          <div className="published-date">
            &nbsp;{Messages.t('dates.published')}:&nbsp;
            {LongDateTimeFormat(property.published_date)}
          </div>
          <PropertySummaryIcons candidate={candidate} />
        </div>
      </div>
      <PropertyPhotosCarousel candidate={candidate} />

      <div className="see-more-on-source">
        <PropertySourceUrl property={property} />
      </div>
      <div>
        {
          candidate.property.original_price !== candidate.property.price
          && candidate.property.original_price
          && (
            <div className="price-container">
              {candidate.property.original_price > candidate.property.price ? (
                <span>
                  {Messages.t('candidate.card.badge.priceDrop')} <TrendingDownIcon />
                </span>
              ) : (
                <span>
                  {Messages.t('candidate.card.badge.priceGrow')} <TrendingUpIcon />
                </span>
              )}
              <span className="property-new-price">
                {candidate.property.price} €
              </span>
              <span className="property-old-price">
                {candidate.property.original_price} €
              </span>
            </div>
          )
        }
        {
          candidate.property.available === false && (
            <div className="unavailable-property">
              {Messages.t('candidate.detail.linkExpired')}
            </div>
          )
        }
      </div>
      <div>
        <CandidateTabs candidate={candidate} />
      </div>
    </>
  );
}
