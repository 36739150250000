import React from 'react';
import { useParams } from 'react-router-dom';
import { useCandidates } from 'network/api/CandidateQueries';
import { CandidateResearch } from 'types/candidate';
import CandidateCard from 'features/hunter/common/CandidateCard';
import LoaderRing from 'theme/hunter-utils/LoaderRing';

type Param = {
  contactId: string,
};

export default function ContactProperties() {
  const { contactId } = useParams<Param>();
  const { getCandidatesWithContact } = useCandidates();
  const { data: candidates, isLoading } = getCandidatesWithContact(contactId);

  if (!candidates && isLoading) {
    return <LoaderRing visible />;
  }
  return (
    <>
      {
        candidates && candidates.results.map((candidate: CandidateResearch) => (
          <CandidateCard candidate={candidate} />
        ))
      }
    </>
  );
}
