import React, { useMemo } from 'react';
import { Candidate } from 'types/candidate';
import { Routes } from 'routes/Routes';
import { Link, useLocation } from 'react-router-dom';
import { CANDIDATE_QUERY_ID } from 'routes/ClientRoutes';

import {
  TemplateIcon,
  LocationMarkerIcon,
} from '@heroicons/react/solid';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Cloudinary } from 'cloudinary-core';
import CardActionButtons from 'features/client/common/CardActionButtons';
import { detectMobile } from 'services/utils';
import StringUtils from 'services/StringUtils';
import Messages from 'services/i18n/Messages';

type Props = {
  candidate: Candidate,
  onArchive: () => void
};

export default function MapCandidateCard({ candidate, onArchive }: Props) {
  const location = useLocation();
  const cloudinary = useMemo(
    () => new Cloudinary({
      cloud_name: 'settlesweet',
    }),
    [],
  );

  const firstImageUrl = useMemo(() => {
    if (candidate.property && candidate.property.images_url !== null) {
      const firstUrl = candidate.property.images_url[0] as any;
      if (firstUrl) {
        return firstUrl;
      }
    }
    const cloudinaryUrl = candidate.property.uploaded_images[0];
    if (cloudinaryUrl) {
      return cloudinary.url(cloudinaryUrl.image_id, {
        transformation: [{ width: 400, height: 400, crop: 'limit' }],
      });
    }
    return null;
  }, [candidate, cloudinary]);
  const isMobile = detectMobile();

  return (
    <Link
      className="map-candidate-card"
      to={Routes.updatePathWithQuery(
        location,
        [
          { label: CANDIDATE_QUERY_ID, value: candidate.id.toString() },
        ],
      )}
      target={!isMobile ? '_blank' : undefined}
    >
      <div className="image-container">
        <img alt="property" src={firstImageUrl} />
      </div>
      <div className="candidate-card-content">
        <div className="candidate-card-data">
          <div className="candidate-card-price">
            {candidate.property.price} &euro;<span className="price-month">{Messages.t('candidate.card.byMonth')}</span>
          </div>
          <div className="candidate-card-dimensions">
            <TemplateIcon />
            {candidate.property.rooms}
            <ArrowsExpandIcon />
            {candidate.property.area} m2
          </div>

          <div className="candidate-card-data-row">
            <div className="candidate-card-address">
              <LocationMarkerIcon />
              <span>{`${StringUtils.getPrettyAddress(candidate.property.city)}, ${candidate.property.postal_code}`}</span>
            </div>
          </div>
        </div>
        <div className="candidate-card-data actions-container">
          <CardActionButtons candidate={candidate} onArchive={onArchive} />
        </div>
      </div>
    </Link>
  );
}
