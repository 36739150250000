import React, { useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import { useCandidates } from 'network/api/CandidateQueries';
import ActivitySent from 'features/hunter/pages/research/researchDetails/candidateDetails/activities/ActivitySent';
import { Candidate } from 'types/candidate';
import Messages from 'services/i18n/Messages';

type Props = {
  candidate: Candidate,
  handleSend: () => void,
};

export function ActionSent(
  {
    candidate,
    handleSend,
  }: Props,
) {
  const candidatesQueries = useCandidates();
  const { isLoading, data } = candidatesQueries.nextClientIdentifier(
    candidate.research,
  );

  const candidateWithTempClientIdentifier = useMemo(() => {
    if (!isLoading && data) {
      return {
        ...candidate,
        client_identifier: data.next_client_identifier,
      };
    }
    return null;
  }, [isLoading, data, candidate]);

  return (
    <>
      {candidateWithTempClientIdentifier && (
        <ActivitySent candidate={candidateWithTempClientIdentifier} />
      )}
      <Button variant="primary" className="float-right" onClick={handleSend}>
        {Messages.t('formButton.confirm')}
      </Button>
    </>
  );
}
