import React,
{
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useState,
} from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import PhoneInput from 'react-phone-number-input';
import { TextField } from '@material-ui/core';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  label?: string
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
};

// @ts-ignore
export default function PhoneNumberInputWrapper(
  {
    control,
    label,
    error,
    apiErrors,
  }: Props,
) {
  const { field } = control;
  const [ForwardInputComponent] = useState<ForwardRefExoticComponent<RefAttributes<any>>>(
    forwardRef((props, ref) => {
      const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);
      return (
        <TextField
          {...props}
          error={!!errorMessage}
          helperText={errorMessage}
          fullWidth
          label={label || undefined}
          variant="outlined"
          inputRef={ref}
        />
      );
    }),
  );
  return (
    <PhoneInput
      {...field}
      country="FR"
      defaultCountry="FR"
      inputComponent={ForwardInputComponent}
    />
  );
}
