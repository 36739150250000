import React, { useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { useActivity } from 'network/api/ActivityQueries';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import { Activity } from 'types/Activity';
import SpinButton from 'theme/hunter-utils/SpinButton';
import { FetchError } from 'network/Errors';

type Props = {
  className: string,
  activity: Activity,
};

export default function DeleteActivityControl(
  {
    className,
    activity,
  }: Props,
) {
  const { deleteActivity } = useActivity();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    await deleteActivity.mutateAsync(activity, {
      onSuccess: () => {
        NotificationService.notifySuccess(Messages.t('toast.hunter.activityDeleted'));
      },
      onError: (error: FetchError) => {
        const { status } = error;
        if (status === 500) {
          NotificationService.notifyError(Messages.t('toast.hunter.error'));
        }
        setSubmitting(false);
      },
    });
  };

  return (
    <SpinButton
      editing
      className={className}
      variant="error"
      spin={submitting}
      onClick={handleSubmit}
      waitTitle=" "
      title={<BiTrash />}
    />
  );
}
