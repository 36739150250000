import React from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, UseFormTrigger } from 'react-hook-form';
import { PostSearch, PostSearchForm } from 'types/postSearch/PostSearch';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';

type Props = {
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  errors: FieldErrors
  trigger: UseFormTrigger<PostSearchForm>
  handleSubmit: (data: Partial<PostSearch>) => void,
  formField: PostSearchForm,
};

export default function UseFullInformationForm(
  {
    control,
    trigger,
    errors,
    apiErrors,
    formField,
    handleSubmit,
  }: Props,
) {
  return (
    <div>
      <div className="row-form">
        <Controller
          name="client_phone_number"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ client_phone_number: value })}
              label={Messages.t('form.field.client_phone_number')}
            />
          )}
        />
        <Controller
          name="previous_tenat_name"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ previous_tenat_name: value })}
              label={Messages.t('form.field.previous_tenat_name')}
            />
          )}
        />
      </div>
      <div className="row-form">
        <Controller
          name="pdl"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ pdl: value })}
              label={Messages.t('form.field.pdl')}
            />
          )}
        />
        <Controller
          name="linky_serial_number"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ linky_serial_number: value })}
              label={Messages.t('form.field.linky_serial_number')}
            />
          )}
        />
      </div>
      <div className="row-form">
        <Controller
          name="pce"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ pce: value })}
              label={Messages.t('form.field.pce')}
            />
          )}
        />
        <Controller
          name="gas_meter_serial_number"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ gas_meter_serial_number: value })}
              label={Messages.t('form.field.gas_meter_serial_number')}
            />
          )}
        />
      </div>
      <div className="row-form">
        <Controller
          name="building_access_codes"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ building_access_codes: value })}
              label={Messages.t('form.field.building_access_codes')}
            />
          )}
        />
        <Controller
          name="appartment_info"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ appartment_info: value })}
              label={Messages.t('form.field.appartment_info')}
            />
          )}
        />
      </div>
      <Controller
        name="janitor_in_building"
        control={control}
        render={(controller) => (
          <CheckboxWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            onChange={(value) => handleSubmit({ janitor_in_building: value })}
            label={Messages.t('form.field.janitor_in_building')}
          />
        )}
      />
      {
        formField.janitor_in_building && (
          <div className="row-form">
            <Controller
              name="janitor_name"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ janitor_name: value })}
                  label={Messages.t('form.field.janitor_name')}
                />
              )}
            />
            <Controller
              name="janitor_phone"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ janitor_phone: value })}
                  label={Messages.t('form.field.janitor_phone')}
                />
              )}
            />
          </div>
        )
      }
    </div>
  );
}
