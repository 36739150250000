import React, { ReactNode, useState } from 'react';
import NavBar from 'features/hunter/layout/NavBar';
import { detectTablette } from 'services/utils';
import { useUser } from 'reactfire';

type Props = {
  children: ReactNode
};

export default function Layout({ children }: Props) {
  const isTablette = detectTablette();
  const [showNavBar, setShowNavBar] = useState(!isTablette);
  const { data: user } = useUser();

  if (!user || !user.email) {
    return null;
  }
  return (
    <div id="hunter-container">
      <NavBar showNavBar={showNavBar} toogle={() => setShowNavBar((prevState) => !prevState)} />
      <div id="hunter-content">
        {children}
      </div>
    </div>
  );
}
