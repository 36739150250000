import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, useForm } from 'react-hook-form';
import {
  PostSearch,
} from 'types/postSearch/PostSearch';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { FinancialInformation } from 'types/postSearch/FinancialInformation';
import { useFinancialInformations } from 'network/api/FinancialQueries';
import { FetchError } from 'network/Errors';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import { Button, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import DocumentUtils from 'services/DocumentUtils';
import { NotificationService } from 'services/notification';

type Props = {
  financialInformation: FinancialInformation,
  postSearch: PostSearch,
};

export default function BankForm(
  {
    financialInformation,
    postSearch,
  }: Props,
) {
  const financialQueries = useFinancialInformations();
  const {
    updateFinancialInformation,
    deleteFinancialInformation,
    uploadFinancialFile,
  } = financialQueries;
  const [apiErrors, setApiError] = useState({});

  const {
    control,
    trigger,
    formState: { errors },
  } = useForm<FinancialInformation>({
    defaultValues: financialInformation,
  });

  const [submitting, setSubmitting] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,application/pdf',
    maxFiles: 1,
    disabled: submitting,
    onDrop: async (dropedFiles: File[]) => {
      const filesResult = await Promise.all(dropedFiles.map(async (file) => {
        const fileBase64 = await DocumentUtils.getBase64(file);
        return {
          filename: file.name,
          fileBase64,
          fileType: file.type,
        };
      }));
      setSubmitting(true);
      uploadFinancialFile.mutateAsync({
        postSearchId: postSearch.id,
        financialInformationId: financialInformation.id || '',
        field: 'file_url',
        data: filesResult[0],
      }).catch(() => NotificationService.notifyError(Messages.t('toast.hunter.error')))
        .finally(() => setSubmitting(false));
    },
  });

  const handleSubmit = async (data: Partial<FinancialInformation>) => {
    setApiError({});
    await updateFinancialInformation.mutateAsync({
      postSearchId: postSearch.id,
      financialInformationsId: financialInformation.id || '',
      data,
    }).catch((error: FetchError) => {
      setApiError(error.json_response);
    });
  };

  const deleteFinancial = async () => {
    setApiError({});
    await deleteFinancialInformation.mutateAsync({
      postSearchId: postSearch.id,
      financialInformationId: financialInformation.id || '',
    }).catch((error: FetchError) => {
      setApiError(error.json_response);
    });
  };

  return (
    <div className="bank-form-row">
      <div className="bank-form-body">
        <div className="row-form">
          <Controller
            name="iban"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                trigger={trigger}
                autoSaveSubmit={(value) => handleSubmit({ iban: value })}
                label={Messages.t('form.field.iban')}
              />
            )}
          />
          <Controller
            name="bic"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                trigger={trigger}
                autoSaveSubmit={(value) => handleSubmit({ bic: value })}
                label={Messages.t('form.field.bic')}
              />
            )}
          />
          <Controller
            name="bank_name"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                trigger={trigger}
                autoSaveSubmit={(value) => handleSubmit({ bank_name: value })}
                label={Messages.t('form.field.bank_name')}
              />
            )}
          />
        </div>
        <h5>{Messages.t('form.field.useAccountFor')}</h5>
        <Controller
          name="deposit_amount"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ deposit_amount: value })}
              label={Messages.t('form.field.deposit')}
            />
          )}
        />
        <Controller
          name="pro_rated_agent"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ pro_rated_agent: value })}
              label={Messages.t('form.field.pro_rated_agent')}
            />
          )}
        />
        <Controller
          name="agency_fees"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ agency_fees: value })}
              label={Messages.t('form.field.agency_fees')}
            />
          )}
        />
        <div className="file-upload-button-container">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button disabled={submitting}>
              {Messages.t('formButton.uploadRib')}
            </Button>
          </div>
          {
            financialInformation.file_url && (
              <div>
                <a
                  rel="noreferrer"
                  href={financialInformation.file_url}
                  target="_blank"
                >
                  {financialInformation.file_url.split('/').slice(-1)[0]}
                </a>
                <IconButton
                  onClick={() => handleSubmit({ file_url: '' })}
                >
                  <Delete />
                </IconButton>
              </div>
            )
          }
        </div>
      </div>
      <IconButton onClick={deleteFinancial}>
        <Delete />
      </IconButton>
    </div>
  );
}
