import Messages from 'services/i18n/Messages';
import { observable, WritableObservable } from 'micro-observables';

const LANGUAGE_KEY = 'currentLanguage';

class LocalService {
  private readonly local: WritableObservable<string> =
  observable('');

  private availableLocal: string[] = [];

  getLocal() {
    return this.local.readOnly();
  }

  setAvailableLocal(locals: string[]) {
    this.availableLocal = locals;
  }

  getAvailableLocal() {
    return this.availableLocal;
  }

  // eslint-disable-next-line class-methods-use-this
  getdefaultLocal(): string {
    let currentLocal = localStorage.getItem(LANGUAGE_KEY);
    if (!currentLocal) {
      currentLocal = document.documentElement.getAttribute('lang');
    }
    return currentLocal || 'en';
  }

  toogleLanguage(local: string) {
    let updateLocal = local;
    if (!this.availableLocal.includes(updateLocal)) {
      updateLocal = 'en';
    } else {
      localStorage.setItem(LANGUAGE_KEY, local);
    }
    Messages.updateTraductionWithLocal(updateLocal);
    if (document) {
      document.documentElement.setAttribute('lang', updateLocal);
    }
    this.local.set(updateLocal);
  }
}

const localService = new LocalService();
export default localService;
