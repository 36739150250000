import React from 'react';
import ClientPageModal from 'features/client/common/ClientPageModal';
import { useForm, Controller } from 'react-hook-form';
import { ClientFilter, publishedDateStatus } from 'types/ClientFilter';
import clientFilterService from 'services/client/ClientFilterService';
import RadioWrapper from 'lib/form/RadioWrapper';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import { NotificationService } from 'services/notification';
import FilterSelectorWrapper from 'lib/form/FilterSelectorWrapper';
import { Research } from 'types/research';
import Messages from 'services/i18n/Messages';
import { useZipCodeBackend } from 'network/api/ZipCodesQueries';

type Props = {
  research: Research
};

export default function Filter({ research }: Props) {
  const {
    control,
    watch,
    formState: { errors },
  } = useForm<ClientFilter>({
    defaultValues: {
      ...clientFilterService.getFilterValue(),
      onlyHmPicks: !research.marketplace_search,
    },
  });
  const formField = watch();
  clientFilterService.updateFilter(formField);
  const { getWithZipcode } = useZipCodeBackend();
  const { data } = getWithZipcode(research.zip_codes.join(','));
  return (
    <ClientPageModal className="filters" title={Messages.t('filter.title')} quitOnCliqueOut>
      <form>
        <h4>{Messages.t('filter.display')}</h4>
        <div className="filter-section">
          <Controller
            name="onlyHmPicks"
            control={control}
            render={(controller) => (
              <CheckboxWrapper
                control={controller}
                onClick={() => formField.onlyHmPicks && NotificationService.notifyWarning(Messages.t('filter.onlyHMPickToast'))}
                error={errors}
                label={Messages.t('filter.onlyHMPick')}
              />
            )}
          />
        </div>
        <h4>{Messages.t('filter.publishedDate')}</h4>
        <div className="filter-section">
          <Controller
            name="publishedDateStatus"
            control={control}
            render={(controller) => (
              <RadioWrapper
                control={controller}
                error={errors}
                row
                values={
                  Object.values(publishedDateStatus).map((key) => (
                    { key, label: Messages.t(`filters.publishedDate.${key}`) }
                  ))
                }
              />
            )}
          />
        </div>
        {
          research.price_max_search && research.price_max_search !== research.price_max && (
            <>
              <h4>{Messages.t('filter.price')}</h4>
              <div className="filter-section">
                <Controller
                  name="showAbovePrice"
                  control={control}
                  render={(controller) => (
                    <CheckboxWrapper
                      control={controller}
                      error={errors}
                      label={Messages.t('filter.showOptionAboveBudget')}
                    />
                  )}
                />
              </div>
            </>
          )
        }
        {
          research.bedrooms && (
            <>
              <h4>{Messages.t('filter.bedrooms')}</h4>
              <Controller
                name="bedrooms"
                control={control}
                render={(controller) => (
                  <FilterSelectorWrapper
                    control={controller}
                    values={
                      Array.from({ length: 4 }, (_, i) => i + (research.bedrooms || 0))
                        .map((number) => ({
                          key: number.toString(),
                          label: `${number} ${Messages.t('filter.bedrooms')}`,
                        }))
                      || []
                    }
                  />
                )}
              />
            </>
          )
        }
        <h4>{Messages.t('filter.cities')}</h4>
        {
          data && (
            <Controller
              name="excludedZipCode"
              control={control}
              render={(controller) => (
                <FilterSelectorWrapper
                  control={controller}
                  reverse
                  values={
                    research
                      .zip_codes.map((zipCode) => ({
                        key: zipCode,
                        label: data[zipCode]?.map((zips) => zips.city).join(', ') || zipCode,
                      }))
                    || []
                  }
                />
              )}
            />
          )
        }
      </form>
    </ClientPageModal>
  );
}
