import React, { ReactNode, useState } from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useProperty } from 'network/api/PropertyQueries';
import PropertyAttributeInput
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/PropertyAttributeInput';
import MatchedPropertyAttribute
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/MatchedPropertyAttribute';
import { Candidate } from 'types/candidate';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/hunter-utils/SpinButton';

type Props = {
  attribute: any,
  candidate: Candidate,
  picto?: ReactNode,
  plainText?: boolean,
};

export default function EditablePropertyAttributePopover(
  {
    attribute,
    candidate,
    picto,
    plainText,
  }: Props,
) {
  const { property } = candidate;
  const { updateProperty } = useProperty();
  const [submitting, setSubmitting] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const [formValue, setFormValue] = useState(() => attribute
    .getPropertyValue(property));

  const formValid = attribute.isValid(formValue);

  const closePopover = () => {
    setPopoverVisible(false);
  };

  const handleSubmit = async () => {
    if (!formValid === null) {
      throw Error('Can\'t submit invalid form');
    }

    setSubmitting(true);
    await updateProperty.mutateAsync({
      propertyId: candidate.property.id,
      data: { [attribute.property_attr]: attribute.fromFormValue(formValue) },
      candidate,
    }, {
      onSuccess: () => {
        NotificationService.notifySuccess(Messages.t('toast.hunter.propertyUpdated'));
      },
    });
    setSubmitting(false);
    closePopover();
  };

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      show={popoverVisible}
      onToggle={(show) => {
        setPopoverVisible(show);
      }}
      overlay={(
        <Popover id={`popover-${attribute.name}`}>
          <Popover.Title className="d-flex justify-content-between">
            <h5>{Messages.t('property.modification')}</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={closePopover}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Popover.Title>
          <Popover.Content className="d-flex flex-column">
            <PropertyAttributeInput
              attribute={attribute}
              formValue={formValue}
              onChange={setFormValue}
            />
            <SpinButton
              editing
              className="ml-auto"
              disabled={!formValid}
              spin={submitting}
              onClick={handleSubmit}
            />
          </Popover.Content>
        </Popover>
      )}
    >
      {({ ref, ...triggerHandler }) => (
        <div ref={ref} {...triggerHandler} className="cursor-pointer">
          <MatchedPropertyAttribute
            attribute={attribute}
            picto={picto}
            property={candidate.property}
            researchId={candidate.research}
            plainText={plainText}
          />
        </div>
      )}
    </OverlayTrigger>
  );
}
