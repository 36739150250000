import React, { useMemo } from 'react';
import { Candidate } from 'types/candidate';
import { Routes } from 'routes/Routes';
import { Link, useLocation } from 'react-router-dom';
import { CANDIDATE_QUERY_ID } from 'routes/ClientRoutes';

import {
  TemplateIcon,
} from '@heroicons/react/solid';
import {
  ArrowsExpandIcon,
  LocationMarkerIcon,
  ClockIcon,
} from '@heroicons/react/outline';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Cloudinary } from 'cloudinary-core';
import DateUtils from 'services/DateUtils';
import { useViewingAppointment } from 'network/api/CandidateQueries';
import { format } from 'date-fns';
import { detectMobile } from 'services/utils';
import StringUtils from 'services/StringUtils';
import Messages from 'services/i18n/Messages';
import { showNotationDependingWorkflowStep } from 'services/hunter/WorkflowStep';
import { getCurrentNotation } from 'types/forms/CommentForm';

type Props = {
  candidate: Candidate,
  showViewing?: boolean
};

const getPrettyDate = (dateStr: string) => {
  const date = new Date(dateStr);
  return format(date, 'dd/MM/yyyy - HH:mm');
};

export default function TrackCandidateCard({ candidate, showViewing }: Props) {
  const location = useLocation();
  const cloudinary = useMemo(
    () => new Cloudinary({
      cloud_name: 'settlesweet',
    }),
    [],
  );
  const viewingQueries = useViewingAppointment();

  const { data: viewingAppointement } = viewingQueries.getViewingAppointment(
    candidate.id, !!showViewing,
  );

  const firstImageUrl = useMemo(() => {
    if (candidate.property && candidate.property.images_url !== null) {
      const firstUrl = candidate.property.images_url[0] as any;
      if (firstUrl) {
        return firstUrl;
      }
    }
    const cloudinaryUrl = candidate.property.uploaded_images[0];
    if (cloudinaryUrl) {
      return cloudinary.url(cloudinaryUrl.image_id, {
        transformation: [{ width: 400, height: 400, crop: 'limit' }],
      });
    }
    return null;
  }, [candidate, cloudinary]);

  const displayViewing = viewingAppointement && viewingAppointement.length > 0 && showViewing;
  const isMobile = detectMobile();

  const currentNotationObject = getCurrentNotation(candidate.client_notation?.toString());

  return (
    <Link
      className="track-candidate-card"
      to={Routes.updatePathWithQuery(
        location,
        [
          { label: CANDIDATE_QUERY_ID, value: candidate.id.toString() },
        ],
      )}
      target={!isMobile ? '_blank' : undefined}
    >
      <div className="image-container">
        <img alt="property" src={firstImageUrl} />
        <div className="image-overlay">
          <div className="badge-container">
            {candidate.client_identifier && (
              <div>
                <div className="image-badge number">
                  #{candidate.client_identifier}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="candidate-card-content">
        {showNotationDependingWorkflowStep(candidate) && currentNotationObject?.emoji && (
          <div className="client-notation-wrapper">
            <div className="emoji">
              {currentNotationObject.emoji}
            </div>
          </div>
        )}
        <div className="candidate-card-data">
          <div className="candidate-card-price">
            {candidate.property.price} &euro;<span className="price-month">/month</span>
          </div>
          <div className="publised-date">
            {`${Messages.t('dates.published')} ${DateUtils.getPrettyDateDeltaFromNow(candidate.property.published_date)}`}
          </div>
          <div className="candidate-card-dimensions">
            <TemplateIcon />
            {candidate.property.rooms}
            <ArrowsExpandIcon />
            {candidate.property.area} m2
          </div>
          {
            !displayViewing && (
              <div className="candidate-card-data-row">
                <div className="candidate-card-address">
                  <div>
                    <LocationMarkerIcon />
                  </div>
                  <span>
                    {
                      candidate.property.address
                        ? candidate.property.address
                        : `${StringUtils.getPrettyAddress(candidate.property.city)}, ${candidate.property.postal_code}`
                    }
                  </span>
                </div>
              </div>
            )
          }
          {
            viewingAppointement && displayViewing && (
              <div className="candidate-card-data-row">
                <div className="candidate-card-metting-address">
                  <LocationMarkerIcon />
                  <div
                    className="metting-address"
                  >
                    {viewingAppointement[0]?.address || candidate.property.address}
                  </div>
                </div>
                <div className="text-icon">
                  <ClockIcon />
                  <div>{getPrettyDate(viewingAppointement[0].date)}</div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </Link>
  );
}
