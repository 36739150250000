import { useEffect, useState } from 'react';
import { allMaxTravelTime } from 'services/isochrone/TravelOptions';
import services from 'services/isochrone/services';
import { EMPTY_GEOJSON } from 'services/isochrone/geometry/geojson_helpers';
import { MapSource, MapLayer } from 'services/isochrone/MapSourceAndLayer';
import LoaderRing, { displayLoader } from 'theme/hunter-utils/LoaderRing';

export default function IsochroneLayer(
  {
    map, // must not be null
    index,
    isochone_paint,
    travel_options,
    location,
    onIsochroneChanged,
  },
) {
  const { duration } = travel_options.max_travel_time;
  const { travel_mode } = travel_options;
  const { geocoded_coordinates } = location;
  const { address } = location;

  const map_source = MapSource.ISOCHRONE(index);
  const map_layer = MapLayer.ISOCHRONE(index);

  // ########### initialization
  useEffect(() => {
    map.addSource(map_source, {
      type: 'geojson',
      data: EMPTY_GEOJSON,
    });

    map.addLayer({
      id: map_layer,
      type: 'fill',
      source: map_source,
      paint: isochone_paint,
    });

    // Cannot read property 'removeLayer' of undefined ???
    return () => {
      map.removeLayer(map_layer);
      map.removeSource(map_source);
    };
    // eslint-disable-next-line
  }, [map]);

  // ########### update
  useEffect(() => {
    map.setFilter(map_layer, ['==', 'time', duration]);
  }, [map, map_layer, duration]);

  const [loading, setLoading] = useState(0);

  useEffect(() => {
    const load_isochrone = (function () {
      if (geocoded_coordinates === null) {
        return Promise.resolve(null);
      }

      const travel_times = allMaxTravelTime.map((t) => t.duration);
      return services.isochrone.isochrone_geojson({
        arrival_point: geocoded_coordinates,
        travel_mode,
        travel_times,
        address,
      });
    }())
      .then((isochrone_geojson) => (isochrone_geojson === null
        ? Promise.resolve(null)
        : services.geojson_operation.simplifyGeojson(isochrone_geojson)))
      .then((isochrone_geojson) => {
        console.log(isochrone_geojson);
        map
          .getSource(map_source)
          .setData(
            isochrone_geojson == null ? EMPTY_GEOJSON : isochrone_geojson,
          );
        onIsochroneChanged(index, isochrone_geojson);
      });

    displayLoader(setLoading, load_isochrone);
  }, [
    map,
    geocoded_coordinates,
    map_source,
    travel_mode,
    address,
    index,
    onIsochroneChanged,
  ]);

  return <LoaderRing visible={!!loading} />;
}
