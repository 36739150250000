import { Enum } from 'lib/type/enum';

export type PapernestStatusType =
  'CLIENT_CREATED'
  | 'MEETING_DONE'
  | 'UTILITIES_SUSCRIBED';

export const papernestStatusTypeEnum: Enum<PapernestStatusType> = {
  CLIENT_CREATED: 'CLIENT_CREATED',
  MEETING_DONE: 'MEETING_DONE',
  UTILITIES_SUSCRIBED: 'UTILITIES_SUSCRIBED',
};
