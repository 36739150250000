import { Enum } from 'lib/type/enum';

export type GasStatusType =
  'PROVIDER_SELECTED'
  | 'ACCOUNT_CREATED'
  | 'ACTIVATED';

export const gasStatusTypeEnum: Enum<GasStatusType> = {
  PROVIDER_SELECTED: 'PROVIDER_SELECTED',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
  ACTIVATED: 'ACTIVATED',
};
