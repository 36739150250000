export interface ViewingAppointment{
  address: string
  candidate: number
  created_at: string
  date: string
  different_address_for_viewing: false
  ground_agent_email: string
  ground_agent_first_name: string
  ground_agent_last_name: string
  ground_agent_phone_number: string
  id: number
  remark: string
  remoteComments: string
  updated_at: string
  viewing_type: string
  selected_contact: number
  remote_type?: RemoteType
}

export type ViewingType = 'CLASSIC' | 'ACCOMPANIED' | 'REMOTE';

type ViewingTypeEnum = {
  [key in ViewingType]: ViewingType;
};

export const viewingType: ViewingTypeEnum = {
  CLASSIC: 'CLASSIC',
  ACCOMPANIED: 'ACCOMPANIED',
  REMOTE: 'REMOTE',
};

export type RemoteType = 'UBIQUITY' | 'GROUND_AGENT';

type RemoteTypeEnum = {
  [key in RemoteType]: RemoteType;
};

export const remoteType: RemoteTypeEnum = {
  UBIQUITY: 'UBIQUITY',
  GROUND_AGENT: 'GROUND_AGENT',
};
