import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import services from 'services/isochrone/services';
import { MapSource, MapLayer } from 'services/isochrone/MapSourceAndLayer';
import { district_selection_toggle } from 'services/isochrone/District';
import { buildDistrictSelection } from 'features/isochroneMap/search_params';

export default function DistrictLayer(
  {
    map,
    availableDistricts,
    setAvailableDistricts,
    districtsSelection,
    setDistrictsSelection,
  },
) {
  useEffect(() => {
    // pageload: fetch available districts
    services.district.districts_geojson().then((geojson) => {
      setAvailableDistricts(services.district.available_districts(geojson));
      map.getSource(MapSource.QUARTIER_PARIS).setData(geojson);
    });
  }, [map, setAvailableDistricts]);

  const location = useLocation();
  useEffect(() => {
    if (availableDistricts !== null && districtsSelection === null) {
      // pageload: When availableDistricts are available, load districtsSelection from url
      const new_district_selection = buildDistrictSelection(
        location.search,
        availableDistricts,
      );
      setDistrictsSelection(new_district_selection);

      // select district in mapbox
      new_district_selection.forEach((district) => selectDistrictOnMap(map, district, true));
    }
  }, [
    map,
    availableDistricts,
    districtsSelection,
    setDistrictsSelection,
    location.search,
  ]);

  // #################################
  // district interactions

  useEffect(() => {
    map.addSource(MapSource.QUARTIER_PARIS, {
      type: 'geojson',
      data: null,
    });

    services.district.districts_geojson().then((geojson) => {
      map.getSource(MapSource.QUARTIER_PARIS).setData(geojson);
    });

    map.addLayer({
      id: MapLayer.QUARTIER_PARIS,
      type: 'fill',
      source: MapSource.QUARTIER_PARIS,
      paint: {
        'fill-color': [
          'case',
          ['boolean', ['feature-state', 'selected'], false],
          'rgba(250, 130, 130, 0.8)',
          'rgba(0, 0, 0, 0.0)',
        ],
        // "fill-outline-color": "rgba(40, 40, 100, 1.0)",
      },
    });

    map.addLayer({
      id: MapLayer.QUARTIER_PARIS_HIGHLIGHT,
      type: 'line',
      source: MapSource.QUARTIER_PARIS,
      paint: {
        'line-width': 3,
        'line-color': 'rgba(100, 149, 237, 0.8)',
      },
      filter: quartier_paris_highlight_filter(''),
    });

    // #################################
    // district popup and highlight

    const popup = new mapboxgl.Popup({
      closeButton: false,
    });

    map.on('mousemove', MapLayer.QUARTIER_PARIS, (e) => {
      map.getCanvas().style.cursor = 'pointer';
      const feature = e.features[0];

      map.setFilter(
        MapLayer.QUARTIER_PARIS_HIGHLIGHT,
        quartier_paris_highlight_filter(feature.properties.c_qu),
      );
      const suffix = feature.properties.c_ar === 1 ? 'er' : 'ème';
      const text = `${feature.properties.l_qu} (${feature.properties.c_ar}${suffix})`;
      popup.setLngLat(e.lngLat).setText(text).addTo(map);
    });

    map.on('mouseleave', MapLayer.QUARTIER_PARIS, () => {
      map.getCanvas().style.cursor = '';
      map.setFilter(
        MapLayer.QUARTIER_PARIS_HIGHLIGHT,
        quartier_paris_highlight_filter(''),
      );
      popup.remove();
    });
  }, [map]);

  // #################################
  // district interactions
  useEffect(() => {
    // wait for availableDistricts
    if (!availableDistricts) {
      return;
    }

    map.on('click', MapLayer.QUARTIER_PARIS, (e) => {
      const feature = e.features[0];

      // new mapboxgl.Popup()
      //   .setLngLat(e.lngLat)
      //   .setHTML(`${feature.properties.l_qu} `)
      //   .addTo(map);
      // c_ar: 12
      // c_qu: 48
      // c_quinsee: 7511204
      // l_qu: "Quinze-Vingts";
      setDistrictsSelection((districtsSelection) => {
        const district = availableDistricts.find(
          (district) => district.id === feature.id,
        );
        if (!district) {
          throw Error('Unknown district');
        }

        // toggle selection
        const [new_selection, added] = district_selection_toggle(
          districtsSelection,
          district,
        );

        selectDistrictOnMap(map, district, added);

        return new_selection;
      });
    });
  }, [map, availableDistricts, setDistrictsSelection]);

  return <></>;
}

function quartier_paris_highlight_filter(quartier_id) {
  return ['==', 'c_qu', quartier_id];
}

function selectDistrictOnMap(map, district, selected) {
  map.setFeatureState(
    {
      source: MapSource.QUARTIER_PARIS,
      id: district.id,
    },
    {
      selected,
    },
  );
}
