import React, { useState, useEffect } from 'react';
import { useOpinion } from 'network/api/CandidateQueries';
import { Candidate } from 'types/candidate';
import { OpinionType, opinionType } from 'types/Opinion';
import { TextField } from '@material-ui/core';
import OpinionForm from 'features/hunter/pages/research/researchDetails/workflowStepTransition/forms/OpinionForm';
import { useParams } from 'react-router-dom';
import { useResearch } from 'network/api/ResearchQueries';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/hunter-utils/SpinButton';
import { FetchError } from 'network/Errors';

type Props = {
  candidate: Candidate,
  setDirty?: (val: boolean) => void;
};

type Param = {
  researchId: string,
};

// TODO some refacto
export default function ActivityShortlistForm(
  {
    candidate,
    setDirty,
  }: Props,
) {
  const { researchId } = useParams<Param>();
  const researchQueries = useResearch();
  const { data: research } = researchQueries.getResearch(researchId);

  const initialState = '';
  const [pro, setPro] = useState(initialState);
  const [cons, setCons] = useState(initialState);
  const [other, setOhter] = useState(initialState);
  const [submitting, setSubmitting] = useState(false);
  const { getOpinion, createOpinion } = useOpinion();
  const { data: opinions, refetch } = getOpinion(candidate.id);

  useEffect(() => {
    const fields = [pro, cons, other];
    if (setDirty && fields.some((field) => field !== initialState)) {
      setDirty(true);
    }
  }, [setDirty, pro, cons, other]);

  const handleCreateOpinion = (text: string, typeOpinion: OpinionType) => {
    setSubmitting(true);
    createOpinion.mutate(
      {
        candidateId: candidate.id,
        data: { text, opinion_type: typeOpinion, candidate: candidate.id },
      },
      {
        onSuccess: () => {
          NotificationService.notifySuccess(Messages.t('toast.hunter.opinionAdd'));
          setSubmitting(false);
          setPro('');
          setCons('');
          setOhter('');
        },
        onError: (error: FetchError) => {
          const { status } = error;
          if (status === 500) {
            NotificationService.notifyError(Messages.t('toast.hunter.error'));
          }
          setSubmitting(false);
        },
      },
    );
  };

  const handleKeypress = (e, func) => {
    if (e.charCode === 13) {
      func();
    }
  };

  return (
    <div className="opinion-form-container">
      <div>
        <h5>{Messages.t('opinion.pros')}</h5>
        <div className="opinion-tag-container">
          {
            opinions && opinions
              .filter((opinion) => opinion.opinion_type === opinionType.PRO)
              .sort((a, b) => a.created_at.localeCompare(b.created_at))
              .map((opinion) => (
                <OpinionForm refetch={refetch} opinion={opinion} />
              ))

          }
        </div>
        <div className="opinion-form">
          <TextField
            value={pro}
            fullWidth
            variant="standard"
            type="text"
            onKeyPress={(e) => handleKeypress(e, () => handleCreateOpinion(pro, opinionType.PRO))}
            label={Messages.t('opinion.pros')}
            placeholder={Messages.t('opinion.prosPlaceholder')}
            onChange={(e) => setPro(e.target.value)}
          />
          <SpinButton
            editing
            onClick={() => handleCreateOpinion(pro, opinionType.PRO)}
            spin={submitting}
            variant="primary"
            className="mb-2"
            title={Messages.t('formButton.add')}
          />
        </div>
      </div>
      <div>
        <h5>{Messages.t('opinion.cons')}</h5>
        {
          opinions && opinions
            .filter((opinion) => opinion.opinion_type === opinionType.CONS)
            .sort((a, b) => a.created_at.localeCompare(b.created_at))
            .map((opinion) => (
              <OpinionForm refetch={refetch} opinion={opinion} />
            ))

        }
        {
          research
          && research.price_max_search
          && candidate.property.price > research.price_max_search && (
            <div className="opinion-tag over-budget">
              <span>
                {Messages.t('opinion.overBudget')}
              </span>
            </div>
          )
        }
        <div className="opinion-form">
          <TextField
            value={cons}
            fullWidth
            variant="standard"
            type="text"
            label={Messages.t('opinion.cons')}
            onKeyPress={(e) => handleKeypress(e, () => handleCreateOpinion(cons, opinionType.CONS))}
            placeholder={Messages.t('opinion.consPlaceholder')}
            onChange={(e) => setCons(e.target.value)}
          />
          <SpinButton
            editing
            onClick={() => handleCreateOpinion(cons, opinionType.CONS)}
            spin={submitting}
            variant="primary"
            className="mb-2"
            title={Messages.t('formButton.add')}
          />
        </div>
      </div>
      <div>
        <h5>{Messages.t('opinion.other')}</h5>
        {
          opinions && opinions
            .filter((opinion) => opinion.opinion_type === opinionType.OTHER)
            .sort((a, b) => a.created_at.localeCompare(b.created_at))
            .map((opinion) => (
              <OpinionForm refetch={refetch} opinion={opinion} />
            ))
        }
        <div className="opinion-form">
          <TextField
            value={other}
            fullWidth
            variant="standard"
            type="text"
            onKeyPress={(e) => handleKeypress(
              e,
              () => handleCreateOpinion(other, opinionType.OTHER),
            )}
            label={Messages.t('opinion.other')}
            placeholder={Messages.t('opinion.otherPlaceholder')}
            onChange={(e) => setOhter(e.target.value)}
          />
          <SpinButton
            editing
            onClick={() => handleCreateOpinion(other, opinionType.OTHER)}
            spin={submitting}
            variant="primary"
            className="mb-2"
            title={Messages.t('formButton.add')}
          />
        </div>
      </div>
    </div>
  );
}
