import React from 'react';
import { Candidate } from 'types/candidate';
import AddNoteForm from 'features/hunter/pages/research/researchDetails/candidateDetails/notes/AddNoteForm';
import Messages from 'services/i18n/Messages';
import NoteCard from 'features/hunter/pages/research/researchDetails/candidateDetails/notes/NoteCard';
import { useUser } from 'reactfire';
import { Note } from 'types/Note';

type Props = {
  candidate: Candidate,
  notes?: Note[],
};

export default function NotesTimeline({ candidate, notes }: Props) {
  const { data: user } = useUser();

  return (
    <div className="note-tab-container">
      <h4>{Messages.t('note.note')}</h4>
      <hr />
      <div className="note-list">
        {
          notes
          && notes.length > 0
          && user.email && notes?.sort((a, b) => b.updated_at.localeCompare(a.updated_at))
            .map((note) => (
              <NoteCard note={note} key={note.id} hmEmail={user.email} />
            ))
        }
      </div>
      {user.email && (
        <AddNoteForm propertyId={candidate.property.id} hmEmail={user.email} />
      )}
    </div>
  );
}
