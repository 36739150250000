import { Enum } from 'lib/type/enum';

export interface FinancialInformation {
  iban?: string,
  bic?: string,
  bank_name?: string,
  file_url?: string,
  kind: FinancialInformationKind,
  deposit_amount?: boolean,
  pro_rated_agent?: boolean,
  agency_fees?: boolean,
  post_search: string,
  id?: string,
}

export type FinancialInformationKind = 'RIB' | 'PROOF_OF_PAYMENT';

export const financialInformationKindEnum: Enum<FinancialInformationKind> = {
  RIB: 'RIB',
  PROOF_OF_PAYMENT: 'PROOF_OF_PAYMENT',
};
