import React, { ReactNode, useState } from 'react';
import ActivityShortlistForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/activities/ActivityShortlistForm';
import ActivitySimpleCommentForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/activities/ActivitySimpleCommentForm';
import ActionConfirm from 'features/hunter/pages/research/researchDetails/workflowStepTransition/ActionConfirm';
import { ActionSent } from 'features/hunter/pages/research/researchDetails/workflowStepTransition/ActionSent';
import ViewingBookFormWrapper
  from 'features/hunter/pages/research/researchDetails/workflowStepTransition/forms/ViewingBookFormWrapper';
import { WorkflowStep, workflowStep } from 'types/WorkflowStep';
import { workflowStepMetaData } from 'services/hunter/WorkflowStep';
import { Candidate } from 'types/candidate';
import Button from 'react-bootstrap/Button';
import SignedTransitionForm
  from 'features/hunter/pages/research/researchDetails/workflowStepTransition/forms/SignedTransitionForm';
import Messages from 'services/i18n/Messages';
import { useHistory, useLocation } from 'react-router-dom';
import ReadyToSignTransitionForm
  from 'features/hunter/pages/research/researchDetails/workflowStepTransition/forms/ReadyToSignTransitionForm';
import ConfirmationModal from 'features/hunter/common/ConfirmationModal';

type Props = {
  editingActivity: WorkflowStep | null,
  candidate: Candidate,
  setCandidateStep: (callBackData: any) => void,
  candidateIds: string[],
  researchId: string,
};

export default function WorkflowStepTransitionPanel(
  {
    editingActivity,
    candidate,
    setCandidateStep,
    candidateIds,
    researchId,
  }: Props,
) {
  const [dirty, setDirty] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const handleCloseEditing = () => {
    history.push(location.pathname);
  };

  if (candidateIds && candidateIds.length > 1) {
    return (
      <TransitionPanel
        title={workflowStepMetaData(editingActivity).getDisplayName()}
        onClose={handleCloseEditing}
      >
        <ActionConfirm
          handleConfirm={async () => {
            await setCandidateStep({
              workflow_step: editingActivity,
              workflow_step_changed_date: new Date(),
            });
          }}
          onCancelClick={handleCloseEditing}
          toStatusTransition={workflowStepMetaData(editingActivity).getDisplayName() || ''}
        />
      </TransitionPanel>
    );
  }
  return (
    <>
      {editingActivity === workflowStep.SHORTLIST && (
        <TransitionPanel
          title={workflowStepMetaData(workflowStep.SHORTLIST).getDisplayName()}
          onClose={handleCloseEditing}
          dirty={dirty}
        >
          <ActivityShortlistForm
            candidate={candidate}
            setDirty={setDirty}
          />
          <Button
            variant="primary"
            onClick={async () => {
              await setCandidateStep({
                workflow_step: workflowStep.SHORTLIST,
                workflow_step_changed_date: new Date(),
              });
            }}
          >
            {Messages.t('formButton.confirm')}
          </Button>
        </TransitionPanel>
      )}

      {editingActivity === workflowStep.MATCH && (
        <TransitionPanel
          title={workflowStepMetaData(workflowStep.MATCH).getDisplayName()}
          onClose={handleCloseEditing}
          dirty={dirty}
        >
          <ActivitySimpleCommentForm
            setDirty={setDirty}
            defaultValue={candidate.match_comment}
            handleCommentWorkflowUpdate={async (comment) => {
              await setCandidateStep({
                message_content: comment,
                match_comment: comment,
                workflow_step: workflowStep.MATCH,
                workflow_step_changed_date: new Date(),
              });
            }}
          />
        </TransitionPanel>
      )}

      {editingActivity === workflowStep.NOT_SURE && (
        <TransitionPanel
          title={workflowStepMetaData(workflowStep.NOT_SURE).getDisplayName()}
          onClose={handleCloseEditing}
          dirty={dirty}
        >
          <ActivitySimpleCommentForm
            setDirty={setDirty}
            handleCommentWorkflowUpdate={async (comment) => {
              await setCandidateStep({
                message_content: comment,
                workflow_step: workflowStep.NOT_SURE,
                workflow_step_changed_date: new Date(),
              });
            }}
          />
        </TransitionPanel>
      )}

      {editingActivity === workflowStep.SENT && (
        <TransitionPanel
          title={Messages.t('SENT.transitionTitle')}
          onClose={handleCloseEditing}
        >
          <ActionSent
            candidate={candidate}
            handleSend={async () => {
              await setCandidateStep({
                workflow_step: workflowStep.SENT,
                workflow_step_changed_date: new Date(),
              });
            }}
          />
        </TransitionPanel>
      )}

      {editingActivity === workflowStep.BOOKED && (
        <TransitionPanel
          title={workflowStepMetaData(workflowStep.BOOKED).getDisplayName()}
          onClose={handleCloseEditing}
          dirty={dirty}
        >
          <ViewingBookFormWrapper
            candidate={candidate}
            setDirty={setDirty}
            formSubmited={async () => {
              await setCandidateStep({
                workflow_step: workflowStep.BOOKED,
                workflow_step_changed_date: new Date(),
              });
            }}
          />
        </TransitionPanel>
      )}
      {editingActivity === workflowStep.AGREEMENT && (
        <TransitionPanel
          title={workflowStepMetaData(workflowStep.AGREEMENT).getDisplayName()}
          onClose={handleCloseEditing}
          dirty={dirty}
        >
          <ActivitySimpleCommentForm
            setDirty={setDirty}
            handleCommentWorkflowUpdate={async (comment) => {
              await setCandidateStep({
                match_comment: comment,
                message_content: comment,
                workflow_step: workflowStep.AGREEMENT,
                workflow_step_changed_date: new Date(),
              });
            }}
          />
        </TransitionPanel>
      )}
      {editingActivity === workflowStep.READY_TO_SIGN && (
        <TransitionPanel
          title={workflowStepMetaData(workflowStep.READY_TO_SIGN).getDisplayName()}
          onClose={handleCloseEditing}
          dirty={dirty}
        >
          <ReadyToSignTransitionForm
            setDirty={setDirty}
            onSubmited={async (datas) => {
              await setCandidateStep({
                ...datas,
                workflow_step: workflowStep.READY_TO_SIGN,
                workflow_step_changed_date: new Date(),
              });
            }}
            researchId={researchId}
            candidate={candidate}
          />
        </TransitionPanel>
      )}

      {editingActivity === workflowStep.SIGNED && (
        <TransitionPanel
          title={workflowStepMetaData(workflowStep.SIGNED).getDisplayName()}
          onClose={handleCloseEditing}
          dirty={dirty}
        >
          <SignedTransitionForm
            setDirty={setDirty}
            onSubmited={async (datas) => {
              await setCandidateStep({
                ...datas,
                workflow_step: workflowStep.SIGNED,
                workflow_step_changed_date: new Date(),
              });
            }}
            candidate={candidate}
          />
        </TransitionPanel>
      )}

      {editingActivity === workflowStep.DISCARDED && (
        <TransitionPanel
          title={workflowStepMetaData(workflowStep.DISCARDED).getDisplayName()}
          onClose={handleCloseEditing}
          dirty={dirty}
        >
          <ActivitySimpleCommentForm
            setDirty={setDirty}
            defaultValue={candidate.discarded_comment}
            handleCommentWorkflowUpdate={async (comment) => {
              await setCandidateStep({
                match_comment: comment,
                message_content: comment,
                workflow_step: workflowStep.DISCARDED,
                workflow_step_changed_date: new Date(),
              });
            }}
          />
        </TransitionPanel>
      )}

      {editingActivity === workflowStep.TRASH && (
        <TransitionPanel
          dirty={dirty}
          title={workflowStepMetaData(workflowStep.TRASH).getDisplayName()}
          onClose={handleCloseEditing}
        >
          <ActivitySimpleCommentForm
            setDirty={setDirty}
            handleCommentWorkflowUpdate={async (comment) => {
              await setCandidateStep({
                message_content: comment,
                workflow_step: workflowStep.TRASH,
                workflow_step_changed_date: new Date(),
              });
            }}
          />
        </TransitionPanel>
      )}

      {editingActivity === workflowStep.NOT_INTERESTED && (
        <TransitionPanel
          dirty={dirty}
          title={workflowStepMetaData(workflowStep.NOT_INTERESTED).getDisplayName()}
          onClose={handleCloseEditing}
        >
          <ActivitySimpleCommentForm
            setDirty={setDirty}
            handleCommentWorkflowUpdate={async (comment) => {
              await setCandidateStep({
                message_content: comment,
                workflow_step: workflowStep.NOT_INTERESTED,
                workflow_step_changed_date: new Date(),
              });
            }}
          />
        </TransitionPanel>
      )}
    </>
  );
}

type PanelProps = {
  title: string | null,
  onClose: () => void,
  children: ReactNode,
  dirty?: boolean,
};

function TransitionPanel(
  {
    title,
    onClose,
    children,
    dirty,
  }: PanelProps,
) {
  const [confirmationModal, setConfirmationModal] = useState(false);
  return (
    <div className="transition-panel-container">
      <ConfirmationModal
        isOpen={confirmationModal && (dirty || false)}
        onClose={() => setConfirmationModal(false)}
        onConfirm={() => {
          setConfirmationModal(false);
          onClose();
        }}
        title={Messages.t('search.warning')}
        text={Messages.t('search.loseChange')}
      />
      <div className="transition-header">
        <button
          onClick={() => {
            if (dirty) {
              setConfirmationModal(true);
            } else {
              onClose();
            }
          }}
          type="button"
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="flex items-start justify-between">
          <h2
            id="slide-over-heading"
            className="text-lg font-medium text-black"
          >
            {title}
          </h2>
          <hr />
        </div>
      </div>
      <div className="transition-content">{children}</div>
    </div>
  );
}
