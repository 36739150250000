export const TravelMode = {
  WALK: { name: 'WALK', displayName: 'Walk', targomo: 'walk' },
  BIKE: { name: 'BIKE', displayName: 'Bike', targomo: 'bike' },
  CAR: { name: 'CAR', displayName: 'Car', targomo: 'car' },
  TRANSIT: { name: 'TRANSIT', displayName: 'Transit', targomo: 'transit' },
};

// Targomo user account limitation:
// User with key '...' is not authorized for the requested Targomo service:
// max travel time (2400) too high, allowed: 1800
export const MaxTravelTime = {
  SHORT: { name: 'SHORT', order: 0, duration: 10 * 60 },
  MEDIUM: { name: 'MEDIUM', order: 1, duration: 20 * 60 },
  LONG: { name: 'LONG', order: 2, duration: 30 * 60 },
  //  LONG: { name: "LONG", order: 2, duration: 70 * 60 },
  //  VERY_LONG: { name: "VERY_LONG", order: 3, duration: 120 * 60 },
};

export const allMaxTravelTime = Object.values(MaxTravelTime);

export const defaultTravelOptions = {
  travel_mode: TravelMode.TRANSIT,
  max_travel_time: MaxTravelTime.MEDIUM,
};
