import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/hunter-utils/SpinButton';
import { useNote } from 'network/api/PropertyQueries';
import { Note } from 'types/Note';

type Props = {
  note: Note
  onClose: () => void
};

export default function EditNoteForm({ note, onClose }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [newNote, setNewNote] = useState(note.note_content);
  const noteQueries = useNote();
  const { updateNote } = noteQueries;

  const handleSubmit = async () => {
    setSubmitting(true);
    await updateNote.mutateAsync({
      noteId: note.id,
      data: {
        note_content: newNote,
      },
    });
    onClose();
    setSubmitting(false);
  };

  return (
    <div className="privatenote-form-container edit-form">
      <TextField
        variant="outlined"
        multiline
        fullWidth
        label=""
        onChange={(e) => setNewNote(e.target.value)}
        value={newNote}
      />
      <SpinButton
        spin={submitting}
        title={Messages.t('formButton.edit')}
        editing={false}
        type="button"
        onClick={handleSubmit}
        className="submit-btn"
        disabled={newNote === ''}
      />
    </div>
  );
}
