export interface Opinion{
  id: number,
  candidate: string,
  text: string,
  created_at: string,
  opinion_type: OpinionType,
}

export interface OpinionForm{
  candidate: string,
  text: string,
  opinion_type: OpinionType,
}

export type OpinionType = 'OTHER' | 'PRO' | 'CONS';

type OpinionTypeEnum = {
  [key in OpinionType]: OpinionType;
};

export const opinionType: OpinionTypeEnum = {
  OTHER: 'OTHER',
  PRO: 'PRO',
  CONS: 'CONS',
};
