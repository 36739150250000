import React, { useEffect, useState } from 'react';
// Import auth directly because most components need it
// Other Firebase libraries can be lazy-loaded as-needed
import 'firebase/auth';
import { Route, Switch } from 'react-router-dom';
import { useObservable } from 'micro-observables';
import localService from 'services/i18n/LocalService';
import {
  CLIENT_RESEARCH_BASE,
} from 'routes/ClientRoutes';
import ClientRoot from 'features/client/ClientRoot';
import { useMessageBackend } from 'network/api/MessageQueries';
import Messages from 'services/i18n/Messages';
import { Translations } from 'services/i18n/Traduction';
import HunterRoot from 'features/hunter/HunterRoot';
import { Checkbox, Drawer, FormControlLabel } from '@material-ui/core';
import Button from 'features/client/common/Button';
import clientSearchService from 'services/client/ClientSearchService';
import { IosShare } from '@material-ui/icons';
import settings from 'services/hunterSettings';
import { detectTablette } from 'services/utils';

function App() {
  const { getMessage } = useMessageBackend();
  const [hidePopUp, setHidePopUp] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data } = getMessage((successData: { [key: string]: Translations }) => {
    if (successData) {
      Messages.setAvailableTranslation(Object.keys(successData).map((key) => ({
        language: key,
        messages: successData[key],
      })));
      localService.setAvailableLocal(Object.keys(successData));
      localService.toogleLanguage(localService.getdefaultLocal());
    }
  });
  // import local at the higher node to force rerender on local change
  useObservable(localService.getLocal());

  useEffect(() => {
    const beforeInstall = (e) => {
      // Prevent Chrome 76 and earlier from automatically showing a prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Show the install button
      const installButton = document.getElementById('install_button') as HTMLButtonElement;
      if (installButton) {
        installButton.hidden = false;
        // installButton.addEventListener("click", installApp);
      }
    };
    window.addEventListener('beforeinstallprompt', beforeInstall);
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstall);
    };
  }, []);

  const installApp = () => {
    if (!deferredPrompt) {
      return;
    }
    // @ts-ignore
    deferredPrompt?.prompt();
    setIsLoading(true);

    // @ts-ignore
    deferredPrompt.userChoice.then(() => {
      setDeferredPrompt(null);
    });
  };

  if (!data) {
    return null;
  }

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  const isMobile = detectTablette();

  // @ts-ignore
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  return (
    <div>
      <Switch>
        <Route path={CLIENT_RESEARCH_BASE}>
          <ClientRoot />
        </Route>
        <Route>
          <HunterRoot />
        </Route>
      </Switch>
      {
        isMobile && !clientSearchService.getHideInstall() && (
          <Drawer
            anchor="bottom"
            open={!!deferredPrompt}
            onClose={() => setDeferredPrompt(null)}
          >
            <div className="install-drawer">
              <div className="install-header">
                <img
                  alt="Settlesweet logo"
                  src={`${settings.publicUrl}/settlesweet-removebg.png`}
                  className="d-inline-block align-top"
                  style={{ height: '45px' }}
                />
                {Messages.t('install.app')}
              </div>
              <div>
                <Button disable={isLoading} variant="primary" onClick={installApp}>
                  {Messages.t('formButton.install')}
                </Button>
                <Button variant="primary" onClick={() => setDeferredPrompt(null)}>
                  {Messages.t('formButton.cancel')}
                </Button>
              </div>
              <FormControlLabel
                control={
                  (
                    <Checkbox
                      onChange={() => {
                        clientSearchService.setHideInstall();
                        setDeferredPrompt(null);
                      }}
                      checked={clientSearchService.getHideInstall()}
                    />
                  )
                }
                label={Messages.t('install.never')}
              />
            </div>
          </Drawer>
        )
      }
      {
        !clientSearchService.getHideInstall() && isIos() && !isInStandaloneMode() && !hidePopUp && (
          <div className="ios-install-container">
            <div className="ios-install">
              <div>
                {Messages.t('install.install')} <IosShare /> {Messages.t('install.andAdd')}
              </div>
              <FormControlLabel
                control={
                  (
                    <Checkbox
                      onChange={() => {
                        clientSearchService.setHideInstall();
                        setHidePopUp(true);
                      }}
                      checked={clientSearchService.getHideInstall()}
                    />
                  )
                }
                label={Messages.t('install.never')}
              />
            </div>
            <div className="arrow-bottom" />
          </div>
        )
      }
    </div>
  );
}

export default App;
