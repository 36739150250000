import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Messages from 'services/i18n/Messages';
import { Button } from '@material-ui/core';
import SelectWrapper from 'lib/form/SelectWrapper';
import candidateFilterService, {
  CandidateSort,
  possibleFilter,
  sortType,
} from 'services/CandidateFilterService';

import { WorkflowStep } from 'types/WorkflowStep';

type Props = {
  onClose: () => void,
  currentWorkflowStep: WorkflowStep,
};

export default function Sort({ onClose, currentWorkflowStep }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<CandidateSort>({
    defaultValues: {
      ...candidateFilterService.getSort(currentWorkflowStep),
    },
  });

  const formField = watch();

  const close = () => {
    candidateFilterService.updateSort({
      ...formField,
      ordering: `${formField.sort === sortType.DESC ? '-' : ''}${formField.field.replace('.', '__').replace('property', 'proprty')}`,
    }, currentWorkflowStep);
    onClose();
  };

  useEffect(() => {
    const handleKeyBoardEvent = (event: KeyboardEvent) => {
      if (event.keyCode === 27 || event.keyCode === 13) {
        close();
      }
    };
    document.addEventListener('keydown', handleKeyBoardEvent);
    return () => {
      document.removeEventListener('keydown', handleKeyBoardEvent);
    };
  }, []);

  return (
    <>
      <div role="presentation" className="background" onClick={close} />
      <div ref={ref} className="filters">
        <div>
          {Messages.t('sort.title')}
        </div>
        <div>
          <div className="row-form">
            <Controller
              name="field"
              control={control}
              rules={{ required: true }}
              render={(controller) => (
                <SelectWrapper
                  error={errors}
                  control={controller}
                  requierd
                  label={Messages.t('form.field.field')}
                  values={Object.keys(possibleFilter)
                    .filter((key) => possibleFilter[key].isSortable)
                    .map((key) => (
                      { key, label: Messages.t(possibleFilter[key].label) }
                    ))}
                />
              )}
            />
            <Controller
              name="sort"
              control={control}
              rules={{ required: true }}
              render={(controller) => (
                <SelectWrapper
                  error={errors}
                  control={controller}
                  label={Messages.t('form.field.sortBy')}
                  values={Object.keys(sortType)
                    .map((key) => (
                      { key, label: Messages.t(`filter.${key}`) }
                    ))}
                />
              )}
            />
          </div>
        </div>
        <div className="action-container">
          <Button
            variant="contained"
            onClick={close}
          >
            {Messages.t('formButton.ok')}
          </Button>
        </div>
      </div>
    </>
  );
}
