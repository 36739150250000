import React, { useEffect } from 'react';
import { TabContext, TabList } from '@material-ui/lab';
import { IconButton, Tab } from '@material-ui/core';
import NotificationList from 'features/magicbell/NotificationList';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NotificationStore } from '@magicbell/react-headless/dist/hooks/useNotifications';
import Messages from 'services/i18n/Messages';
import { detectMobile } from 'services/utils';
import { XIcon } from '@heroicons/react/outline';

type Props = {
  notficationsList: [string, NotificationStore][],
  onClose: () => void
  position: {
    top?: number,
    bottom?: number,
    left?: number,
    right?: number
  }
};

export default function MagicBellContainer({ notficationsList, position, onClose }: Props) {
  const [value, setValue] = React.useState('ALL');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const handleKeyBoardEvent = (event: KeyboardEvent) => {
      if (event.keyCode === 27 || event.keyCode === 13) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyBoardEvent);
    return () => {
      document.removeEventListener('keydown', handleKeyBoardEvent);
    };
  }, []);

  const isMobile = detectMobile();

  return (
    <>
      <div
        role="presentation"
        className="background"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      />
      <div style={isMobile ? undefined : position} className="notification-modal">
        {
          isMobile && (
            <div className="page-modal-header">
              <IconButton onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
              >
                <XIcon />
              </IconButton>
              <h2>{Messages.t('navbar.notifications')}</h2>
            </div>
          )
        }
        <TabContext value={value}>
          <TabList className="notification-select" variant="scrollable" scrollButtons onChange={handleChange}>
            {
              notficationsList.map((entry) => (
                <Tab
                  key={entry[0]}
                  label={`${Messages.t(`notification.category.${entry[0]}`)}${entry[1].unreadCount ? `(${entry[1].unreadCount})` : ''}`}
                  value={entry[0]}
                />
              ))
            }
          </TabList>
        </TabContext>
        {
          notficationsList.filter((entry) => entry[0] === value).map((entry) => (
            <NotificationList onClose={onClose} showAllRead={entry[0] === 'ALL'} notifications={entry[1]} key={entry[0]} />
          ))
        }
      </div>
    </>
  );
}
