import React, { ReactNode } from 'react';

type Props = {
  icon: ReactNode
  children: ReactNode,
  title: string,
  show: boolean
};

export default function SearchCard(
  {
    icon,
    children,
    title,
    show,
  }: Props,
) {
  if (!show) {
    return null;
  }
  return (
    <div className="search-criteria">
      <div className="icon-container">
        {icon}
      </div>
      <div>
        <h4>{title}</h4>
        <div className="search-info-container">
          {children}
        </div>
      </div>
    </div>
  );
}
