import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import ResearchEdit from 'features/hunter/pages/research/editAdd/ResearchEdit';
import Research from 'features/hunter/pages/research/researchDetails/layout/Research';
import {
  DEFAULT,
  RESEARCH_ADD,
  RESEARCH_WITH_OPTIONAL_CANDIDATE,
  RESEARCH_EDIT,
  RESEARCH_HISTORY,
  RESEARCH_BASE,
} from 'routes/HmRoutes';
import ResearchHistory from 'features/hunter/pages/history/ResearchHistory';
import { useResearch } from 'network/api/ResearchQueries';
import ResearchHeader from 'features/hunter/pages/research/header/ResearchHeader';

type Param = {
  researchId: string,
};

export default function ResearchRouter() {
  const { researchId } = useParams<Param>();
  const researchQueries = useResearch();
  const { data: research } = researchQueries.getResearch(researchId, !!researchId && researchId !== 'add');

  return (
    <>
      <ResearchHeader research={research} />
      <div id="main-content">
        <Switch>
          <Route exact path={RESEARCH_ADD} component={ResearchEdit} />
          <Route
            exact
            path={RESEARCH_EDIT}
            component={ResearchEdit}
          />
          <Route
            exact
            path={RESEARCH_HISTORY}
            component={ResearchHistory}
          />
          <Route path={RESEARCH_WITH_OPTIONAL_CANDIDATE} component={Research} />
          <Route exact path={RESEARCH_BASE}>
            <Redirect to={DEFAULT} />
          </Route>
        </Switch>
        <div id="chat-wrapper" className="chat-wrapper --none" />
      </div>
    </>
  );
}
