import React, { useCallback } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useCandidate } from 'network/api/CandidateQueries';
import ActivitySimpleCommentForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/activities/ActivitySimpleCommentForm';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import { Candidate } from 'types/candidate';

type Props = {
  candidate: Candidate,
  commentField: string,
};

export default function ActivitySimpleComment({ candidate, commentField }: Props) {
  const { updateCandidate } = useCandidate();

  const handleCommentWorkflowUpdate = useCallback(
    async (comment) => {
      await updateCandidate.mutateAsync(
        {
          candidate,
          data: {
            match_comment: comment,
          },
        }, {
          onSuccess: () => {
            NotificationService.notifySuccess(Messages.t('toast.hunter.candidateUpdate'));
          },
        },
      );
    },
    [updateCandidate, candidate],
  );

  return (
    <>
      {candidate[commentField] && <Alert variant="secondary">{candidate[commentField]}</Alert>}
      <ActivitySimpleCommentForm
        defaultValue={candidate[commentField]}
        handleCommentWorkflowUpdate={handleCommentWorkflowUpdate}
      />
    </>
  );
}
