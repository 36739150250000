import React, { MouseEventHandler } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  }, // TODO Better type
  label?: string,
  values: { key: string, label: string }[],
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  onClick?: MouseEventHandler<HTMLLIElement>
  onChange?: (value: string) => void
  multiple?: boolean,
  requierd?: boolean,
  disableFullwidth?: boolean,
  variant?: 'standard' | 'outlined' | 'filled',
  action?: () => void,
  actionLabel?: string
};

export default function SelectWrapper(
  {
    control,
    label,
    values,
    error,
    apiErrors,
    onClick,
    requierd,
    onChange,
    multiple,
    disableFullwidth,
    variant,
    action,
    actionLabel,
  }: Props,
) {
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);
  return (
    <div className={`material-select-wrapper ${disableFullwidth ? 'no-full-widht' : ''}`}>
      <FormControl variant={variant || 'outlined'} fullWidth={!disableFullwidth} error={!!errorMessage}>
        <InputLabel>{label}{requierd ? ' *' : ''}</InputLabel>
        <Select
          {...field}
          onChange={(e) => {
            let { value } = e.target;
            if (value === 'action_reserved_long_name') {
              return;
            }
            if (multiple) {
              value = value.join(',');
            }
            if (onChange) {
              onChange(value);
            }
            field.onChange(value);
          }}
          value={multiple ? field.value?.split(',').filter((v) => v !== '') || [] : field.value || ''}
          multiple={multiple}
          renderValue={multiple ? (selected) => (selected && selected.join(', ')) : undefined}
          label={`${label}${requierd ? ' *' : ''}`}
        >
          {
            values.map((value) => (
              <MenuItem
                onClick={onClick}
                key={value.label}
                value={value.key}
              >
                {
                  multiple && (
                    <Checkbox checked={field.value?.includes(value.key)} />
                  )
                }
                <ListItemText>{value.label}</ListItemText>
              </MenuItem>
            ))
          }
          {
            action && actionLabel && (
              <MenuItem
                className="select-action"
                onClick={action}
                value="action_reserved_long_name"
                key="action_reserved_long_name"
              >
                <ListItemText>{actionLabel}</ListItemText>
              </MenuItem>
            )
          }
        </Select>
        {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
}
