import React from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, UseFormTrigger } from 'react-hook-form';
import {
  PostSearch,
  PostSearchForm,
} from 'types/postSearch/PostSearch';
import {
  garantorStatusByType,
  garantorTypeEnum,
} from 'types/postSearch/Garantor';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/core';
import TimelineWrapper from 'lib/form/TimelineWrapper';

type Props = {
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  formField: PostSearchForm,
  errors: FieldErrors
  trigger: UseFormTrigger<PostSearchForm>
  handleSubmit: (data: Partial<PostSearch>) => void
};

export default function GarantorForm(
  {
    control,
    trigger,
    formField,
    errors,
    apiErrors,
    handleSubmit,
  }: Props,
) {
  return (
    <div>
      <h5>{Messages.t('form.field.garantorKind')}</h5>
      <Controller
        name="garantor_kind"
        control={control}
        rules={{ required: true }}
        render={(controller) => (
          <ToggleButtonGroup
            {...controller.field}
            color="primary"
            exclusive
            onChange={
              (_, val) => {
                controller.field.onChange(val);
                handleSubmit({ garantor_kind: val });
              }
            }
          >
            {
              Object.values(garantorTypeEnum).map((type) => (
                <ToggleButton
                  key={type}
                  value={type}
                >
                  {Messages.t(`garantor.kind.${type}`)}
                </ToggleButton>
              ))
            }
          </ToggleButtonGroup>
        )}
      />
      {
        formField.garantor_kind && (
          <Controller
            name="garantor_status"
            control={control}
            render={(controller) => (
              <TimelineWrapper
                control={controller}
                onChange={(value) => handleSubmit({ garantor_status: value })}
                values={
                  garantorStatusByType[formField.garantor_kind || '']
                    .map((type) => ({ key: type, label: Messages.t(`garantor.status.${type}`) }))
                }
              />
            )}
          />
        )
      }
      {
        formField.garantor_kind === garantorTypeEnum.VISAL && (
          <Controller
            name="garantor_visal_number"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                trigger={trigger}
                autoSaveSubmit={(value) => handleSubmit({ garantor_visal_number: value })}
                label={Messages.t('form.field.garantor_visal_number')}
              />
            )}
          />
        )
      }
      {
        formField.garantor_kind === garantorTypeEnum.PHYSICAL && (
          <div className="row-form">
            <Controller
              name="garantor_name"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ garantor_name: value })}
                  label={Messages.t('form.field.garantor_name')}
                />
              )}
            />
            <Controller
              name="garantor_mail"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ garantor_mail: value })}
                  label={Messages.t('form.field.garantor_mail')}
                />
              )}
            />
            <Controller
              name="garantor_phone"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ garantor_phone: value })}
                  label={Messages.t('form.field.garantor_phone')}
                />
              )}
            />
          </div>
        )
      }
    </div>
  );
}
