import React, { useState } from 'react';
import { useUser } from 'reactfire';
import { useActivities, useActivity } from 'network/api/ActivityQueries';
import {
  DataGrid,
  GridColDef,
  GridRowData,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import Messages from 'services/i18n/Messages';
import { ActivityParial } from 'types/Activity';
import { format } from 'date-fns';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle, IconButton,
} from '@material-ui/core';
import { NotificationService } from 'services/notification';
import ActivityForm from 'features/hunter/pages/research/researchDetails/candidateDetails/actionBar/ActivityForm';
import { CandidateResearch } from 'types/candidate';
import { CANDIDATE_ID, RESEARCH_ID, Routes } from 'routes/Routes';
import {
  DETAIL_ID, RESEARCH_CANDIDATE_DETAIL, TAB_ID,
} from 'routes/HmRoutes';
import { useHistory } from 'react-router-dom';
import { OpenInNew } from '@material-ui/icons';
import { CanditateTab } from 'features/hunter/pages/research/researchDetails/candidateDetails/CandidateTabs';
import { FetchError } from 'network/Errors';
import { ActivityExtended } from 'types/task';

function getCandidateDesc(candidate: CandidateResearch): string {
  const { property } = candidate;
  return `${candidate.client_identifier
    ? `#${candidate.client_identifier} | ` : ''}${property.rooms
    ? `${property.rooms}${Messages.t('form.field.rooms')} | ` : ''}${property.bedrooms
    ? `${property.bedrooms}${Messages.t('form.field.bedrooms')} | ` : ''}${property.area
    ? `${property.area}${Messages.t('form.field.area')} | ` : ''}${property.price
    ? `${property.price}${Messages.t('form.field.price')}` : ''} 
        `;
}

export default function Tasks() {
  const { data: user } = useUser();
  const { getHmTask } = useActivities();
  const { updateTask } = useActivity();
  const history = useHistory();
  const [showDone, setShowDone] = useState(false);
  const [selectedActivity, setSelectedActivty] = useState<ActivityExtended | null>(null);

  const { data: taskList } = getHmTask(user && user.email, !!user && !!user.email);

  const handleSubmit = async (data: ActivityParial) => {
    await updateTask.mutateAsync({
      mail: user.email || '',
      data,
    }, {
      onSuccess: () => {
        NotificationService.notifySuccess(Messages.t('toast.hunter.taskUpdated'));
      },
      onError: (error: FetchError) => {
        const { status } = error;
        if (status === 500) {
          NotificationService.notifyError(Messages.t('toast.hunter.error'));
        }
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'task_done',
      headerName: Messages.t('task.list.task_done'),
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { row } = params;
        return (
          <div>
            <Checkbox
              onChange={(e) => handleSubmit({
                id: (row as ActivityExtended).id,
                task_done: e.target.checked,
              })}
              checked={(row as ActivityExtended).task_done}
            />
            <IconButton
              onClick={() => history.push(
                Routes.withPath(RESEARCH_CANDIDATE_DETAIL, [
                  {
                    label: RESEARCH_ID,
                    value: (row as ActivityExtended).candidate_extended.research.id,
                  },
                  {
                    label: TAB_ID,
                    value: (row as ActivityExtended)
                      .candidate_extended.workflow_step.toLowerCase(),
                  },
                  {
                    label: CANDIDATE_ID,
                    value: (row as ActivityExtended).candidate_extended.id,
                  },
                  {
                    label: DETAIL_ID,
                    value: CanditateTab.DETAILS,
                  },
                ]),
              )}
            >
              <OpenInNew />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'name',
      headerName: Messages.t('task.list.name'),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => `${(params.row as ActivityExtended).candidate_extended.research.client_first_name || ''} ${
        (params.row as ActivityExtended).candidate_extended.research.client_last_name || ''}`,
    },
    {
      field: 'property',
      headerName: Messages.t('task.list.property'),
      width: 250,
      valueGetter: (params: GridValueGetterParams) => getCandidateDesc(
        (params.row as ActivityExtended).candidate_extended,
      ),
    },
    {
      field: 'task_type',
      headerName: Messages.t('task.list.task_type'),
      width: 150,
    },
    {
      field: 'message_content',
      headerName: Messages.t('task.list.message_content'),
      width: 150,
    },
    {
      field: 'date',
      disableColumnMenu: true,
      headerName: Messages.t('task.list.date'),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        const activity = (params.row as ActivityExtended);
        return format(new Date(activity.date), 'yyyy/MM/dd hh:mm');
      },
    },
  ];

  return (
    <div className="task-page">
      <div>
        <h2>
          {Messages.t('task.list.title')}
        </h2>
      </div>
      {
        selectedActivity && (
          <Dialog open={!!selectedActivity} onClose={() => setSelectedActivty(null)}>
            <DialogTitle>
              {`${selectedActivity.candidate_extended.research.client_first_name || ''} ${
                selectedActivity.candidate_extended.research.client_last_name || ''}`}
            </DialogTitle>
            <div className="modal-subtitle">{getCandidateDesc(selectedActivity.candidate_extended)}</div>
            <DialogContent>
              <ActivityForm
                onClose={() => setSelectedActivty(null)}
                activity={selectedActivity}
              />
            </DialogContent>
          </Dialog>
        )
      }
      <div>
        <button
          type="button"
          className={`button-filter-radio ${showDone ? 'checked' : ''}`}
          onClick={() => setShowDone((prevState) => !prevState)}
        >
          {Messages.t('task.showDone')}
        </button>
      </div>
      <div className="task-grid data-grid-list">
        {
          taskList && (
            <DataGrid
              onCellClick={(params) => {
                if (params.field !== 'task_done') {
                  setSelectedActivty((params.row as ActivityExtended));
                }
              }}
              rows={taskList.filter((task) => showDone || !task.task_done) as GridRowData[]}
              getRowClassName={() => 'research-row'}
              columns={columns}
            />
          )
        }
      </div>
    </div>
  );
}
