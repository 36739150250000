import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useCandidates } from 'network/api/CandidateQueries';
import InfiniteScroll from 'lib/InfiniteScroll';
import { useParams } from 'react-router-dom';
import { Candidate } from 'types/candidate';
import { workflowStep, WorkflowStep } from 'types/WorkflowStep';
import clientFilterService from 'services/client/ClientFilterService';
import { useObservable } from 'micro-observables';
import { CircularProgress } from '@material-ui/core';
import { detectTablette } from 'services/utils';
import MapWrapper from 'features/client/pages/map/MapWrapper';
import MapButton from 'features/client/pages/map/MapButton';
import Button from 'features/client/common/Button';
import { useResearch } from 'network/api/ResearchQueries';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import candidateFilterService from 'services/CandidateFilterService';
import CandidateCard from 'features/client/CandidateCard';
import { showNotationDependingWorkflowStep } from 'services/hunter/WorkflowStep';

type Params = {
  researchId: string,
};

type Props = {
  listWorkflowStep: WorkflowStep
  doFilter?: boolean,
};

export default function CandidateList({ listWorkflowStep, doFilter }: Props) {
  const [selectedCandidateId, setSelectedCandidateId] = useState<string | null>(null);
  const { researchId } = useParams<Params>();

  const researchQueries = useResearch();
  const { data: research } = researchQueries.getResearch(researchId);

  const candidatesQueries = useCandidates();
  const {
    isLoading,
    isFetchingNextPage,
    data: dataAPI,
    fetchNextPage,
    hasNextPage,
  } = candidatesQueries.getCandidatesForStep(
    researchId,
    listWorkflowStep,
    candidateFilterService.getSort(listWorkflowStep).ordering,
  );

  useEffect(() => {
    if (research) {
      clientFilterService.updateFilter({
        ...clientFilterService.getFilterValue(),
        onlyHmPicks: !research.marketplace_search,
      });
    }
  }, [research?.id]);
  const filters = useObservable(clientFilterService.getFilters());
  let candidates = dataAPI?.pages
    .map((result) => result.results)?.flat();
  if (!isLoading && candidates && doFilter) {
    candidates = clientFilterService.filterCandidates(candidates, research);
  }
  const isTablette = detectTablette();

  const scrollableRef = useRef<HTMLDivElement>(null);
  const isLoadingMoreData = isLoading || isFetchingNextPage;

  const onSelectedCandidate = useCallback((node: HTMLDivElement | null) => {
    node?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div className="candidate-list-container">
      <div ref={scrollableRef} className="scrollable-candidate-list">
        {
          !isLoadingMoreData && candidates && candidates.length === 0 && (
            doFilter ? (
              filters.onlyHmPicks ? (
                <div className="error-message">
                  <div>
                    {Messages.t('candidate.list.seeHmPick')}
                  </div>
                  <Button
                    onClick={() => {
                      NotificationService.notifyWarning(Messages.t('toast.client.seeHmPick'));
                      clientFilterService.setHmPick(false);
                    }}
                    variant="primary"
                  >
                    {Messages.t('candidate.list.browseMore')}
                  </Button>
                </div>
              ) : (
                <div className="error-message">
                  {Messages.t('candidate.list.noMatch')}
                </div>
              )
            ) : (
              <div className="error-message">
                {Messages.t('candidate.list.noProperty')}
              </div>
            )
          )
        }
        {
          isLoadingMoreData && (
            <CircularProgress />
          )
        }
        <div className="candidate-list">
          {
            scrollableRef.current && candidates && (
              <InfiniteScroll
                isLoading={isLoadingMoreData}
                next={fetchNextPage}
                size={candidates.length}
                hasNext={!!hasNextPage}
                scrollableDivRef={scrollableRef}
                childClassName="candidate-card-container"
              >
                {
                  research && candidates.map((candidate: Candidate) => (
                    <div
                      key={candidate.id}
                      ref={
                        candidate.id === selectedCandidateId
                          ? onSelectedCandidate
                          : undefined
                      }
                      className={candidate.id === selectedCandidateId ? 'selected-card' : 'card-ref-wrapper'}
                    >
                      <CandidateCard
                        research={research}
                        candidate={candidate}
                        showViewing={listWorkflowStep.includes(workflowStep.BOOKED)}
                        displayActions
                        showNotation={showNotationDependingWorkflowStep(candidate)}
                      />
                    </div>
                  ))
                }
              </InfiniteScroll>
            )
          }
        </div>
      </div>
      {
        isTablette ? (
          <MapButton steps={[listWorkflowStep]} />
        ) : (
          candidates && (
            <MapWrapper
              candidates={candidates}
              setSelectedCandidateId={
                (candidateIdList) => setSelectedCandidateId(candidateIdList[0])
              }
              selectedCandidateId={selectedCandidateId}
            />
          )
        )
      }
    </div>
  );
}
