import React, { useState } from 'react';
import { Candidate } from 'types/candidate';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import Messages from 'services/i18n/Messages';

type Props = {
  candidate: Candidate
};

export default function Description({ candidate }: Props) {
  const [showAll, setShowAll] = useState(false);
  if (!candidate.property.description) {
    return null;
  }
  return (
    <div className="candidate-section">
      <h4>{Messages.t('candidate.description.description')}</h4>
      <div className="candidate-description">
        <div>
          {
            candidate.property.description && (
              showAll
                ? candidate.property.description?.replaceAll('\\n', '\n')
                : `${candidate.property.description?.replaceAll('\\n', '\n').split(' ').slice(0, 20).join(' ')}...`
            )
          }
        </div>
        <button type="button" onClick={() => setShowAll(((prevState) => !prevState))}>
          {
            showAll ? (
              <b className="button-description">
                <ChevronLeftIcon />{Messages.t('candidate.description.hide')}
              </b>
            ) : (
              <b className="button-description">{Messages.t('candidate.description.showMore')}<ChevronRightIcon /></b>
            )
          }
        </button>
      </div>
    </div>
  );
}
