import React from 'react';
import CandidateList from 'features/client/common/CandidateList';
import { workflowStep } from 'types/WorkflowStep';
import { useObservable } from 'micro-observables';
import clientFilterService from 'services/client/ClientFilterService';

export default function Discover() {
  const filters = useObservable(clientFilterService.getFilters());
  return (
    <CandidateList
      listWorkflowStep={filters.onlyHmPicks ? workflowStep.SENT : workflowStep.REVIEW}
      doFilter
    />
  );
}
