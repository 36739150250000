import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { urlRegex } from 'lib/form/FormUtils';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Alert from 'react-bootstrap/Alert';
import { AddPropertyByUrlType } from 'types/forms/AddPropertyByUrl';
import { useCandidate } from 'network/api/CandidateQueries';
import { useHistory, useParams } from 'react-router-dom';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import { CANDIDATE_QUERY_ID, CLIENT_SHORTLIST } from 'routes/ClientRoutes';
import Button from 'features/client/common/Button';
import { workflowStep } from 'types/WorkflowStep';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { NotificationService } from 'services/notification';
import { workflowStepMetaData } from 'services/hunter/WorkflowStep';
import Messages from 'services/i18n/Messages';

type Params = {
  researchId: string,
};

export default function Add() {
  const history = useHistory();
  const { researchId } = useParams<Params>();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [apiErrors, setApiError] = useState({});
  const { addCandidateByUrl } = useCandidate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddPropertyByUrlType>();

  const onSubmit: SubmitHandler<AddPropertyByUrlType> = (formData: AddPropertyByUrlType) => {
    setSubmitError(null);
    setSubmitting(true);
    addCandidateByUrl.mutate(
      {
        url: formData.url,
        research: researchId,
        author: 'CLIENT',
        workflow_step: workflowStep.NOT_SURE,
        added_by_client: true,
        manually_added: true,
      },
      {
        onSuccess: (data) => {
          setSubmitting(false);
          if (data.status === 200 && data.response !== null) {
            const candidateId = data.response.id.toString();
            NotificationService.notifySuccess(Messages.t('toast.client.listingAdded', { stepDisplayName: workflowStepMetaData(workflowStep.NOT_SURE).getClientDisplayName() }));
            history.push(
              Routes.updateUrlWithQuery(
                Routes.withPath(
                  CLIENT_SHORTLIST,
                  [{ label: RESEARCH_ID, value: researchId }],
                ),
                [
                  { label: CANDIDATE_QUERY_ID, value: candidateId },
                ],
              ),
            );
          } else if (data.status === 202) {
            setApiError({ url: [Messages.t('candidate.add.processing')] });
          } else {
            setApiError({ url: [Messages.t('candidate.add.error')] });
          }
        },
        onError: () => {
          setSubmitting(false);
          setApiError({ url: [Messages.t('candidate.add.error')] });
        },
      },
    );
  };

  return (
    <div className="add-listing">
      <div className="add-listing-text">
        {Messages.t('candidate.add.paste')}
      </div>
      <form className="add-listing-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="url"
          control={control}
          rules={
            {
              required: true,
              pattern: {
                value: urlRegex,
                message: Messages.t('form.error.url'),
              },
            }
          }
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              variant="outlined"
              error={errors}
              control={controller}
              placeholder={Messages.t('form.field.copyLinkPlaceholder')}
              label={Messages.t('form.field.copyLink')}
            />
          )}
        />
        {submitError && <Alert variant="danger">{submitError}</Alert>}
        <div className="buttons-container">
          <Button
            type="submit"
            variant="primary"
            spin={submitting}
          >
            <div className="flex-filler">
              <CheckCircleIcon />
            </div>
            <span>{Messages.t('candidate.add.addlisting')}</span>
            <div className="flex-filler" />
          </Button>
        </div>
      </form>
    </div>
  );
}
