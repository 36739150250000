import React, { useEffect } from 'react';
import { Candidate } from 'types/candidate';
import ActivityCard from 'features/hunter/pages/research/researchDetails/candidateDetails/activities/ActivityCard';
import Messages from 'services/i18n/Messages';
import CommentCard from 'features/hunter/pages/research/researchDetails/candidateDetails/comments/CommentCard';
import AddCommentForm from 'features/hunter/pages/research/researchDetails/candidateDetails/comments/AddCommentForm';
import { ActivityComment } from 'types/Activity';
import { useComment } from 'network/api/CommentQueries';
import { useActivities } from 'network/api/ActivityQueries';

type Props = {
  candidate: Candidate,
  commentsAndActivities: ActivityComment[],
};

export default function ActivityTimeline(
  {
    candidate,
    commentsAndActivities,
  }: Props,
) {
  const plannedActivities = commentsAndActivities.filter(
    (activityOrComment) => activityOrComment.activity && !activityOrComment.activity.task_done,
  );

  const doneActivitiesOrComments = commentsAndActivities.filter(
    (activityOrComment) => (
      (activityOrComment.activity && activityOrComment.activity.task_done)
      || activityOrComment.comment
    ),
  );

  const { markCommentsAsRead } = useComment();
  const { markActivitiesAsRead } = useActivities();

  useEffect(() => {
    const unreadComments = commentsAndActivities
      .filter((commentOrActivity) => commentOrActivity.comment && !commentOrActivity.comment.read)
      .map((comment) => comment.comment?.id);

    if (unreadComments.length > 0) {
      markCommentsAsRead.mutateAsync({
        idList: unreadComments as number[],
        candidate: candidate.id,
      });
    }

    const unreadActivities = commentsAndActivities
      .filter((commentOrActivity) => commentOrActivity.activity && !commentOrActivity.activity.read)
      .map((activity) => activity.activity?.id);

    if (unreadActivities.length > 0) {
      markActivitiesAsRead.mutateAsync({
        idList: unreadActivities as number[],
        candidate: candidate.id,
      });
    }
  }, []);

  return (
    <>
      {
        commentsAndActivities.length > 0 ? (
          <h4>{Messages.t('activity.latest')}</h4>
        ) : <h4>{Messages.t('candidate.activities')}</h4>
      }
      {plannedActivities.length > 0 && (
        <>
          <h5>{Messages.t('activity.planned')}</h5>
          <ul className="timeline">
            {
              plannedActivities.map((activity) => (
                activity.activity && (
                  <ActivityCard
                    key={activity.activity.id}
                    candidate={candidate}
                    activity={activity.activity}
                  />
                )
              ))
            }
          </ul>
        </>
      )}
      <>
        {doneActivitiesOrComments.length > 0 ? (
          <h5>{Messages.t('activity.done')}</h5>
        ) : (
          <p>{Messages.t('activity.noActivity')}</p>
        )}
        <hr />
        <AddCommentForm candidate={candidate} author="HOME_MATCHER" />
        {doneActivitiesOrComments.length > 0 && (
          <ul className="timeline">
            {
              doneActivitiesOrComments
                .map((activityOrComment) => (
                  activityOrComment.activity ? (
                    <ActivityCard
                      key={activityOrComment.activity.id}
                      candidate={candidate}
                      activity={activityOrComment.activity}
                    />
                  ) : (activityOrComment.comment) ? (
                    <CommentCard
                      key={activityOrComment.comment.id}
                      candidate={candidate}
                      comment={activityOrComment.comment}
                    />
                  ) : null
                ))
            }
          </ul>
        )}
      </>
    </>
  );
}
