import { useQuery } from 'react-query';
import { useBackend } from 'network/BackendFetchAdapter';
import { TaskType } from 'types/TaskType';

export function useTaskType() {
  const backend = useBackend();

  const GetTaskType = (enabled = true) => useQuery<TaskType[]>(['getTaskType'], async () => {
    const response = await backend.fetchJson(
      '/api/task-types',
    );
    return response;
  }, { enabled });

  return {
    getTaskType: GetTaskType,
  };
}
