import React, { useMemo, useState } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import Messages from 'services/i18n/Messages';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Autocomplete, Checkbox, TextField } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { useZipCodeBackend } from 'network/api/ZipCodesQueries';
import { ZipCodes } from 'types/ZipCodes';
import { getFinalErrorMessage } from 'lib/form/FormUtils';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  errors?: FieldErrors,
  apiErrors?: { [key: string]: string[] }
};

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function ZipCodeAutoCompleteWrapper(
  {
    control, errors, apiErrors,
  }: Props,
) {
  const { getAllZipcode } = useZipCodeBackend();
  const [searchValue, setSearchValue] = useState('');
  const { data: zipCodes } = getAllZipcode();
  const zipCodeMap = useMemo(() => zipCodes?.reduce((acc, value) => {
    if (!acc[value.zip_code]) {
      acc[value.zip_code] = [];
    }
    acc[value.zip_code].push(value);
    return acc;
  }, {}), [zipCodes?.length]);

  const filterOptions = (options, { inputValue }) => {
    if (!inputValue || inputValue.length < 2) {
      return [];
    }
    const option = options.filter(
      (zipCode) => zipCode.city.toLowerCase().startsWith(inputValue.toLowerCase())
        || zipCode.zip_code.toLowerCase().startsWith(inputValue.toLowerCase()),
    ).slice(0, 40);
    return option.sort((a, b) => (b.city || '').localeCompare(a.city || ''));
  };

  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, errors, apiErrors);

  let value = field.value.split(',') || [];
  if (value.length === 1 && value[0] === '') {
    value = [];
  }
  return (
    <div className="material-select-wrapper">
      <Autocomplete
        {...field}
        value={value}
        onChange={(e, val) => {
          field.onChange(val.map((zip) => {
            if (typeof zip === 'string') {
              return zip;
            }
            return zip.zip_code;
          }).join(','));
        }}
        multiple
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        options={zipCodes || []}
        disableCloseOnSelect
        freeSolo
        inputValue={searchValue}
        onInputChange={(e, v, reason) => {
          if (reason === 'input') {
            setSearchValue(v);
          }
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            if (zipCodeMap) {
              const zipCodeList: ZipCodes[] = zipCodeMap[option];
              if (zipCodeList) {
                return `${zipCodeList[0].city} (${zipCodeList[0].zip_code})`;
              }
            }
            return option;
          }
          return '';
        }}
        getOptionDisabled={(option) => field.value && field.value.includes(option.zip_code)}
        renderOption={(props, zipCode) => (
          <li {...props} key={`${zipCode.zip_code}-${zipCode.city}`}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={field.value && field.value.includes(zipCode.zip_code)}
            />
            <div>
              {`${zipCode.city} (${zipCode.zip_code})`}
            </div>
          </li>
        )}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={Messages.t('form.field.zipcodes')}
            placeholder={Messages.t('form.field.city')}
            error={!!errorMessage}
            helperText={errorMessage}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </div>
  );
}
