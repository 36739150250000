import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useResearches,
  useResearch,
} from 'network/api/ResearchQueries';

import DeleteConfirmationDialog from 'theme/hunter-utils/DeleteConfirmationDialog';
import {
  RESEARCH_ADD,
  RESEARCH_EDIT,
  RESEARCH_TAB,
  TAB_ID,
} from 'routes/HmRoutes';
import LoaderRing from 'theme/hunter-utils/LoaderRing';
import { useObservable } from 'micro-observables';
import researchFilterService, { ResearchFilterService } from 'services/ResearchFilterService';
import ResearchFilter from 'features/hunter/pages/research/researchList/ResearchFilter';
import { useUserBackend } from 'network/api/UserQueries';
import { useUser } from 'reactfire';
import { NotificationService } from 'services/notification';
import {
  DataGrid,
  GridColDef,
  GridRowData,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import {
  Research,
  ResearchStatus,
  researchStatus,
} from 'types/research';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import { CLIENT_DISCOVER } from 'routes/ClientRoutes';
import { workflowStep } from 'types/WorkflowStep';
import StringUtils from 'services/StringUtils';
import { Delete, Edit } from '@material-ui/icons';
import Messages from 'services/i18n/Messages';
import { FetchError } from 'network/Errors';
import { fuzzysearch } from 'lib/FuzzySearch';

const ACTION_FIELD = 'action';
const CLIENT_VIEW = 'client_view';

export default function ResearchList() {
  const { isLoading, data: researches } = useResearches();
  const [selectedSearchIds, setSelectedSearchIds] = useState<string[]>([]);
  const { deleteResearch } = useResearch();
  const { getUser } = useUserBackend();
  const { data: user } = useUser();
  const { data: users } = getUser(user.email, !!user.email);
  const filter = useObservable(researchFilterService.getFilters());

  const history = useHistory();
  const [deleteConfirmation, setDeleteConfirmation] = useState<string[] | null>(null);
  const researchQueries = useResearch();

  const handleDeleteResearch = useCallback((research_id: string[]) => {
    setDeleteConfirmation(research_id);
  }, []);

  const handleDeleteConfirmation = () => {
    selectedSearchIds.forEach((research_id) => {
      deleteResearch.mutate(
        research_id,
        {
          onSuccess: () => {
            NotificationService.notifySuccess(Messages.t('toast.hunter.reasearchDeleted'));
          },
          onError: (error: FetchError) => {
            const { status } = error;
            if (status === 500) {
              NotificationService.notifyError(Messages.t('toast.hunter.error'));
            }
          },
        },
      );
    });
  };

  const handleAddResearch = () => history.push(RESEARCH_ADD);

  const reducedHMList: string[] = useMemo(() => {
    const valueObject = researches?.reduce((acc, value) => {
      if (value.homematcher_email) {
        acc[value.homematcher_email] = value.homematcher_email;
      }
      return acc;
    }, {}) as { [key: string]: string } || {};
    return Object.values(valueObject);
  }, [researches]);

  const filteredResearch = useMemo(() => researches?.filter(
    (research) => {
      if (
        filter.current_user_research
        && filter.current_user_research !== ResearchFilterService.EVERYONE
        && filter.current_user_research !== ResearchFilterService.NONE
        && research.homematcher_email?.toLowerCase() !== filter.current_user_research.toLowerCase()
      ) {
        return false;
      }
      if (
        filter.current_user_research
        && filter.current_user_research === ResearchFilterService.NONE
        && !!research.homematcher_email
      ) {
        return false;
      }
      if (filter.status
        && filter.status !== 'ALL'
        && research.status !== filter.status
      ) {
        return false;
      }
      const name = `${research.client_first_name} ${research.client_last_name}`;
      return !filter.searchText || fuzzysearch(filter.searchText, name);
    },
  ), [researches, filter]);

  const updateResearchStatus = (status: ResearchStatus, research: Research) => {
    const data: Partial<Research> = { status };
    if (status === researchStatus.IN_PROGRESS && user && (!research.homematcher_email || research.homematcher_email === '')) {
      data.homematcher_email = user.email;
    }
    return researchQueries.updateResearch.mutate({ research_id: research.id, data }, {
      onSuccess: () => {
        NotificationService.notifySuccess(Messages.t('toast.hunter.reasearchUpdated'));
      },
      onError: (error: FetchError) => {
        const { status: errorStatus } = error;
        if (errorStatus === 500) {
          NotificationService.notifyError(Messages.t('toast.hunter.error'));
        }
      },
    });
  };

  const handleModification = (researchId: string, field: string, value: any) => {
    if (field === 'status' && researches) {
      updateResearchStatus(
        value as ResearchStatus,
        researches.filter((research) => research.id === researchId)[0],
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'candidate_count',
      headerName: Messages.t('research.list.newListing'),
      width: 130,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { value, id } = params;
        const diffCount = value
          && ((value as number || 0) - (
            (users && users[id])
              ? users[id].research_last_count
              : 0));
        return (
          <div>
            <span className={`new-candidate-counter ${diffCount === 0 ? 'no-new-candidate' : ''}`}>
              +{diffCount}
            </span>
          </div>
        );
      },
    },
    {
      field: 'fullName',
      headerName: Messages.t('research.list.fullName'),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => `${params.row.client_first_name || ''} ${
        params.row.client_last_name || ''}`,
    },
    {
      field: 'status',
      headerName: Messages.t('research.list.status'),
      width: 185,
      type: 'singleSelect',
      renderCell: (params) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [anchorEl, setAnchorEl] = React.useState(null);

        const open = Boolean(anchorEl);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const { value } = params;
        return (
          <>
            <button
              type="button"
              onClick={handleClick}
              className="research-status"
            >
              {Messages.t(`research.status.${value}`)}
            </button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  maxHeight: 300,
                },
              }}
            >
              {
                Object.values(researchStatus).map((key) => (
                  <MenuItem
                    key={key}
                    onClick={() => {
                      updateResearchStatus(key, params.row as Research);
                      handleClose();
                    }}
                  >
                    {Messages.t(`research.status.${key}`)}
                  </MenuItem>
                ))
              }
            </Menu>
          </>
        );
      },
      valueOptions: Object.values(researchStatus)
        .map((value) => ({ label: Messages.t(`research.status.${value}`), value })),
    },
    {
      field: 'zip_codes',
      headerName: Messages.t('research.list.zipcode'),
      width: 150,
    },
    {
      field: 'homematcher_email',
      headerName: Messages.t('research.list.owner'),
      type: 'string',
      valueFormatter: (params) => params.value
        && StringUtils.capitalizeFirstLetter((params.value as string).split('@')[0]),
      width: 150,
    },
    {
      field: CLIENT_VIEW,
      headerName: Messages.t('research.list.clientView'),
      width: 120,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            window.open(
              Routes.withPath(CLIENT_DISCOVER, [
                { label: RESEARCH_ID, value: params.id as string },
              ]),
              '_blank',
            );
          }}
        >
          {Messages.t('research.list.clientView')}
        </Button>
      ),
    },
    {
      field: 'price_min',
      headerName: Messages.t('research.list.priceMin'),
      hideSortIcons: true,
      type: 'number',
      width: 130,
    },
    {
      field: 'price_max',
      headerName: Messages.t('research.list.priceMax'),
      hideSortIcons: true,
      type: 'number',
      width: 130,
    },
    {
      field: 'price_max_search',
      headerName: Messages.t('research.list.idealBudget'),
      hideSortIcons: true,
      type: 'number',
      width: 130,
    },
    {
      field: 'fluximmo_alert_id',
      headerName: Messages.t('research.list.alertId'),
      type: 'number',
      width: 130,
    },
    {
      field: ACTION_FIELD,
      headerName: Messages.t('research.list.actions'),
      width: 100,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => history.push(
              Routes.withPath(RESEARCH_EDIT, [{ label: RESEARCH_ID, value: params.id as string }]),
            )}
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteResearch([params.id] as string[])}
          >
            <Delete />
          </IconButton>
        </div>
      ),
    },
  ];

  const archiveList = (idList: string[]) => {
    if (researches) {
      idList.forEach((researchId) => updateResearchStatus(
        researchStatus.ARCHIVED,
        researches.filter((research) => research.id === researchId)[0],
      ));
    }
  };

  return (
    <div className="research-container">
      <DeleteConfirmationDialog
        deleteConfirmation={deleteConfirmation}
        setDeleteConfirmation={setDeleteConfirmation}
        onDeleteConfirmed={handleDeleteConfirmation}
        elementDisplayName="research"
      />
      <div>
        <div>
          {!isLoading && <h1>{Messages.t('research.researchCount', { count: filteredResearch?.length })}</h1>}
          <LoaderRing visible={isLoading} />
        </div>
        <div className="text-right">
          <Button
            variant="contained"
            color="primary"
            className="align-bottom"
            onClick={handleAddResearch}
          >
            {Messages.t('research.add')}
          </Button>
        </div>
      </div>
      <ResearchFilter homematcherList={Object.values(reducedHMList)} />
      {
        selectedSearchIds.length > 0 && (
          <div className="delete-button-container">
            <Button variant="contained" onClick={() => archiveList(selectedSearchIds)}>
              {Messages.t('research.archive', { number: selectedSearchIds.length })}
            </Button>
            <Button variant="contained" onClick={() => handleDeleteResearch(selectedSearchIds)}>
              {Messages.t('research.deleted', { number: selectedSearchIds.length })}
            </Button>
          </div>
        )
      }
      <div className="data-grid-list">
        {
          filteredResearch && (
            <DataGrid
              onEditCellPropsChange={(params) => handleModification(
                params.id as string,
                params.field,
                params.props.value,
              )}
              onCellClick={(params) => {
                if (!params.isEditable
                  && params.field !== 'status'
                  && params.field !== ACTION_FIELD
                  && params.field !== CLIENT_VIEW) {
                  history.push(
                    Routes.withPath(RESEARCH_TAB, [
                      { label: RESEARCH_ID, value: params.id as string },
                      {
                        label: TAB_ID,
                        value: filteredResearch
                          .filter((search) => search.id === params.id)[0]?.status
                        === researchStatus.POST_SEARCH
                          ? workflowStep.AGREEMENT.toLowerCase()
                          : workflowStep.REVIEW.toLowerCase(),
                      },
                    ]),
                  );
                }
              }}
              rows={filteredResearch as GridRowData[]}
              getRowClassName={() => 'research-row'}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(value) => setSelectedSearchIds(value as string[])}
              columns={columns}
            />
          )
        }
      </div>
    </div>
  );
}
