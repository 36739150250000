import React from 'react';
import { Candidate } from 'types/candidate';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import { Coordinates } from 'features/googleMap/GoogleMap';
import Messages from 'services/i18n/Messages';
import CandidateMap from 'theme/CandidateMap';

type Props = {
  candidate: Candidate
};

export default function Location({ candidate }: Props) {
  const position: Coordinates = {
    lat: candidate.property.latitude || candidate.property.fluximmo_raw_advert?.latitude || 0,
    lng: candidate.property.longitude || candidate.property.fluximmo_raw_advert?.longitude || 0,
  };

  const hasValideCoordinates = position.lat !== 0 && position.lng !== 0;

  if (!hasValideCoordinates && !candidate.property.address) {
    return null;
  }
  return (
    <div className="candidate-section">
      <h4>{Messages.t('candidate.location.location')}</h4>
      <div className="candidate-map">
        <CandidateMap candidate={candidate} />
        {
          candidate.property.address && (
            <div className="text-icon">
              <LocationMarkerIcon />
              <a className="address-link" href={`https://www.google.com/maps/place/${candidate.property.address}`}>
                {candidate.property.address}
              </a>
            </div>
          )
        }
      </div>
    </div>
  );
}
