import { Enum } from 'lib/type/enum';

export type GarantorType = 'NOT_NEEDED' | 'GARANT_ME' | 'VISAL' | 'PHYSICAL';

export const garantorTypeEnum: Enum<GarantorType> = {
  NOT_NEEDED: 'NOT_NEEDED',
  GARANT_ME: 'GARANT_ME',
  VISAL: 'VISAL',
  PHYSICAL: 'PHYSICAL',
};

export type GarantorStatus =
  'VISAL_CERTIFICATE_OBTAINED'
  | 'VISAL_ACTIVATED'
  | 'GARANT_ME_OBTAINED'
  | 'PROJECT_LEASE_SENT'
  | 'PAYMENT_LINK_SENT'
  | 'PAYMENT_DONE'
  | 'SENT_GARANTOR'
  | 'SIGNED_BY_GARANTOR';

export const garantorStatusEnum: Enum<GarantorStatus> = {
  VISAL_CERTIFICATE_OBTAINED: 'VISAL_CERTIFICATE_OBTAINED',
  VISAL_ACTIVATED: 'VISAL_ACTIVATED',
  GARANT_ME_OBTAINED: 'GARANT_ME_OBTAINED',
  PROJECT_LEASE_SENT: 'PROJECT_LEASE_SENT',
  PAYMENT_LINK_SENT: 'PAYMENT_LINK_SENT',
  PAYMENT_DONE: 'PAYMENT_DONE',
  SENT_GARANTOR: 'SENT_GARANTOR',
  SIGNED_BY_GARANTOR: 'SIGNED_BY_GARANTOR',
};

export const garantorStatusByType: { [key in GarantorType]: GarantorStatus[] } = {
  NOT_NEEDED: [],
  GARANT_ME: [
    garantorStatusEnum.GARANT_ME_OBTAINED,
    garantorStatusEnum.PROJECT_LEASE_SENT,
    garantorStatusEnum.PAYMENT_LINK_SENT,
    garantorStatusEnum.PAYMENT_DONE,
  ],
  VISAL: [
    garantorStatusEnum.VISAL_CERTIFICATE_OBTAINED,
    garantorStatusEnum.VISAL_ACTIVATED,
  ],
  PHYSICAL: [
    garantorStatusEnum.SENT_GARANTOR,
    garantorStatusEnum.SIGNED_BY_GARANTOR,
  ],
};
