import React, { useEffect } from 'react';
import { Research } from 'types/research';
import { Candidate } from 'types/candidate';
import { Link } from 'react-router-dom';
import {
  RESEARCH_CANDIDATE_GIFT,
  RESEARCH_CANDIDATE_SIGN,
  RESEARCH_CANDIDATE_UTILITIES,
  TAB_ID,
} from 'routes/HmRoutes';
import { CANDIDATE_ID, RESEARCH_ID, Routes } from 'routes/Routes';
import { LinearProgress } from '@material-ui/core';
import { Build, Cake, Extension } from '@material-ui/icons';
import Messages from 'services/i18n/Messages';
import { usePostSearch } from 'network/api/PostSearchQueries';
import PostSearchUtils from 'services/PostSearchUtils';
import { useCandidate } from 'network/api/CandidateQueries';

type Props = {
  research: Research,
  candidate: Candidate
};

export default function PostSearchActions({ candidate, research }: Props) {
  const postSearchQueries = usePostSearch();
  const { getPostSearch } = postSearchQueries;
  const { data: postSearch } = getPostSearch(candidate.post_search || '', !!candidate.post_search);

  const candidatesQueries = useCandidate();
  const { createCandidatePostSearch } = candidatesQueries;

  useEffect(() => {
    if (candidate.id && !candidate.post_search) {
      createCandidatePostSearch.mutateAsync({ candidateId: candidate.id });
    }
  }, [candidate.id]);

  const signProgression = postSearch ? PostSearchUtils.getProgression([
    PostSearchUtils.isGarantorChecked(postSearch),
    PostSearchUtils.isFinancialChecked(postSearch),
    PostSearchUtils.isSignatureChecked(postSearch),
    PostSearchUtils.isRentalChecked(postSearch),
  ]) : 0;
  const utilitiesProgression = postSearch ? PostSearchUtils.getProgression([
    PostSearchUtils.isInsuranceChecked(postSearch),
    PostSearchUtils.isPapernestChecked(postSearch),
    PostSearchUtils.isElectricityChecked(postSearch),
    PostSearchUtils.isGasChecked(postSearch),
    PostSearchUtils.isInternetChecked(postSearch),
  ]) : 0;
  const giftProgression = postSearch ? PostSearchUtils.getProgression([
    PostSearchUtils.isGiftChecked(postSearch),
    PostSearchUtils.isVoucherChecked(postSearch),
  ]) : 0;
  return (
    <div className="post-search-actions">
      <Link
        className="post-search-action"
        to={
          Routes.withPath(
            RESEARCH_CANDIDATE_SIGN,
            [
              { label: CANDIDATE_ID, value: candidate.id },
              { label: TAB_ID, value: candidate.workflow_step.toLocaleLowerCase() },
              { label: RESEARCH_ID, value: research.id },
            ],
          )
        }
      >
        <div className="action-header">
          <div className="action-logo"><Extension /></div>
          <div>{Messages.t('postSearch.actions.sign')}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={signProgression}
        />
        <div className="action-progression">
          {`${Math.floor(signProgression)} %`}
        </div>
      </Link>
      <Link
        className="post-search-action"
        to={
          Routes.withPath(
            RESEARCH_CANDIDATE_UTILITIES,
            [
              { label: CANDIDATE_ID, value: candidate.id },
              { label: TAB_ID, value: candidate.workflow_step.toLocaleLowerCase() },
              { label: RESEARCH_ID, value: research.id },
            ],
          )
        }
      >
        <div className="action-header">
          <div className="action-logo"><Build /></div>
          <div>{Messages.t('postSearch.actions.utilities')}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={utilitiesProgression}
        />
        <div className="action-progression">
          {`${Math.floor(utilitiesProgression)} %`}
        </div>
      </Link>
      <Link
        className="post-search-action"
        to={
          Routes.withPath(
            RESEARCH_CANDIDATE_GIFT,
            [
              { label: CANDIDATE_ID, value: candidate.id },
              { label: TAB_ID, value: candidate.workflow_step.toLocaleLowerCase() },
              { label: RESEARCH_ID, value: research.id },
            ],
          )
        }
      >
        <div className="action-header">
          <div className="action-logo"><Cake /></div>
          <div>{Messages.t('postSearch.actions.gift')}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={giftProgression}
        />
        <div className="action-progression">
          {`${Math.floor(giftProgression)} %`}
        </div>
      </Link>

    </div>
  );
}
