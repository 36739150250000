import React, { useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { useComment } from 'network/api/CommentQueries';
import { CommentType } from 'types/Comment';
import SpinButton from 'theme/hunter-utils/SpinButton';

type Props = {
  className?: string,
  comment: CommentType,
};

export default function DeleteCommentControl({ className, comment }: Props) {
  const { deleteComment } = useComment();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    await deleteComment.mutateAsync(comment);
    setSubmitting(false);
  };

  return (
    <SpinButton
      editing
      className={className}
      variant="error"
      spin={submitting}
      onClick={handleSubmit}
      waitTitle=" "
      title={<BiTrash />}
    />
  );
}
