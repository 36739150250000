import React from 'react';
import LoaderRing from 'theme/hunter-utils/LoaderRing';
import 'react-phone-number-input/style.css';
import {
  usePropertiesContact,
} from 'network/api/PropertyQueries';
import PropertyContactInfo from 'features/hunter/pages/research/researchDetails/candidateDetails/property/PropertyContactInfo';
import { Candidate } from 'types/candidate';
import Messages from 'services/i18n/Messages';

type Props = {
  candidate: Candidate,
  isSelected?: boolean,
};

export default function PropertyContactInfoTab({ candidate, isSelected }: Props) {
  const { property } = candidate;
  const propertiesContactQueries = usePropertiesContact();
  const { isLoading, data: propertiesContact, refetch } = propertiesContactQueries
    .getPropertiesContact(property.id, isSelected);

  return (
    <>
      {!isLoading && propertiesContact ? (
        <>
          <h4>{Messages.t('contacts.title')}</h4>
          <hr />
          <br />
          <PropertyContactInfo
            candidate={candidate}
            property={property}
            contacts={propertiesContact}
            refetchContacts={refetch}
          />
        </>
      ) : (
        <LoaderRing visible />
      )}
    </>
  );
}
