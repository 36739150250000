import { FluximmoAlert } from 'types/FluximmoAlert';
import { Criteria } from 'types/Criteria';

export interface Research {
  id: string,
  marketplace_search?: boolean
  client_first_name: string,
  client_last_name: string,
  client_email: string,
  homematcher_email: string | null,
  zip_codes: string[],
  area_min: number | null,
  area_max: number | null,
  price_min: number | null,
  price_max: number | null,
  price_max_search: number | null,
  rooms: number | null,
  bedrooms: number | null,
  floor_min: number | null,
  floor_max: number | null,
  furnished: boolean | null,
  elevator: boolean | null,
  notes: string | null,
  internal_notes: string | null,
  sort_order?: number,
  created_at: string;
  fluximmo_alert?: FluximmoAlert | null;
  fluximmo_alert_id?: string | null;
  status?: ResearchStatus | null;
  updated_at?: string | null;
  candidate_count?: number;
  collect_link?: string;
  property_type: PropertyType,
  viewing_reports_link?: string
  criterias: Criteria[]
  language?: string | null,
  locationOverApartment?: boolean,
  latest_move_in_date?: string,
  ready_move_in_date?: string,
  start_date?: string,
  number_remote: number,
  number_accompagnied: number,
  enable_notification?: boolean,
  lease_draft_url?: string,
  lease_url?: string,
  move_in_date?: string,
  sign_in_date?: string,
}

export interface ResearchPartialType extends Partial<Research> {
}

export type ResearchStatus = 'NEW' | 'HOLD' | 'IN_PROGRESS' | 'POST_SEARCH' | 'ARCHIVED';

export type FilterResearchStatus = 'ALL' | ResearchStatus;

type ResearchEnum = {
  [key in ResearchStatus]: key;
};

export const researchStatus: ResearchEnum = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  POST_SEARCH: 'POST_SEARCH',
  HOLD: 'HOLD',
  ARCHIVED: 'ARCHIVED',
};

export type PropertyType = 'ALL' | 'HOUSE' | 'APARTMENT';

type PropertyTypeEnum = {
  [key in PropertyType]: key;
};

export const propertyType: PropertyTypeEnum = {
  ALL: 'ALL',
  HOUSE: 'HOUSE',
  APARTMENT: 'APARTMENT',
};
