import React,
{
  ReactNode,
  useEffect, useRef,
  useState,
} from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { Button, Menu, MenuItem } from '@material-ui/core';

import {
  ArrowDropDown,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatListBulleted,
  FormatListNumbered,
} from '@material-ui/icons';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  placeholder?: string,
  parentId: string,
};

const icons = {
  unordered: <FormatListBulleted />,
  ordered: <FormatListNumbered />,
  indent: <FormatIndentIncrease />,
  outdent: <FormatIndentDecrease />,
  left: <FormatAlignLeft />,
  center: <FormatAlignCenter />,
  right: <FormatAlignRight />,
  justify: <FormatAlignJustify />,
};

const useHasChanged = (val: any) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

type ComponentProps<T> = {
  // eslint-disable-next-line react/no-unused-prop-types
  config: {
    options: string[],
  },
  onChange: (value: string) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  currentState: T
  // eslint-disable-next-line react/no-unused-prop-types
  indentDisabled: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  outdentDisabled: boolean
};

type Toolbar = {
  options: string[],
  textAlign: { component: (props: ComponentProps<string>) => ReactNode },
  list: { component: (props: ComponentProps<string>) => ReactNode },
  blockType: { component: (props: ComponentProps<{ blockType: string }>) => ReactNode },
  fontSize: { component: (props: ComponentProps<{ fontSize: string }>) => ReactNode },
  inline: { component: (props: ComponentProps<string>) => ReactNode },
};

// TODO clean component
export default function RichTextEditorWrapper(
  {
    control,
    placeholder,
    parentId,
  }: Props,
) {
  const { field } = control;
  const hasParentChanged = useHasChanged(parentId);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [hasChanged, _setHasChanged] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(field.value ? () => {
    const blocksFromHTML = convertFromHTML(field.value);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    return EditorState.createWithContent(state);
  } : () => EditorState.createEmpty());

  const hasChangedRef = React.useRef(hasChanged);

  const setHasChanged = (data: boolean) => {
    hasChangedRef.current = data;
    _setHasChanged(data);
  };
  useEffect(() => {
    if (hasParentChanged) {
      setHasChanged(true);
    }
  });

  useEffect(() => {
    if (hasChangedRef.current) {
      setHasChanged(false);
      const blocksFromHTML = convertFromHTML(field.value);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      setEditorState(EditorState.createWithContent(state));
    }
  }, [field.value]);

  const onEditorStateChange = (newState: EditorState) => {
    setEditorState(newState);
    return field.onChange(
      draftToHtml(convertToRaw(newState.getCurrentContent())),
    );
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        placeholder={placeholder || 'Type your text here...'}
        toolbar={{
          options: ['blockType', 'inline', 'fontSize', 'list', 'textAlign'],
          textAlign: {
            component: ({ config, onChange, currentState }: ComponentProps<string>) => (
              config.options.map((key) => (
                <Button
                  key={key}
                  type="button"
                  className={`inline-button ${key} ${currentState[key] === true ? 'selected' : ''}`}
                  onClick={() => onChange(key)}
                >
                  {icons[key]}
                </Button>
              ))),
          },
          list: {
            component: (
              {
                config,
                onChange,
                currentState,
                indentDisabled,
                outdentDisabled,
              }: ComponentProps<string>,
            ) => (
              config.options.map((key) => (
                <Button
                  key={key}
                  type="button"
                  disabled={(key === 'indent' && indentDisabled) || (key === 'outdent' && outdentDisabled)}
                  className={`inline-button ${key} ${currentState[key] === true ? 'selected' : ''}`}
                  onClick={() => onChange(key)}
                >
                  {icons[key]}
                </Button>
              ))),
          },
          blockType: {
            component: (
              {
                config,
                onChange,
                currentState,
              }: ComponentProps<{ blockType: string }>,
            ) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const [anchorEl, setAnchorEl] = React.useState(null);
              const open = Boolean(anchorEl);
              const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
              };
              const handleClose = () => {
                setAnchorEl(null);
              };
              return (
                <>
                  <Button
                    type="button"
                    onClick={handleClick}
                  >
                    {currentState.blockType}
                    <ArrowDropDown />
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    {
                      config.options.map((key) => (
                        <MenuItem
                          key={key}
                          selected={key === currentState.blockType}
                          onClick={() => {
                            onChange(key);
                            setTimeout(handleClose, 50);
                          }}
                        >
                          {key}
                        </MenuItem>
                      ))
                    }
                  </Menu>
                </>
              );
            },
          },
          fontSize: {
            component: (
              {
                config,
                onChange,
                currentState,
              }: ComponentProps<{ fontSize: string }>,
            ) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const [anchorEl, setAnchorEl] = React.useState(null);
              const open = Boolean(anchorEl);
              const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
              };
              const handleClose = () => {
                setAnchorEl(null);
              };
              return (
                <>
                  <Button
                    type="button"
                    onClick={handleClick}
                  >
                    {currentState.fontSize || 16}
                    <ArrowDropDown />
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    {
                      config.options.map((key) => (
                        <MenuItem
                          key={key}
                          onClick={() => {
                            onChange(key);
                            setTimeout(handleClose, 50);
                          }}
                        >
                          {key}
                        </MenuItem>
                      ))
                    }
                  </Menu>
                </>
              );
            },
          },
          inline: {
            component: ({ config, onChange, currentState }: ComponentProps<string>) => (
              // eslint-disable-next-line react/prop-types
              config.options.map((key) => (
                <Button
                  key={key}
                  type="button"
                  className={`inline-button ${key} ${currentState[key] === true ? 'selected' : ''}`}
                  onClick={() => onChange(key)}
                >
                  {key === 'monospace' ? '{}' : key[0].toUpperCase()}
                </Button>
              ))),
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
          },
        } as Toolbar}
      />
    </div>
  );
}
