import React, { ReactNode, useEffect, useRef } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { XIcon } from '@heroicons/react/outline';

type Props = {
  children: ReactNode
  footer?: ReactNode
  title: string,
  className?: string,
  quitOnCliqueOut?: boolean,
};

export default function ClientPageModal(
  {
    children,
    footer,
    title,
    className,
    quitOnCliqueOut,
  }: Props,
) {
  const location = useLocation();
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        history.push(location.pathname);
      }
    }

    if (quitOnCliqueOut) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      if (quitOnCliqueOut) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [ref]);

  return (
    <div ref={ref} className={`client-page-modal ${className || ''}`}>
      <div className="client-page-modal-header">
        <Link to={location.pathname}>
          <XIcon />
        </Link>
        <h2>{title}</h2>
      </div>
      <div className="client-page-modal-content">
        {children}
      </div>
      {
        footer && (
          <div className="client-page-modal-footer">
            {footer}
          </div>
        )
      }
    </div>
  );
}
