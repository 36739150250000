import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { defaultWorkflowTabs } from 'services/hunter/defaultworkflowTabs';
import LoaderRing from 'theme/hunter-utils/LoaderRing';
import { useResearch } from 'network/api/ResearchQueries';
import { useCandidate } from 'network/api/CandidateQueries';
import {
  CANDIDATE_ID_AGREEMENT_REVIEW, CANDIDATE_ID_SIGNED_REVIEW,
  CANDIDATE_TAB_ID, DETAIL_ID,
  RESEARCH_CANDIDATE_DETAIL,
  TAB_ID,
} from 'routes/HmRoutes';
import ResearchTabs from 'features/hunter/pages/research/researchDetails/layout/ResearchTabs';
import WorkflowStepTab from 'features/hunter/pages/research/researchDetails/layout/WorkflowStepTab';
import { CanditateTab } from 'features/hunter/pages/research/researchDetails/candidateDetails/CandidateTabs';
import { useUserBackend } from 'network/api/UserQueries';
import { useUser } from 'reactfire';
import { onMount } from 'lib/alias/useEffectAlias';
import { CANDIDATE_ID, RESEARCH_ID, Routes } from 'routes/Routes';
import { workflowStep } from 'types/WorkflowStep';
import ConfirmationModal from 'features/hunter/common/ConfirmationModal';
import Messages from 'services/i18n/Messages';
import ResearchRightPanel from 'features/hunter/pages/research/researchDetails/layout/ResearchRightPanel';
import Modal from 'lib/Modal';
import SignedTransitionForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/form/SignedReviewFrom';
import AgreementReviewFrom
  from 'features/hunter/pages/research/researchDetails/candidateDetails/form/AgreementReviewFrom';
import panelCollapseService from 'services/PanelCollapseService';
import { researchStatus } from 'types/research';

type Param = {
  researchId: string,
  tabId: string,
  candidateId: string,
};

export default function Research() {
  const { researchId, tabId, candidateId } = useParams<Param>();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const agreementReview = queryParams.get(CANDIDATE_ID_AGREEMENT_REVIEW);
  const signedReview = queryParams.get(CANDIDATE_ID_SIGNED_REVIEW);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [firstMounted, setFirstMounted] = useState(true);

  const researchQueries = useResearch();
  const { isLoading, data: research } = researchQueries.getResearch(researchId);
  useEffect(() => {
    if (research) {
      panelCollapseService.updateIsPostSearch(research.status === researchStatus.POST_SEARCH);
    }
  }, [research?.id]);
  const finalTabId = tabId || workflowStep.REVIEW;
  const { updateUser } = useUserBackend();
  const { data: user } = useUser();

  const updateUserCount = () => {
    // @ts-ignore
    updateUser.mutate({ userMail: user.email, researchId });
  };

  onMount(updateUserCount);

  useCandidate().getCandidate(candidateId, (data) => {
    const detail = location.pathname.split('/')[location.pathname.split('/').length - 1];
    history.push(Routes.updateUrlWithQuery(Routes.withPath(RESEARCH_CANDIDATE_DETAIL, [
      { label: RESEARCH_ID, value: researchId },
      { label: TAB_ID, value: data.workflow_step.toLowerCase() },
      { label: CANDIDATE_ID, value: candidateId },
      {
        label: DETAIL_ID,
        value: CanditateTab[detail.toUpperCase()]
          ? detail.toLowerCase()
          : CanditateTab.DETAILS.toLowerCase(),
      },
    ]), [
      ...Array.from(queryParams.entries()).map((entry) => ({ label: entry[0], value: entry[1] })),
    ]));
  }, tabId === CANDIDATE_TAB_ID || firstMounted);

  useEffect(() => {
    setFirstMounted(false);
  }, []);

  return (
    <>
      {
        signedReview && research && (
          <Modal
            isOpen
            header={Messages.t('search.edit')}
            onClose={() => {
              setConfirmationModal(true);
            }}
          >
            <div className="search-edit-container">
              <SignedTransitionForm
                candidateId={signedReview}
                research={research}
                onValidate={() => history.push(location.pathname)}
              />
            </div>
          </Modal>
        )
      }
      {
        agreementReview && research && (
          <Modal
            isOpen
            header={Messages.t('search.edit')}
            onClose={() => {
              setConfirmationModal(true);
            }}
          >
            <div className="search-edit-container">
              <AgreementReviewFrom
                candidateId={agreementReview}
                research={research}
                onValidate={() => history.push(location.pathname)}
              />
            </div>
          </Modal>
        )
      }
      <ConfirmationModal
        isOpen={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onConfirm={() => {
          setConfirmationModal(false);
          history.push(location.pathname);
        }}
        title={Messages.t('search.warning')}
        text={Messages.t('search.loseChange')}
      />
      {
        (isLoading || !research)
          ? <LoaderRing visible />
          : <ResearchTabs research={research} activeStep={finalTabId.toUpperCase()} />
      }
      <div className="research-body-container">
        {
          tabId !== CANDIDATE_TAB_ID && research && defaultWorkflowTabs().map((tab) => (
            <div
              key={tab.step}
              className={`workflow-tab-container ${tab.step.toUpperCase() === finalTabId.toUpperCase() ? 'd-visible' : 'd-none'}`}
            >
              <WorkflowStepTab
                candidateId={candidateId}
                tabWorkflowStep={tab.step}
                researchId={researchId}
                tabId={finalTabId.toUpperCase()}
                isActive={finalTabId.toUpperCase() === tab.step}
                research={research}
              />
            </div>
          ))
        }
        <ResearchRightPanel
          research={research}
        />
      </div>
    </>
  );
}
