import React, { ReactNode } from 'react';
import StringUtils from 'services/StringUtils';
import Messages from 'services/i18n/Messages';
import { splitPostalCodes } from 'lib/form/FormUtils';
import { propertyType } from 'types/research';
import { TemplateIcon } from '@heroicons/react/solid';
import {
  Apartment,
  Chair,
  Elevator, Hotel, House,
  Stairs,
} from '@material-ui/icons';
import { ArrowsExpandIcon } from '@heroicons/react/outline';

export const PropertyEditor = {
  TEXT: 'TEXT',
  OPTIONAL_INTEGER: 'OPTIONAL_INTEGER',
  OPTIONAL_BOOLEAN: 'OPTIONAL_BOOLEAN',
  SELECT: 'SELECT',
};

export type PropertyAttributeType = {
  getPropertyValue: (p) => string,
  getReseachValue: (r) => string | Range | null,
  match_research: (value, research_range) => boolean,
  isValid: (value) => boolean,
  fromFormValue: (value) => any,
  property_attr: string,
  no_match_symbol: string,
  name: string,
  getDisplayName: () => string,
  picto?: ReactNode,
  extraPicto?: ReactNode,
  unit?: string,
  editor: string,
  getLongDisplayName?: () => string,
  getEditor_feedback?: () => string,
  editorParams?: { key: string, getLabel: () => string }[]
};

export type PropertyAttributesType = {
  AREA_RANGE: PropertyAttributeType,
  PRICE_RANGE: PropertyAttributeType,
  ROOMS: PropertyAttributeType,
  BEDROOMS: PropertyAttributeType,
  FLOOR_RANGE: PropertyAttributeType,
  ELEVATOR: PropertyAttributeType,
  FURNISHED: PropertyAttributeType,
  PROPERTY_TYPE: PropertyAttributeType,
  POSTAL_CODE: PropertyAttributeType,
};

export const PropertyAttribute: PropertyAttributesType = {
  AREA_RANGE: {
    ...makeRangeAttribute('area_min', 'area_max', 'area'),
    name: 'AREA_RANGE',
    getDisplayName: () => Messages.t('form.field.area'),
    // longDisplayName: "Area of the property",
    unit: '㎡',
    picto: <ArrowsExpandIcon />,
    fromFormValue(value) {
      return toPositiveInteger(value, 1);
    },
    editor: PropertyEditor.OPTIONAL_INTEGER,
  },
  PRICE_RANGE: {
    ...makeRangeAttribute('price_min', 'price_max', 'price'),
    name: 'PRICE_RANGE',
    getDisplayName: () => Messages.t('form.field.price'),
    unit: '€',
    fromFormValue(value) {
      return toPositiveInteger(value, 1);
    },
    editor: PropertyEditor.OPTIONAL_INTEGER,
  },
  ROOMS: {
    ...makeSingleValueAttribute('rooms'),
    name: 'ROOMS',
    getDisplayName: () => Messages.t('form.field.rooms'),
    getLongDisplayName: () => Messages.t('form.field.roomsExtended'),
    picto: <TemplateIcon />,
    match_research: (a, b) => a >= b,
    fromFormValue(value) {
      return toPositiveInteger(value, 1);
    },
    no_match_symbol: '<=',
    editor: PropertyEditor.OPTIONAL_INTEGER,
  },
  BEDROOMS: {
    ...makeSingleValueAttribute('bedrooms'),
    name: 'BEDROOMS',
    getDisplayName: () => Messages.t('form.field.bedrooms'),
    getLongDisplayName: () => Messages.t('form.field.bedroomsExtended'),
    picto: <Hotel />,
    match_research: (a, b) => a >= b,
    fromFormValue(value) {
      return toPositiveInteger(value, 0);
    },
    no_match_symbol: '<=',
    editor: PropertyEditor.OPTIONAL_INTEGER,
  },
  FLOOR_RANGE: {
    ...makeRangeAttribute('floor_min', 'floor_max', 'floor'),
    name: 'FLOOR_RANGE',
    getDisplayName: () => Messages.t('form.field.floor'),
    fromFormValue(value) {
      return toPositiveInteger(value, 0);
    },
    picto: <Stairs />,
    no_match_symbol: '>=',
    editor: PropertyEditor.OPTIONAL_INTEGER,
    getEditor_feedback: () => Messages.t('form.field.floor.feedback'),
  },
  ELEVATOR: {
    ...makeSingleValueAttribute('elevator'),
    name: 'ELEVATOR',
    getDisplayName: () => Messages.t('form.field.elevator'),
    picto: <Elevator />,
    extraPicto: (
      <div className="slashed-elevator">
        <Elevator />
        <div className="slash features" />
      </div>
    ),
    match_research: (a, b) => a === b,
    no_match_symbol: '≠',
    editor: PropertyEditor.OPTIONAL_BOOLEAN,
    editorParams: [
      { key: 'YES', getLabel: () => Messages.t('property.elevator.YES') },
      { key: 'NO', getLabel: () => Messages.t('property.elevator.NO') },
      { key: 'NONE', getLabel: () => Messages.t('property.elevator.NONE') },
    ],
  },
  FURNISHED: {
    ...makeSingleValueAttribute('furnished'),
    name: 'FURNISHED',
    getDisplayName: () => Messages.t('form.field.furnished'),
    picto: <Chair />,
    match_research: (a, b) => a === b,
    no_match_symbol: '≠',
    editor: PropertyEditor.OPTIONAL_BOOLEAN,
    editorParams: [
      { key: 'YES', getLabel: () => Messages.t('property.furnished.YES') },
      { key: 'NO', getLabel: () => Messages.t('property.furnished.NO') },
      { key: 'NONE', getLabel: () => Messages.t('property.furnished.NONE') },
    ],
  },
  PROPERTY_TYPE: {
    ...makeSingleValueAttribute('property_type'),
    name: 'PROPERTY_TYPE',
    getDisplayName: () => Messages.t('form.field.propertyType'),
    picto: <Apartment />,
    extraPicto: <House />,
    no_match_symbol: '≠',
    match_research: (a, b) => a.toLowerCase() === b.toLowerCase(),
    editor: PropertyEditor.SELECT,
    editorParams: Object.values(propertyType)
      .map((key) => (
        { key, getLabel: () => StringUtils.capitalizeFirstLetter(Messages.t(`property.propertyType.${key}`)) }
      )),
  },

  POSTAL_CODE: {
    ...makeAttribute('postal_code'),
    getPropertyValue: (p) => p.postal_code,
    getReseachValue: (r) => r.zip_codes,
    match_research: (value, zip_codes) => zip_codes.includes(value),
    fromFormValue(formValue) {
      const zipCodes = splitPostalCodes(formValue);
      if (zipCodes === null || zipCodes.length !== 1) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw INVALID_VALUE;
      }
      return zipCodes[0];
    },
    name: 'POSTAL_CODE',
    getDisplayName: () => Messages.t('form.field.zipcode'),
    // longDisplayName: "Postcode",
    // no_match_symbol: "≠",
    editor: PropertyEditor.TEXT,
    getEditor_feedback: () => Messages.t('form.field.zipcode.feedback'),
  },
};

const INVALID_VALUE = 'INVALID_VALUE';

function makeAttribute(property_attr) {
  return {
    isValid(value) {
      try {
        this.fromFormValue(value);
        return true;
      } catch (e) {
        if (e === INVALID_VALUE) {
          return false;
        }
        throw e;
      }
    },
    fromFormValue(value) {
      return value;
    },
    property_attr,
    no_match_symbol: '',
  };
}

function makeSingleValueAttribute(name) {
  return {
    ...makeAttribute(name),
    getPropertyValue: (p) => p[name],
    getReseachValue: (r) => r[name],
  };
}

function toPositiveInteger(value, minimum) {
  if (value === null || value === '') {
    return null;
  }

  const finalValue = parseInt(value, 10);
  if (Number.isNaN(finalValue)) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw INVALID_VALUE;
  }
  if (finalValue < minimum) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw INVALID_VALUE;
  }
  return finalValue;
}

function makeRangeAttribute(
  research_attr_min,
  research_attr_max,
  property_attr,
) {
  return {
    ...makeAttribute(property_attr),
    getPropertyValue: (p) => p[property_attr],
    getReseachValue: (r) => {
      const min = r[research_attr_min];
      const max = r[research_attr_max];
      return min === null && max === null ? null : new Range(min, max);
    },
    match_research: (value, research_range) => (
      (research_range.min === null || value >= research_range.min)
      && (research_range.max === null || value <= research_range.max)
    ),
  };
}

export class Range {
  min;

  max;

  constructor(min, max) {
    this.min = min;
    this.max = max;
  }
}
