import {
  CANDIDATE_ID,
  PathParamString, QueryParamString,
  RESEARCH_ID,
  Route,
} from 'routes/Routes';

export const CANDIDATE_ID_AGREEMENT_REVIEW: QueryParamString = 'agreementReview';
export const CANDIDATE_ID_SIGNED_REVIEW: QueryParamString = 'signedReview';

export const CONTACT_ID: PathParamString = ':contactId';
export const CONTACT_TAB: PathParamString = ':tab';

export const CANDIDATE_TAB_ID = 'candidate';
export const TRANSITION_TO_QUERY_ID: QueryParamString = 'transitionTo';

export const ISOCHRONE: Route = '/isochrone';

export const RESEARCH_BASE: Route = '/research';

export const CHAT: Route = '/chat';

export const RESEARCH: Route = `${RESEARCH_BASE}/${RESEARCH_ID}?`;
export const RESEARCH_ADD: Route = `${RESEARCH_BASE}/add`;
export const RESEARCH_EDIT: Route = `${RESEARCH_BASE}/${RESEARCH_ID}/edit`;
export const RESEARCH_HISTORY: Route = `${RESEARCH_BASE}/${RESEARCH_ID}/history`;

export const TAB_ID: PathParamString = ':tabId';
export const RESEARCH_TAB: Route = `${RESEARCH_BASE}/${RESEARCH_ID}/${TAB_ID}`;

export const RESEARCH_CANDIDATE: Route = `${RESEARCH_TAB}/${CANDIDATE_ID}`;
export const RESEARCH_WITH_OPTIONAL_CANDIDATE: Route = `${RESEARCH_CANDIDATE}?`;

export const RESEARCH_CANDIDATE_SIGN: Route = `${RESEARCH_CANDIDATE}/sign`;
export const RESEARCH_CANDIDATE_UTILITIES: Route = `${RESEARCH_CANDIDATE}/utilities`;
export const RESEARCH_CANDIDATE_GIFT: Route = `${RESEARCH_CANDIDATE}/gift`;

export const DETAIL_ID: PathParamString = ':detailId';
export const RESEARCH_CANDIDATE_DETAIL: Route = `${RESEARCH_CANDIDATE}/${DETAIL_ID}`;

export const TRADUCTIONS: Route = '/traductions';
export const TASKS: Route = '/tasks';
export const CONTACTS_LIST: Route = '/contacts';
export const CONTACTS: Route = `${CONTACTS_LIST}/${CONTACT_ID}/${CONTACT_TAB}`;

export const CONTACT_TAB_DETAILS: PathParamString = 'details';
export const CONTACT_TAB_PROPERTIES: PathParamString = 'properties';

export const CONTACTS_DETAILS: Route = `${CONTACTS_LIST}/${CONTACT_ID}/${CONTACT_TAB_DETAILS}`;
export const CONTACTS_PROPERTIES: Route = `${CONTACTS_LIST}/${CONTACT_ID}/${CONTACT_TAB_PROPERTIES}`;

export const DEFAULT: Route = '/';
