import React, { useState } from 'react';
import { Research, researchStatus } from 'types/research';
import {
  ArrowBack,
  ChevronLeft, History,
  Link as LinkIcon, MoreVert,
  Notifications,
} from '@material-ui/icons';
import CopyLinkToClipboard from 'features/hunter/common/CopyLinkToClipboard';
import panelCollapseService from 'services/PanelCollapseService';
import {
  Button, CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip,
} from '@material-ui/core';
import AddPropertyByUrlControl
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/forms/AddPropertyByUrlControl';
import { useLocation, Link, useParams } from 'react-router-dom';
import { RESEARCH_ID, Routes } from 'routes/Routes';

import { RESEARCH_EDIT, RESEARCH_HISTORY } from 'routes/HmRoutes';
import HeaderSearchForm from 'features/hunter/pages/research/header/HeaderSearchForm';
import Messages from 'services/i18n/Messages';
import DateUtils from 'services/DateUtils';
import { format } from 'date-fns';
import { useResearch } from 'network/api/ResearchQueries';
import { NotificationService } from 'services/notification';
import { useUser } from 'reactfire';
import Chat from 'features/common/chat/Chat';
import ChatCounter from 'features/common/chat/ChatCounter';

type Props = {
  research?: Research
};

type Params = {
  researchId: string,
};

const getPrettyDate = (dateStr: string) => {
  const date = new Date(dateStr);
  return format(date, 'dd-MM-yyyy');
};

export default function ResearchHeader({ research }: Props) {
  const { researchId } = useParams<Params>();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [displayDrawer, setDisplayDrawer] = useState(false);
  const researchQueries = useResearch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null);
  const { data: user } = useUser();
  const currentUser = user && {
    id: user.email,
    name: user.displayName,
    email: user.email,
    photoUrl: user.photoURL,
    role: 'matcher',
    welcomeMessage: Messages.t('chat.matcher.welcomeMessage'),
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!research) {
    return null;
  }

  const viewingLeft = 8 - (research.number_accompagnied + research.number_remote);

  const toggleSearchNotification = async () => {
    if (research) {
      setLoading(true);
      try {
        await researchQueries.updateResearch.mutateAsync({
          research_id: research.id,
          data: { enable_notification: !research.enable_notification },
        });
        setLoading(false);
        NotificationService.notifySuccess(Messages.t('toast.hunter.reasearchUpdated'));
      } catch (error) {
        setLoading(false);
        // @ts-ignore
        const { status }: number = error;
        if (status === 500) {
          NotificationService.notifyError(Messages.t('toast.hunter.error'));
        }
      }
    }
  };
  const otherUser = research && {
    id: research.client_email,
    name: `${research.client_first_name} ${research.client_last_name}`,
    email: research.client_email,
    role: 'default',
  };

  const moveInDate = research.status === researchStatus.POST_SEARCH
    ? research.move_in_date
    : research.latest_move_in_date;

  return (
    <header className="hunter-header">
      <div className="header-content">
        <div className="header-start">
          <Tooltip
            title={research.enable_notification ? Messages.t('research.disable_notification') : Messages.t('research.enable_notification')}
          >
            <IconButton
              disabled={loading}
              color={research.enable_notification ? 'primary' : undefined}
              onClick={toggleSearchNotification}
            >
              <Notifications />
            </IconButton>
          </Tooltip>
          <div className="copy-link">
            <CopyLinkToClipboard link={`${window.location.host}/client/research/${research.id}/discover`}>
              <>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {research.client_first_name} {research.client_last_name} <LinkIcon />
              </>
            </CopyLinkToClipboard>
          </div>
          {
            moveInDate ? (
              <Tooltip
                title={Messages.t('research.readyDateDelta', { days: DateUtils.getPositiveDaysDelta(new Date(), new Date(moveInDate)) })}
              >
                <div>
                  {
                    Messages.t(
                      research.status === researchStatus.POST_SEARCH
                        ? 'research.move_in_date'
                        : 'research.latest_move_in_date',
                      { date: getPrettyDate(moveInDate) },
                    )
                  }
                </div>
              </Tooltip>
            ) : (
              <div>
                {
                  Messages.t(
                    research.status === researchStatus.POST_SEARCH
                      ? 'research.no_move_in_date'
                      : 'research.no_latest_move_in_date',
                  )
                }
              </div>
            )
          }
          <IconButton
            className={`open-drawer-button ${displayDrawer ? 'is-open' : ''}`}
            onClick={() => setDisplayDrawer((prevState) => !prevState)}
          >
            <ChevronLeft />
          </IconButton>
        </div>
        {
          displayDrawer && (
            <>
              <div role="presentation" className="background" onClick={() => setDisplayDrawer(false)} />
              <div className="header-drawer">
                <HeaderSearchForm research={research} />
              </div>
            </>
          )
        }
        <div className="header-end">
          <ChatCounter conversationId={researchId} currentUser={currentUser}>
            <Chat
              currentUser={currentUser}
              hasCustomClose
              research={otherUser}
              showCloseInHeader
              isChatbox
              isToggle
            />
          </ChatCounter>
          {
            research.status !== researchStatus.POST_SEARCH && (
              <>
                <Tooltip
                  title={
                    Messages.t('research.veiwingLeftDetails', {
                      accompanied: research.number_accompagnied,
                      remote: research.number_remote,
                    })
                  }
                >
                  <div className="visit-number-container">
                    {Messages.t('research.veiwingLeft')}
                    <div className="circular-container">
                      <CircularProgress
                        size={36}
                        thickness={5}
                        color="inherit"
                        variant="determinate"
                        value={100}
                      />
                      <CircularProgress
                        size={36}
                        thickness={5}
                        className="progress-top"
                        variant="determinate"
                        value={(viewingLeft / 8) * 100}
                      />
                      <div className="viewing-value-container">
                        <div className="viewing-value-content">
                          {viewingLeft}/8
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <AddPropertyByUrlControl
                  researchId={research.id}
                />
              </>
            )
          }
          {
            !Routes.locationMatchWithPath(location, RESEARCH_EDIT) && (
              <Button
                startIcon={<ArrowBack />}
                onClick={() => panelCollapseService.updateOpenSearchEdit(true)}
              >
                Search
              </Button>
            )
          }
          <IconButton
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            className="menu-header"
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <History />
              </ListItemIcon>
              <Link
                to={Routes.withPath(
                  RESEARCH_HISTORY,
                  [
                    { label: RESEARCH_ID, value: research.id },
                  ],
                )}
              >
                <ListItemText>Search history</ListItemText>
              </Link>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
}
