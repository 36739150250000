import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, UseFormTrigger } from 'react-hook-form';
import {
  PostSearch,
  PostSearchForm,
} from 'types/postSearch/PostSearch';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Button, IconButton } from '@material-ui/core';
import TimelineWrapper from 'lib/form/TimelineWrapper';
import { Property } from 'types/property';
import { insuranceStatusTypeEnum } from 'types/postSearch/Insurance';
import AddressAutocompleteWrapper from 'lib/form/AddressAutocompleteWrapper';
import { usePostSearch } from 'network/api/PostSearchQueries';
import { useDropzone } from 'react-dropzone';
import DocumentUtils from 'services/DocumentUtils';
import { NotificationService } from 'services/notification';
import { Delete } from '@material-ui/icons';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

type Props = {
  candidateId: string
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  errors: FieldErrors
  trigger: UseFormTrigger<PostSearchForm>
  handleSubmit: (data: Partial<PostSearch>) => void
  handleSubmitProperty: (data: Partial<Property>) => void
  postSearch: PostSearch,
  setValue: UseFormSetValue<PostSearchForm>,
};

export default function InsuranceForm(
  {
    setValue,
    candidateId,
    control,
    trigger,
    errors,
    apiErrors,
    handleSubmit,
    handleSubmitProperty,
    postSearch,
  }: Props,
) {
  const { uploadPostSearchFile, createLukoLink } = usePostSearch();
  const [submitting, setSubmitting] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,application/pdf',
    maxFiles: 1,
    disabled: submitting,
    onDrop: async (dropedFiles: File[]) => {
      const filesResult = await Promise.all(dropedFiles.map(async (file) => {
        const fileBase64 = await DocumentUtils.getBase64(file);
        return {
          filename: file.name,
          fileBase64,
          fileType: file.type,
        };
      }));
      setSubmitting(true);
      uploadPostSearchFile.mutateAsync({
        postSearchId: postSearch.id,
        field: 'inssurance_certificate_url',
        data: filesResult[0],
      }).catch(() => NotificationService.notifyError(Messages.t('toast.hunter.error')))
        .finally(() => setSubmitting(false));
    },
  });

  return (
    <div>
      <Controller
        name="property_address"
        control={control}
        rules={{ required: true }}
        render={(controller) => (
          <AddressAutocompleteWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            autoSaveSubmit={(value) => handleSubmitProperty({ address: value })}
            label={Messages.t('form.field.address')}
          />
        )}
      />
      <Button onClick={async () => {
        const res = await trigger([
          'property_address',
        ]);
        if (res) {
          createLukoLink.mutateAsync({ candidateId, postSearchId: postSearch.id }).then((data) => setValue('luko_link', data.link));
        }
      }}
      >
        {Messages.t('formButton.getLukoLink')}
      </Button>
      <Controller
        name="luko_link"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            trigger={trigger}
            autoSaveSubmit={(value) => handleSubmit({ luko_link: value })}
            label={Messages.t('form.field.luko_link')}
          />
        )}
      />
      <div className="file-upload-button-container">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button disabled={submitting}>
            {Messages.t('formButton.uploadInsurance')}
          </Button>
        </div>
        {
          postSearch.inssurance_certificate_url && (
            <div>
              <a
                rel="noreferrer"
                href={postSearch.inssurance_certificate_url}
                target="_blank"
              >
                {postSearch.inssurance_certificate_url.split('/').slice(-1)[0]}
              </a>
              <IconButton
                onClick={() => handleSubmit({ inssurance_certificate_url: '' })}
              >
                <Delete />
              </IconButton>
            </div>
          )
        }
      </div>
      <Controller
        name="inssurance_status"
        control={control}
        render={(controller) => (
          <TimelineWrapper
            control={controller}
            onChange={(value) => handleSubmit({ inssurance_status: value })}
            values={
              Object.values(insuranceStatusTypeEnum)
                .map((type) => ({ key: type, label: Messages.t(`insurance.status.${type}`) }))
            }
          />
        )}
      />
    </div>
  );
}
