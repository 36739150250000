import React from 'react';
import { defaultWorkflowTabs, postSearchTabs } from 'services/hunter/defaultworkflowTabs';
import { useCandidates } from 'network/api/CandidateQueries';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import { CANDIDATE_TAB_ID, RESEARCH_TAB, TAB_ID } from 'routes/HmRoutes';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import { Research, researchStatus } from 'types/research';

type Props = {
  research: Research,
  activeStep: string
};

export default function ResearchTabs(
  {
    research,
    activeStep,
  }: Props,
) {
  const candidatesQueries = useCandidates();
  const { isLoading, data: countPerSteps } = candidatesQueries.getCountPerStep(research.id);
  const history = useHistory();
  const selectStep = (event, step) => {
    history.push(
      Routes.withPath(RESEARCH_TAB, [
        { label: RESEARCH_ID, value: research.id },
        { label: TAB_ID, value: step.toLowerCase() },
      ]),
    );
  };

  const getTextLenght = (tab) => {
    let lengthText = '0';
    if (countPerSteps) {
      const stepCount = countPerSteps.find(
        (item) => item.workflow_step === tab.step,
      );
      if (stepCount) {
        lengthText = stepCount.count.toString();
      }
    }
    return lengthText;
  };
  if (activeStep.toLowerCase() === CANDIDATE_TAB_ID.toLowerCase()) {
    return null;
  }

  return (
    <div className="tab-container">
      <Tabs
        value={activeStep}
        onChange={selectStep}
        variant="scrollable"
        scrollButtons
      >
        {
          defaultWorkflowTabs()
            .filter((tab) => research.status !== researchStatus.POST_SEARCH
              || postSearchTabs.includes(tab.step))
            .filter((tab) => getTextLenght(tab) !== '0')
            .map((tab) => (
              <Tab
                disabled={tab.disabled}
                key={tab.step}
                value={tab.step}
                label={`${tab.displayName} ${!isLoading && `(${getTextLenght(tab)})`}`}
              />
            ))
        }
      </Tabs>
    </div>
  );
}
