import React from 'react';
import Messages, { DEFAULT_LANG } from 'services/i18n/Messages';
import TraductionInput from 'features/hunter/pages/traductions/TraductionInput';
import StringUtils from 'services/StringUtils';
import localService from 'services/i18n/LocalService';

type Props = {
  traductionKey: string,
  label: string,
};

export default function TraductionInputs({ traductionKey, label }: Props) {
  return (
    <>
      <div className="traduction-name">{StringUtils.prettyCamelCase(label)}</div>
      {
        localService.getAvailableLocal().map((lang) => (
          lang !== DEFAULT_LANG && (
            <div key={lang}>
              <TraductionInput
                traductionKey={traductionKey}
                language={lang}
                defaultValue={Messages.getTranslations().get(DEFAULT_LANG)?.messages[traductionKey] || ''}
                value={Messages.getTranslations().get(lang)?.messages[traductionKey]}
              />
            </div>
          )
        ))
      }
    </>
  );
}
