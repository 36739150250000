import { useBackend } from 'network/BackendFetchAdapter';
import { useMutation, useQuery } from 'react-query';
import { Translations } from 'services/i18n/Traduction';

export interface Message {
  key: string,
  traduction: string,
  language: string,
}

export interface MessageLanguage {
  label: string,
  code: string,
}

export function useMessageBackend() {
  const backend = useBackend();

  const GetMessage = (onSuccess, enabled = true) => useQuery<{ [key: string]: Translations }>(
    ['getMessage'],
    async () => {
      const response: Message[] = await backend.fetchJson(
        '/api/messages',
      );
      return response.reduce((acc, value) => {
        if (!acc[value.language]) {
          acc[value.language] = { };
        }
        acc[value.language][value.key] = value.traduction;
        return acc;
      }, {}) as { [key: string]: Translations };
    },
    { enabled, onSuccess },
  );

  const GetMessageLanguage = (enabled = true) => useQuery<MessageLanguage[]>(
    ['getMessageLanguage'],
    async () => {
      const response: MessageLanguage[] = await backend.fetchJson(
        '/api/message-languages',
      );
      return response;
    },
    { enabled },
  );

  const UpdateMessage = useMutation(
    // @ts-ignore
    async ({ language, traduction, key }) => {
      const response = await backend.post(
        '/api/messages/create_or_update/',
        {
          language,
          key,
          traduction,
        },
      );
      return response;
    },
  );

  return {
    getMessage: GetMessage,
    updateMessage: UpdateMessage,
    getMessageLanguage: GetMessageLanguage,
  };
}
