import React from 'react';
import { usePropertiesContact } from 'network/api/PropertyQueries';
import {
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import {
  CONTACT_ID,
  CONTACT_TAB,
  CONTACT_TAB_DETAILS,
  CONTACT_TAB_PROPERTIES,
  CONTACTS, CONTACTS_DETAILS,
  CONTACTS_PROPERTIES,
} from 'routes/HmRoutes';
import { Routes } from 'routes/Routes';
import ContactInfoForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/forms/ContactInfoForm';
import ContactProperties from 'features/hunter/pages/contacts/ContactProperties';

type Param = {
  contactId: string,
  tab: string
};

export default function ContactsDetails() {
  const { contactId, tab } = useParams<Param>();
  const { getContact } = usePropertiesContact();
  const { data: contact } = getContact(contactId);
  const history = useHistory();

  if (!contact) {
    return null;
  }
  return (
    <>
      <Tabs
        value={tab}
        onChange={(_, step) => {
          history.push(Routes.withPath(
            CONTACTS,
            [
              { label: CONTACT_ID, value: contactId },
              { label: CONTACT_TAB, value: step.toLowerCase() },
            ],
          ));
        }}
        variant="scrollable"
        scrollButtons
      >
        {
          [CONTACT_TAB_DETAILS, CONTACT_TAB_PROPERTIES].map((step) => (
            <Tab
              key={step}
              value={step}
              label={step}
            />
          ))
        }
      </Tabs>
      <Switch>
        <Route exact path={CONTACTS_DETAILS} render={() => <ContactInfoForm contact={contact} />} />
        <Route exact path={CONTACTS_PROPERTIES} component={ContactProperties} />
      </Switch>
    </>
  );
}
