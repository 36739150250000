import React from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, UseFormTrigger } from 'react-hook-form';
import {
  PostSearch,
  PostSearchForm,
} from 'types/postSearch/PostSearch';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import TimelineWrapper from 'lib/form/TimelineWrapper';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import DateTimePickerWrapper from 'lib/form/DateTimePickerWrapper';
import { internetStatusTypeEnum } from 'types/postSearch/Internet';

type Props = {
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  errors: FieldErrors
  trigger: UseFormTrigger<PostSearchForm>
  handleSubmit: (data: Partial<PostSearch>) => void
};

export default function InternetForm(
  {
    control,
    trigger,
    errors,
    apiErrors,
    handleSubmit,
  }: Props,
) {
  return (
    <div>
      <h5>
        {Messages.t('form.field.buildingInfo')}
      </h5>
      <Controller
        name="internet_fiber"
        control={control}
        render={(controller) => (
          <CheckboxWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            onChange={(value) => handleSubmit({ internet_fiber: value })}
            label={Messages.t('form.field.internet_fiber')}
          />
        )}
      />
      <Controller
        name="internet_doorstep_number"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            trigger={trigger}
            autoSaveSubmit={(value) => handleSubmit({
              internet_doorstep_number: value,
            })}
            label={Messages.t('form.field.internet_doorstep_number')}
          />
        )}
      />
      <Controller
        name="internet_provider"
        control={control}
        rules={{ required: true }}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            trigger={trigger}
            autoSaveSubmit={(value) => handleSubmit({
              internet_provider: value,
            })}
            label={Messages.t('form.field.internet_provider')}
          />
        )}
      />
      <Controller
        name="internet_appointment_date"
        control={control}
        render={(controller) => (
          <DateTimePickerWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            trigger={trigger}
            autoSaveSubmit={(value) => handleSubmit({
              internet_appointment_date: value?.toISOString(),
            })}
            label={Messages.t('form.field.internet_appointment_date')}
          />
        )}
      />
      <Controller
        name="internet_status"
        control={control}
        render={(controller) => (
          <TimelineWrapper
            control={controller}
            onChange={(value) => handleSubmit({ internet_status: value })}
            values={
              Object.values(internetStatusTypeEnum)
                .map((type) => ({ key: type, label: Messages.t(`internet.status.${type}`) }))
            }
          />
        )}
      />
      <Controller
        name="internet_notes"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            trigger={trigger}
            type="textarea"
            rows={5}
            autoSaveSubmit={(value) => handleSubmit({
              internet_notes: value,
            })}
            label={Messages.t('form.field.notes')}
          />
        )}
      />
    </div>
  );
}
