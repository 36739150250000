import React from 'react';
import CandidateList from 'features/client/common/CandidateList';
import { workflowStep } from 'types/WorkflowStep';

export default function Shortlist() {
  return (
    <CandidateList
      listWorkflowStep={workflowStep.NOT_SURE}
    />
  );
}
