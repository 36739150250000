import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { workflowStepMetaData } from 'services/hunter/WorkflowStep';
import { BiTime, BiEdit, BiX } from 'react-icons/bi';
import DeleteCommentControl
  from 'features/hunter/pages/research/researchDetails/candidateDetails/comments/DeleteCommentControl';
import EditCommentForm from 'features/hunter/pages/research/researchDetails/candidateDetails/comments/EditCommentForm';
import { Candidate } from 'types/candidate';
import { CommentType } from 'types/Comment';
import { ShortDateTimeFormat } from 'services/utils';

type Props = {
  candidate: Candidate,
  comment: CommentType,
};

export default function CommentCard({ candidate, comment }: Props) {
  const [editMode, setEditMode] = useState(false);

  return (
    <li>
      <div className="timeline_badge primary">
        {comment.author === 'HOME_MATCHER' ? 'HM' : 'C'}
      </div>
      <div className={`timeline_panel ${!comment.read ? 'unread' : ''}`}>
        <div className="timeline_heading">
          {comment.author === 'HOME_MATCHER' && (
            <Row className="float-right no-gutters">
              <Col className="mr-1">
                {editMode ? (
                  <Button
                    style={{ outline: 'none !important', boxShadow: 'none' }}
                    variant="secondary"
                    size="sm"
                    onClick={() => setEditMode(false)}
                  >
                    <BiX />
                  </Button>
                ) : (
                  <Button
                    style={{ outline: 'none !important', boxShadow: 'none' }}
                    variant="secondary"
                    size="sm"
                    onClick={() => setEditMode(true)}
                  >
                    <BiEdit />
                  </Button>
                )}
              </Col>
              <Col>
                <DeleteCommentControl comment={comment} />
              </Col>
            </Row>
          )}
          <p>
            <small className="text-muted">
              <BiTime />
              &nbsp;
              {ShortDateTimeFormat(comment.updated_at)}
              &nbsp;-&nbsp;
              {workflowStepMetaData(comment.workflow_step).getDisplayName()}
              <br />
              {comment.edited ? '(edited)' : null}
            </small>
          </p>
        </div>
        <div className="timeline_body">
          {
            editMode ? (
              <EditCommentForm
                candidate={candidate}
                onCommentEdited={() => {
                  setEditMode(false);
                }}
                comment={comment}
                author="HOME_MATCHER"
              />
            ) : (
              <p style={{ whiteSpace: 'pre-wrap' }}>{comment.message}</p>
            )
          }
        </div>
      </div>
    </li>
  );
}
