import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useBackend } from 'network/BackendFetchAdapter';
import {
  Activity, ActivityParial,
  ActivityTaskForm, MarkAsReadActivityType,
} from 'types/Activity';
import candidateFilterService from 'services/CandidateFilterService';
import { Candidate } from 'types/candidate';

export function useActivities() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const GetActivities = (candidate_id, enabled = true) => useQuery<Activity[]>(['getActivities', candidate_id], async () => {
    const response = await backend.fetchJson(
      `/api/activities/?candidate=${candidate_id}`,
    );
    return response;
  }, { enabled });

  const GetSearchActivities = (search_id, enabled = true) => useQuery<Activity[]>(['getSearchActivities', search_id], async () => {
    const response = await backend.fetchJson(
      `/api/activities/?candidate__research_id=${search_id}`,
    );
    return response;
  }, { enabled });

  const GetHmTask = (mail, enabled = true) => useQuery<Activity[]>(['getTasks', mail], async () => {
    const response = await backend.fetchJson(
      `/api/activities/?candidate__research__homematcher_email=${mail}&activity_type=TASK&extended=true`,
    );
    return response;
  }, { enabled });

  const MarkActivitiesAsRead = useMutation<any, any, MarkAsReadActivityType>(
    async (data) => {
      const response = await backend.post('/api/activities/mark_as_read/', data);
      return response;
    },
    {
      onSuccess: (_, { candidate }) => {
        queryClient.refetchQueries(['getActivities', candidate]);
      },
    },
  );

  return {
    getActivities: GetActivities,
    getHmTask: GetHmTask,
    getSearchActivities: GetSearchActivities,
    markActivitiesAsRead: MarkActivitiesAsRead,
  };
}

export function useActivity() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const CreateActivity = useMutation<any, any, { candidate: Candidate, data: ActivityTaskForm }>(
    async ({ candidate, data }) => {
      data.candidate = candidate.id;
      const response = await backend.post('/api/activities/', data);
      return response;
    },
    {
      onSuccess: (data, variables) => {
        const { candidate } = variables;
        queryClient.refetchQueries([
          'getActivities',
          data.response.candidate,
        ]);
        queryClient.refetchQueries([
          'getCandidatesForStep',
          {
            research_id: candidate.research,
            step: candidate.workflow_step,
            orderingBy: candidateFilterService.getSort(candidate.workflow_step).ordering,
          },
        ]);
      },
    },
  );

  const UpdateTask = useMutation<any, any, { data: ActivityParial, mail: string }>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ data, mail }) => {
      const response = await backend.patch(`/api/activities/${data.id}/`, data);
      return response;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.refetchQueries([
          'getTasks',
          variables.mail,
        ]);
      },
    },
  );

  const DeleteActivity = useMutation<any, any, Activity>(
    async (data) => {
      const response = await backend.delete(`/api/activities/${data.id}`);
      return response;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.refetchQueries([
          'getActivities',
          variables.candidate,
        ]);
      },
    },
  );

  return {
    createActivity: CreateActivity,
    updateTask: UpdateTask,
    deleteActivity: DeleteActivity,
  };
}
