import React from 'react';
import ClientPageModal from 'features/client/common/ClientPageModal';
import { propertyType, Research } from 'types/research';
import {
  ArrowsExpandIcon, ChipIcon, ColorSwatchIcon,
  CurrencyEuroIcon, EmojiHappyIcon, HomeIcon,
  LocationMarkerIcon,
  MailIcon,
  MailOpenIcon, OfficeBuildingIcon, PencilAltIcon, TemplateIcon,
  UserIcon,
} from '@heroicons/react/outline';
import SearchCard from 'features/client/pages/search/SearchCard';
import Messages from 'services/i18n/Messages';
import { criteriaValue } from 'types/Criteria';
import StringUtils from 'services/StringUtils';

type Props = {
  research: Research
};

const isDefined = (testVar: any): boolean => testVar !== null
  && testVar !== undefined
  && testVar !== '';

export default function Search({ research }: Props) {
  return (
    <ClientPageModal title={Messages.t('clientSearch.title')}>
      <div className="search-display-details">
        <SearchCard icon={<UserIcon />} title={Messages.t('clientSearch.searchClient')} show>
          <>
            {research.client_first_name} {research.client_last_name}
          </>
        </SearchCard>
        <SearchCard icon={<MailIcon />} title={Messages.t('clientSearch.clientMail')} show>
          <>
            {research.client_email}
          </>
        </SearchCard>
        <SearchCard
          icon={<MailOpenIcon />}
          title={Messages.t('clientSearch.hmMail')}
          show={isDefined(research.homematcher_email)}
        >
          <>
            {StringUtils.capitalizeFirstLetter(research.homematcher_email && research.homematcher_email.split('@')[0])}
          </>
        </SearchCard>
        <SearchCard icon={<LocationMarkerIcon />} title={Messages.t('clientSearch.zipCode')} show>
          <>
            {research.zip_codes?.join(', ')}
          </>
        </SearchCard>
        <SearchCard
          icon={<ArrowsExpandIcon />}
          title={Messages.t('clientSearch.area')}
          show={isDefined(research.area_min) || isDefined(research.area_max)}
        >
          {isDefined(research.area_min) && (<div>{Messages.t('clientSearch.min', { value: research.area_min })}</div>)}
          {isDefined(research.area_max) && (<div>{Messages.t('clientSearch.max', { value: research.area_max })}</div>)}
        </SearchCard>

        <SearchCard
          icon={<CurrencyEuroIcon />}
          title={Messages.t('clientSearch.price')}
          show={isDefined(research.price_min) || isDefined(research.price_max)}
        >
          {isDefined(research.price_min) && (
            <div>{Messages.t('clientSearch.min', { value: research.price_min })}</div>)}
          {isDefined(research.price_max) && (
            <div>{Messages.t('clientSearch.max', { value: research.price_max })}</div>)}
        </SearchCard>
        <SearchCard
          icon={<EmojiHappyIcon />}
          title={Messages.t('clientSearch.idealBudget')}
          show={isDefined(research.price_max_search)}
        >
          <>
            {research.price_max_search}
          </>
        </SearchCard>
        <SearchCard
          icon={<TemplateIcon />}
          title={Messages.t('clientSearch.room')}
          show={isDefined(research.rooms) || isDefined(research.bedrooms)}
        >
          {isDefined(research.rooms) && (<div>{Messages.t('clientSearch.minRoom', { value: research.rooms })}</div>)}
          {isDefined(research.bedrooms) && (
            <div>{Messages.t('clientSearch.minBedroom', { value: research.bedrooms })}</div>)}
        </SearchCard>
        <SearchCard
          icon={<ColorSwatchIcon />}
          title={Messages.t('clientSearch.carateristics')}
          show={isDefined(research.furnished) || isDefined(research.elevator)}
        >
          <div>
            {isDefined(research.furnished) && (
              <div>{research.furnished ? Messages.t('property.furnished.YES') : Messages.t('property.furnished.NO')}</div>)}
            {isDefined(research.elevator) && (
              <div>{research.elevator ? Messages.t('property.elevator.YES') : Messages.t('property.elevator.NO')}</div>)}
          </div>
        </SearchCard>
        <SearchCard
          icon={<HomeIcon />}
          title={Messages.t('clientSearch.type')}
          show={research.property_type !== propertyType.ALL}
        >
          <>
            {Messages.t(`property.propertyType.${research.property_type}`)}
          </>
        </SearchCard>
        <SearchCard
          icon={<OfficeBuildingIcon />}
          title={Messages.t('clientSearch.floor')}
          show={isDefined(research.floor_min) || isDefined(research.floor_max)}
        >
          {isDefined(research.floor_min) && (
            <div>{Messages.t('clientSearch.min', { value: research.floor_min })}</div>)}
          {isDefined(research.floor_max) && (
            <div>{Messages.t('clientSearch.max', { value: research.floor_max })}</div>)}
        </SearchCard>
        <SearchCard icon={<PencilAltIcon />} title={Messages.t('clientSearch.notes')} show={isDefined(research.notes)}>
          {
            research.notes && (
              // eslint-disable-next-line react/no-danger
              <div dangerouslySetInnerHTML={{ __html: research.notes }} />
            )
          }
        </SearchCard>
        <SearchCard
          icon={<ChipIcon />}
          title={Messages.t('clientSearch.criterias')}
          show={research.criterias.length > 0}
        >
          <div>
            {research.criterias
              .filter((criteria) => criteria.criteria !== criteriaValue.CITY)
              .map((criteria) => (
                <div className="criteria-row">
                  <div className="criteria-name">
                    {Messages.t(`research.criterias.value.${criteria.criteria}`)}:
                  </div>
                  <div>
                    {criteria.value}
                  </div>
                </div>
              ))}
          </div>
        </SearchCard>
      </div>
    </ClientPageModal>
  );
}
