import React from 'react';
import { Button, FormControl, FormHelperText } from '@material-ui/core';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import Messages from 'services/i18n/Messages';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  error?: FieldErrors,
  apiErrors?: { [key: string]: string[] },
  onPostalCodeChanged?: (zipCode: string) => void,
  autoSaveSubmit?: (value: string) => void,
};

export default function AddressInputWrapper(
  {
    onPostalCodeChanged,
    control,
    apiErrors,
    error,
    autoSaveSubmit,
  }: Props,
) {
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);

  const values = field.value?.split(',') || [];

  const street = (values.length > 2 && values[values.length - 3]) || '';
  const zipCode = (values.length > 1 && values[values.length - 2].trim().split(' ')[0]) || '';
  const city = (values.length > 1 && values[values.length - 2].trim().split(' ')[1]) || '';
  const country = (values.length > 0 && values[values.length - 1].trim()) || '';

  const onChange = (streetInput, zipCodeInput, cityInput, countryInput) => {
    if (zipCodeInput !== zipCode && onPostalCodeChanged) {
      onPostalCodeChanged(zipCodeInput);
    }

    field.onChange(`${streetInput}, ${zipCodeInput} ${cityInput}, ${countryInput}`);
  };

  return (
    <div className="address-input-container">
      <FormControl>
        <div className="row-form">
          <div className="material-textfield-wrapper">
            <TextFieldWrapper
              control={{
                ...control,
                field: {
                  ...field,
                  value: street,
                  onChange: (e) => onChange(
                    e,
                    zipCode,
                    city,
                    country,
                  ),
                },
              }}
              label={Messages.t('form.field.street')}
            />
          </div>
          <div className="material-textfield-wrapper">
            <TextFieldWrapper
              control={{
                ...control,
                field: {
                  ...field,
                  value: zipCode,
                  onChange: (e) => onChange(
                    street,
                    e,
                    city,
                    country,
                  ),
                },
              }}
              label={Messages.t('form.field.zipcode')}
            />
          </div>
        </div>
        <div className="row-form">
          <div className="material-textfield-wrapper">
            <TextFieldWrapper
              control={{
                ...control,
                field: {
                  ...field,
                  value: city,
                  onChange: (e) => onChange(
                    street,
                    zipCode,
                    e,
                    country,
                  ),
                },
              }}
              label={Messages.t('form.field.city')}
            />
          </div>
          <div className="material-textfield-wrapper">
            <TextFieldWrapper
              control={{
                ...control,
                field: {
                  ...field,
                  value: country,
                  onChange: (e) => onChange(
                    street,
                    zipCode,
                    city,
                    e,
                  ),
                },
              }}
              label={Messages.t('form.field.country')}
            />
          </div>
        </div>
        {!!errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        {
          autoSaveSubmit && (
            <Button variant="contained" onClick={() => autoSaveSubmit(field.value)}>
              {Messages.t('formButton.save')}
            </Button>
          )
        }
      </FormControl>
    </div>
  );
}
