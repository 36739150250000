import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TravelMode } from 'services/isochrone/TravelOptions';
import { buildTravelConstraints, stringifyState } from 'features/isochroneMap/search_params';
import Map from 'features/isochroneMap/Map';
import IsochroneLayer from 'features/isochroneMap/IsochroneLayer';
import TravelLocation from 'features/travelLocation/TravelLocation';
import TransitLayer from 'features/isochroneMap/TransitLayer';
import DistrictLayer from 'features/isochroneMap/DistrictLayer';
import IntersectionLayer from 'features/isochroneMap/IntersectionLayer';

export default function IsochroneMap() {
  const location = useLocation();

  const [availableDistricts, setAvailableDistricts] = useState(null);

  const [districtsSelection, setDistrictsSelection] = useState(null);

  const [travelConstraints, setTravelConstraints] = useState(() =>
    // Build travelConstraints from url params (used during first page load)
    buildTravelConstraints(location.search));

  // update search url when params change
  const new_search = useMemo(
    () => (districtsSelection === null
      ? null
      : stringifyState(travelConstraints, districtsSelection)),
    [travelConstraints, districtsSelection],
  );

  // update history when search url
  const history = useHistory();
  useEffect(() => {
    // wait for availableDistricts and districtsSelection
    if (new_search === null) {
      return;
    }
    if (new_search !== location.search) {
      const new_location = {
        pathname: location.pathname,
        search: new_search,
      };
      history.replace(new_location);
    }
  }, [location, history, new_search]);

  const [map, setMap] = useState(null);

  const setTravelContraintByIndex = useCallback(
    (index, transform_fn) => {
      setTravelConstraints((travelConstraints) => {
        const tc = [...travelConstraints];
        transform_fn(tc[index]);
        return tc;
      });
    },
    [setTravelConstraints],
  );

  const handleTravelOptionChanged = useCallback(
    (index, new_travel_options) => setTravelContraintByIndex(index, (tc) => {
      tc.travel_options = new_travel_options;
    }),
    [setTravelContraintByIndex],
  );

  const handleLocationChanged = useCallback(
    (index, new_location) => setTravelContraintByIndex(index, (tc) => {
      tc.location = new_location;
    }),
    [setTravelContraintByIndex],
  );

  const handleIsochoneChanged = useCallback(
    (index, isochrone_geojson) => setTravelContraintByIndex(index, (tc) => {
      tc.isochrone_geojson = isochrone_geojson;
    }),
    [setTravelContraintByIndex],
  );

  const displayTransit = travelConstraints.some(
    (tc) => tc.location.geocoded_coordinates != null
      && tc.travel_options.travel_mode === TravelMode.TRANSIT,
  );

  return (
    <>
      <Map onMapReady={setMap} />
      {map != null && (
        <>
          <div className="loader-container">
            {travelConstraints.map(
              ({
                index, isochone_paint, travel_options, location,
              }) => (
                <IsochroneLayer
                  key={index}
                  map={map}
                  index={index}
                  isochone_paint={isochone_paint}
                  travel_options={travel_options}
                  location={location}
                  onIsochroneChanged={handleIsochoneChanged}
                />
              ),
            )}
          </div>
          <div className="travel-card-container">
            {travelConstraints.map(({ index, travel_options, location }) => (
              <TravelLocation
                key={index}
                map={map}
                id={index}
                travel_options={travel_options}
                location={location}
                onTravelOptionsChanged={handleTravelOptionChanged}
                onLocationChanged={handleLocationChanged}
              />
            ))}
          </div>
          <TransitLayer map={map} displayTransit={displayTransit} />
          <DistrictLayer
            map={map}
            availableDistricts={availableDistricts}
            setAvailableDistricts={setAvailableDistricts}
            districtsSelection={districtsSelection}
            setDistrictsSelection={setDistrictsSelection}
          />
          <IntersectionLayer
            map={map}
            travelConstraints={travelConstraints}
            districtsSelection={districtsSelection}
          />
        </>
      )}
    </>
  );
}
