import { Candidate } from 'types/candidate';
import { Research } from 'types/research';

export interface ClientFilter {
  onlyHmPicks: boolean,
  onlyAvailable: boolean,
  publishedDateStatus: PublishedDateStatus
  showAbovePrice: boolean,
  showBelowPrice: boolean,
  excludedZipCode: string[],
  bedrooms: string[],
}

export type ClientFilterFunctions = {
  [key in keyof ClientFilter]: (
    candidate: Candidate,
    clientFilter: ClientFilter,
    research: Research,
  ) => boolean;
};

export type PublishedDateStatus = 'NEW' | 'CURRENT' | 'ALL';

type PublishedDateStatusEnum = {
  [key in PublishedDateStatus]: PublishedDateStatus;
};

export const publishedDateStatus: PublishedDateStatusEnum = {
  NEW: 'NEW',
  CURRENT: 'CURRENT',
  ALL: 'ALL',
};

type PublishedDateStatusDeltaDays = {
  [key in PublishedDateStatus]: number;
};

export const publishedDateStatusDeltaDay: PublishedDateStatusDeltaDays = {
  NEW: 1, // 1 day
  CURRENT: 4, // 10 day
  ALL: 0,
};
