import React from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  values: { key: string, label: string }[],
  reverse?: boolean
};

export default function FilterSelectorWrapper({ control, values, reverse }: Props) {
  const { field } = control;

  const addToList = (key) => {
    const array = [...field.value, key];
    field.onChange(array);
  };

  const removeFromList = (key) => {
    field.onChange([...field.value.filter((value) => value !== key)]);
  };

  return (
    <div>
      {
        values.map(((value) => (
          <button
            key={value.key}
            type="button"
            className={`button-filter-radio ${field.value.includes(value.key) === !reverse ? 'checked' : ''}`}
            onClick={
              field.value.includes(value.key)
                ? () => removeFromList(value.key)
                : () => addToList(value.key)
            }
          >
            {value.label}
          </button>
        )))
      }
    </div>
  );
}
