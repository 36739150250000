import Polyglot from 'node-polyglot';
import React from 'react';
import { Traduction } from 'services/i18n/Traduction';

export const DEFAULT_LANG = 'default';

export default class Messages {
  private static polyglot = new Polyglot();

  private static translations: Map<string, Traduction> = new Map<string, Traduction>();

  static setAvailableTranslation(traductions: Traduction[]) {
    Messages.translations = new Map<string, Traduction>();
    traductions.forEach((traduction) => Messages.translations.set(traduction.language, traduction));
    Messages.polyglot.extend(Messages.translations.get(DEFAULT_LANG)?.messages);
  }

  static updateTraductionWithLocal(local: string) {
    Messages.polyglot.extend(Messages.translations.get(DEFAULT_LANG)?.messages);
    Messages.polyglot.extend(Messages.translations.get(local)?.messages);
  }

  static getTranslations() {
    return Messages.translations;
  }

  static t(key: string, option?: any): string {
    const str = this.polyglot.t(key, option);
    if (!str.includes('<b>') && !str.includes('<br/>')) {
      return str;
    }
    const strs = str.split('<br/>');
    const manageBTag = (value) => {
      if (!value.includes('<b>')) {
        return value;
      }
      const strsBtag = value.split('<b>');
      return (
        <>
          {value.startsWith('<b>') ? (
            <>
              <b>
                {strsBtag[0].split('</b>')[0]}
              </b>
              {strsBtag[0].split('</b>')[1]}
            </>
          ) : strsBtag[0]}
          {strsBtag.map((strBtag, index) => (
            <React.Fragment key={`${strBtag}`}>
              {
                index !== 0 && (
                  <>
                    <b>
                      {strBtag.split('</b>')[0]}
                    </b>
                    {strBtag.split('</b>')[1]}
                  </>
                )
              }
            </React.Fragment>
          ))}
        </>
      );
    };

    // @ts-ignore
    return (
      <>
        {str.startsWith('<br/>') && <br />}
        {strs.map((value, index) => (
          <React.Fragment key={value}>
            {manageBTag(value)}
            {index !== strs.length - 1 && <br />}
          </React.Fragment>
        ))}
        {str.endsWith('<br/>') && <br />}
      </>
    );
  }
}
