import React from 'react';
import { useCandidate, useViewingAppointment } from 'network/api/CandidateQueries';

import { Research } from 'types/research';
import { detectMobile } from 'services/utils';
import CandidateDetailsMobile from 'features/client/pages/candidateDetails/CandidateDetailsMobile';
import CandidateDetailsDesktop from 'features/client/pages/candidateDetails/CandidateDetailsDesktop';
import { workflowStep } from 'types/WorkflowStep';
import Firework from 'theme/Firework';

type Props = {
  candidateId: string
  research?: Research
};

export default function CandidateDetails({ candidateId, research }: Props) {
  const candidateQueries = useCandidate();
  const viewingQueries = useViewingAppointment();

  const updateViewedCandidate = (candidateData) => {
    if (candidate
      && candidate.workflow_step === workflowStep.APPLICATION_ACCEPTED
      && !candidate.acceptation_viewed) {
      setTimeout(() => {
        candidateQueries.updateCandidate.mutate({
          candidate: candidateData,
          data: { acceptation_viewed: true },
        });
      }, 3000);
    }
    if (candidateData.viewed_by_client === false) {
      candidateQueries.updateCandidate.mutate({
        candidate: candidateData,
        data: { viewed_by_client: true },
      });
    }
  };

  const { data: candidate } = candidateQueries.getCandidate(
    candidateId,
    updateViewedCandidate,
  );

  const { data: viewingAppointement } = viewingQueries.getViewingAppointment(
    candidateId,
  );

  const isMobile = detectMobile();
  return (
    <>
      {
        isMobile ? (
          <CandidateDetailsMobile
            research={research}
            candidate={candidate}
            viewingAppointement={viewingAppointement}
          />
        ) : (
          <CandidateDetailsDesktop
            research={research}
            candidate={candidate}
            viewingAppointement={viewingAppointement}
          />
        )
      }
      {
        candidate
        && candidate.workflow_step === workflowStep.APPLICATION_ACCEPTED
        && !candidate.acceptation_viewed && (
          <Firework />
        )
      }
    </>
  );
}
