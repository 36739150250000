import React from 'react';

import localService from 'services/i18n/LocalService';
import Messages, { DEFAULT_LANG } from 'services/i18n/Messages';
import Modal from 'lib/Modal';
import { useObservable } from 'micro-observables';
import { useMessageBackend } from 'network/api/MessageQueries';

type Props = {
  isOpen: boolean
  onClose: () => void
};

export default function LanguageSelector({ isOpen, onClose }: Props) {
  const { getMessageLanguage } = useMessageBackend();
  const { data } = getMessageLanguage();
  const local = useObservable(localService.getLocal());
  if (!data) {
    return null;
  }

  const getLangLabel = (lang) => {
    const messageLanguage = data.filter((messageL) => messageL.code === lang)[0];
    return messageLanguage ? messageLanguage.label : lang;
  };

  return (
    <Modal
      header={Messages.t('traduction.select')}
      contentClassName="language-selector"
      isOpen={isOpen}
      onClose={onClose}
    >
      <>
        {
          localService.getAvailableLocal().map((lang) => (
            lang !== DEFAULT_LANG && (
              <button
                type="button"
                key={lang}
                className={lang === local ? 'selected' : ''}
                onClick={() => {
                  onClose();
                  localService.toogleLanguage(lang);
                }}
              >
                {getLangLabel(lang)}
              </button>
            )
          ))
        }
      </>
    </Modal>
  );
}
