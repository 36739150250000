import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Messages from 'services/i18n/Messages';

type Props = {
  defaultValue?: string,
  handleCommentWorkflowUpdate: (comment: string) => void,
  setDirty?: (val: boolean) => void,
};

export default function ActivitySimpleCommentForm(
  {
    defaultValue,
    handleCommentWorkflowUpdate,
    setDirty,
  }: Props,
) {
  const defaultVal = defaultValue || '';
  const [value, setValue] = useState(defaultVal);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await handleCommentWorkflowUpdate(value);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (setDirty && value !== defaultVal) {
      setDirty(true);
    }
  }, [setDirty, value]);

  return (
    <>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>{Messages.t('comment.comment')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={value}
              onChange={(event) => setValue(event.target.value)}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <div className="float-right">
        <Button disabled={submitting} onClick={handleSubmit}>{Messages.t('formButton.confirm')}</Button>
      </div>
    </>
  );
}
