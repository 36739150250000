import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, UseFormTrigger } from 'react-hook-form';
import {
  PostSearch,
  PostSearchForm,
} from 'types/postSearch/PostSearch';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import {
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/core';
import {
  meetingTypeEnum, onlineStatusTypeEnum,
  proxyStatusTypeEnum,
  signatureOrganisationByType, signatureOrganisationTypeEnum,
  signatureTypeEnum,
} from 'types/postSearch/Signature';
import DatePickerWrapper from 'lib/form/DatePickerWrapper';
import TimelineWrapper from 'lib/form/TimelineWrapper';
import DateTimePickerWrapper from 'lib/form/DateTimePickerWrapper';
import { Property } from 'types/property';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { usePostSearch } from 'network/api/PostSearchQueries';
import { useDropzone } from 'react-dropzone';
import DocumentUtils from 'services/DocumentUtils';
import { NotificationService } from 'services/notification';
import { Delete } from '@material-ui/icons';

type Props = {
  candidateId: string,
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  formField: PostSearchForm,
  errors: FieldErrors
  trigger: UseFormTrigger<PostSearchForm>
  handleSubmit: (data: Partial<PostSearch>) => void
  handleSubmitProperty: (data: Partial<Property>) => void
  setValue: UseFormSetValue<PostSearchForm>,
  postSearch: PostSearch,
};

export default function SignatureForm(
  {
    candidateId,
    control,
    trigger,
    formField,
    errors,
    apiErrors,
    handleSubmit,
    handleSubmitProperty,
    postSearch,
    setValue,
  }: Props,
) {
  const { uploadPostSearchFile, createSignaturePostSearch } = usePostSearch();
  const [submitting, setSubmitting] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,application/pdf',
    maxFiles: 1,
    disabled: submitting,
    onDrop: async (dropedFiles: File[]) => {
      const filesResult = await Promise.all(dropedFiles.map(async (file) => {
        const fileBase64 = await DocumentUtils.getBase64(file);
        return {
          filename: file.name,
          fileBase64,
          fileType: file.type,
        };
      }));
      setSubmitting(true);
      uploadPostSearchFile.mutateAsync({
        postSearchId: postSearch.id,
        field: 'proxy_letter_url',
        data: filesResult[0],
      }).catch(() => NotificationService.notifyError(Messages.t('toast.hunter.error')))
        .finally(() => setSubmitting(false));
    },
  });

  return (
    <div>
      <h5>{Messages.t('form.field.signatureKind')}</h5>
      <Controller
        name="signature_kind"
        control={control}
        render={(controller) => (
          <ToggleButtonGroup
            {...controller.field}
            color="primary"
            exclusive
            onChange={
              (_, val) => {
                controller.field.onChange(val);
                handleSubmit({ signature_kind: val });
              }
            }
          >
            {
              Object.values(signatureTypeEnum).map((type) => (
                <ToggleButton
                  key={type}
                  value={type}
                >
                  {Messages.t(`signature.kind.${type}`)}
                </ToggleButton>
              ))
            }
          </ToggleButtonGroup>
        )}
      />
      <div>
        <Controller
          name="signature_organisation_kind"
          control={control}
          defaultValue={signatureOrganisationTypeEnum.PERSON}
          render={(controller) => (
            <ToggleButtonGroup
              {...controller.field}
              color="primary"
              exclusive
              onChange={
                (_, val) => {
                  controller.field.onChange(val);
                  handleSubmit({ signature_organisation_kind: val });
                }
              }
            >
              {
                Object.values(signatureOrganisationByType[formField.signature_kind])
                  .map((type) => (
                    <ToggleButton
                      key={type}
                      value={type}
                    >
                      {Messages.t(`signature.organisationKind.${type}`)}
                    </ToggleButton>
                  ))
              }
            </ToggleButtonGroup>
          )}
        />
      </div>
      {
        formField.signature_kind === signatureTypeEnum.PHYSICAL
        && formField.signature_organisation_kind === signatureOrganisationTypeEnum.ACCOMPAGNIED && (
          <div className="row-form">
            <Controller
              name="ga_name"
              control={control}
              rules={{ required: true }}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ ga_name: value })}
                  label={Messages.t('form.field.ga_name')}
                />
              )}
            />
            <Controller
              name="ga_mail"
              control={control}
              rules={{ required: true }}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ ga_mail: value })}
                  label={Messages.t('form.field.ga_mail')}
                />
              )}
            />
            <Controller
              name="ga_phone"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  trigger={trigger}
                  autoSaveSubmit={(value) => handleSubmit({ ga_phone: value })}
                  label={Messages.t('form.field.ga_phone')}
                />
              )}
            />
          </div>
        )
      }
      {
        formField.signature_kind === signatureTypeEnum.PHYSICAL
        && formField.signature_organisation_kind === signatureOrganisationTypeEnum.ON_BEHALF && (
          <>
            <div className="row-form">
              <Controller
                name="proxy_name"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ proxy_name: value })}
                    label={Messages.t('form.field.proxy_name')}
                  />
                )}
              />
              <Controller
                name="proxy_mail"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ proxy_mail: value })}
                    label={Messages.t('form.field.proxy_mail')}
                  />
                )}
              />
              <Controller
                name="proxy_phone"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ proxy_phone: value })}
                    label={Messages.t('form.field.proxy_phone')}
                  />
                )}
              />
            </div>
            <div className="row-form">
              <Controller
                name="proxy_birthdate"
                control={control}
                render={(controller) => (
                  <DatePickerWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({
                      proxy_birthdate: value?.toISOString().split('T')[0],
                    })}
                    label={Messages.t('form.field.proxy_birthdate')}
                  />
                )}
              />
              <Controller
                name="proxy_birthplace"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ proxy_birthplace: value })}
                    label={Messages.t('form.field.proxy_birthplace')}
                  />
                )}
              />
            </div>
            <Controller
              name="proxy_status"
              control={control}
              render={(controller) => (
                <TimelineWrapper
                  control={controller}
                  onChange={(value) => handleSubmit({ proxy_status: value })}
                  values={
                    Object.values(proxyStatusTypeEnum)
                      .map((type) => ({ key: type, label: Messages.t(`proxy.status.${type}`) }))
                  }
                />
              )}
            />
            <div className="file-upload-button-container">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button disabled={submitting}>
                  {Messages.t('formButton.uploadLetter')}
                </Button>
              </div>
              {
                postSearch.proxy_letter_url && (
                  <div>
                    <a
                      rel="noreferrer"
                      href={postSearch.proxy_letter_url}
                      target="_blank"
                    >
                      {postSearch.proxy_letter_url.split('/').slice(-1)[0]}
                    </a>
                    <IconButton
                      onClick={() => handleSubmit({ proxy_letter_url: '' })}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                )
              }
            </div>
          </>
        )
      }
      {
        formField.signature_kind === signatureTypeEnum.PHYSICAL && (
          <>
            <h5>{Messages.t('form.field.meeting_address')}</h5>
            <Controller
              name="meeting_address_kind"
              control={control}
              render={(controller) => (
                <ToggleButtonGroup
                  {...controller.field}
                  color="primary"
                  exclusive
                  onChange={
                    (_, val) => {
                      controller.field.onChange(val);
                      handleSubmit({ meeting_address_kind: val });
                    }
                  }
                >
                  {
                    Object.values(meetingTypeEnum).map((type) => (
                      <ToggleButton
                        key={type}
                        value={type}
                      >
                        {Messages.t(`meeting_address.kind.${type}`)}
                      </ToggleButton>
                    ))
                  }
                </ToggleButtonGroup>
              )}
            />
            <div className="row-form">
              {
                formField.meeting_address_kind === meetingTypeEnum.PROPERTY ? (
                  <Controller
                    name="meeting_address"
                    control={control}
                    render={(controller) => (
                      <TextFieldWrapper
                        apiErrors={apiErrors}
                        error={errors}
                        control={controller}
                        trigger={trigger}
                        autoSaveSubmit={(value) => handleSubmit({ meeting_address: value })}
                        label={Messages.t('form.field.meeting_address')}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    name="property_address"
                    control={control}
                    render={(controller) => (
                      <TextFieldWrapper
                        apiErrors={apiErrors}
                        error={errors}
                        control={controller}
                        trigger={trigger}
                        autoSaveSubmit={(value) => handleSubmitProperty({ address: value })}
                        label={Messages.t('form.field.address')}
                      />
                    )}
                  />
                )
              }
              <Controller
                name="meeting_date"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <DateTimePickerWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    trigger={trigger}
                    autoSaveSubmit={(value) => handleSubmit({ meeting_date: value?.toISOString() })}
                    label={Messages.t('form.field.meeting_date')}
                  />
                )}
              />
            </div>
            <Button onClick={async () => {
              const res = await trigger([
                'meeting_date',
                'proxy_mail',
                'proxy_name',
                'ga_name',
                'ga_mail',
              ]);
              if (res) {
                setValue('signature_checked', true);
                handleSubmit({ signature_checked: true });
                createSignaturePostSearch.mutateAsync({ candidateId });
              }
            }}
            >
              {Messages.t('formButton.sendInvitation')}
            </Button>
          </>
        )
      }
      {
        formField.signature_kind === signatureTypeEnum.ONLINE
        && formField.signature_organisation_kind === signatureOrganisationTypeEnum.ORGANISED_BY_SWT
        && (
          <Controller
            name="signature_status"
            control={control}
            render={(controller) => (
              <TimelineWrapper
                control={controller}
                onChange={(value) => handleSubmit({ signature_status: value })}
                values={
                  Object.values(onlineStatusTypeEnum)
                    .map((type) => ({ key: type, label: Messages.t(`signature.status.${type}`) }))
                }
              />
            )}
          />
        )
      }
    </div>
  );
}
