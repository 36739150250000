import React, { useState } from 'react';
import { WorkflowStep } from 'types/WorkflowStep';
import ClientPageModal from 'features/client/common/ClientPageModal';
import { useCandidates } from 'network/api/CandidateQueries';
import { useParams } from 'react-router-dom';
import MapCandidateCard from 'features/client/pages/map/MapCandidateCard';
import MapWrapper from 'features/client/pages/map/MapWrapper';
import CardCarrousel from 'features/client/common/CardCarrousel';
import Messages from 'services/i18n/Messages';

type Props = {
  mapStep: WorkflowStep
};

type Params = {
  researchId: string,
};

export default function Map({ mapStep }: Props) {
  const { researchId } = useParams<Params>();
  const [selectedCandidateIds, setSelectedCandidateIds] = useState<string[] | null>(null);

  const candidatesQueries = useCandidates();
  const {
    isLoading,
    data: dataAPI,
  } = candidatesQueries.getCandidatesForStep(
    researchId,
    mapStep,
    '-workflow_step_changed_date',
  );

  const candidates = dataAPI?.pages
    .map((result) => result.results)?.flat()
    .filter((candidate) => !!candidate.property?.latitude
      || !!candidate.property?.fluximmo_raw_advert?.latitude);

  const selectedCandidates = selectedCandidateIds
    && candidates?.filter((candidate) => selectedCandidateIds.includes(candidate.id));
  return (
    <ClientPageModal className="map-container" title={Messages.t('map.title')}>
      {
        candidates && candidates.length > 0 && (
          <MapWrapper
            displayErrorMessage
            candidates={candidates}
            setSelectedCandidateId={setSelectedCandidateIds}
            selectedCandidateId={selectedCandidateIds && selectedCandidateIds[0]}
          />
        )
      }
      {
        !(candidates && candidates.length > 0) && !isLoading && (
          <div className="error-message">
            {Messages.t('map.unableToLocateTracked')}
          </div>
        )
      }
      {
        selectedCandidates && (
          <div className="candidate-list-card-container">
            <CardCarrousel enableTouch listLength={selectedCandidates.length}>
              {
                selectedCandidates
                  .map((candidate) => (
                    <MapCandidateCard
                      onArchive={() => setSelectedCandidateIds(null)}
                      candidate={candidate}
                    />
                  ))
              }
            </CardCarrousel>
          </div>
        )
      }
    </ClientPageModal>
  );
}
