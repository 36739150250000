import React from 'react';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Control, Controller } from 'react-hook-form';
import { ResearchFormData } from 'types/forms/ResearchType';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import {
  Chip, IconButton, Popover,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Messages from 'services/i18n/Messages';
import SelectWrapper from 'lib/form/SelectWrapper';
import {
  CriteriaPartial,
  criteriaPossibleValues,
  criteriaTypeValue,
  editorType,
  required,
} from 'types/Criteria';
import SliderWrapper from 'lib/form/SliderWrapper';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form';

type Props = {
  control?: Control<ResearchFormData>,
  index: number,
  formField: ResearchFormData,
  errors: FieldErrors
  apiErrors: { [key: string]: string[] }
  setValue: UseFormSetValue<ResearchFormData>
  remove: () => void,
  trigger: UseFormTrigger<ResearchFormData>,
  item: CriteriaPartial,
};

export default function CriteriaForm(
  {
    index,
    control,
    formField,
    errors,
    apiErrors,
    remove,
    setValue,
    trigger,
    item,
  }: Props,
) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const criteriaItem = formField.criterias[index] || item;
  const possibleValues = criteriaPossibleValues[criteriaItem.criteria || ''];
  return (
    <>
      <Chip
        label={`${Messages.t(`research.criterias.value.${criteriaItem.criteria}`)} ${criteriaItem.value === required ? '' : ((!possibleValues || possibleValues.editor === editorType.TEXTFIELD) ? criteriaItem.value : Messages.t(`research.criterias.enum.${criteriaItem.value}`))} ${criteriaItem?.weight}/5`}
        onDelete={remove}
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={async () => {
          const result = await trigger(`criterias.${index}.value`)
            && await trigger(`criterias.${index}.criteria`)
            && await trigger(`criterias.${index}.weight`);
          if (result) {
            handleClose();
          }
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >

        <div className="criteria-container">
          <div className="row-form">
            <Controller
              name={`criterias.${index}.criteria` as 'criterias.0.criteria'}
              control={control}
              rules={{ required: true }}
              defaultValue={criteriaItem.criteria}
              render={(controller) => (
                <SelectWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  onChange={(value) => setValue(`criterias.${index}.value`, criteriaPossibleValues[value]?.defaultValue)}
                  requierd
                  label={Messages.t('form.field.criteria')}
                  values={(criteriaTypeValue[criteriaItem.type || ''] || [])
                    .map((key) => (
                      { key, label: Messages.t(`research.criterias.value.${key}`) }
                    ))}
                />
              )}
            />
            <Controller
              name={`criterias.${index}.value` as 'criterias.0.value'}
              control={control}
              defaultValue={criteriaItem.value}
              rules={{ required: true }}
              render={(controller) => {
                if (!possibleValues) {
                  return (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      disabled
                      label={Messages.t('research.criterias.pleaseSelectValue')}
                    />
                  );
                }
                if (possibleValues.editor === editorType.TEXTFIELD) {
                  return (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      disabled={!possibleValues.editable}
                      requierd
                      suffix={possibleValues.postfix && Messages.t(`research.criterias.${possibleValues.postfix}`)}
                      type={possibleValues.onlyNumbers ? 'number' : 'text'}
                      label={Messages.t('form.field.value')}
                    />
                  );
                }
                return (
                  <SelectWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    requierd
                    label={Messages.t('form.field.value')}
                    values={possibleValues.posibleValue
                      .map((key) => (
                        { key, label: Messages.t(`research.criterias.enum.${key}`) }
                      ))}
                  />
                );
              }}
            />
            <Controller
              name={`criterias.${index}.weight` as 'criterias.0.weight'}
              control={control}
              rules={{ required: true }}
              defaultValue={criteriaItem.weight || '3'}
              render={(controller) => (
                <SliderWrapper
                  control={controller}
                  requierd
                  min={1}
                  max={5}
                  label={Messages.t('form.field.weight')}
                  marks={[{ value: 1, label: 'Not important' }, { value: 5, label: 'Important' }]}
                />
              )}
            />
            <IconButton onClick={remove}>
              <CancelIcon />
            </IconButton>
          </div>
        </div>
      </Popover>
    </>
  );
}
