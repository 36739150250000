import React, { useEffect } from 'react';
import ClientRouter from 'features/client/ClientRouter';
import Header from 'features/client/layout/Header';
import NavBar from 'features/client/layout/NavBar';
import { useParams } from 'react-router-dom';
import { ClientTabTypeLower } from 'routes/ClientRoutes';
import { useResearch } from 'network/api/ResearchQueries';
import { ClientSearchService } from 'services/client/ClientSearchService';

type Params = {
  researchId: string,
  clientTabId: ClientTabTypeLower
};

export default function ClientApp() {
  const { researchId } = useParams<Params>();
  const researchQueries = useResearch();

  const { data: research } = researchQueries.getResearch(researchId);

  useEffect(() => {
    ClientSearchService.setSearch(researchId);
  }, [researchId]);

  return (
    <div className="client-root">
      <div className="client-navigation">
        <Header research={research} />
        <ClientRouter research={research} />
      </div>
      <NavBar />
    </div>
  );
}
