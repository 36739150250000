import React from 'react';
import { Candidate } from 'types/candidate';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { Menu, MenuItem } from '@material-ui/core';
import Button from 'features/client/common/Button';
import Messages from 'services/i18n/Messages';
import { domainFromUrl } from 'services/utils';

type Props = {
  candidate: Candidate,
};

export default function Links({ candidate }: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (candidate.property.duplicate_urls.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      window.open(candidate.property.source_url, '_blank')?.focus();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!candidate.property.source_url || candidate.property.source_url === '') {
    return null;
  }
  return (
    <div className="link-container">
      <Button
        onClick={handleClick}
        variant="primary"
      >
        <ChevronRightIcon />
        {
          candidate.property.duplicate_urls.length > 0 ? (
            <span>{Messages.t('candidate.links.openOriginal')}</span>
          ) : (
            <span>{Messages.t('candidate.linsk.seeMore', { link: domainFromUrl(candidate.property.source_url) })}</span>
          )
        }
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <a href={candidate.property.source_url} target="_blank" rel="noreferrer">
            {domainFromUrl(candidate.property.source_url)}
          </a>
        </MenuItem>
        {
          candidate.property.duplicate_urls.map((url) => (
            <MenuItem key={url} onClick={handleClose}>
              <a href={url} target="_blank" rel="noreferrer">
                {domainFromUrl(url)}
              </a>
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}
