import React, { useEffect, useState } from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Button from 'features/client/common/Button';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { ClientSearchService } from 'services/client/ClientSearchService';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import { CLIENT_DISCOVER, CLIENT_RESEARCH } from 'routes/ClientRoutes';
import { SetSearchForm } from 'types/forms/SetSearchForm';

export default function ClientSearchInput() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState({});

  const goToSearch = (searchId?: string) => {
    if (searchId) {
      history.push(Routes.withPath(CLIENT_DISCOVER, [
        { label: RESEARCH_ID, value: searchId },
      ]));
    }
  };

  useEffect(() => {
    ClientSearchService.getSearch().then((data) => {
      goToSearch(data.token);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SetSearchForm>();

  const onSubmit: SubmitHandler<SetSearchForm> = (formData: SetSearchForm) => {
    // eslint-disable-next-line no-useless-escape
    const url = formData.url.replace(/^.*\/\/[^\/]+/, '');
    const searchId = Routes.retrieveInPath(url, RESEARCH_ID, CLIENT_RESEARCH);
    if (searchId) {
      goToSearch(searchId);
    } else {
      setApiError({ url: [Messages.t('candidate.add.processing')] });
    }
  };
  if (isLoading) {
    return null;
  }

  return (
    <div className="client-root">
      <div className="add-listing">
        <div className="add-listing-text">
          {Messages.t('searchForm.paste')}
        </div>
        <form className="add-listing-form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="url"
            control={control}
            rules={
              {
                required: true,
              }
            }
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiError}
                variant="outlined"
                error={errors}
                control={controller}
                placeholder={Messages.t('form.field.copyLinkPlaceholder')}
                label={Messages.t('form.field.copyLink')}
              />
            )}
          />
          <div className="buttons-container">
            <Button
              type="submit"
              variant="primary"
              spin={false}
            >
              <div className="flex-filler">
                <CheckCircleIcon />
              </div>
              <span>{Messages.t('searchForm.setLink')}</span>
              <div className="flex-filler" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
