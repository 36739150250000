import React, { useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory, useParams } from 'react-router-dom';
import { useCandidate } from 'network/api/CandidateQueries';
import { SubmitHandler, Controller, useForm } from 'react-hook-form';
import {
  clientNotationExtraInfo,
  CommentForm,
} from 'types/forms/CommentForm';
import { Candidate } from 'types/candidate';
import RadioWrapper from 'lib/form/RadioWrapper';
import { WorkflowStep, workflowStep } from 'types/WorkflowStep';
import Button from 'features/client/common/Button';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Dialog } from '@material-ui/core';
import { workflowStepClientAction, workflowStepMetaData, WorkflowStepMetaDataType } from 'services/hunter/WorkflowStep';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import { activityType } from 'types/Activity';
import { FetchError } from 'network/Errors';
import { CLIENT_TRACK } from 'routes/ClientRoutes';

type Props = {
  candidate: Candidate,
  targetWorkflowStep: WorkflowStep | null,
  onClose: () => void,
  isOpen: boolean,
  workflowStepMetadata: WorkflowStepMetaDataType | null,
};

type Params = {
  researchId: string,
};

export const NO_COMMENT = 'The customer did not leave a comment';

export default function CandidateModal(
  {
    candidate,
    targetWorkflowStep,
    onClose,
    isOpen,
    workflowStepMetadata,
  }: Props,
) {
  const { updateCandidateStep } = useCandidate();
  const { researchId } = useParams<Params>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [displayAgreement, setDisplayAgreement] = useState(false);
  const [apiErrors, setApiError] = useState({});

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CommentForm>({
    defaultValues: {
      clientNotation: String(candidate?.client_notation) || '',
      comment: '',
    },
  });
  const onSubmit: SubmitHandler<CommentForm> = (formData: CommentForm) => {
    setLoading(true);

    updateCandidateStep.mutate(
      {
        candidate,
        data: {
          is_client_update: true,
          workflow_step: targetWorkflowStep,
          workflow_step_changed_date: new Date(),
          activity_type: activityType.CLIENT,
          message_title: 'Client comment',
          message_content: formData.comment || NO_COMMENT,
          client_notation: formData.clientNotation ? parseInt(formData.clientNotation, 10) : 0,
        },
      },
      {
        onSuccess: () => {
          setLoading(false);
          if (targetWorkflowStep && targetWorkflowStep === workflowStep.AGREEMENT) {
            setDisplayAgreement(true);
          } else {
            onClose();
            if (targetWorkflowStep) {
              NotificationService.notifySuccess(Messages.t('toast.client.movedListing', { stepDisplayName: workflowStepMetaData(targetWorkflowStep).getClientDisplayName() }));
              const clientAction = workflowStepClientAction[targetWorkflowStep];
              if (clientAction) {
                history.push(
                  Routes.withPath(
                    clientAction.clientRedirection,
                    [{ label: RESEARCH_ID, value: researchId }],
                  ),
                );
              }
            }
          }
        },
        onError: (error: FetchError) => {
          setApiError(error.json_response);
          setLoading(false);
        },
      },
    );
  };
  const closeAgreementModal = () => {
    history.push(
      Routes.withPath(
        CLIENT_TRACK,
        [{ label: RESEARCH_ID, value: researchId }],
      ),
    );
    onClose();
  };

  if (displayAgreement) {
    return (
      <Dialog
        open={isOpen}
        onClose={closeAgreementModal}
      >
        <div className="candidate-modal-content">
          <h3>
            {Messages.t('AGREEMENT.second_dialog.title')}
          </h3>
          <div>
            {Messages.t('AGREEMENT.second_dialog.text')}
          </div>
        </div>
        <DialogActions>
          <Button onClick={closeAgreementModal} variant="primary">
            {Messages.t('formButton.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="candidate-modal-content">
        {
          targetWorkflowStep && (
            <>
              <h3>
                {
                  workflowStepMetadata?.clientDialog?.getTitle()
                }
              </h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                {
                  targetWorkflowStep === workflowStep.VIEWING_REQUESTED && (
                    <>
                      <div>
                        {Messages.t('candidate.modal.like')}
                      </div>
                      <Controller
                        name="clientNotation"
                        control={control}
                        render={(controller) => (
                          <RadioWrapper
                            hideButton
                            control={controller}
                            error={errors}
                            row
                            apiErrors={apiErrors}
                            values={
                              Object.keys(clientNotationExtraInfo).map((key) => ({
                                key,
                                label: (
                                  <div>
                                    <div className="emoji big-emoji">{clientNotationExtraInfo[key].emoji}</div>
                                    <div className="notation-label">{clientNotationExtraInfo[key].text()}</div>
                                  </div>
                                ),
                              }))
                            }
                          />
                        )}
                      />
                    </>
                  )
                }
                {
                  workflowStepMetadata?.clientDialog?.getText()
                }
                {
                  targetWorkflowStep !== workflowStep.NOT_SURE
                  && targetWorkflowStep !== workflowStep.AGREEMENT && (
                    <Controller
                      name="comment"
                      control={control}
                      rules={{
                        required: (targetWorkflowStep === workflowStep.NOT_INTERESTED
                            && candidate.workflow_step === workflowStep.SENT
                            && Messages.t('candidate.modal.helpHm'))
                          || (targetWorkflowStep === workflowStep.NOT_SURE
                            && Messages.t('candidate.modal.missing')),
                      }}
                      render={(controller) => (
                        <TextFieldWrapper
                          error={errors}
                          control={controller}
                          label="Comment"
                        />
                      )}
                    />
                  )
                }
                <DialogActions>
                  <Button onClick={onClose} variant="secondary">
                    {Messages.t('formButton.cancel')}
                  </Button>
                  <Button spin={loading} type="submit" variant="primary">
                    {Messages.t('formButton.confirm')}
                  </Button>
                </DialogActions>
              </form>
            </>
          )
        }
      </div>
    </Dialog>
  );
}
