import { observable, WritableObservable } from 'micro-observables';
import { EditorType, editorType } from 'types/Criteria';
import { propertyType, Research } from 'types/research';
import { Candidate } from 'types/candidate';
import ObjectUtils from 'services/ObjectUtils';
import { format } from 'date-fns';
import StringUtils from 'services/StringUtils';
import { WorkflowStep, workflowStep } from 'types/WorkflowStep';
import { fuzzysearch } from 'lib/FuzzySearch';
import { ContactType } from 'types/forms/ContactInfoForm';

type PossibleOperand =
  'IN'
  | 'EQUAL'
  | 'NOT_EQUAL'
  | 'GREATER_THAN'
  | 'LOWER_THAN'
  | 'IS_EMPTY'
  | 'NOT_IN'
  | 'FUZZY_SEARCH'
  | 'FUZZY_NOT'
  | 'SEARCH'
  | 'NOT_IN_SEARCH'
  ;

type PossibleOperandEnum = { [key in PossibleOperand]: key };

export const possibleOperand: PossibleOperandEnum = {
  IN: 'IN',
  EQUAL: 'EQUAL',
  GREATER_THAN: 'GREATER_THAN',
  LOWER_THAN: 'LOWER_THAN',
  NOT_IN: 'NOT_IN',
  NOT_EQUAL: 'NOT_EQUAL',
  IS_EMPTY: 'IS_EMPTY',
  FUZZY_SEARCH: 'FUZZY_SEARCH',
  FUZZY_NOT: 'FUZZY_NOT',
  SEARCH: 'SEARCH',
  NOT_IN_SEARCH: 'NOT_IN_SEARCH',
};

type Sort = 'ASC' | 'DESC';

type SortEnum = { [key in Sort]: Sort };

export const sortType: SortEnum = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export type PossibleFilter = {
  [key: string]: {
    label: string,
    field: string,
    defaultOperand?: PossibleOperand,
    possibleOperand: PossibleOperand[],
    editor: EditorType,
    getPossibleValues?: (research: Research) => { key: string, label: string }[],
    isNumber?: boolean,
    isSortable?: boolean,
    isZipCode?: boolean,
    isString?: boolean,
    tooltip?: string,
  }
};

export const possibleFilter: PossibleFilter = {
  'property.title': {
    label: 'filter.titleDescription',
    field: 'property.title',
    defaultOperand: possibleOperand.SEARCH,
    possibleOperand: [
      possibleOperand.FUZZY_SEARCH,
      possibleOperand.FUZZY_NOT,
      possibleOperand.SEARCH,
      possibleOperand.NOT_IN_SEARCH,
    ],
    editor: editorType.TEXTFIELD,
    tooltip: 'filter.tooltip.title',
  },
  'property.postal_code': {
    label: 'filter.city',
    field: 'property.postal_code',
    defaultOperand: possibleOperand.IN,
    possibleOperand: [possibleOperand.IN, possibleOperand.NOT_IN],
    editor: editorType.SELECT,
    isZipCode: true,
  },
  'property.bedrooms': {
    label: 'filter.bedrooms',
    field: 'property.bedrooms',
    defaultOperand: undefined,
    possibleOperand: [
      possibleOperand.EQUAL,
      possibleOperand.NOT_EQUAL,
      possibleOperand.GREATER_THAN,
      possibleOperand.LOWER_THAN,
    ],
    editor: editorType.TEXTFIELD,
    isNumber: true,
  },
  'property.rooms': {
    label: 'filter.rooms',
    field: 'property.rooms',
    defaultOperand: undefined,
    possibleOperand: [
      possibleOperand.EQUAL,
      possibleOperand.NOT_EQUAL,
      possibleOperand.GREATER_THAN,
      possibleOperand.LOWER_THAN,
    ],
    editor: editorType.TEXTFIELD,
    isNumber: true,
  },
  'property.published_date': {
    label: 'filter.publishedDate',
    field: 'property.published_date',
    defaultOperand: undefined,
    possibleOperand: [
      possibleOperand.EQUAL,
      possibleOperand.NOT_EQUAL,
      possibleOperand.GREATER_THAN,
      possibleOperand.LOWER_THAN,
    ],
    editor: editorType.DATE,
    isSortable: true,
  },
  'property.overBudget': {
    label: 'filter.overBudget',
    field: 'property.price',
    defaultOperand: possibleOperand.EQUAL,
    possibleOperand: [possibleOperand.EQUAL],
    getPossibleValues: (research) => [{
      key: 'true',
      label: 'Yes',
    }, { key: research.price_max_search?.toString() || 'false', label: 'No' }],
    editor: editorType.SELECT,
    isNumber: true,
  },
  'property.available': {
    label: 'filter.available',
    field: 'property.available',
    defaultOperand: possibleOperand.EQUAL,
    possibleOperand: [possibleOperand.EQUAL],
    getPossibleValues: () => [{ key: 'true', label: 'Yes' }, { key: 'false', label: 'No' }],
    editor: editorType.SELECT,
  },
  viewed: {
    label: 'filter.seen',
    field: 'viewed',
    defaultOperand: possibleOperand.EQUAL,
    possibleOperand: [possibleOperand.EQUAL],
    getPossibleValues: () => [{ key: 'true', label: 'Yes' }, { key: 'false', label: 'No' }],
    editor: editorType.SELECT,
  },
  'property.furnished': {
    label: 'filter.furnished',
    field: 'property.furnished',
    defaultOperand: possibleOperand.EQUAL,
    possibleOperand: [possibleOperand.EQUAL],
    getPossibleValues: () => [{ key: 'true', label: 'Yes' }, { key: 'false', label: 'No' }],
    editor: editorType.SELECT,
  },
  'property.floor': {
    label: 'filter.floor',
    field: 'property.floor',
    defaultOperand: undefined,
    possibleOperand: [
      possibleOperand.EQUAL,
      possibleOperand.NOT_EQUAL,
      possibleOperand.GREATER_THAN,
      possibleOperand.LOWER_THAN,
    ],
    editor: editorType.TEXTFIELD,
    isNumber: true,
  },
  'property.elevator': {
    label: 'filter.elevator',
    field: 'property.elevator',
    defaultOperand: possibleOperand.EQUAL,
    possibleOperand: [possibleOperand.EQUAL],
    getPossibleValues: () => [{ key: 'true', label: 'Yes' }, { key: 'false', label: 'No' }],
    editor: editorType.SELECT,
  },
  'property.source_website': {
    label: 'filter.source',
    field: 'property.source_website',
    defaultOperand: possibleOperand.IN,
    possibleOperand: [possibleOperand.IN, possibleOperand.EQUAL, possibleOperand.NOT_IN],
    editor: editorType.TEXTFIELD,
    isString: true,
  },
  'property.property_contact.contact_type': {
    label: 'filter.contact_type',
    field: 'property.property_contact.contact_type',
    defaultOperand: possibleOperand.EQUAL,
    possibleOperand: [possibleOperand.EQUAL],
    getPossibleValues: () => Object.values(ContactType).map((key) => ({
      key,
      label: StringUtils.capitalizeFirstLetter(key),
    })),
    editor: editorType.SELECT,
  },
  'property.property_type': {
    label: 'filter.property_type',
    field: 'property.property_type',
    defaultOperand: possibleOperand.EQUAL,
    possibleOperand: [possibleOperand.EQUAL],
    getPossibleValues: () => Object.values(propertyType)
      .filter((key) => key !== propertyType.ALL)
      .map((key) => ({
        key,
        label: StringUtils.capitalizeFirstLetter(key),
      })),
    editor: editorType.SELECT,
  },
  'property.property_contact.agency_name': {
    label: 'filter.agency_name',
    field: 'property.property_contact.agency_name',
    defaultOperand: possibleOperand.IN,
    possibleOperand: [possibleOperand.IN, possibleOperand.EQUAL, possibleOperand.NOT_IN],
    editor: editorType.TEXTFIELD,
    isSortable: true,
    isString: true,
  },
  'property.created_at': {
    label: 'filter.created_at',
    field: 'property.created_at',
    defaultOperand: undefined,
    possibleOperand: [
      possibleOperand.EQUAL,
      possibleOperand.NOT_EQUAL,
      possibleOperand.GREATER_THAN,
      possibleOperand.LOWER_THAN,
    ],
    editor: editorType.DATE,
    isSortable: true,
  },
  workflow_step_changed_date: {
    label: 'filter.workflow_step_changed_date',
    field: 'workflow_step_changed_date',
    defaultOperand: undefined,
    possibleOperand: [
      possibleOperand.EQUAL,
      possibleOperand.NOT_EQUAL,
      possibleOperand.GREATER_THAN,
      possibleOperand.LOWER_THAN,
    ],
    editor: editorType.DATE,
    isSortable: true,
  },
  client_identifier: {
    label: 'filter.client_identifier',
    field: 'client_identifier',
    defaultOperand: undefined,
    possibleOperand: [
      possibleOperand.EQUAL,
      possibleOperand.NOT_EQUAL,
      possibleOperand.GREATER_THAN,
      possibleOperand.LOWER_THAN,
    ],
    editor: editorType.TEXTFIELD,
    isNumber: true,
    isSortable: true,
  },
  'property.price': {
    label: 'filter.price',
    field: 'property.price',
    defaultOperand: undefined,
    possibleOperand: [
      possibleOperand.EQUAL,
      possibleOperand.NOT_EQUAL,
      possibleOperand.GREATER_THAN,
      possibleOperand.LOWER_THAN,
    ],
    editor: editorType.TEXTFIELD,
    isNumber: true,
    isSortable: true,
  },
  'property.area': {
    label: 'filter.area',
    field: 'property.area',
    defaultOperand: undefined,
    possibleOperand: [
      possibleOperand.EQUAL,
      possibleOperand.NOT_EQUAL,
      possibleOperand.GREATER_THAN,
      possibleOperand.LOWER_THAN,
    ],
    editor: editorType.TEXTFIELD,
    isNumber: true,
    isSortable: true,
  },
  'property.square_meter_price': {
    label: 'filter.priceByMeter',
    field: 'property.square_meter_price',
    defaultOperand: undefined,
    possibleOperand: [
      possibleOperand.EQUAL,
      possibleOperand.NOT_EQUAL,
      possibleOperand.GREATER_THAN,
      possibleOperand.LOWER_THAN,
    ],
    editor: editorType.TEXTFIELD,
    isNumber: true,
    isSortable: true,
  },
};

export type CriteriaFilter = {
  field?: string,
  operand?: PossibleOperand,
  value?: string,
};

export type CandidateFilter = {
  criterias: CriteriaFilter[];
};

export type CandidateSort = {
  default: string;
  field: string;
  ordering: string;
  sort: Sort;
};

type FilterStepList = {
  [key in WorkflowStep]: WritableObservable<CandidateFilter>;
};

type SortStepList = {
  [key in WorkflowStep]: WritableObservable<CandidateSort>;
};

export class CandidateFilterService {
  private readonly filters: FilterStepList = Object.keys(workflowStep)
    .reduce((acc: Partial<FilterStepList>, key) => {
      acc[key] = observable({ criterias: [{}] });
      return acc;
    }, {}) as FilterStepList;

  private readonly sort: SortStepList = Object.keys(workflowStep)
    .reduce((acc: Partial<SortStepList>, key) => {
      let val: WritableObservable<CandidateSort>;
      if (key === workflowStep.REVIEW) {
        val = observable({
          default: 'property.published_date',
          field: 'property.published_date',
          ordering: '-proprty__published_date',
          sort: sortType.DESC,
        });
      } else {
        val = observable({
          default: 'workflow_step_changed_date',
          field: 'workflow_step_changed_date',
          ordering: '-workflow_step_changed_date',
          sort: sortType.DESC,
        });
      }

      acc[key] = val;
      return acc;
    }, {}) as SortStepList;

  getSort(step: WorkflowStep): CandidateSort {
    return this.sort[step].get();
  }

  getSortObservable(step: WorkflowStep) {
    return this.sort[step].readOnly();
  }

  getFilterObservable(step: WorkflowStep) {
    return this.filters[step].readOnly();
  }

  updateSort(sort: CandidateSort, step: WorkflowStep) {
    this.sort[step].set(sort);
  }

  getFilters(step: WorkflowStep): CandidateFilter {
    return this.filters[step].get();
  }

  updateFilter(filter: CandidateFilter, step: WorkflowStep) {
    this.filters[step].set(filter);
  }

  static getValue(candidate: Candidate, field: string): string | number | null {
    if (!field) {
      return null;
    }
    let value = ObjectUtils.accesObjectProperty((field === 'property.overBudget') ? 'property.price' : field, candidate);

    if (field === 'property.title') {
      value = `${value} ${ObjectUtils.accesObjectProperty('property.description', candidate)}`;
    }

    if (value === false) {
      return 'false';
    }

    if (!value) {
      return null;
    }

    if (field === 'property.is_agency') {
      return value.toString();
    }

    value = value.toString();
    if (possibleFilter[field].editor === editorType.DATE) {
      value = format(new Date(value), 'yyyy-MM-dd');
    }
    if (possibleFilter[field].isNumber) {
      value = parseInt(value, 10);
      if (!Number.isInteger(value)) {
        return null;
      }
    }
    return value;
  }

  // eslint-disable-next-line class-methods-use-this
  private matchFilter(candidate: Candidate, filter: CriteriaFilter): boolean {
    if (!filter.value || !filter.operand || !filter.field) {
      return true;
    }
    const value = CandidateFilterService.getValue(candidate, filter.field);
    let filterValue: string | number = filter.value;

    if (filter.operand === possibleOperand.EQUAL
      && typeof filterValue === 'string'
      && (typeof value === 'string')) {
      if (filter.field === 'property.property_contact.contact_type') {
        const extraValue = CandidateFilterService.getValue(candidate, 'property.is_agency');
        if (typeof extraValue === 'string') {
          const extraFilterValue = (filter.value === 'AGENCY').toString();
          return value?.toLowerCase() === filterValue.toLowerCase()
            || extraValue?.toLowerCase() === extraFilterValue.toLowerCase();
        }
      }
      return value?.toLowerCase() === filterValue.toLowerCase();
    }

    if (!value) {
      return filter.operand === possibleOperand.IS_EMPTY;
    }

    if (possibleFilter[filter.field].editor === editorType.DATE) {
      filterValue = format(new Date(filterValue), 'yyyy-MM-dd');
    }
    if (possibleFilter[filter.field].isNumber) {
      filterValue = parseInt(filterValue, 10);
      if (!Number.isInteger(filterValue)) {
        return true;
      }
    }

    if (filter.field === 'property.overBudget') {
      return value < filterValue;
    }
    if (filter.operand === possibleOperand.EQUAL) {
      return value === filterValue;
    }
    if (filter.operand === possibleOperand.NOT_EQUAL) {
      return value !== filterValue;
    }
    if (filter.operand === possibleOperand.GREATER_THAN) {
      return value > filterValue;
    }
    if (filter.operand === possibleOperand.LOWER_THAN) {
      return value < filterValue;
    }
    if (filter.operand === possibleOperand.IN && typeof filterValue === 'string' && typeof value === 'string') {
      return filterValue.toLowerCase().includes(value.toLowerCase())
        || value.toLowerCase().includes(filterValue.toLowerCase());
    }
    if (filter.operand === possibleOperand.NOT_IN && typeof filterValue === 'string' && typeof value === 'string') {
      return !filterValue.toLowerCase().includes(value.toLowerCase())
        && !value.toLowerCase().includes(filterValue.toLowerCase());
    }
    if (typeof filterValue === 'string' && typeof value === 'string') {
      let filters: string[] = [];
      const filtersSplit = filterValue.split('"');
      let isExactKey = filterValue.startsWith('"');
      for (let i = 0; i < filtersSplit.length; i++) {
        if (filtersSplit[i].trim() === '') {
          continue;
        }
        if (isExactKey) {
          filters.push(filtersSplit[i].trim());
        } else {
          filters = filters.concat(filtersSplit[i].split(' ').map((s) => s.trim()).filter((s) => s !== ''));
        }
        isExactKey = !isExactKey;
      }
      if (filter.operand === possibleOperand.FUZZY_SEARCH) {
        return filters
          .some((searchedTerm) => fuzzysearch(searchedTerm.toLowerCase(), value.toLowerCase()));
      }
      if (filter.operand === possibleOperand.FUZZY_NOT) {
        return filters
          .every((searchedTerm) => !fuzzysearch(searchedTerm.toLowerCase(), value.toLowerCase()));
      }
      if (filter.operand === possibleOperand.SEARCH) {
        return filters
          .some((searchedTerm) => value.toLowerCase().includes(searchedTerm.toLowerCase()));
      }
      if (filter.operand === possibleOperand.NOT_IN_SEARCH) {
        return filters
          .every((searchedTerm) => !value.toLowerCase().includes(searchedTerm.toLowerCase()));
      }
    }
    return false;
  }

  getNumberActiveFilter(step: WorkflowStep): number {
    return this.filters[step].get().criterias
      .filter((criteria) => criteria.field && criteria.value && criteria.operand).length;
  }

  filterCandidates(candidates: Candidate[], step: WorkflowStep): Candidate[] {
    const filtersToApply = this.filters[step].get().criterias;
    return candidates.filter(
      (candidate) => filtersToApply.every((filter) => this.matchFilter(candidate, filter)),
    );
  }
}

const candidateFilterService = new CandidateFilterService();
export default candidateFilterService;
