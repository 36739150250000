import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Messages from 'services/i18n/Messages';

type Props = {
  visible?: boolean
};

// TODO to delete
export default function LoaderRing({ visible }: Props) {
  return (
    <div style={{ position: 'relative' }}>
      <Spinner
        className="top-right-corner"
        style={{ display: visible ? 'block' : 'none' }}
        animation="border"
        role="status"
      >
        <span className="sr-only">{Messages.t('loadingRing.loading')}</span>
      </Spinner>
    </div>
  );
}

export async function displayLoader(setLoading, promise) {
  setLoading((loading) => loading + 1);
  try {
    // @ts-ignore
    return await ((promise instanceof Function) ? promise() : promise);
  } finally {
    setLoading((loading) => loading - 1);
  }
}
