import { defaultTravelOptions } from 'services/isochrone/TravelOptions';
import { defaultLocation } from 'services/isochrone/location';

export const defaultTravelConstraints = [
  {
    isochone_paint: {
      'fill-color': 'rgba(240, 80, 200, 0.15)',
      'fill-outline-color': 'rgba(240, 80, 200, 1)',
    },
  },
  {
    isochone_paint: {
      'fill-color': 'rgba(200, 20, 240, 0.15)',
      'fill-outline-color': 'rgba(240, 80, 200, 1)',
    },
  },
].map((tc, index) => ({
  index,
  travel_options: defaultTravelOptions,
  location: defaultLocation,
  isochrone_geojson: null,
  ...tc,
}));
