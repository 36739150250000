import React from 'react';
import { Candidate } from 'types/candidate';
import { useActivities } from 'network/api/ActivityQueries';
import { workflowStepColor, workFlowStepIcon, workflowStepMetaData } from 'services/hunter/WorkflowStep';
import { workflowStep } from 'types/WorkflowStep';
import { format } from 'date-fns';
import { ClipboardListIcon } from '@heroicons/react/outline';
import { NO_COMMENT } from 'features/client/pages/candidateDetails/CandidateModal';
import Messages from 'services/i18n/Messages';

type Props = {
  candidate: Candidate
};

const getPrettyDate = (dateStr: string) => {
  const date = new Date(dateStr);
  return format(date, 'dd MMMM');
};

const getPrettyTime = (dateStr: string) => {
  const date = new Date(dateStr);
  return format(date, 'HH:mm');
};

export default function ActivitiesTimeline({ candidate }: Props) {
  const activitiesQuery = useActivities();
  const { data: activities } = activitiesQuery.getActivities(
    candidate.id,
  );
  return (
    <div className="candidate-section">
      <h4>{Messages.t('candidate.activities')}</h4>
      <div>
        {
          activities && activities
            .filter((activity) => activity.workflow_step !== workflowStep.SHORTLIST)
            .map((activity) => (
              <div
                className="activity-container"
                key={activity.workflow_step}
              >
                <div className="activity">
                  <div className="activity-icon-container">
                    <div
                      className={`activity-icon ${activity.workflow_step ? workflowStepColor[activity.workflow_step] : 'blue'}`}
                    >
                      {
                        activity.workflow_step
                          ? workFlowStepIcon[activity.workflow_step]
                          : <ClipboardListIcon />
                      }
                    </div>
                    <div className="activity-separator" />
                  </div>
                  <div className="activity-text">
                    <div className="activity-text-name">
                      {
                        activity.workflow_step
                          ? workflowStepMetaData(activity.workflow_step).getDisplayName()
                          : activity.message_title
                      }
                    </div>
                    <div className="subtitle">
                      {
                        activity.message_content !== NO_COMMENT && activity.message_content
                      }
                    </div>
                  </div>
                  <div className="datetime-container">
                    <div>
                      {getPrettyDate(activity.created_at)}
                    </div>
                    <div>
                      {getPrettyTime(activity.created_at)}
                    </div>
                  </div>
                </div>
              </div>
            ))
        }
      </div>
    </div>
  );
}
