import React, { useRef, useEffect } from 'react';
import {
  clientNotationExtraInfo, getCurrentNotation, NotationDetail,
} from 'types/forms/CommentForm';
import { useCandidate } from 'network/api/CandidateQueries';
import { Candidate } from 'types/candidate';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import { AddReactionOutlined, CloseRounded } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { CLIENT_BASE_PATH } from 'routes/ClientRoutes';

type Props = {
  candidate: Candidate,
  editable?: boolean,
  deployed?: boolean,
  setDeployed?: (val: boolean) => void,
};

export default function ClientNotation(
  {
    candidate,
    editable,
    deployed,
    setDeployed,
  }: Props,
) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { updateCandidate } = useCandidate();

  const currentNotationObject = getCurrentNotation(candidate.client_notation?.toString());

  const location = useLocation();

  const updateClientNotation = (newNote: number, newNoteDetail: NotationDetail) => {
    updateCandidate.mutate(
      {
        candidate,
        data: { client_notation: newNote },
      },
      {
        onSuccess: () => {
          NotificationService.notifySuccess(Messages.t('toast.client.changeNote', {
            newNote: `${newNoteDetail.emoji} (${newNoteDetail.text()})`,
          }));
        },
      },
    );
  };

  useEffect(() => {
    const closeMenu = (e) => {
      if (setDeployed && ref.current && !ref.current.contains(e.target)) {
        setDeployed(false);
      }
    };
    document.addEventListener('click', closeMenu);
    return () => document.removeEventListener('click', closeMenu);
  }, []);

  return (
    <div
      className={`client-notation-wrapper ${!location.pathname.includes(CLIENT_BASE_PATH) ? 'home-matcher-view' : ''}`}
    >
      {editable && deployed ? (
        <div className="notation" ref={ref}>
          {
            (Object.keys(clientNotationExtraInfo))
              .map(
                (key) => (
                  <button
                    key={key}
                    type="button"
                    className="emoji-plus-text"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (setDeployed) {
                        setDeployed(false);
                      }
                      if (Number(key) !== candidate?.client_notation) {
                        updateClientNotation(Number(key), clientNotationExtraInfo[key]);
                      }
                    }}
                  >
                    <div
                      className={`notation-name ${Number(key) === candidate.client_notation ? 'selected' : ''}`}
                    >
                      {clientNotationExtraInfo[key]?.text() || ''}
                    </div>
                    <div className="emoji">{clientNotationExtraInfo[key]?.emoji || ''}</div>
                  </button>
                ),
              )
          }
          <button
            type="button"
            className="close-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (setDeployed) {
                setDeployed(false);
              }
            }}
          >
            <CloseRounded fontSize="small" />
          </button>
        </div>
      ) : (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (setDeployed) {
              setDeployed(true);
            }
          }}
        >
          <div className="emoji big-emoji">
            {currentNotationObject?.emoji || <AddReactionOutlined />}
          </div>
        </button>
      )}
    </div>
  );
}
