import { useBackend } from 'network/BackendFetchAdapter';
import { useQuery } from 'react-query';
import { ZipCodes } from 'types/ZipCodes';

export function useZipCodeBackend() {
  const backend = useBackend();

  const GetAllZipcode = (enabled = true) => useQuery<ZipCodes[]>(
    ['getZipcodes'],
    async () => {
      const response: ZipCodes[] = await backend.fetchJson(
        '/api/zipcodes',
      );
      return response;
    },
    { enabled, refetchOnMount: false },
  );

  const GetWithCity = (city: string, enabled = true) => useQuery<ZipCodes[]>(
    ['getZipcode'],
    async () => {
      const response: ZipCodes[] = await backend.fetchJson(
        `/api/zipcodes?city=${city}`,
      );
      return response;
    },
    { enabled },
  );

  const GetWithZipcode = (
    zipCode: string,
    enabled = true,
  ) => useQuery<{ [zipCode: string]: ZipCodes[] }>(
    ['getCity'],
    async () => {
      const response: ZipCodes[] = await backend.fetchJson(
        `/api/zipcodes?zip_code__in=${zipCode}`,
      );
      return response.reduce((acc, zip) => {
        if (!acc[zip.zip_code]) {
          acc[zip.zip_code] = [];
        }
        acc[zip.zip_code].push(zip);
        return acc;
      }, {});
    },
    { enabled },
  );

  return {
    getWithZipcode: GetWithZipcode,
    getWithCity: GetWithCity,
    getAllZipcode: GetAllZipcode,
  };
}
