import React, { useRef, useState } from 'react';
import InfiniteScroll from 'lib/InfiniteScroll';
import NotificationItem from 'features/magicbell/NotificationItem';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NotificationStore } from '@magicbell/react-headless/dist/hooks/useNotifications';
import Messages from 'services/i18n/Messages';

type Props = {
  onClose: () => void
  notifications: NotificationStore,
  showAllRead?: boolean,
};

export default function NotificationList({ notifications, showAllRead, onClose }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [submitting, setSubmitting] = useState(false);
  return (
    <div className="notification-list-container">
      <div ref={ref} className="notification-list">
        <InfiniteScroll
          scrollableDivRef={ref}
          size={notifications.notifications.length}
          hasNext={notifications.hasNextPage}
          next={async () => {
            setSubmitting(true);
            await notifications.fetchNextPage(notifications.context);
            setSubmitting(false);
          }}
          isLoading={submitting}
        >
          {
            notifications.notifications.map((notification) => (
              <NotificationItem
                onClose={onClose}
                key={notification.id}
                notification={notification}
              />
            ))
          }
        </InfiniteScroll>
      </div>
      {
        showAllRead && (
          <button className="mark-button" type="button" onClick={() => notifications.markAllAsRead()}>
            {Messages.t('notification.allAsRead')}
          </button>
        )
      }
    </div>
  );
}
