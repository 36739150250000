import { Observable, observable, WritableObservable } from 'micro-observables';
import {
  ClientFilter,
  ClientFilterFunctions,
  publishedDateStatus,
  publishedDateStatusDeltaDay,
} from 'types/ClientFilter';
import { Candidate } from 'types/candidate';
import { workflowStep } from 'types/WorkflowStep';
import { addDays, isBefore } from 'date-fns';
import { Research } from 'types/research';

const filteringFunctions: ClientFilterFunctions = {
  onlyHmPicks: (candidate, clientFilter) => !clientFilter.onlyHmPicks
    || candidate.workflow_step === workflowStep.SENT,
  onlyAvailable: (candidate, clientFilter) => !clientFilter.onlyAvailable
    || candidate.workflow_step === workflowStep.SENT
    || candidate.property.available !== false, // accept null
  publishedDateStatus: (candidate, clientFilter) => {
    if (clientFilter.publishedDateStatus === publishedDateStatus.ALL) {
      return true;
    }
    return isBefore(
      new Date(),
      addDays(
        new Date(candidate.property.published_date),
        publishedDateStatusDeltaDay[clientFilter.publishedDateStatus],
      ),
    );
  },
  showAbovePrice: (candidate, clientFilter, research) => clientFilter.showAbovePrice
    || !research.price_max_search
    || candidate.property.price < research.price_max_search,
  showBelowPrice: () => true,
  excludedZipCode: (candidate, clientFilter) => !clientFilter.excludedZipCode
    || !clientFilter.excludedZipCode.includes(candidate.property.postal_code),
  bedrooms: (candidate, clientFilter) => !clientFilter.bedrooms
    || clientFilter.bedrooms.length === 0
    || !!(candidate.property.bedrooms
      && (
        clientFilter.bedrooms.includes(candidate.property.bedrooms.toString())
        || (clientFilter.bedrooms.includes('1') && candidate.property.rooms === 1)
      )),
};

export class ClientFilterService {
  filterInitialState: ClientFilter = {
    onlyHmPicks: true,
    onlyAvailable: true,
    publishedDateStatus: publishedDateStatus.ALL,
    showAbovePrice: true,
    showBelowPrice: false,
    excludedZipCode: [],
    bedrooms: [],
  };

  private readonly filters: WritableObservable<ClientFilter> = observable(this.filterInitialState);

  updateFilter(filter: ClientFilter) {
    this.filters.set(filter);
  }

  setHmPick(hmPick: boolean) {
    this.filters.update((filter) => ({ ...filter, onlyHmPicks: hmPick }));
  }

  getFilters(): Observable<ClientFilter> {
    return this.filters.readOnly();
  }

  getFilterValue(): ClientFilter {
    return this.filters.get();
  }

  filterCandidates(candidates: Candidate[], research: Research | undefined): Candidate[] {
    if (research) {
      return candidates.filter((candidate) => Object.keys(filteringFunctions)
        .every((functionKey) => filteringFunctions[functionKey](
          candidate,
          this.getFilterValue(),
          research,
        )));
    }
    return [];
  }
}

const clientFilterService = new ClientFilterService();
export default clientFilterService;
