import { Property } from 'types/property';

export type Image = {
  image: string,
  isCloudinaryImage: boolean,
};

export default class ImageUtils {
  static getImageArray(property: Property, fileredImage: string[]): Image[] {
    let images: Image[] = [];
    if (property.images_url) {
      images = images.concat(property.images_url
        .filter((images_url) => !fileredImage.includes(images_url))
        .map((image) => ({
          image,
          isCloudinaryImage: false,
        })));
    }
    if (property.uploaded_images) {
      images = images.concat(property.uploaded_images
        .filter((image) => !fileredImage.includes(image.image_id))
        .map((image) => ({
          image: image.image_id,
          isCloudinaryImage: true,
        })));
    }
    return images;
  }
}
