import { toWgs84 } from '@turf/projection';

export default class FakeTargomoIsochroneService {
  private fetchAdapter;

  constructor(fetchAdapter) {
    this.fetchAdapter = fetchAdapter;
  }

  isochrone_geojson(
    {
      /* arrival_point, travel_mode, travel_times, */ address,
    },
  ) {
    const source = address.includes('Italie')
      ? '/targomo_response_place_italie.geojson'
      : address.includes('Gaulle')
        ? '/targomo_response_charles-de-gaulle.geojson'
        : address.includes('Yerres')
          ? '/targomo_response_yerres.geojson'
          : '/targomo_response_brunoy.geojson';

    return this.fetchAdapter.fetchJson(source).then((json) => {
      console.log('FakeTargomoIsochroneService:isochrone_geojson');
      return toWgs84(json, {
        mutate: true,
      });
    });
  }
}
