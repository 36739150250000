import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Messages from 'services/i18n/Messages';
import { FetchError } from 'network/Errors';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Firework from 'theme/Firework';
import RadioWrapper from 'lib/form/RadioWrapper';
import { Research } from 'types/research';
import SpinButton from 'theme/hunter-utils/SpinButton';
import { useCandidate } from 'network/api/CandidateQueries';
import { moveInNotation } from 'types/forms/SignedReviewType';
import { AgreementReviewType } from 'types/forms/AgreementReviewType';
import SelectWrapper from 'lib/form/SelectWrapper';
import { stringToBoolean } from 'lib/form/FormUtils';
import { garantorTypeEnum } from 'types/postSearch/Garantor';
import DateTimePickerWrapper from 'lib/form/DateTimePickerWrapper';
import { usePostSearch } from 'network/api/PostSearchQueries';

type Props = {
  candidateId: string,
  research: Research
  onValidate: () => void
};

export default function AgreementReviewFrom(
  {
    candidateId,
    research,
    onValidate,
  }: Props,
) {
  const { getCandidate } = useCandidate();

  const { updateCandidate } = useCandidate();
  const { updatePostSearch } = usePostSearch();
  const [apiErrors, setApiError] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const { data: candidate } = getCandidate(candidateId);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AgreementReviewType>();

  const onSubmit: SubmitHandler<AgreementReviewType> = (formData: AgreementReviewType) => {
    if (candidate) {
      setSubmitting(true);
      setApiError({});
      updatePostSearch.mutateAsync({
        postSearchId: candidate.post_search || '',
        data: {
          client_has_european_bank_account: stringToBoolean(
            formData.client_has_european_bank_account,
          ),
          proof_of_transfer_only: stringToBoolean(formData.proof_of_transfer_only),
          preferred_communication_channel: formData.preferred_communication_channel,
          garantor_kind: formData.garantor_kind,
          client_phone_number: formData.client_phone_number,
          main_ga: formData.main_ga,
          preferred_date_for_sign: formData.preferred_date_for_sign,
          use_voucher: stringToBoolean(formData.use_voucher),

        },
      });
      updateCandidate.mutate(
        {
          candidate,
          data: {
            agreement_notation: formData.agreement_notation,
            agreement_done_well_review: formData.agreement_done_well_review,
            agreement_improvement_review: formData.agreement_improvement_review,
          },
        },
        {
          onSuccess: () => {
            setSubmitting(false);
            onValidate();
          },
          onError: (error: FetchError) => {
            setSubmitting(false);
            setApiError(error.json_response);
          },
        },
      );
    }
  };

  if (!candidate) {
    return null;
  }
  return (
    <>
      <Firework />
      <form className="signed-form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          {Messages.t('candidate.review.agreementNotation', { name: `${research.client_first_name} ${research.client_last_name}` })}
        </div>
        <Controller
          name="agreement_notation"
          control={control}
          rules={{ required: true }}
          render={(controller) => (
            <RadioWrapper
              control={controller}
              error={errors}
              row
              apiErrors={apiErrors}
              hideButton
              values={
                Object.keys(moveInNotation).map((key) => (
                  { key, label: moveInNotation[key] }
                ))
              }
            />
          )}
        />
        <Controller
          name="agreement_done_well_review"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="textarea"
              rows={5}
              control={controller}
              label={Messages.t('form.field.done_well_review')}
            />
          )}
        />
        <Controller
          name="agreement_improvement_review"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="textarea"
              rows={5}
              control={controller}
              label={Messages.t('form.field.improvement_review')}
            />
          )}
        />
        <Controller
          name="client_has_european_bank_account"
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.client_has_european_bank_account')}
              values={[
                { key: 'true', label: Messages.t('generics.YES') },
                { key: 'false', label: Messages.t('generics.NO') },
              ]}
            />
          )}
        />
        <Controller
          name="proof_of_transfer_only"
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.proof_of_transfer_only')}
              values={[
                { key: 'true', label: Messages.t('generics.YES') },
                { key: 'false', label: Messages.t('generics.NO') },
              ]}
            />
          )}
        />
        <Controller
          name="garantor_kind"
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.garantorKind')}
              values={
                Object.values(garantorTypeEnum).map((type) => (
                  { key: type, label: Messages.t(`garantor.kind.${type}`) }
                ))
              }
            />
          )}
        />
        <Controller
          name="use_voucher"
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.use_voucher')}
              values={[
                { key: 'true', label: Messages.t('generics.YES') },
                { key: 'false', label: Messages.t('generics.NO') },
              ]}
            />
          )}
        />
        <Controller
          name="client_phone_number"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.client_phone_number')}
            />
          )}
        />
        <Controller
          name="preferred_communication_channel"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.preferred_communication_channel')}
            />
          )}
        />
        <Controller
          name="main_ga"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.main_ga')}
            />
          )}
        />
        <Controller
          name="preferred_date_for_sign"
          control={control}
          render={(controller) => (
            <DateTimePickerWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.preferred_date_for_sign')}
            />
          )}
        />
        <SpinButton
          editing
          spin={submitting}
          variant="primary"
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </>
  );
}
