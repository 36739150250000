export interface ContactInfoFormType {
  id: number,
  properties: string[]
  contact_type: ContactTypeEnum,
  agency_name: string,
  first_name: string,
  last_name: string,
  phone_number: string,
  email: string,
  comment: string,
}

export type ContactTypeEnum = 'AGENCY' | 'OWNER';

type ContactTypeType = { [key in ContactTypeEnum]: ContactTypeEnum };

export const ContactType: ContactTypeType = {
  AGENCY: 'AGENCY',
  OWNER: 'OWNER',
};
