import React from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import DateTimePicker from '@material-ui/lab/DateTimePicker';
import { InputAdornment, TextField } from '@material-ui/core';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { UseFormTrigger } from 'react-hook-form';
import useAutoSave from 'lib/form/autosave';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  label?: string
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  disablePast?: boolean,
  onChange?: (newDate: string) => void,
  autoSaveSubmit?: (value: Date | undefined) => void,
  trigger?: UseFormTrigger<any>,
  submitting?: boolean,
};

export default function DateTimePickerWrapper(
  {
    control,
    label,
    error,
    apiErrors,
    disablePast,
    onChange,
    autoSaveSubmit,
    trigger,
    submitting,
  }: Props,
) {
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);
  const {
    hasSaveResult,
    getSaveResult,
    handleChange,
  } = useAutoSave(control, autoSaveSubmit, trigger, submitting);

  const value = field.value || null; // force null instead of undefined
  return (
    <div className="material-textfield-wrapper">
      <DateTimePicker
        {...field}
        onChange={(e) => {
          handleChange(e || undefined);
          if (onChange) {
            onChange(e);
          }
        }}
        value={value}
        disablePast={disablePast}
        clearable
        ampm={false}
        label={label || undefined}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!errorMessage}
            helperText={errorMessage}
            fullWidth
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (hasSaveResult)
                && <InputAdornment position="end">{getSaveResult()}</InputAdornment>,
            }}
          />
        )}
      />
    </div>
  );
}
