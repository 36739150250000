import React from 'react';
import Button from 'react-bootstrap/Button';
import Messages from 'services/i18n/Messages';

type Props = {
  handleConfirm: () => void,
  onCancelClick: () => void,
  toStatusTransition: string,
};

export default function ActionConfirm({ handleConfirm, onCancelClick, toStatusTransition }: Props) {
  return (
    <div className="container">
      <div className="row justify-content-md-center mb-3">
        <div className="col-md-auto">
          {Messages.t('activity.transition', { toStatusTransition })}
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-md-auto">
          <Button
            variant="primary"
            onClick={handleConfirm}
          >
            {Messages.t('formButton.yes')}
          </Button>
        </div>
        <div className="col-md-auto">
          <Button
            variant="secondary"
            onClick={onCancelClick}
          >
            {Messages.t('formButton.no')}
          </Button>
        </div>
      </div>
    </div>
  );
}
