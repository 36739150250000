import React from 'react';

type Props = {
  obj: any
};

export default function Stringify({ obj }: Props) {
  return (
    <div style={{ maxWidth: '500px', overflow: 'hidden' }}>
      <pre
        style={{ overflow: 'auto' }}
        className="p-block text-left text-monospace"
      >
        <small>{JSON.stringify(obj, null, 2)}</small>
      </pre>
    </div>
  );
}
