import React from 'react';
import { usePostSearch } from 'network/api/PostSearchQueries';
import { Link } from 'react-router-dom';
import { CANDIDATE_ID, RESEARCH_ID, Routes } from 'routes/Routes';
import { DETAIL_ID, RESEARCH_CANDIDATE_DETAIL, TAB_ID } from 'routes/HmRoutes';
import { CanditateTab } from 'features/hunter/pages/research/researchDetails/candidateDetails/CandidateTabs';
import { ArrowBack } from '@material-ui/icons';
import Messages from 'services/i18n/Messages';
import { Candidate } from 'types/candidate';
import { Research } from 'types/research';
import UtilitiesAndInsuranceForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/utilitiesAndInsurance/UtilitiesAndInsuranceForm';

type Props = {
  candidate: Candidate,
  research: Research,
};

export default function UtilitiesAndInsurance({ research, candidate }: Props) {
  const postSearchQueries = usePostSearch();
  const { getPostSearch } = postSearchQueries;
  const { data: postSearch } = getPostSearch(candidate.post_search || '', !!candidate.post_search);
  return (
    <div>
      <Link
        className="post-search-back-arrow"
        to={
          Routes.withPath(RESEARCH_CANDIDATE_DETAIL,
            [
              { label: RESEARCH_ID, value: research.id },
              { label: TAB_ID, value: candidate.workflow_step.toLocaleLowerCase() },
              { label: CANDIDATE_ID, value: candidate.id },
              {
                label: DETAIL_ID,
                value: CanditateTab.DETAILS.toLowerCase(),
              },
            ])
        }
      >
        <ArrowBack />
        <h4>
          {Messages.t('postSearch.actions.utilities')}
        </h4>
      </Link>
      {
        postSearch && (
          <UtilitiesAndInsuranceForm
            property={candidate.property}
            postSearch={postSearch}
            candidate={candidate}
          />
        )
      }
    </div>
  );
}
