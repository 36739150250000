import React, { useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import ResearchForm from 'features/hunter/pages/research/form/ResearchForm';
import { Research } from 'types/research';
import { Close, OpenInNew } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Modal from 'lib/Modal';
import Messages from 'services/i18n/Messages';
import ConfirmationModal from 'features/hunter/common/ConfirmationModal';
import { useObservable } from 'micro-observables';
import panelCollapseService from 'services/PanelCollapseService';
import SpinButton from 'theme/hunter-utils/SpinButton';

type Props = {
  research?: Research,
};

// TODO To refacto
export default function ResearchRightPanel(
  {
    research,
  }: Props,
) {
  const openSearchEdit = useObservable(panelCollapseService.getOpenSearchEdit());
  const [modalOpen, setModalOpen] = useState(false);
  const [isDirty, updateIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationCallback, setConfirmationCallback] = useState<() => void>(() => {
  });

  const ref = useRef();
  if (!research) {
    return null;
  }

  const saveSearch = async () => {
    setIsSubmitting(true);
    // @ts-ignore
    await ref.current?.save();
    setIsSubmitting(false);
  };

  return (
    <Col
      xs="auto"
      className="full-height-container overflow-y-auto light-border-left panel"
      style={{
        width: openSearchEdit ? '350px' : '0px',
      }}
    >
      <ConfirmationModal
        isOpen={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onConfirm={() => {
          setConfirmationModal(false);
          confirmationCallback();
        }}
        title={Messages.t('search.warning')}
        text={Messages.t('search.loseChange')}
      />
      <Modal
        isOpen={modalOpen}
        disableOutClose
        header={Messages.t('search.edit')}
        onClose={() => {
          if (isDirty) {
            setConfirmationModal(true);
            setConfirmationCallback(() => (() => setModalOpen(false)));
          } else {
            setModalOpen(false);
          }
        }}
      >
        <div className="search-edit-container">
          {
            modalOpen && (
              <ResearchForm
                updateIsDirty={updateIsDirty}
                onValidate={() => {
                  setModalOpen(false);
                }}
                research={research}
              />
            )
          }
        </div>
      </Modal>
      <div className="panel-header">
        <h4>{Messages.t('search.edit')}</h4>
        <div>
          <SpinButton
            editing
            isShort
            spin={isSubmitting}
            variant="primary"
            className="mb-2"
            onClick={() => saveSearch()}
          />
          <IconButton
            onClick={
              () => {
                if (isDirty) {
                  setConfirmationModal(true);
                  setConfirmationCallback(() => (() => {
                    setModalOpen(true);
                    updateIsDirty(false);
                    panelCollapseService.updateOpenSearchEdit(false);
                  }));
                } else {
                  setModalOpen(true);
                  panelCollapseService.updateOpenSearchEdit(false);
                }
              }
            }
          >
            <OpenInNew />
          </IconButton>
          <IconButton onClick={() => panelCollapseService.updateOpenSearchEdit(false)}>
            <Close />
          </IconButton>
        </div>
      </div>
      <div className="panel-content">
        <ResearchForm
          ref={ref}
          hideSubmit
          updateIsDirty={updateIsDirty}
          research={research}
        />
      </div>
    </Col>
  );
}
