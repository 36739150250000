import React, { useEffect, useState } from 'react';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import withCluster from 'features/googleMap/WithCluster';
import { Coordinates } from 'features/googleMap/GoogleMap';
import withMap from 'features/googleMap/Withmap';
/* global google */

type Props = {
  id: string,
  text?: string,
  className?: string
  coordinates: Coordinates,
  map: google.maps.Map,
  cluster?: MarkerClusterer,
  onClick?: () => void,
  showMarkerIcon?: boolean,
};

function GoogleMarker(
  {
    id,
    text,
    className,
    map,
    coordinates,
    cluster,
    onClick,
    showMarkerIcon,
  }: Props,
) {
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const addMarker = () => {
    if (marker) {
      return null;
    }
    const newMarker = new google.maps.Marker({
      position: coordinates,
      clickable: true,
      label: { className, text: (text && text !== '') ? text : 'Location' },
      title: id,
      icon: !showMarkerIcon ? { url: '', size: new google.maps.Size(50, 30) } : undefined,
    });
    setMarker(newMarker);
    if (cluster) {
      cluster.addMarker(newMarker);
    } else {
      newMarker.setMap(map);
    }
    newMarker.addListener('click', () => {
      if (onClick) {
        onClick();
      }
    });
    return newMarker;
  };
  useEffect(() => {
    const newMarker = addMarker();
    return () => {
      if (newMarker) {
        cluster?.removeMarker(newMarker);
      }
    };
  }, []);

  useEffect(() => {
    marker?.setLabel({ className, text: (text && text !== '') ? text : 'Location' });
  }, [className, text, marker]);

  return (
    <></>
  );
}

export default withCluster(withMap(GoogleMarker));
