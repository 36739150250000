import React from 'react';
import { Candidate } from 'types/candidate';
import WorkflowService from 'services/WorkflowService';
import { defaultWorkflowTabs, postSearchTabs } from 'services/hunter/defaultworkflowTabs';
import Messages from 'services/i18n/Messages';
import { workflowStep, WorkflowStep } from 'types/WorkflowStep';
import {
  Button, Dialog, DialogContent, DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Add,
  KeyboardArrowUp,
  Publish,
  Report,
  FlashOn,
} from '@material-ui/icons';
import ActivityAction from 'features/hunter/pages/research/researchDetails/candidateDetails/actionBar/ActivityAction';
import { Routes } from 'routes/Routes';
import { useHistory, useLocation } from 'react-router-dom';
import { TRANSITION_TO_QUERY_ID } from 'routes/HmRoutes';
import { workFlowValidationStep } from 'services/hunter/WorkflowStep';
import { useCandidate } from 'network/api/CandidateQueries';
import { Research, researchStatus } from 'types/research';
import { NotificationService } from 'services/notification';
import IssueForm from 'features/hunter/pages/research/researchDetails/candidateDetails/actionBar/IssueForm';
import { ActivityType, activityType } from 'types/Activity';

type Props = {
  candidateIds: string[],
  candidate: Candidate
  submittingCandidateStep: boolean,
  setCandidateStep: (data: {
    workflow_step: WorkflowStep,
    workflow_step_changed_date: Date,
    message_content?: string,
    is_client_update?: boolean,
    activity_type?: ActivityType,
    message_title?: string,
  }) => void,
  research: Research
};

const disabledForMulti = [
  workflowStep.BOOKED,
  workflowStep.SIGNED,
  workflowStep.READY_TO_SIGN,
];

export default function CandidateActionBar(
  {
    candidate,
    submittingCandidateStep,
    setCandidateStep,
    candidateIds,
    research,
  }: Props,
) {
  const candidatesQueries = useCandidate();
  const { reportCandidates } = candidatesQueries;
  const tabs = defaultWorkflowTabs();
  const history = useHistory();
  const location = useLocation();

  const nextworkflowStep = WorkflowService.findNextWorkflowStep(
    tabs,
    candidate.workflow_step,
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorReportEl, setAnchorReportEl] = React.useState(null);

  const [openAddActivity, setOpenAddActivity] = React.useState(false);
  const [openIssueModal, setOpenIssueModal] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReportClick = (event) => {
    setAnchorReportEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReportClose = () => {
    setAnchorReportEl(null);
  };

  const reportAsScam = () => {
    reportCandidates.mutateAsync({
      candidate_id_list: candidateIds,
      researchId: research.id,
      step: candidate.workflow_step,
    }).then(() => NotificationService.notifySuccess(Messages.t('toast.hunter.scam')))
      .catch(() => NotificationService.notifyError(Messages.t('toast.hunter.error')));
  };

  const handleSetCandidateStepClick = async (newStep) => {
    if (
      workFlowValidationStep.includes(newStep)
    ) {
      history.push(Routes.updatePathWithQuery(
        location,
        [
          { label: TRANSITION_TO_QUERY_ID, value: newStep },
        ],
      ));
    } else {
      await setCandidateStep({
        workflow_step: newStep,
        workflow_step_changed_date: new Date(),
      });
    }
  };

  const updateFastDiscard = async () => {
    await setCandidateStep({
      workflow_step: workflowStep.DISCARDED,
      workflow_step_changed_date: new Date(),
      is_client_update: false,
      activity_type: activityType.WORKFLOW,
      message_title: 'Fast discard',
      message_content: 'Fast discarded by the HM',
    });
  };

  return (
    <>
      {
        openAddActivity && (
          <ActivityAction candidate={candidate} onClose={() => setOpenAddActivity(false)} />
        )
      }
      <div className={`candidate-action-bar ${openAddActivity ? 'hide' : ''}`}>
        {candidate.workflow_step === workflowStep.REVIEW && (
          <Button
            color="inherit"
            onClick={updateFastDiscard}
            startIcon={<FlashOn color="warning" />}
          >
            {Messages.t('actionBar.fastDiscard')}
          </Button>
        )}
        <Button color="inherit" className="report-button" onClick={handleReportClick} startIcon={<Report />}>
          {Messages.t('actionBar.report')}
        </Button>
        <Button color="inherit" onClick={() => setOpenAddActivity(true)} startIcon={<Add />}>
          {Messages.t('actionBar.addActivty')}
        </Button>
        <Button
          color="inherit"
          startIcon={<Publish />}
          disabled={nextworkflowStep === null || submittingCandidateStep}
          onClick={() => handleSetCandidateStepClick(nextworkflowStep.step)}
        >
          {nextworkflowStep ? (
            Messages.t('home.dropup.toStep', { step: nextworkflowStep.displayName })
          ) : (
            <span className="font-italic">{Messages.t('home.dropup.lastStep')}</span>
          )}
        </Button>
        <IconButton
          color="inherit"
          onClick={handleClick}
          disabled={submittingCandidateStep}
        >
          <KeyboardArrowUp />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              maxHeight: 300,
            },
          }}
        >
          {
            tabs
              .filter((tab) => research.status !== researchStatus.POST_SEARCH
                || postSearchTabs.includes(tab.step))
              .map((tab) => (
                <MenuItem
                  key={tab.step}
                  onClick={() => handleSetCandidateStepClick(tab.step)}
                  disabled={
                    tab.disabled
                    || tab.step === candidate.workflow_step
                    || (candidateIds.length > 1 && disabledForMulti.includes(tab.step))
                  }
                >
                  {tab.displayName}
                </MenuItem>
              ))
          }
        </Menu>
        <Menu
          anchorEl={anchorReportEl}
          open={Boolean(anchorReportEl)}
          onClose={handleReportClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              maxHeight: 300,
            },
          }}
        >
          <MenuItem
            onClick={() => {
              reportAsScam();
              handleReportClose();
            }}
          >
            {Messages.t('actionBar.report.scam')}
          </MenuItem>
          <MenuItem
            disabled={candidateIds.length > 1}
            onClick={() => {
              handleReportClose();
              setOpenIssueModal(true);
            }}
          >
            {Messages.t('actionBar.report.issue')}
          </MenuItem>
        </Menu>
      </div>
      {
        openIssueModal && (
          <Dialog open={openIssueModal} onClose={() => setOpenIssueModal(false)}>
            <DialogTitle>{Messages.t('actionBar.report.issue')}</DialogTitle>
            <DialogContent>
              <IssueForm candidate={candidate} onClose={() => setOpenIssueModal(false)} />
            </DialogContent>
          </Dialog>
        )
      }
    </>
  );
}
