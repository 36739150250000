/* eslint-disable react/prop-types */
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import Form from 'react-bootstrap/Form';
import {
  AiOutlineLoading,
  AiOutlineEdit,
  AiOutlineCheck,
} from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import PhoneInput from 'react-phone-number-input';

const AutoSaveStatus = {
  NONE: 'NONE',

  SUBMITTING: 'SUBMITTING',
  ERROR: 'ERROR',
  SAVED: 'SAVED',
};
// TODO to delete
export default function AutoSaveFormField(
  {
    as,
    rows,
    custom,
    backendValue,
    onChangeFormValue,
    submit,
    type,
    children,
    placeholder,
    validator,
    invalidFeedback,
  },
) {
  const [status, setStatus] = useState(AutoSaveStatus.NONE);
  const [initialBackendValue, setInitialBackendValue] = useState(backendValue);
  const [formValue, setFormValue] = useState(backendValue);

  const editing = useMemo(() => initialBackendValue !== formValue, [
    initialBackendValue,
    formValue,
  ]);

  const isInvalid = useMemo(() => (validator ? !validator(formValue) : false), [
    formValue,
    validator,
  ]);

  const [, setSubmitTimer] = useState(null);

  const handleSubmit = useCallback(async () => {
    if (isInvalid) {
      return;
    }
    if (initialBackendValue !== formValue) {
      setStatus(AutoSaveStatus.SUBMITTING);
      try {
        await submit(formValue);
        setStatus(AutoSaveStatus.SAVED);
      } catch {
        setStatus(AutoSaveStatus.ERROR);
      }
    }
  }, [formValue, submit, initialBackendValue, isInvalid]);

  useEffect(() => {
    const AUTOSAVE_DELAY = type === 'address-input' ? 500 : 3000;
    const timer = setTimeout(handleSubmit, AUTOSAVE_DELAY);
    // @ts-ignore
    setSubmitTimer(timer);
    return () => {
      setSubmitTimer((callBackTimer) => {
        if (callBackTimer !== null) {
          // @ts-ignore
          clearTimeout(callBackTimer);
        }
        return null;
      });
    };
  }, [handleSubmit, type]);

  const handleFormValueChanged = useCallback(
    (value) => {
      setFormValue(value);
      if (onChangeFormValue) {
        onChangeFormValue(value);
      }
      setStatus(AutoSaveStatus.NONE);
    },
    [onChangeFormValue],
  );

  const handlePhoneNumberChanged = useCallback(
    (value) => handleFormValueChanged(value || ''),
    [handleFormValueChanged],
  );

  const handleAnyInputChanged = useCallback(
    (event) => handleFormValueChanged(event.target.value),
    [handleFormValueChanged],
  );

  const handleOnBlur = useCallback(() => {
    setSubmitTimer((timer) => {
      if (timer !== null) {
        // @ts-ignore
        clearTimeout(timer);
      }
      handleSubmit();
      return null;
    });
  }, [handleSubmit]);

  useEffect(() => {
    // the backendValue has changed
    if (initialBackendValue !== backendValue) {
      setInitialBackendValue(backendValue);
      if (!editing) {
        handleFormValueChanged(backendValue);
      }
    }
  }, [initialBackendValue, backendValue, editing, handleFormValueChanged]);

  return (
    <div
      className={`inner_addon ${
        as === 'select' ? 'right_addon_select' : 'right_addon'
      }`}
    >
      {status === AutoSaveStatus.NONE && editing && (
        <AiOutlineEdit className="glyphicon" />
      )}
      {status === AutoSaveStatus.SUBMITTING && (
        <AiOutlineLoading className="glyphicon fa_spin" />
      )}
      {status === AutoSaveStatus.SAVED && (
        <AiOutlineCheck className="glyphicon" />
      )}
      {status === AutoSaveStatus.ERROR && (
        <BiError className="glyphicon" />
      )}
      {
        type === 'phone-input' ? (
          <PhoneInput
            placeholder={placeholder}
            country="FR"
            defaultCountry="FR"
            value={formValue}
            onBlur={handleOnBlur}
            onChange={handlePhoneNumberChanged}
          />
        ) : (
          <>
            <Form.Control
              as={as}
              rows={rows}
              type={type}
              placeholder={placeholder}
              custom={custom}
              value={formValue}
              isInvalid={isInvalid}
              onBlur={handleOnBlur}
              onChange={handleAnyInputChanged}
            >
              {children}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {invalidFeedback}
            </Form.Control.Feedback>
          </>
        )
}
    </div>
  );
}
