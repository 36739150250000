import React, { useMemo } from 'react';
import { Property } from 'types/property';
import Messages from 'services/i18n/Messages';
import { domainFromUrl } from 'services/utils';

type Props = {
  property: Property,
};

export default function PropertySourceUrl({ property }: Props) {
  const sourceWebsite = useMemo(() => property.source_website || domainFromUrl(property.source_url),
    [property.source_website, property.source_url]);

  const extractBaseUrl = (url) => url.split('/')[2];

  return sourceWebsite ? (
    <div>
      <a href={property.source_url} target="_blank" rel="noreferrer">
        {Messages.t('candidate.url.viewMore', { domain: sourceWebsite })}
      </a>
      {
        property.duplicate_urls.map((url) => (
          <a href={url} key={url} target="_blank" rel="noreferrer">
            {` | ${Messages.t('candidate.url.viewMore', { domain: extractBaseUrl(url) })}`}
          </a>
        ))
      }
    </div>
  ) : (
    <span className="font-italic">{Messages.t('candidate.url.noSource')}</span>
  );
}
