import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/hunter-utils/SpinButton';
import { useNote } from 'network/api/PropertyQueries';

type Props = {
  propertyId: string
  hmEmail: string,
};

export default function AddNoteForm({ propertyId, hmEmail }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [newNote, setNewNote] = useState('');
  const noteQueries = useNote();
  const { createNote } = noteQueries;

  const handleSubmit = async () => {
    setSubmitting(true);
    await createNote.mutateAsync({
      propertyId,
      data: {
        author: hmEmail,
        note_content: newNote,
      },
    });
    setSubmitting(false);
    setNewNote('');
  };

  return (
    <div className="privatenote-form-container">
      <TextField
        variant="outlined"
        multiline
        fullWidth
        label={Messages.t('form.field.newNote')}
        onChange={(e) => setNewNote(e.target.value)}
        value={newNote}
      />
      <SpinButton
        spin={submitting}
        title={Messages.t('formButton.add')}
        editing={false}
        type="button"
        onClick={handleSubmit}
        className="submit-btn"
        disabled={newNote === ''}
      />
    </div>
  );
}
