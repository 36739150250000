import React, { useCallback } from 'react';
import firebase from 'firebase/app';
import { useAuth } from 'reactfire';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

export default function LogInForm() {
  const auth = useAuth();

  const uiCallback = useCallback((ui) => {
    ui.disableAutoSignIn();
  }, []);

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };

  return (
    <StyledFirebaseAuth
      uiConfig={uiConfig}
      firebaseAuth={auth}
      uiCallback={uiCallback}
    />
  );
}
