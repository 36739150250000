import { useAuth } from 'reactfire';
import FetchAdapter from 'network/FetchAdapter';
import settings from 'services/hunterSettings';

export function useBackend() {
  const auth = useAuth();
  const fetchAdapter = new FetchAdapter();
  return new BackendFetchAdapter(fetchAdapter, settings.backendEndpoint, () => (auth.currentUser
    ? auth.currentUser.getIdToken()
    : Promise.resolve(null)));
}

class BackendFetchAdapter {
  private fetchAdapter;

  private backendEndpoint;

  private getToken;

  constructor(fetchAdapter, backendEndpoint, getToken) {
    this.fetchAdapter = fetchAdapter;
    this.backendEndpoint = backendEndpoint;
    this.getToken = getToken;
  }

  async fetch(path: string, funcParams?: any) {
    const url = `${this.backendEndpoint}${path}`;
    const token = await this.getToken();

    let params = funcParams || {};

    if (token !== null) {
      params = {
        ...params,
        headers: {
          ...(params.headers || {}),
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return this.fetchAdapter.fetch(url, params);
  }

  async fetchJson(path: string, params?: any) {
    const response = await this.fetch(path, params);
    return response.json();
  }

  postFormData(path: string, formData, params = {}) {
    return this.fetchJson(path, {
      method: 'POST',
      body: formData,
      ...params,
    });
  }

  async post(path: string, jsonBody?: any) {
    const ret = await this.fetch(path, buildParamsJsonBody('POST', jsonBody));
    try {
      return {
        response: await ret.json(),
        status: ret.status,
      };
    } catch (e) {
      return {
        response: {},
        status: ret.status,
      };
    }
  }

  patch(path: string, jsonBody?: any) {
    return this.fetchJson(path, buildParamsJsonBody('PATCH', jsonBody));
  }

  put(path: string, jsonBody?: any) {
    return this.fetchJson(path, buildParamsJsonBody('PUT', jsonBody));
  }

  delete(path: string, jsonBody?: any) {
    return this.fetch(path, buildParamsJsonBody('DELETE', jsonBody));
  }
}

function buildParamsJsonBody(method, jsonBody) {
  return {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(jsonBody || {}),
  };
}
