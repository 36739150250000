export default class WorkflowService {
  static findNextWorkflowStep(tabs, currentStep) {
    const stepIndex = tabs.findIndex((tab) => tab.step === currentStep);
    if (stepIndex === -1) {
      throw Error('unknown step');
    }
    const nextStepIndex = stepIndex + 1;
    const hasNextStep = nextStepIndex < tabs.length;
    return hasNextStep ? tabs[nextStepIndex] : null;
  }
}
