import React, { useRef } from 'react';
import { useNotifications } from '@magicbell/magicbell-react';
import { clientNotificationType } from 'types/Notifications';
import MagicBellContainer from 'features/magicbell/MagicBellContainer';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NotificationStore } from '@magicbell/react-headless/dist/hooks/useNotifications';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Routes } from 'routes/Routes';
import { NOTIFICATION_QUERY } from 'routes/ClientRoutes';
import { BellIcon } from '@heroicons/react/outline';

type Props = {
  open: boolean
};

export default function ClientMagicBell({ open }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const history = useHistory();

  const allStore = useNotifications('ALL');
  const commentStore = useNotifications(clientNotificationType.COMMENT);
  const viewingStore = useNotifications(clientNotificationType.VIEWING);
  const applicationStore = useNotifications(clientNotificationType.APPLICATION);
  const newHomeStore = useNotifications(clientNotificationType.NEW_HOME);

  const storeList: [string, NotificationStore][] = [
    ['ALL', allStore],
    [clientNotificationType.COMMENT, commentStore],
    [clientNotificationType.NEW_HOME, newHomeStore],
    [clientNotificationType.VIEWING, viewingStore],
    [clientNotificationType.APPLICATION, applicationStore],

  ].filter((entry) => !!entry[1]) as [string, NotificationStore][];

  let position: {
    top?: number,
    bottom?: number,
    left?: number,
    right?: number
  } | null = null;

  if (ref && ref.current && ref.current.offsetParent) {
    const viewportOffset = ref.current.getBoundingClientRect();
    const parentViewportOffset = ref.current.offsetParent.getBoundingClientRect();
    const { top } = viewportOffset;
    position = {
      top: top + viewportOffset.height,
      right: parentViewportOffset.width - viewportOffset.left,
    };
  }

  return (
    <div
      className="notification-button nav-bar-item notification-button-container client-notification-button-container"
      ref={ref}
    >
      <Link
        className="header-button"
        to={Routes.updatePathWithQuery(
          location,
          [
            { label: NOTIFICATION_QUERY, value: 'true' },
          ],
        )}
      >
        <BellIcon />
        {
          (allStore?.unseenCount || 0) > 0 && (
            <div className="notification-count notification-badge" />
          )
        }
      </Link>
      {
        open && ref && position && (
          <MagicBellContainer
            onClose={() => {
              allStore?.markAllAsSeen();
              history.push(Routes.updatePathWithQuery(
                location,
                [
                  { label: NOTIFICATION_QUERY, value: 'false' },
                ],
              ));
            }}
            position={position}
            notficationsList={storeList}
          />
        )
      }
    </div>
  );
}
