import { Enum } from 'lib/type/enum';

export type InternetStatusType =
  'PROVIDER_SELECTED'
  | 'ACCOUNT_CREATED'
  | 'APPOINTEMENT'
  | 'ACTIVATED';

export const internetStatusTypeEnum: Enum<InternetStatusType> = {
  PROVIDER_SELECTED: 'PROVIDER_SELECTED',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
  APPOINTEMENT: 'APPOINTEMENT',
  ACTIVATED: 'ACTIVATED',
};
