import React from 'react';
import Messages from 'services/i18n/Messages';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { CONTACTS, CONTACTS_LIST } from 'routes/HmRoutes';

import Contacts from 'features/hunter/pages/contacts/Contacts';
import ContactsDetails from 'features/hunter/pages/contacts/ContactsDetails';
import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

export default function ContactsRouter() {
  const location = useLocation();
  const history = useHistory();
  return (
    <div className="contacts-page">
      <div className="contact-header">
        {
          location.pathname !== CONTACTS_LIST && (
            <IconButton onClick={() => history.push(CONTACTS_LIST)}>
              <ArrowBack />
            </IconButton>
          )
        }
        <h2>{Messages.t('navbar.contacts')}</h2>
      </div>
      <Switch>
        <Route path={CONTACTS} component={ContactsDetails} />
        <Route exact path={CONTACTS_LIST} component={Contacts} />
      </Switch>
    </div>
  );
}
