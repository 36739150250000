import React, { useRef, useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl';

import { TravelMode, allMaxTravelTime } from 'services/isochrone/TravelOptions';

export default function TravelLocation(
  {
    map, // must not be null
    id,
    travel_options,
    location,
    onTravelOptionsChanged,
    onLocationChanged,
  },
) {
  const handleTravelTimeChanged = (event) => {
    onTravelOptionsChanged(id, {
      ...travel_options,
      max_travel_time: allMaxTravelTime[event.target.value],
    });
  };

  const handleTravelModeChanged = (event) => {
    onTravelOptionsChanged(id, {
      ...travel_options,
      travel_mode: TravelMode[event.target.value],
    });
  };

  const geoCoderControl = useRef(null);
  const [geocoder, setGeocoder] = useState(null);

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      // limit results to France
      countries: 'fr',
      // language: 'fr-FR',
      // bbox: [139.965, -38.03, 155.258, -27.839],

      accessToken: mapboxgl.accessToken,
      mapboxgl,
    });

    setGeocoder(geocoder);

    const geocoder_div = geocoder.onAdd(map);
    // @ts-ignore
    geoCoderControl.current.appendChild(geocoder_div);
    // geocoder.setInput(location.address);
    geocoder._inputEl.value = location.address;

    return () => {
    };
    // location.address is just an initial value
    // eslint-disable-next-line
  }, [map]);

  useEffect(() => {
    if (!geocoder) {
      return;
    }

    const onResult = ({ result }) => {
      onLocationChanged(id, {
        address: result.place_name,
        geocoded_coordinates: result.center,
      });
    };

    const onClear = () => {
      onLocationChanged(id, {
        address: '',
        geocoded_coordinates: null,
      });
    };

    const onError = ({ error }) => {
      if (error.statusCode === 422 || error.statusCode === 403) {
        // ignore
      } else {
        throw error;
      }
    };
    // @ts-ignore
    geocoder.on('result', onResult);
    // @ts-ignore
    geocoder.on('error', onError);
    // @ts-ignore
    geocoder.on('clear', onClear);

    return () => {
      // @ts-ignore
      geocoder.off('result', onResult);
      // @ts-ignore
      geocoder.off('error', onError);
      // @ts-ignore
      geocoder.off('clear', onClear);
    };
  }, [geocoder, onLocationChanged, id]);

  return (
    <Card className="text-left mb-4">
      {/* <Card.Header>{location.address}</Card.Header> */}
      <Card.Header>
        {/* <p>{location.geocoded_coordinates}</p> */}
        <div ref={geoCoderControl} />
      </Card.Header>
      {/* @ts-ignore */}
      <ListGroup variant="flush" bg="dark" text="white">
        <ListGroup.Item>
          <Card.Text>
            Up to {Math.round(travel_options.max_travel_time.duration / 60.0)}{' '}
            minutes
          </Card.Text>
          <input
            type="range"
            min="0"
            max={allMaxTravelTime.length - 1}
            value={travel_options.max_travel_time.order}
            className="slider traveltime-slider"
            onChange={handleTravelTimeChanged}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <Form.Control
            as="select"
            custom
            value={travel_options.travel_mode.name}
            onChange={handleTravelModeChanged}
          >
            {Object.values(TravelMode).map((t) => (
              <option key={t.name} value={t.name}>
                {t.displayName}
              </option>
            ))}
          </Form.Control>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
}
