/* eslint import/no-webpack-loader-syntax: off */
// @ts-ignore
import worker from 'workerize-loader!services/isochrone/geojson_operation_worker';
import settings from 'services/hunterSettings';

export default class GeojsonOperationService {
  private worker;

  constructor() {
    this.worker = null;
  }

  lazyLoad() {
    if (this.worker === null) {
      if (settings.disableWorker) {
        return import('services/isochrone/geojson_operation_worker').then((instance) => {
          this.worker = instance;
          return Promise.resolve(instance);
        });
      }
      this.worker = worker();
    }
    return Promise.resolve(this.worker);
  }

  // TODO(idea): perfect application for ES6 Proxy
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy

  discretizeHegonalGridGeojson(geojson) {
    return this.lazyLoad().then((instance) => instance.discretizeHegonalGridGeojson(geojson, settings.geojsonGridCellSide));
  }

  simplifyGeojson(geojson) {
    return this.lazyLoad().then((instance) => instance.simplifyGeojson(geojson));
  }

  differenceGeojsons(multipolygons) {
    return this.lazyLoad().then((instance) => instance.differenceGeojsons(multipolygons));
  }

  intersectGeojsons(multipolygons) {
    return this.lazyLoad().then((instance) => instance.intersectGeojsons(multipolygons));
  }

  unionGeojsons(multipolygons) {
    return this.lazyLoad().then((instance) => instance.unionGeojsons(multipolygons));
  }
}
