import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import DateTimePickerWrapper from 'lib/form/DateTimePickerWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import { useActivity } from 'network/api/ActivityQueries';
import {
  Activity, ActivityTaskForm, activityType, taskRemind,
} from 'types/Activity';
import SpinButton from 'theme/hunter-utils/SpinButton';
import { Button } from '@material-ui/core';
import { useUser } from 'reactfire';
import { Candidate } from 'types/candidate';
import { FetchError } from 'network/Errors';

type Props = {
  candidate?: Candidate,
  onClose: () => void,
  activity?: Activity,
};

export default function ActivityForm(
  {
    candidate,
    onClose,
    activity,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiError] = useState({});
  const { createActivity, updateTask } = useActivity();
  const { data: user } = useUser();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ActivityTaskForm>({
    defaultValues: {
      activity_type: activityType.TASK,
      task_reminder: activity && taskRemind
        && (activity.task_done ? taskRemind.DONE : taskRemind.REMIND),
      ...activity,
    },
  });
  const formField = watch(); // when pass nothing as argument, you are watching everything
  const onSubmit: SubmitHandler<ActivityTaskForm> = (formData: ActivityTaskForm) => {
    setApiError({});
    setSubmitting(true);
    if (activity) {
      updateTask.mutate(
        {
          mail: user.email || '',
          data: {
            ...formData,
            id: activity.id,
            remind_date: formData.date,
            task_done: formData.task_reminder === taskRemind.DONE,
          },
        },
        {
          onSuccess: () => {
            NotificationService.notifySuccess(Messages.t('toast.hunter.viewingCreated'));
            setSubmitting(false);
            onClose();
          },
          onError: (error: FetchError) => {
            const { status } = error;
            if (status === 500) {
              NotificationService.notifyError(Messages.t('toast.hunter.taskUpdated'));
            }
            setApiError(error.json_response);
            setSubmitting(false);
          },
        },
      );
    } else if (candidate) {
      createActivity.mutate(
        {
          candidate,
          data: {
            ...formData,
            remind_date: formData.date,
            task_done: formData.task_reminder === taskRemind.DONE,
            message_title: 'Task',
          },
        },
        {
          onSuccess: () => {
            NotificationService.notifySuccess(Messages.t('toast.hunter.taskCreated'));
            setSubmitting(false);
            onClose();
          },
          onError: (error: FetchError) => {
            const { status } = error;
            if (status === 500) {
              NotificationService.notifyError(Messages.t('toast.hunter.error'));
            }
            setApiError(error.json_response);
            setSubmitting(false);
          },
        },
      );
    }
  };
  return (
    <form className="activity-form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="message_content"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            type="textarea"
            rows={5}
            control={controller}
            label={Messages.t('form.field.message_content')}
          />
        )}
      />
      <div className="row-form">
        <Controller
          name="task_reminder"
          control={control}
          rules={{ required: true }}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.task_reminder')}
              values={Object.keys(taskRemind).map((key) => (
                { key, label: Messages.t(`form.field.${key}`) }
              ))}
            />
          )}
        />
        {
          formField.task_reminder === taskRemind.REMIND && (
            <Controller
              name="date"
              control={control}
              rules={{ required: true }}
              render={(controller) => (
                <DateTimePickerWrapper
                  disablePast
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  label={Messages.t('form.field.date')}
                />
              )}
            />
          )
        }
      </div>
      <SpinButton
        editing={!!activity}
        spin={submitting}
        variant="primary"
      />
      <Button
        onClick={onClose}
        color="secondary"
        variant="contained"
        className="ml-2"
      >
        {Messages.t('formButton.cancel')}
      </Button>
    </form>
  );
}
