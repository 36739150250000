import React, { useEffect } from 'react';
// import mapboxgl from "mapbox-gl";
// import services from "services/services";
import { MapSource, MapLayer } from 'services/isochrone/MapSourceAndLayer';

export default function TransitLayer({ map, displayTransit }) {
  useEffect(() => {
    map.addSource(MapSource.TRAIN_IDF, {
      type: 'geojson',
      data: '/geojson/traces-du-reseau-ferre-idf.geojson',
    });

    map.addLayer({
      id: MapLayer.TRAIN_IDF,
      type: 'line',
      source: MapSource.TRAIN_IDF,
      paint: {
        'line-width': [
          'match',
          ['get', 'mode'],
          'Metro',
          2,
          'RER',
          3,
          'Train',
          4,
          1, // DEFAULT
        ],
        // 'line-color': ["concat", "#", ["get", "idrefliga"]]
        'line-color': 'rgba(253, 208, 23, 1.0)',
      },
      layout: {
        visibility: 'none',
      },
    });
  }, [map]);

  useEffect(() => {
    map.setLayoutProperty(
      MapLayer.TRAIN_IDF,
      'visibility',
      displayTransit ? 'visible' : 'none',
    );
  }, [map, displayTransit]);
  return <></>;
}
