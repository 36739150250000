import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Messages from 'services/i18n/Messages';

type Props = {
  location: Location
};

export default function NotFound({ location }: Props) {
  return (
    <>
      <h3>
        {Messages.t('notFoundPage.noMatch')} <code>{location.pathname}</code>
      </h3>
      <Button as={Link} to="/">{Messages.t('notFoundPage.goHome')}</Button>
    </>
  );
}
