import React, { useEffect, useRef, useState } from 'react';
import Button from 'features/client/common/Button';
import Characteristics from 'features/client/pages/candidateDetails/Characteristics';
import Opinion from 'features/client/pages/candidateDetails/Opinion';
import Location from 'features/client/pages/candidateDetails/Location';
import Description from 'features/client/pages/candidateDetails/Description';
import Comments from 'features/client/pages/candidateDetails/comments/Comments';
import ViewingDetails from 'features/client/pages/candidateDetails/ViewingDetails';
import { Research } from 'types/research';
import CandidateModal from 'features/client/pages/candidateDetails/CandidateModal';
import { WorkflowStep } from 'types/WorkflowStep';
import { Candidate } from 'types/candidate';
import { ViewingAppointment } from 'types/ViewingAppointment';
import DateUtils from 'services/DateUtils';
import ImageGallery from 'features/client/pages/candidateDetails/ImageGallery';
import CarrouselModal from 'features/client/common/CarrouselModal';
import { ClientAction, workflowStepMetaData } from 'services/hunter/WorkflowStep';
import { useCandidate } from 'network/api/CandidateQueries';
import { useHistory, useParams } from 'react-router-dom';
import Links from 'features/client/pages/candidateDetails/Links';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import { NotificationService } from 'services/notification';
import StringUtils from 'services/StringUtils';
import ActivitiesTimeline from 'features/client/pages/candidateDetails/ActivitiesTimeline';
import Messages from 'services/i18n/Messages';
import { activityType } from 'types/Activity';
import { WarningAmberOutlined } from '@material-ui/icons';

type Props = {
  candidate?: Candidate,
  viewingAppointement?: ViewingAppointment[],
  research?: Research
};

type Params = {
  researchId: string,
};

export default function CandidateDetailsDesktop(
  {
    candidate,
    viewingAppointement,
    research,
  }: Props,
) {
  const [carrouselModalOpen, setCarrouselModalOpen] = useState(false);
  const { updateCandidateStep } = useCandidate();
  const { researchId } = useParams<Params>();

  const history = useHistory();
  const [openWorkflowStepModal, setOpenWorkflowStepModal] = useState<WorkflowStep | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scroll(0, 0);
    }
  }, [candidate?.id]);

  if (!candidate) {
    return null;
  }

  const updateCandidate = (clientAction: ClientAction) => {
    updateCandidateStep.mutate(
      // @ts-ignore
      {
        candidate,
        data: {
          is_client_update: true,
          workflow_step: clientAction.targetWorkflowStep,
          workflow_step_changed_date: new Date(),
          activity_type: activityType.CLIENT,
          message_title: 'Client comment',
          message_content: 'The customer did not leave a comment',
          client_notation: 0,
        },
      },
      {
        onSuccess: () => {
          NotificationService.notifySuccess(Messages.t('toast.client.movedListing', { stepDisplayName: workflowStepMetaData(clientAction.targetWorkflowStep).getClientDisplayName() }));
          history.push(
            Routes.withPath(
              clientAction.clientRedirection,
              [{ label: RESEARCH_ID, value: researchId }],
            ),
          );
        },
      },
    );
  };

  const action = workflowStepMetaData(candidate.workflow_step).clientActions;

  return (
    <div className="candidate-detail" ref={ref}>
      <h2>
        {`${candidate.client_identifier
          ? `#${candidate.client_identifier} : `
          : ''} ${
          StringUtils.getPrettyAddress(candidate.property.city)
        } ${
          candidate.property.postal_code
        } - ${
          candidate.property.price
        }€ ${candidate.property.rooms ? `- ${
          candidate.property.rooms
        } ${Messages.t('form.field.rooms')}` : ''} - ${
          candidate.property.area
        } m²`}
      </h2>
      {
        !DateUtils.dateIsNullOtooOld(candidate.property.published_date) && (
          <div className="subtitle">
            {`${Messages.t('dates.published')} ${DateUtils.getPrettyDateDeltaFromNow(candidate.property.published_date)}`}
          </div>
        )
      }
      <div className="gallery-container">
        <ImageGallery property={candidate.property} candidate={candidate} />
        <Button
          className="see-more-button"
          onClick={() => setCarrouselModalOpen(true)}
          variant="primary"
        >
          {Messages.t('candidate.seePhotos')}
        </Button>
        <CarrouselModal
          onClose={() => setCarrouselModalOpen(false)}
          isOpen={carrouselModalOpen}
          property={candidate.property}
        />
      </div>
      {
        candidate.property.gli_asked && (
          <div className="gli-requested">
            <WarningAmberOutlined />{Messages.t('candidate.detail.gli')}
          </div>
        )
      }
      {
        candidate.property.available === false && (
          <div className="unavailable-property">
            {Messages.t('candidate.detail.linkExpired')}
          </div>
        )
      }
      <div className="candidate-detail-main-content">
        <div className="candidate-detail-main-data">
          {
            viewingAppointement && viewingAppointement.length > 0 && (
              <>
                <ViewingDetails
                  viewingAppointement={viewingAppointement[0]}
                  candidate={candidate}
                />
              </>
            )
          }
          <Opinion candidate={candidate} research={research} />
          <Characteristics candidate={candidate} research={research} />
          {
            research && (
              <>
                <Comments research={research} candidate={candidate} />
              </>
            )
          }
        </div>
        <div>
          <div className="action-button-container">
            <div
              className="subtitle"
            >
              {Messages.t('candidate.status', { step: workflowStepMetaData(candidate.workflow_step).getClientDisplayName() })}
            </div>
            {
              action && action.length > 0 && (
                <h4>{Messages.t('candidate.nextStep')}</h4>
              )
            }

            <div className="button-container">
              {
                action?.map((clientAction) => (
                  <Button
                    key={clientAction.targetWorkflowStep}
                    onClick={
                      clientAction.showModal
                        ? () => setOpenWorkflowStepModal(clientAction.targetWorkflowStep)
                        : () => updateCandidate(clientAction)
                    }
                    variant={clientAction.buttonType}
                  >
                    {clientAction.getDisplayName()}
                  </Button>
                ))
              }
            </div>
          </div>
          <Links candidate={candidate} />
        </div>
      </div>
      <Location candidate={candidate} />
      <ActivitiesTimeline candidate={candidate} />
      <Description candidate={candidate} />
      {!!openWorkflowStepModal && (
        <CandidateModal
          workflowStepMetadata={
            openWorkflowStepModal
              ? workflowStepMetaData(openWorkflowStepModal)
              : null
          }
          candidate={candidate}
          targetWorkflowStep={openWorkflowStepModal}
          onClose={() => setOpenWorkflowStepModal(null)}
          isOpen={!!openWorkflowStepModal}
        />
      )}
    </div>
  );
}
