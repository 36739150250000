import React, {
  MutableRefObject, useCallback, useEffect, useState,
} from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Candidate } from 'types/candidate';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'lib/InfiniteScroll';
import { Research } from 'types/research';
import ResultCard from 'features/hunter/common/ResultCard';

type Props = {
  candidates: Candidate[],
  selectedCandidateIds: string[],
  setSelectedCandidateIds: (ids: string[]) => void,
  fetchNextPage: () => void,
  hasNextPage: boolean,
  scrollableRef: MutableRefObject<HTMLDivElement | null>,
  isLoading: boolean,
  research: Research,
};

export default function VerticalCandidateList(
  {
    candidates,
    selectedCandidateIds,
    setSelectedCandidateIds,
    hasNextPage,
    fetchNextPage,
    scrollableRef,
    isLoading,
    research,
  }: Props,
) {
  const location = useLocation();
  const [currentFocusedCandidateId, setCurrentFocusedCandidateId] = useState<string | null>(null);
  const handleSelect = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      let target: HTMLElement | null = e.target as HTMLElement;
      while (target) {
        if (target.dataset.candidateid) {
          const candidateId = target.dataset.candidateid;
          if (e.metaKey || e.ctrlKey) {
            if (selectedCandidateIds.includes(candidateId)) {
              setSelectedCandidateIds(selectedCandidateIds
                .filter((id) => id !== candidateId));
              return;
            }
            setSelectedCandidateIds([...selectedCandidateIds, candidateId]);
            return;
          }
          if (e.shiftKey && selectedCandidateIds.length > 0) {
            const selectedsToAdd = [candidateId];
            const lastElt = selectedCandidateIds[selectedCandidateIds.length - 1];
            let adding = false;
            for (let i = 0; i < candidates.length; i++) {
              if (candidates[i].id.toString() === candidateId
                || candidates[i].id.toString() === lastElt) {
                adding = !adding;
                if (adding) {
                  // eslint-disable-next-line no-continue
                  continue;
                }
                break;
              }
              if (adding) {
                selectedsToAdd.push(candidates[i].id.toString());
              }
            }
            setSelectedCandidateIds([...selectedCandidateIds, ...selectedsToAdd]);
            return;
          }
          setSelectedCandidateIds([candidateId]);
        }
        target = target.parentElement;
      }
    },
    [setSelectedCandidateIds],
  );

  useEffect(() => {
    setCurrentFocusedCandidateId(null);
  }, [setCurrentFocusedCandidateId, location]);

  const selectRef = useCallback((node: HTMLDivElement) => {
    if (node && node.firstElementChild && node.firstElementChild.getAttribute('data-candidateid') !== currentFocusedCandidateId) {
      setCurrentFocusedCandidateId(node.firstElementChild.getAttribute('data-candidateid'));
      node.scrollIntoView({ behavior: 'smooth' });
    }
  }, [setCurrentFocusedCandidateId, currentFocusedCandidateId]);

  return (
    <InfiniteScroll
      isLoading={isLoading}
      next={fetchNextPage}
      size={candidates.length}
      hasNext={hasNextPage}
      scrollableDivRef={scrollableRef}
    >
      {
        candidates.map((candidate) => {
          if (selectedCandidateIds[0] === candidate.id.toString()) {
            return (
              <div
                key={candidate.id}
                ref={selectRef}
              >
                <ResultCard
                  candidate={candidate}
                  research={research}
                  handleNavigation={handleSelect}
                  className={selectedCandidateIds[0] === candidate.id.toString() ? 'bg-selected' : ''}
                />
              </div>
            );
          }
          return (
            <div key={candidate.id}>
              <ResultCard
                candidate={candidate}
                research={research}
                handleNavigation={handleSelect}
                className={selectedCandidateIds.includes(candidate.id.toString()) ? 'bg-selected' : ''}
              />
            </div>
          );
        })
      }
    </InfiniteScroll>
  );
}
