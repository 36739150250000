import React, { ReactNode, useRef, useState } from 'react';
import { Candidate } from 'types/candidate';
import { workflowStep, WorkflowStep } from 'types/WorkflowStep';
import { ChevronRightIcon } from '@heroicons/react/outline';
import TrackCandidateCard from 'features/client/pages/track/TrackCandidateCard';
import { detectMobile } from 'services/utils';
import Messages from 'services/i18n/Messages';

type Props = {
  icon: ReactNode,
  count?: number
  workflowStepProps: WorkflowStep,
  label: string,
  candidates?: Candidate[],
  openStep: () => void,
  isSelected: boolean;
};

export default function TrackedWorkflowStep(
  {
    icon,
    label,
    count,
    workflowStepProps,
    candidates,
    openStep,
    isSelected,
  }: Props,
) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const isMobile = detectMobile();

  const containerHeight = (ref.current && isOpen) ? ref.current.offsetHeight : 0;

  const extendStep = () => {
    setIsOpen(((prevState) => !prevState));
    if (!isMobile) {
      openStep();
    }
  };

  return (
    <div className="track-workflow-step-container">
      <button type="button" onClick={extendStep} className={`track-workflow-step ${isSelected ? 'selected' : ''}`}>
        <div className="workflow-step-icon">
          {icon}
        </div>
        <div className="workflow-step-texts">
          <div className="workflow-step-label">
            {label}
          </div>
          <div className="workflow-step-count">
            {Messages.t('trackList.listingCount', { count })}
          </div>
        </div>
        <div>
          <ChevronRightIcon className={`extend-icon ${isOpen ? 'extended' : ''}`} />
        </div>
      </button>
      {
        isMobile && (
          <div style={{ height: containerHeight }} className="candidate-list-container">
            <div ref={ref} className="candidate-list-content">
              {
                candidates && candidates.map((candidate) => (
                  <TrackCandidateCard
                    key={candidate.id}
                    candidate={candidate}
                    showViewing={workflowStepProps === workflowStep.BOOKED}
                  />
                ))
              }
            </div>
          </div>
        )
      }
    </div>
  );
}
