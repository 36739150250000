import React from 'react';
import { CandidateResearch } from 'types/candidate';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  CANDIDATE_TAB_ID,
  DETAIL_ID,
  RESEARCH_CANDIDATE_DETAIL,
  TAB_ID,
} from 'routes/HmRoutes';
import { CanditateTab } from 'features/hunter/pages/research/researchDetails/candidateDetails/CandidateTabs';
import Badge from 'react-bootstrap/Badge';
import { workflowStepMetaData } from 'services/hunter/WorkflowStep';
import { SearchResult } from 'theme/SearchResult';
import { CANDIDATE_ID, RESEARCH_ID, Routes } from 'routes/Routes';
import Messages from 'services/i18n/Messages';
import { LocationOn } from '@material-ui/icons';

type Props = {
  candidate: CandidateResearch
  onClick?: () => void
  searchQuery?: string | null
};

export default function CandidateCard({ candidate, onClick, searchQuery }: Props) {
  return (
    <Link
      onClick={onClick}
      to={
        Routes.withPath(
          RESEARCH_CANDIDATE_DETAIL,
          [
            { label: RESEARCH_ID, value: candidate.research.id },
            { label: CANDIDATE_ID, value: String(candidate.id) },
            { label: TAB_ID, value: CANDIDATE_TAB_ID },
            { label: DETAIL_ID, value: CanditateTab.DETAILS },
          ],
        )
      }
      className="search-card"
      key={candidate.id}
    >
      <div className="candidate-card-image">
        <img className="image" alt="" src={candidate.property.images_url && candidate.property.images_url[0]} />
        <div className="badge-container left-align">
          {!candidate.property.available && (
            <Badge
              variant="danger"
              className="candidate-badge"
            >
              {Messages.t('candidate.card.badge.expired')}
            </Badge>
          )}
        </div>
      </div>
      <div className="search-card-content">
        <div className="search-card-header">
          <h6>
            {Messages.t('property.infoInline', {
              price: candidate.property.price,
              area: candidate.property.area,
              rooms: candidate.property.rooms || 'Unkwnon',
              bedrooms: candidate.property.bedrooms || 'Unkwnon',
            })}

          </h6>
        </div>
        <div className="property-address">
          <LocationOn />
          {candidate.property.address}
        </div>
        <div>
          {Messages.t('search.currentStep')}
          <span className="button-filter-radio checked">{workflowStepMetaData(candidate.workflow_step).getDisplayName()}</span>
        </div>
        <div className="search-card-footer">
          <div className="search-footer-content">
            <div>
              {Messages.t('research.list.fullName')} :
              <span className="property-info">
                <SearchResult
                  value={`${candidate.research.client_first_name} ${candidate.research.client_last_name}`}
                  searchValue={searchQuery}
                />
              </span>
            </div>
            <div>
              {Messages.t('research.list.owner')} :
              <span className="property-info">
                <SearchResult
                  value={candidate.research.homematcher_email || ''}
                  searchValue={searchQuery}
                />
              </span>
            </div>
            <div>
              {Messages.t('research.list.status')} :
              <span className="property-info">
                <SearchResult
                  value={Messages.t(`research.status.${candidate.research.status}`)}
                  searchValue={searchQuery}
                />
              </span>
            </div>
          </div>
          <ArrowForwardIcon className="link-icon" />
        </div>
      </div>
    </Link>
  );
}
