import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SpinButton from 'theme/hunter-utils/SpinButton';
import { mailRegex, validatePhoneNumber } from 'lib/form/FormUtils';
import PhoneNumberInputWrapper from 'lib/form/PhoneNumberInputWrapper';
import { ContactInfoFormType, ContactType } from 'types/forms/ContactInfoForm';
import {
  usePropertiesContact, useProperty,
  usePropertyContact,
} from 'network/api/PropertyQueries';
import SelectWrapper from 'lib/form/SelectWrapper';
import StringUtils from 'services/StringUtils';
import { Property } from 'types/property';
import { NotificationService } from 'services/notification';
import { PropertyContact } from 'types/propertyContact';
import Highlighter from 'react-highlight-words';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Candidate } from 'types/candidate';
import Messages from 'services/i18n/Messages';
import { FetchError } from 'network/Errors';

type Props = {
  propertyId?: string,
  property?: Property,
  contact?: PropertyContact,
  onValidate?: (contactId: number | string) => void,
  isFirstPropertyContact?: boolean
  fieldRequierd?: boolean,
  hideTitle?: boolean,
  candidate?: Candidate,
  canConfirm?: boolean,
  updateIsDirty?: (isDirty: boolean) => void
};

export default function ContactInfoForm(
  {
    propertyId,
    contact,
    property,
    onValidate,
    fieldRequierd,
    hideTitle,
    isFirstPropertyContact,
    candidate,
    canConfirm,
    updateIsDirty,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiError] = useState({});
  const [isMainContact, setIsMainContact] = useState(property?.main_contact === contact?.id
    || isFirstPropertyContact);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const { createPropertyContact, updatePropertyContact } = usePropertyContact();
  const { searchPropertiesContact } = usePropertiesContact();
  const { updateProperty } = useProperty();

  const { data: searchResults } = searchPropertiesContact(searchValue, property && !!searchValue && searchValue !== '');

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<ContactInfoFormType>({
    defaultValues: {
      contact_type: property?.is_agency ? ContactType.AGENCY : ContactType.OWNER,
      agency_name: property?.agency_name,
      ...contact,
    },
  });
  useEffect(() => {
    if (updateIsDirty) {
      updateIsDirty(isDirty);
    }
  }, [isDirty, updateIsDirty]);
  const formField = watch(); // when pass nothing as argument, you are watching everything

  const ref = useRef<HTMLDivElement>(null);
  const [openResults, setOpenResults] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenResults(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const updatePropertyMainContact = (contactId: number | undefined) => {
    if (property && candidate && isMainContact !== (property.main_contact === contactId)) {
      updateProperty
        .mutate({
          propertyId: property.id,
          candidate,
          data: { main_contact: isMainContact ? contactId : undefined },
        }, {
          onSuccess: () => {
            property.main_contact = contactId || undefined;
          },
          onError: (error: FetchError) => {
            const { status } = error;
            if (status === 500) {
              NotificationService.notifyError(Messages.t('toast.hunter.error'));
            }
          },
        });
    }
  };

  const dissociate = () => {
    if (property) {
      updatePropertyContact.mutate(
        {
          propertyContactId: contact?.id,
          data: {
            properties: contact?.properties
              .filter((contactPropertyId) => contactPropertyId !== property.id),
          },
        },
        {
          onSuccess: () => {
            if (property.main_contact === contact?.id) {
              updatePropertyMainContact(undefined);
            }
            NotificationService.notifySuccess(Messages.t('toast.hunter.contactDissociated'));
            if (onValidate && contact) {
              onValidate('');
            }
            setSubmitting(false);
          },
          onError: (error: FetchError) => {
            const { status } = error;
            if (status === 500) {
              NotificationService.notifyError(Messages.t('toast.hunter.error'));
            }
            setApiError(error.json_response);
            setSubmitting(false);
          },
        },
      );
    }
  };

  const onSubmit: SubmitHandler<ContactInfoFormType> = (formData: ContactInfoFormType) => {
    setApiError({});
    setSubmitting(true);

    const finalData = {
      ...formData,
      agency_name: formData.agency_name || '',
    };
    if (finalData.id) {
      if (propertyId && !finalData.properties.includes(propertyId)) {
        finalData.properties.push(propertyId);
      }
      // @ts-ignore
      updatePropertyContact.mutate({ propertyContactId: finalData.id, data: finalData },
        {
          onSuccess: () => {
            NotificationService.notifySuccess(Messages.t('toast.hunter.contactEdit'));
            updatePropertyMainContact(finalData.id);
            if (onValidate) {
              onValidate(finalData.id);
            }
            setSubmitting(false);
          },
          onError: (error: FetchError) => {
            const { status } = error;
            if (status === 500) {
              NotificationService.notifyError(Messages.t('toast.hunter.error'));
            }
            setApiError(error.json_response);
            setSubmitting(false);
          },
        });
    } else {
      // @ts-ignore
      createPropertyContact.mutate({ propertyId, data: finalData },
        {
          onSuccess: (data) => {
            NotificationService.notifySuccess(Messages.t('toast.hunter.contactCreate'));
            updatePropertyMainContact(data.response.id);
            if (onValidate) {
              onValidate(data.response.id);
            }
            setSubmitting(false);
          },
          onError: (error: FetchError) => {
            const { status } = error;
            if (status === 500) {
              NotificationService.notifyError(Messages.t('toast.hunter.error'));
            }
            setApiError(error.json_response);
            setSubmitting(false);
          },
        });
    }
  };

  const updateFormValue = (propertyContact: PropertyContact) => {
    setOpenResults(false);
    // @ts-ignore
    Object.keys(propertyContact).map((key) => setValue(key, propertyContact[key]));
  };

  return (
    <div>
      {
        !hideTitle && (
          <>
            {!formField.id ? (
              <>
                <h4 className="mt-4">{Messages.t('contacts.new')}</h4>
                <hr />
              </>
            ) : (
              <>
                <h4 className="mt-4">{Messages.t('contacts.edit')}</h4>
                <hr />
              </>
            )}
          </>
        )
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          candidate && (
            <FormControlLabel
              control={
                (
                  <Checkbox
                    onChange={(e) => setIsMainContact(e.target.checked)}
                    checked={isMainContact}
                  />
                )
              }
              label={Messages.t('form.field.isMainContact')}
            />
          )
        }
        <div className="row-form">
          <Controller
            name="contact_type"
            control={control}
            rules={{ required: true }}
            render={(controller) => (
              <SelectWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('form.field.contactType')}
                values={Object.values(ContactType).map((value) => (
                  { key: value, label: StringUtils.capitalizeFirstLetter(value) }
                ))}
              />
            )}
          />
          {
            formField.contact_type === ContactType.AGENCY && (
              <Controller
                name="agency_name"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('form.field.agency')}
                  />
                )}
              />
            )
          }
        </div>
        <div ref={ref} className="row-form name-container">
          <Controller
            name="first_name"
            control={control}
            rules={{ required: fieldRequierd || (!formField.last_name && Messages.t('form.error.oneRequired')) }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                disableNativeAutocomplete
                delayedAction={(e) => {
                  setOpenResults(true);
                  setSearchValue(`${e.target.value || ''} ${formField.last_name || ''}`);
                }}
                control={controller}
                label={Messages.t('form.field.firstname')}
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            rules={{ required: fieldRequierd || (!formField.first_name && Messages.t('form.error.oneRequired')) }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                disableNativeAutocomplete
                delayedAction={(e) => {
                  setOpenResults(true);
                  setSearchValue(`${formField.first_name || ''} ${e.target.value || ''}`);
                }}
                control={controller}
                label={Messages.t('form.field.lastname')}
              />
            )}
          />
          {
            property && (
              <div
                className={`search-result-container ${openResults && !!searchResults && searchResults.length > 0 ? 'show' : ''}`}
              >
                <div className="search-results-header">
                  <h5>{Messages.t('contacts.existingContacts')}</h5>
                  <IconButton onClick={() => setOpenResults(false)}>
                    <Close />
                  </IconButton>
                </div>
                <div className="quick-access-results">
                  {
                    searchResults && searchResults.map((propertyContact) => (
                      <button type="button" onClick={() => updateFormValue(propertyContact)} className="contact-result">
                        <div className="contact-name">
                          <Highlighter
                            highlightClassName="highlight-text"
                            searchWords={searchValue?.split(' ')}
                            autoEscape
                            textToHighlight={`${propertyContact.first_name} ${propertyContact.last_name}`}
                          />
                        </div>
                        <div>{propertyContact.contact_type === ContactType.AGENCY ? Messages.t('contact.agency', { name: propertyContact.agency_name }) : Messages.t('contact.owner')} </div>
                      </button>
                    ))
                  }
                </div>
              </div>
            )
          }
        </div>
        <div className="row-form">
          <Controller
            name="phone_number"
            control={control}
            rules={{
              required: fieldRequierd,
              validate: (v) => validatePhoneNumber(v)
                || Messages.t('form.error.phoneNumber'),
            }}
            render={(controller) => (
              <PhoneNumberInputWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('form.field.phone')}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              pattern: {
                value: mailRegex,
                message: Messages.t('form.error.email'),
              },
            }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('form.field.email')}
              />
            )}
          />
        </div>
        <Controller
          name="comment"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="textarea"
              rows={5}
              control={controller}
              label={Messages.t('form.field.comment')}
            />
          )}
        />
        <SpinButton
          editing={!!contact?.id}
          title={(!!contact?.id && canConfirm && !isDirty && Messages.t('formButton.confirm')) || undefined}
          spin={submitting}
          variant="primary"
          className="mb-2"
        />
        {
          contact && property && (
            <SpinButton
              editing
              type="button"
              onClick={dissociate}
              spin={submitting}
              variant="secondary"
              title={Messages.t('formButton.dissociate')}
              className="mb-2 ml-2"
            />
          )
        }
      </form>
    </div>
  );
}
