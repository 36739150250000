import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useBackend } from 'network/BackendFetchAdapter';
import { FinancialInformation } from 'types/postSearch/FinancialInformation';

export function useFinancialInformations() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const GetFinancialInformations = (
    postSearchId,
    enabled = true,
  ) => useQuery<FinancialInformation[]>(
    ['getFinancialInformation', postSearchId],
    async () => {
      const response = await backend.fetchJson(
        `/api/financial-informations/?post_search=${postSearchId}`,
      );
      return response;
    },
    { enabled },
  );
  const CreateFinancialInformation = useMutation<any, any, {
    postSearchId: string,
    data: FinancialInformation,
  }>(
    async ({ postSearchId, data }) => {
      data.post_search = postSearchId;
      const response = await backend.post('/api/financial-informations/', data);
      return response;
    },
    {
      onSuccess: (_, { postSearchId }) => {
        queryClient.refetchQueries(['getFinancialInformation', postSearchId]);
      },
    },
  );

  const UpdateFinancialInformation = useMutation<any, any, {
    postSearchId: string,
    financialInformationsId: string
    data: Partial<FinancialInformation>,
  }>(
    async ({ data, financialInformationsId }) => {
      const response = await backend.patch(`/api/financial-informations/${financialInformationsId}/`, data);
      return response;
    },
    {
      onSuccess: (_, { postSearchId }) => {
        queryClient.refetchQueries(['getFinancialInformation', { postSearchId }]);
      },
    },
  );

  const DeleteFinancialInformation = useMutation<any, any, {
    postSearchId: string,
    financialInformationId: string,
  }>(
    async ({ financialInformationId }) => {
      const response = await backend.delete(`/api/financial-informations/${financialInformationId}`);
      return response;
    },
    {
      onSuccess: (_, { postSearchId }) => {
        queryClient.refetchQueries(['getFinancialInformation', postSearchId]);
      },
    },
  );

  const UploadFinancialFile = useMutation<void, any, {
    financialInformationId?: string,
    postSearchId: string
    field: string
    data: {
      filename: string,
      fileBase64: string,
    },
  }>(
    async (
      {
        financialInformationId,
        data,
        field,
        postSearchId,
      },
    ) => {
      const uploadData = {
        id: financialInformationId || null,
        post_search_id: postSearchId,
        field,
        filename: data.filename,
        file_base_64: data.fileBase64,
      };
      await backend.post(
        '/api/financial-informations/upload/',
        uploadData,
      );
    },
    {
      onSuccess: (_, { postSearchId }) => {
        queryClient.refetchQueries(['getFinancialInformation', postSearchId]);
      },
    },
  );

  return {
    uploadFinancialFile: UploadFinancialFile,
    getFinancialInformations: GetFinancialInformations,
    createFinancialInformation: CreateFinancialInformation,
    updateFinancialInformation: UpdateFinancialInformation,
    deleteFinancialInformation: DeleteFinancialInformation,
  };
}
