import React, { useMemo, useState } from 'react';
import { Candidate } from 'types/candidate';
import {
  TemplateIcon,
  LocationMarkerIcon,
} from '@heroicons/react/solid';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Cloudinary } from 'cloudinary-core';
import StringUtils from 'services/StringUtils';
import PropertyImage from 'theme/PropertyImage';
import ImageUtils, { Image } from 'services/ImageUtils';
import Messages from 'services/i18n/Messages';

type Props = {
  candidate: Candidate,
  onClick?: () => void
};

export default function MapDesktopCard({ candidate, onClick }: Props) {
  useMemo(
    () => new Cloudinary({
      cloud_name: 'settlesweet',
    }),
    [],
  );
  const [fileredImage, setFilteredImage] = useState<string[]>([]);
  const images = ImageUtils.getImageArray(candidate.property, fileredImage);
  const firstImage: Image | null = images[0];

  return (
    <button
      type="button"
      className="map-desktop-card"
      onClick={onClick}
    >
      <div className="image-container">
        <PropertyImage
          onError={() => setFilteredImage((prevState) => ([...prevState, firstImage.image]))}
          image={firstImage?.image}
          isCloudinaryImage={firstImage?.isCloudinaryImage}
        />
      </div>
      <div className="candidate-card-content">
        <div className="candidate-card-data">
          <div className="candidate-card-price">
            {candidate.property.price} &euro;<span className="price-month">{Messages.t('candidate.card.byMonth')}</span>
          </div>
          <div className="candidate-card-dimensions">
            <TemplateIcon />
            {candidate.property.rooms}
            <ArrowsExpandIcon />
            {candidate.property.area} m2
          </div>

          <div className="candidate-card-data-row">
            <div className="candidate-card-address">
              <LocationMarkerIcon />
              <span>{`${StringUtils.getPrettyAddress(candidate.property.city)}, ${candidate.property.postal_code}`}</span>
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}
