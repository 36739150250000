import { FluximmoRawAdvert } from 'types/fluximmoRawAdvert';
import { CloudinaryUrl } from 'types/cloudinary';
import { PropertyContact } from 'types/propertyContact';
import { PropertyType } from 'types/research';

export interface Property{
  address: string;
  area: number;
  available?: boolean;
  bedrooms: number | null;
  city: string;
  created_at: string;
  description?: string;
  duplicate_unique_ids: string[];
  duplicate_urls: string[];
  elevator: boolean | null;
  floor: number;
  // TODO To be deleted
  fluximmo_raw_advert?: FluximmoRawAdvert;
  furnished: boolean | null;
  id: string;
  images_url: string[];
  uploaded_images: CloudinaryUrl[];
  last_checked_at: string;
  manually_added: boolean;
  original_price: number;
  postal_code: string;
  price: number;
  published_date: string;
  rooms: number;
  source_url: string;
  source_website: string;
  title: string;
  unique_id: string;
  updated_at: string;
  latitude?: number;
  longitude?: number;
  location_type?: LocationType;
  main_contact?: number;
  property_contact?: PropertyContact;
  agency_name?: string;
  is_agency?:boolean;
  source: string;
  issue_report: string;
  is_scam: boolean;
  gli_asked?: boolean;
  property_type: PropertyType;
}

export interface PropertyPartial extends Partial<Property> {

}

export interface PropertySearch extends Property{
  property_contacts: PropertyContact[];
}

export type LocationType = 'ROOFTOP' | 'RANGE_INTERPOLATED' | 'GEOMETRIC_CENTER' | 'APPROXIMATE';

type LocationTypeEnum = { [key in LocationType]: key };

export const locationType: LocationTypeEnum = {
  ROOFTOP: 'ROOFTOP', // indicates that the returned result is a precise geocode for which we have location information accurate down to street address precision.
  RANGE_INTERPOLATED: 'RANGE_INTERPOLATED', // indicates that the returned result reflects an approximation (usually on a road) interpolated between two precise points (such as intersections). Interpolated results are generally returned when rooftop geocodes are unavailable for a street address.
  GEOMETRIC_CENTER: 'GEOMETRIC_CENTER', // indicates that the returned result is the geometric center of a result such as a polyline (for example, a street) or polygon (region).
  APPROXIMATE: 'APPROXIMATE', // indicates that the returned result is approximate.
};
