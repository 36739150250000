import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useBackend } from 'network/BackendFetchAdapter';
import { InfiniteData } from 'react-query/types/core/types';
import { PaginationWrapper } from 'types/pagination';
import { Candidate } from 'types/candidate';
import { Property, PropertyPartial } from 'types/property';
import { PropertyContact } from 'types/propertyContact';
import candidateFilterService from 'services/CandidateFilterService';
import { Note } from 'types/Note';

export function useProperty() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const GetProperty = (propertyId) => useQuery<Property>(['getProperty', propertyId], async () => {
    const response = await backend.fetchJson(
      `/api/properties/${propertyId}/`,
    );
    return response;
  });

  const CreateProperty = useMutation<any, any, { data: Property }>(async ({ data }) => {
    data.manually_added = true;
    const response = await backend.post('/api/properties/', data);
    return response;
  });

  const UpdatePropertyFromCandidate = useMutation<any, any, {
    propertyId: string,
    candidate: Candidate,
    data: PropertyPartial,
  }>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ propertyId, candidate, data }) => {
      const response = await backend.patch(
        `/api/properties/${propertyId}/`,
        data,
      );
      return response;
    },
    {
      onSuccess: (data: Property, variables) => {
        const { candidate } = variables;
        queryClient.setQueryData<InfiniteData<PaginationWrapper<Candidate>> | undefined>(
          [
            'getCandidatesForStep',
            {
              research_id: candidate.research,
              step: candidate.workflow_step,
              orderingBy: candidateFilterService.getSort(candidate.workflow_step).ordering,
            },
          ],
          (oldData) => oldData && ({
            ...oldData,
            pages: oldData?.pages.map((oldpage) => ({
              ...oldpage,
              results: oldpage.results.map((old) => {
                if (old.id !== candidate.id) {
                  return old;
                }
                return {
                  ...old,
                  property: data,
                };
              }),
            })),
          }),
        );
      },
    },
  );

  return {
    createProperty: CreateProperty,
    getProperty: GetProperty,
    updateProperty: UpdatePropertyFromCandidate,
  };
}

export function usePropertiesContact() {
  const backend = useBackend();

  const GetPropertiesContact = (propertyId, enabled = true) => useQuery<PropertyContact[]>(
    ['getPropertiesContact', propertyId],
    async () => {
      const response = await backend.fetchJson(
        `/api/properties-contact/?properties=${propertyId}`,
      );
      return response;
    },
    { enabled },
  );

  const GetContact = (contactId, enabled = true) => useQuery<PropertyContact>(
    ['getContact', contactId],
    async () => {
      const response = await backend.fetchJson(
        `/api/properties-contact/${contactId}`,
      );
      return response;
    },
    { enabled, refetchOnMount: 'always' },
  );

  const SearchPropertiesContact = (search, enabled = true) => useQuery<PropertyContact[]>(
    ['searchPropertiesContact', search],
    async () => {
      const response = await backend.fetchJson(
        `/api/properties-contact/?search=${search}`,
      );
      return response;
    },
    { enabled },
  );

  const GetAllContact = (enabled = true) => useQuery<PropertyContact[]>(
    ['getAllContacts'],
    async () => {
      const response = await backend.fetchJson(
        '/api/properties-contact',
      );
      return response;
    },
    { enabled },
  );

  return {
    getContact: GetContact,
    getAllContact: GetAllContact,
    getPropertiesContact: GetPropertiesContact,
    searchPropertiesContact: SearchPropertiesContact,
  };
}

export function usePropertyContact() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const UpdatePropertyContact = useMutation<any, any, any>(
    async ({ propertyContactId, data }) => {
      const response = await backend.patch(
        `/api/properties-contact/${propertyContactId}/`,
        data,
      );
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(['getPropertiesContact', data.property]);
        queryClient.invalidateQueries('getContact');
      },
    },
  );

  const CreatePropertyContact = useMutation<any, any, any>(
    async ({ propertyId, data }) => {
      data.properties = [propertyId];
      const response = await backend.post('/api/properties-contact/', data);
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('getContact');
        queryClient.refetchQueries([
          'getPropertiesContact',
          data.response.property,
        ]);
      },
    },
  );

  return {
    createPropertyContact: CreatePropertyContact,
    updatePropertyContact: UpdatePropertyContact,
  };
}

export function useUploadedImage() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const CreateUploadedImage = useMutation<any, any, any>(
    async ({ candidate, image }) => {
      const formData = new FormData();
      formData.append('image', image);
      formData.append('property', candidate.property.id);
      const response = await backend.fetch('/api/uploaded-images/', {
        method: 'POST',
        body: formData,
      });
      return {
        response: await response.json(),
        status: response.status,
      };
    },
    {
      onSuccess: (data: any, variables) => {
        const { candidate } = variables;
        queryClient.setQueryData<InfiniteData<PaginationWrapper<Candidate>> | undefined>(
          [
            'getCandidatesForStep',
            {
              research_id: candidate.research,
              step: candidate.workflow_step,
              orderingBy: candidateFilterService.getSort(candidate.workflow_step).ordering,
            },
          ],
          (oldData) => oldData && ({
            ...oldData,
            pages: oldData?.pages.map((oldpage) => ({
              ...oldpage,
              results: oldpage.results.map((old) => {
                if (old.id !== candidate.id) {
                  return old;
                }
                return {
                  ...old,
                  property: {
                    ...old.property,
                    uploaded_images: [...old.property.uploaded_images, data.response],
                  },
                };
              }),
            })),
          }),
        );
      },
    },
  );

  return {
    createUploadedImage: CreateUploadedImage,
  };
}

export function useNote() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const GetNotes = (propertyId, enabled = true) => useQuery<Note[]>(
    ['getNotes', propertyId],
    async () => {
      const response = await backend.fetchJson(
        `/api/notes/?property=${propertyId}`,
      );
      return response;
    },
    { enabled },
  );

  const UpdateNote = useMutation<any, any, {
    noteId: number, data: Partial<Note>
  }>(
    async ({ noteId, data }) => {
      const response = await backend.patch(
        `/api/notes/${noteId}/`,
        data,
      );
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(['getNotes', data.property]);
      },
    },
  );

  const DeleteNote = useMutation<any, any, Note>(
    async (data) => {
      const response = await backend.delete(
        `/api/notes/${data.id}/`,
      );
      return response;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.refetchQueries(['getNotes', variables.property]);
      },
    },
  );

  const CreateNote = useMutation<any, any, {
    propertyId: string, data: Partial<Note>
  }>(
    async ({ propertyId, data }) => {
      data.property = propertyId;
      const response = await backend.post('/api/notes/', data);
      return response;
    },
    {
      onSuccess: (_, variables) => {
        const { propertyId } = variables;
        queryClient.refetchQueries([
          'getNotes', propertyId,
        ]);
      },
    },
  );

  return {
    getNotes: GetNotes,
    createNote: CreateNote,
    updateNote: UpdateNote,
    deleteNote: DeleteNote,
  };
}
