import { format, parseJSON } from 'date-fns';
import { fr } from 'date-fns/locale';

export function detectMobile(): boolean {
  return (window.innerWidth <= 600);
}

export function detectTablette(): boolean {
  return (window.innerWidth <= 950);
}

export function mapObjectValues(obj, fn) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, fn(value)]),
  );
}

export const ShortDateTimeFormat = (date) => formatDate(date, 'PPp');

export const LongDateTimeFormat = (date) => formatDate(date, 'PPPPpp');

const formatDate = (date, formatting) => {
  try {
    return format(parseJSON(date), formatting, { locale: fr });
  } catch (error) {
    return '...';
  }
};

export function copyToClipboard(elementId) {
  function copyToClip(str) {
    function listener(e) {
      e.clipboardData.setData('text/html', str);
      e.clipboardData.setData('text/plain', str);
      e.preventDefault();
    }

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  copyToClip(document.getElementById(elementId)?.innerHTML);
}

export function stringifyChangesetDates(value) {
  const valueType = typeof value;
  if (value instanceof Date) {
    return value.toISOString();
  }
  if (value instanceof Array) {
    return value.map((e) => stringifyChangesetDates(e));
  }
  if (
    value === null
    || valueType === 'boolean'
    || valueType === 'number'
    || valueType === 'string'
    || valueType === 'bigint'
  ) {
    return value;
  }
  if (value instanceof Object) {
    return mapObjectValues(value, (e) => stringifyChangesetDates(e));
  }
  throw Error('Unexpected type');
}

export function domainFromUrl(sourceUrl) {
  try {
    return new URL(sourceUrl).hostname;
  } catch {
    return null;
  }
}
