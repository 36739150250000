import React from 'react';
import ClientPageModal from 'features/client/common/ClientPageModal';
import { Research } from 'types/research';
import Messages from 'services/i18n/Messages';

type Props = {
  research: Research
};

export default function ViewingReport({ research }: Props) {
  return (
    <ClientPageModal title={Messages.t('viewingReport.pageTitle')}>
      <iframe title="viewingReport" id="idFrame" name="nameFrame" src={research.viewing_reports_link} />
    </ClientPageModal>
  );
}
