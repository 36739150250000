import React, { useEffect, useState } from 'react';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { Carousel } from 'react-responsive-carousel';
import PropertyImage from 'theme/PropertyImage';
import { Property } from 'types/property';
import Button from 'features/client/common/Button';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import ImageUtils from 'services/ImageUtils';
import Messages from 'services/i18n/Messages';

type Props = {
  property: Property,
  showArrows?: boolean
  intitialSelectedImage?: number,
  selectIndex?: (index: number) => void
  enableKeyBoard?: boolean,
  hideOtherPicture?: boolean,
};

function ClientCarousel(
  {
    property,
    showArrows,
    intitialSelectedImage,
    selectIndex,
    enableKeyBoard,
    hideOtherPicture,
  }: Props,
) {
  const [seletedIndex, setSelectedIndex] = useState(intitialSelectedImage || 0);
  const [fileredImage, setFilteredImage] = useState<string[]>([]);
  const imagesData = ImageUtils.getImageArray(property, fileredImage);
  const images = hideOtherPicture ? imagesData.slice(0, 1) : imagesData;

  const nextRef = React.useRef<() => void>(null);
  const prevRef = React.useRef<() => void>(null);

  useEffect(() => {
    const handleKeyBoardEvent = (event: KeyboardEvent) => {
      if (event.keyCode === 37) {
        if (prevRef.current) {
          prevRef.current();
        }
      }
      if (event.keyCode === 39) {
        if (nextRef.current) {
          nextRef.current();
        }
      }
    };
    if (enableKeyBoard) {
      document.addEventListener('keydown', handleKeyBoardEvent);
      return () => {
        document.removeEventListener('keydown', handleKeyBoardEvent);
      };
    }
    return () => {
    };
  }, []);

  if (images.length > 0) {
    return (
      <Carousel
        selectedItem={intitialSelectedImage || 0}
        dynamicHeight={false}
        showThumbs={false}
        showIndicators={!!showArrows}
        onChange={setSelectedIndex}
        autoPlay={false}
        interval={10000000000}
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
        showStatus={false}
        showArrows={!!showArrows}
        renderArrowNext={(clickHandler, hasNext) => {
          // @ts-ignore
          nextRef.current = clickHandler;
          return (
            hasNext && seletedIndex !== images.length - 1 && (
              <Button
                onClick={clickHandler}
                preventDefault
                className="carrousel-arrow right-arrow"
                variant="icon"
              >
                <ChevronRightIcon />
              </Button>
            )
          );
        }}
        renderArrowPrev={(clickHandler, hasPrev) => {
          // @ts-ignore
          prevRef.current = clickHandler;
          return (
            hasPrev && seletedIndex !== 0 && (
              <Button
                onClick={clickHandler}
                preventDefault
                className="carrousel-arrow left-arrow"
                variant="icon"
              >
                <ChevronLeftIcon />
              </Button>
            )
          );
        }}
        infiniteLoop
        emulateTouch
        className="carousel-custom-root"
      >
        {
          images.map((image, index) => (
            <div
              role="presentation"
              onClick={() => {
                if (selectIndex) {
                  selectIndex(index);
                }
              }}
              key={image.image}
            >
              <PropertyImage
                onError={() => setFilteredImage((prevState) => ([...prevState, image.image]))}
                image={image.image}
                isCloudinaryImage={image.isCloudinaryImage}
              />
            </div>
          ))
        }
      </Carousel>
    );
  }

  return (
    <div>
      <div className="">
        <br />
        <p className="no-image">
          {Messages.t('property.image.noImage')} <PhotoCameraIcon />
        </p>
        <hr className="mb-3" />
      </div>
    </div>
  );
}

export default ClientCarousel;
