import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Messages from 'services/i18n/Messages';
import { FetchError } from 'network/Errors';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Firework from 'theme/Firework';
import RadioWrapper from 'lib/form/RadioWrapper';
import { Research } from 'types/research';
import SpinButton from 'theme/hunter-utils/SpinButton';
import { useCandidate } from 'network/api/CandidateQueries';
import { moveInNotation, SignedReviewType } from 'types/forms/SignedReviewType';

type Props = {
  candidateId: string,
  research: Research
  onValidate: () => void
};

export default function SignedTransitionForm(
  {
    candidateId,
    research,
    onValidate,
  }: Props,
) {
  const { getCandidate } = useCandidate();

  const { updateCandidate } = useCandidate();

  const [apiErrors, setApiError] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const { data: candidate } = getCandidate(candidateId);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignedReviewType>();

  const onSubmit: SubmitHandler<SignedReviewType> = (formData: SignedReviewType) => {
    if (candidate) {
      setSubmitting(true);
      setApiError({});
      updateCandidate.mutate(
        {
          candidate,
          data: formData,
        },
        {
          onSuccess: () => {
            setSubmitting(false);
            onValidate();
          },
          onError: (error: FetchError) => {
            setSubmitting(false);
            setApiError(error.json_response);
          },
        },
      );
    }
  };

  if (!candidate) {
    return null;
  }
  return (
    <>
      <Firework />
      <form className="signed-form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          {Messages.t('candidate.review.notation', { name: `${research.client_first_name} ${research.client_last_name}` })}
        </div>
        <Controller
          name="move_in_notation"
          control={control}
          rules={{ required: true }}
          render={(controller) => (
            <RadioWrapper
              control={controller}
              error={errors}
              row
              apiErrors={apiErrors}
              hideButton
              values={
                Object.keys(moveInNotation).map((key) => (
                  { key, label: moveInNotation[key] }
                ))
              }
            />
          )}
        />
        <Controller
          name="done_well_review"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="textarea"
              rows={5}
              control={controller}
              label={Messages.t('form.field.done_well_review')}
            />
          )}
        />
        <Controller
          name="improvement_review"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="textarea"
              rows={5}
              control={controller}
              label={Messages.t('form.field.improvement_review')}
            />
          )}
        />
        <SpinButton
          editing
          spin={submitting}
          variant="primary"
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </>
  );
}
