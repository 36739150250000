/* eslint-disable react/button-has-type */

import React, { MouseEventHandler, ReactNode } from 'react';
import { CircularProgress } from '@material-ui/core';

type Props = {
  variant: 'primary' | 'secondary' | 'secondary-icon' | 'icon'
  className?: string,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  children: ReactNode,
  type?: 'button' | 'submit'
  preventDefault?: boolean,
  spin?: boolean,
  disable?: boolean,
};

export default function Button(
  {
    variant,
    children,
    className,
    onClick,
    type,
    preventDefault,
    spin,
    disable,
  }: Props,
) {
  return (
    <button
      type={type || 'button'}
      onClick={(e) => {
        if (preventDefault) {
          e.preventDefault();
        }
        if (onClick && !disable) {
          onClick(e);
        }
      }}
      disabled={spin}
      className={`client-button ${variant} ${className || ''}`}
    >
      <div className="button-content">
        {
          spin
            ? <CircularProgress className="spinner" />
            : children
        }
      </div>
    </button>
  );
}
