import React,
{
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useResearch } from 'network/api/ResearchQueries';
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import {
  booleanToString,
  mailRegex, splitPostalCodes,
  stringToBoolean,
  stringToNumber, validateMailList,
} from 'lib/form/FormUtils';
import { ResearchFormData } from 'types/forms/ResearchType';
import {
  RESEARCH_TAB,
  TAB_ID,
} from 'routes/HmRoutes';
import SpinButton from 'theme/hunter-utils/SpinButton';
import { propertyType, ResearchPartialType } from 'types/research';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import { workflowStep } from 'types/WorkflowStep';
import { NotificationService } from 'services/notification';
import CriteriaForm from 'features/hunter/pages/research/form/CriteriaForm';
import RichTextEditorWrapper from 'lib/form/RichTextEditorWrapper';
import { Criteria, criteriaType } from 'types/Criteria';
import Messages, { DEFAULT_LANG } from 'services/i18n/Messages';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import { useMessageBackend } from 'network/api/MessageQueries';
import localService from 'services/i18n/LocalService';
import AddCriteriaForm from 'features/hunter/pages/research/form/AddCriteriaForm';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import ZipCodeAutoCompleteWrapper from 'features/hunter/pages/research/form/ZipCodeAutoCompleteWrapper';

type Props = {
  research: ResearchPartialType,
  onValidate?: () => void;
  updateIsDirty?: (isDirty: boolean) => void
  hideSubmit?: boolean
};

const getDefaultValue = (research: ResearchPartialType) => ({
  ...research,
  zip_codes: research.zip_codes?.join(','),
  furnished: booleanToString(research.furnished),
  elevator: booleanToString(research.elevator),
});

const ResearchForm = forwardRef((
  {
    research,
    onValidate,
    updateIsDirty,
    hideSubmit,
  }: Props,
  forwardedref,
) => {
  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiError] = useState({});
  const history = useHistory();
  const researchQueries = useResearch();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    formState: { errors, isDirty },
  } = useForm<ResearchFormData>({
    defaultValues: getDefaultValue(research),
  });

  useEffect(() => {
    reset(getDefaultValue(research));
  }, [research.id]);

  useEffect(() => {
    if (updateIsDirty) {
      updateIsDirty(isDirty);
    }
  }, [isDirty, updateIsDirty]);
  const formField = watch();

  const { getMessageLanguage } = useMessageBackend();
  const { data } = getMessageLanguage();
  const getLangLabel = (lang) => {
    if (!data) {
      return lang;
    }
    const messageLanguage = data.filter((messageL) => messageL.code === lang)[0];
    return messageLanguage ? messageLanguage.label : lang;
  };

  const onSubmit: SubmitHandler<ResearchFormData> = async (formData: ResearchFormData) => {
    setApiError({});
    setSubmitting(true);

    const dataFinal: ResearchPartialType = {
      id: research.id,
      client_first_name: formData.client_first_name,
      client_last_name: formData.client_last_name,
      client_email: formData.client_email,
      homematcher_email: formData.homematcher_email,
      notes: formData.notes,
      internal_notes: formData.internal_notes,
      property_type: formData.property_type,
      language: formData.language,
      zip_codes: splitPostalCodes(formData.zip_codes) || undefined,
      furnished: stringToBoolean(formData.furnished),
      elevator: stringToBoolean(formData.elevator),
      area_min: stringToNumber(formData.area_min),
      area_max: stringToNumber(formData.area_max),
      price_min: stringToNumber(formData.price_min),
      price_max: stringToNumber(formData.price_max),
      price_max_search: stringToNumber(formData.price_max_search),
      rooms: stringToNumber(formData.rooms),
      bedrooms: stringToNumber(formData.bedrooms),
      floor_min: stringToNumber(formData.floor_min),
      floor_max: stringToNumber(formData.floor_max),
      criterias: formData.criterias as Criteria[],
      locationOverApartment: formData.locationOverApartment || undefined,
    };

    if (research.id) {
      try {
        const newResearch = await researchQueries.updateResearch.mutateAsync({
          research_id: research.id,
          data: dataFinal,
        });
        NotificationService.notifySuccess(Messages.t('toast.hunter.reasearchUpdated'));
        if (onValidate) {
          onValidate();
        }
        reset(getDefaultValue(newResearch));
        history.push(Routes.withPath(
          RESEARCH_TAB,
          [
            { label: RESEARCH_ID, value: research.id },
            { label: TAB_ID, value: workflowStep.REVIEW.toLowerCase() },
          ],
        ));
        setSubmitting(false);
      } catch (error) {
        // @ts-ignore
        const { status }: number = error;
        if (status === 500) {
          NotificationService.notifyError(Messages.t('toast.hunter.error'));
        }
        setApiError(error.json_response);
        setSubmitting(false);
      }
    } else {
      try {
        const createdData = await researchQueries.createResearch.mutateAsync(dataFinal);
        NotificationService.notifySuccess(Messages.t('toast.hunter.reasearchCreated'));
        if (onValidate) {
          onValidate();
        }
        history.push(Routes.withPath(
          RESEARCH_TAB,
          [
            { label: RESEARCH_ID, value: createdData.response.id },
            { label: TAB_ID, value: workflowStep.REVIEW.toLowerCase() },
          ],
        ));
        setSubmitting(false);
      } catch (error) {
        // @ts-ignore
        const { status }: number = error;
        if (status === 500) {
          NotificationService.notifyError(Messages.t('toast.hunter.error'));
        }
        // @ts-ignore
        setApiError(error.json_response);
        setSubmitting(false);
      }
    }
  };

  useImperativeHandle(forwardedref, () => ({
    save: async () => {
      await onSubmit(formField);
    },
  }));

  const validateZipCodes = (formValue) => {
    const zipCodes = splitPostalCodes(formValue);
    return zipCodes !== null && zipCodes.length !== 0;
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criterias',
  });

  return (
    <div className="search-form-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>{Messages.t('research.clientInfo')}</h4>
        <div className="row-form">
          <Controller
            name="client_first_name"
            control={control}
            rules={{ required: true }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                requierd
                label={Messages.t('form.field.clientFirstName')}
              />
            )}
          />
          <Controller
            name="client_last_name"
            control={control}
            rules={{ required: true }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                requierd
                label={Messages.t('form.field.clientLastName')}
              />
            )}
          />
        </div>
        <Controller
          name="client_email"
          control={control}
          rules={{
            required: true,
            validate: (v) => validateMailList(v)
              || Messages.t('form.error.clientEmails'),
          }}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              requierd
              label={Messages.t('form.field.clientEmails')}
              placeholder={Messages.t('form.field.clientEmailsPlaceholder')}
            />
          )}
        />
        <h4>{Messages.t('research.hmInfo')}</h4>
        <Controller
          name="homematcher_email"
          control={control}
          rules={{
            pattern: {
              value: mailRegex,
              message: Messages.t('form.error.email'),
            },
          }}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.hmMail')}
              placeholder={Messages.t('form.field.enterMail')}
            />
          )}
        />
        <h4>{Messages.t('form.field.zipcodes')}</h4>
        <div className="name-container">
          <Controller
            name="zip_codes"
            control={control}
            rules={
              {
                required: Messages.t('form.error.postcodeRequired'),
                validate: (v) => validateZipCodes(v)
                  || Messages.t('form.error.postcodeFormat'),
              }
            }
            render={(controller) => (
              <ZipCodeAutoCompleteWrapper
                control={controller}
                apiErrors={apiErrors}
                errors={errors}
              />
            )}
          />
        </div>
        <h4>{Messages.t('form.field.area')}</h4>
        <div className="row-form">
          <Controller
            name="area_min"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="number"
                control={controller}
                label={Messages.t('form.field.min')}
                suffix="㎡"
              />
            )}
          />
          <Controller
            name="area_max"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="number"
                control={controller}
                label={Messages.t('form.field.max')}
                suffix="㎡"
              />
            )}
          />
        </div>
        <h4>{Messages.t('form.field.price')}</h4>
        <div className="row-form">
          <Controller
            name="price_min"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="number"
                control={controller}
                label={Messages.t('form.field.min')}
                suffix="€"
              />
            )}
          />
          <Controller
            name="price_max"
            control={control}
            rules={
              {
                required: !!formField.price_max_search && Messages.t('form.error.maxPrice'),
              }
            }
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="number"
                control={controller}
                label={Messages.t('form.field.max')}
                suffix="€"
              />
            )}
          />
        </div>
        <h4>{Messages.t('research.list.idealBudget')}</h4>
        <div className="row-form">
          <Controller
            name="price_max_search"
            control={control}
            rules={
              {
                required: !!formField.price_max && Messages.t('form.error.idealBudget'),
              }
            }
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="number"
                control={controller}
                label={Messages.t('research.list.idealBudget')}
                suffix="€"
              />
            )}
          />
        </div>
        <h4>{Messages.t('form.field.caracteristics')}</h4>
        <div className="row-form">
          <Controller
            name="rooms"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="number"
                control={controller}
                suffix={Messages.t('form.field.rooms')}
                label={Messages.t('form.field.minRoom')}
              />
            )}
          />
          <Controller
            name="bedrooms"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="number"
                control={controller}
                suffix={Messages.t('form.field.bedrooms')}
                label={Messages.t('form.field.minBedrooms')}
              />
            )}
          />
        </div>
        <div className="row-form">
          <Controller
            name="furnished"
            control={control}
            defaultValue="all"
            render={(controller) => (
              <SelectWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('form.field.furnished')}
                values={[
                  { key: 'all', label: Messages.t('generics.all') },
                  { key: 'true', label: Messages.t('property.furnished.YES') },
                  { key: 'false', label: Messages.t('property.furnished.NO') },
                ]}
              />
            )}
          />
          <Controller
            name="elevator"
            control={control}
            defaultValue="all"
            render={(controller) => (
              <SelectWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('form.field.elevator')}
                values={[
                  { key: 'all', label: Messages.t('generics.all') },
                  { key: 'true', label: Messages.t('property.elevator.YES') },
                  { key: 'false', label: Messages.t('property.elevator.NO') },
                ]}
              />
            )}
          />
          <div className="info-icon">
            <Tooltip
              placement="top-end"
              title={Messages.t('tooltip.fieldNotFiltering')}
            >
              <Info />
            </Tooltip>
          </div>
        </div>
        <Controller
          name="property_type"
          control={control}
          rules={{ required: true }}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              requierd
              label={Messages.t('form.field.propertyType')}
              values={Object.values(propertyType)
                .map((key) => (
                  { key, label: Messages.t(`property.propertyType.${key}`) }
                ))}
            />
          )}
        />
        <h4>{Messages.t('form.field.floor')}</h4>
        <div className="row-form">
          <Controller
            name="floor_min"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="number"
                control={controller}
                label={Messages.t('form.field.min')}
              />
            )}
          />
          <Controller
            name="floor_max"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="number"
                control={controller}
                label={Messages.t('form.field.max')}
              />
            )}
          />
        </div>
        <h4>{Messages.t('form.field.notes')}</h4>
        <Controller
          name="notes"
          control={control}
          render={(controller) => (
            <RichTextEditorWrapper
              control={controller}
              parentId={research.id || ''}
            />
          )}
        />
        <h4>{Messages.t('form.field.internal_notes')}</h4>
        <Controller
          name="internal_notes"
          control={control}
          render={(controller) => (
            <RichTextEditorWrapper
              control={controller}
              parentId={research.id || ''}
            />
          )}
        />
        <h4>{Messages.t('form.field.locationOverApartment')}</h4>
        <Controller
          name="locationOverApartment"
          control={control}
          render={(controller) => (
            <CheckboxWrapper
              control={controller}
              error={errors}
              label={Messages.t('form.field.locationOverApartment')}
            />
          )}
        />
        <h4>{Messages.t('research.criterias')}</h4>
        <div className="criteria-editor">
          <h5>{Messages.t('research.criterias.type.LOCATION')}</h5>
          <div className="criteria-list">
            {
              fields
                .map((item, index) => item.type === criteriaType.LOCATION
                  && (
                    <div key={item.id || formField.criterias?.[index]?.id}>
                      <CriteriaForm
                        formField={formField}
                        apiErrors={apiErrors}
                        errors={errors}
                        index={index}
                        setValue={setValue}
                        control={control}
                        remove={() => remove(index)}
                        trigger={trigger}
                        item={item}
                      />
                    </div>
                  ))
            }
          </div>
          <h5>{Messages.t('research.criterias.type.HOUSING')}</h5>
          <div className="criteria-list">
            {
              fields
                .map((item, index) => item.type !== criteriaType.LOCATION
                  && (
                    <div key={item.id || formField.criterias?.[index]?.id}>
                      <CriteriaForm
                        formField={formField}
                        apiErrors={apiErrors}
                        errors={errors}
                        index={index}
                        setValue={setValue}
                        control={control}
                        remove={() => remove(index)}
                        trigger={trigger}
                        item={item}
                      />
                    </div>
                  ))
            }
          </div>
          <div className="add-criteria">
            <AddCriteriaForm onAdd={(value) => append(value, { shouldFocus: true })} />
          </div>
        </div>
        <h4>{Messages.t('traduction.languages')}</h4>
        <Controller
          name="language"
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('traduction.languages')}
              values={localService.getAvailableLocal()
                .filter((lang) => lang !== DEFAULT_LANG)
                .map((lang) => (
                  { key: lang, label: getLangLabel(lang) }
                ))}
            />
          )}
        />
        {
          !hideSubmit && (
            <SpinButton
              editing={!!research.id}
              spin={submitting}
              variant="primary"
              className="mb-2"
            />
          )
        }
      </form>
    </div>
  );
});
export default ResearchForm;
