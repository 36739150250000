import React from 'react';
import { WorkflowStep } from 'types/WorkflowStep';
import ClientPageModal from 'features/client/common/ClientPageModal';
import { workflowStepMetaData } from 'services/hunter/WorkflowStep';
import CandidateList from 'features/client/common/CandidateList';
import Messages from 'services/i18n/Messages';

type Props = {
  step: WorkflowStep
};

export default function WorkflowStepList({ step }: Props) {
  return (
    <ClientPageModal title={workflowStepMetaData(step).getClientDisplayName() || Messages.t('workflowStep.workflowStep')}>
      <CandidateList listWorkflowStep={step} />
    </ClientPageModal>
  );
}
