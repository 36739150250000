import React from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, UseFormTrigger } from 'react-hook-form';
import { PostSearch, PostSearchForm } from 'types/postSearch/PostSearch';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { stringToBoolean, stringToNumber } from 'lib/form/FormUtils';
import SelectWrapper from 'lib/form/SelectWrapper';
import DateTimePickerWrapper from 'lib/form/DateTimePickerWrapper';

type Props = {
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  errors: FieldErrors
  trigger: UseFormTrigger<PostSearchForm>
  handleSubmit: (data: Partial<PostSearch>) => void
};

export default function UseFullInformationForm(
  {
    control,
    trigger,
    errors,
    apiErrors,
    handleSubmit,
  }: Props,
) {
  return (
    <div>
      <h5>{Messages.t('form.field.paymentInfos')}</h5>
      <div className="row-form">
        <Controller
          name="prorated_rent"
          control={control}
          rules={{ pattern: /([0-9])/ }}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              type="number"
              suffix="€"
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ prorated_rent: value })}
              label={Messages.t('form.field.prorated_rent')}
            />
          )}
        />
        <Controller
          name="deposit_amount"
          control={control}
          rules={{ pattern: /([0-9])/ }}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="number"
              control={controller}
              suffix="€"
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ deposit_amount: stringToNumber(value) })}
              label={Messages.t('form.field.deposit_amount')}
            />
          )}
        />
      </div>
      <div className="row-form">
        <Controller
          name="agency_fees"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="number"
              control={controller}
              suffix="€"
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ agency_fees: stringToNumber(value) })}
              label={Messages.t('form.field.agency_fees')}
            />
          )}
        />
        <Controller
          name="proof_of_transfer_only"
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({ proof_of_transfer_only: stringToBoolean(value) })}
              label={Messages.t('form.field.proof_of_transfer_only')}
              values={[
                { key: 'true', label: Messages.t('generics.YES') },
                { key: 'false', label: Messages.t('generics.NO') },
              ]}
            />
          )}
        />
      </div>
      <h5>{Messages.t('form.field.clientInfos')}</h5>
      <div className="row-form">
        <Controller
          name="client_phone_number"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ client_phone_number: value })}
              label={Messages.t('form.field.client_phone_number')}
            />
          )}
        />
        <Controller
          name="preferred_communication_channel"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ preferred_communication_channel: value })}
              label={Messages.t('form.field.preferred_communication_channel')}
            />
          )}
        />
      </div>
      <div className="row-form">
        <Controller
          name="client_has_european_bank_account"
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onChange={(value) => handleSubmit({
                client_has_european_bank_account: stringToBoolean(value),
              })}
              label={Messages.t('form.field.client_has_european_bank_account')}
              values={[
                { key: 'true', label: Messages.t('generics.YES') },
                { key: 'false', label: Messages.t('generics.NO') },
              ]}
            />
          )}
        />
      </div>
      <div className="row-form">
        <Controller
          name="main_ga"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({ main_ga: value })}
              label={Messages.t('form.field.main_ga')}
            />
          )}
        />
        <Controller
          name="preferred_date_for_sign"
          control={control}
          render={(controller) => (
            <DateTimePickerWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              trigger={trigger}
              autoSaveSubmit={(value) => handleSubmit({
                preferred_date_for_sign: value?.toISOString(),
              })}
              label={Messages.t('form.field.preferred_date_for_sign')}
            />
          )}
        />
      </div>
    </div>
  );
}
