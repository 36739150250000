import React, { ReactNode, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useActivities } from 'network/api/ActivityQueries';
import { useComments } from 'network/api/CommentQueries';
import { workflowStepColor, workFlowStepIcon, workflowStepMetaData } from 'services/hunter/WorkflowStep';
import { ClipboardListIcon } from '@heroicons/react/outline';
import { NO_COMMENT } from 'features/client/pages/candidateDetails/CandidateModal';
import { useResearch } from 'network/api/ResearchQueries';
import { format } from 'date-fns';
import { CANDIDATE_ID, RESEARCH_ID, Routes } from 'routes/Routes';
import {
  CANDIDATE_TAB_ID,
  DETAIL_ID,
  RESEARCH_CANDIDATE_DETAIL,
  TAB_ID,
} from 'routes/HmRoutes';
import { CanditateTab } from 'features/hunter/pages/research/researchDetails/candidateDetails/CandidateTabs';

type Param = {
  researchId: string,
};

type HistoryElement = {
  color: string,
  icon: ReactNode,
  title: string,
  date: string,
  message_content?: string | null,
  candidateId: string,
  property_title?: string,
};

const getPrettyDate = (dateStr: string) => {
  const date = new Date(dateStr);
  return format(date, 'dd MMMM');
};

const getPrettyTime = (dateStr: string) => {
  const date = new Date(dateStr);
  return format(date, 'HH:mm');
};

export default function ResearchHistory() {
  const { researchId } = useParams<Param>();

  const { getResearch } = useResearch();
  const { data: search } = getResearch(researchId);
  const { getSearchActivities } = useActivities();
  const { getSearchComments } = useComments();
  const { data: activities } = getSearchActivities(researchId);
  const { data: comments } = getSearchComments(researchId);

  const historyElements = useMemo(() => {
    let elts: HistoryElement[] = [];
    if (activities) {
      elts = elts.concat(activities.map((activity) => ({
        color: activity.workflow_step ? (workflowStepColor[activity.workflow_step] || 'blue') : 'blue',
        icon: activity.workflow_step
          ? (workFlowStepIcon[activity.workflow_step] || <ClipboardListIcon />)
          : <ClipboardListIcon />,
        title: activity.workflow_step
          ? (workflowStepMetaData(activity.workflow_step).getDisplayName()
            || activity.message_title)
          : activity.message_title,
        message_content: activity.message_content !== NO_COMMENT
          ? activity.message_content
          : undefined,
        date: activity.date,
        candidateId: activity.candidate,
        property_title: activity.property_title,
      })));
    }
    if (comments && search) {
      elts = elts.concat(comments.map((comment) => ({
        color: 'yellow',
        icon: (
          <span>
            {
              comment.author === 'CLIENT'
                ? `${search.client_first_name.substr(0, 1).toUpperCase()}${search.client_last_name.substr(0, 1).toUpperCase()}`
                : 'HM'
            }
          </span>),
        title: comment.author === 'CLIENT' ? `${search.client_first_name} ${search.client_last_name} commented :` : 'Home Matcher commented :',
        message_content: comment.message,
        date: comment.created_at,
        candidateId: comment.candidate,
        property_title: comment.property_title,
      })));
    }
    return elts.sort((a, b) => b.date.localeCompare(a.date));
  }, [activities, comments, search]);

  return (
    <div className="research-history">
      <h2>
        Research History
      </h2>
      {
        historyElements.map((historyElement) => (
          <Link
            to={
              Routes.withPath(RESEARCH_CANDIDATE_DETAIL,
                [
                  { label: RESEARCH_ID, value: researchId },
                  { label: TAB_ID, value: CANDIDATE_TAB_ID },
                  { label: CANDIDATE_ID, value: historyElement.candidateId },
                  {
                    label: DETAIL_ID,
                    value: CanditateTab.DETAILS.toLowerCase(),
                  },
                ])
            }
            className="activity-container"
          >
            <div className="activity">
              <div className="activity-icon-container">
                <div
                  className={`activity-icon ${historyElement.color}`}
                >
                  {
                    historyElement.icon
                  }
                </div>
                <div className="activity-separator" />
              </div>

              <div className="activity-text">
                <div className="activity-text-name">
                  {
                    historyElement.title
                  }
                  {
                    historyElement.property_title && (
                      <span>{historyElement.property_title}</span>
                    )
                  }
                </div>
                <div className="subtitle">
                  {
                    historyElement.message_content
                  }
                </div>
              </div>
              <div className="datetime-container">
                <div>
                  {getPrettyDate(historyElement.date)}
                </div>
                <div>
                  {getPrettyTime(historyElement.date)}
                </div>
              </div>
            </div>
          </Link>
        ))
      }
    </div>
  );
}
