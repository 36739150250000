import React from 'react';
import { Routes } from 'routes/Routes';
import { CANDIDATE_QUERY_ID } from 'routes/ClientRoutes';
import ResultCardContent from 'features/common/ResultCardContent';
import { Link, useLocation } from 'react-router-dom';
import { detectMobile } from 'services/utils';
import { Candidate } from 'types/candidate';
import { Research } from 'types/research';
import Messages from 'services/i18n/Messages';
import { workflowStep } from 'types/WorkflowStep';
import { useViewingAppointment } from 'network/api/CandidateQueries';

type Props = {
  candidate: Candidate,
  research: Research,
  showViewing?: boolean,
  onArchive?: () => void,
  enableFullScreenCarousel?: boolean,
  displayActions?: boolean,
  showNotation?: boolean,
};

export default function CandidateCard(
  {
    candidate,
    research,
    showViewing,
    onArchive,
    enableFullScreenCarousel,
    displayActions,
    showNotation,
  }: Props,
) {
  const location = useLocation();
  const isMobile = detectMobile();

  const viewingQueries = useViewingAppointment();
  const { data: viewingAppointement } = viewingQueries.getViewingAppointment(
    candidate.id, !!showViewing,
  );
  const displayViewing = viewingAppointement && viewingAppointement.length > 0 && showViewing;
  return (
    <Link
      className="candidate-card"
      to={Routes.updatePathWithQuery(
        location,
        [
          { label: CANDIDATE_QUERY_ID, value: candidate.id.toString() },
        ],
      )}
      target={!isMobile ? '_blank' : undefined}
    >
      <ResultCardContent
        candidate={candidate}
        onArchive={onArchive}
        enableFullScreenCarousel={enableFullScreenCarousel}
        displayViewing={displayViewing}
        viewingAppointement={viewingAppointement}
        displayActions={displayActions}
        showCarousel
        showNotation={showNotation}
        notationEditable={showNotation}
      >
        <>
          {candidate.client_identifier && (
            <div>
              <div className="image-badge number">
                #{candidate.client_identifier}
              </div>
            </div>
          )}
          {candidate.viewed_by_client && (
            <div>
              <div className="image-badge success">
                {Messages.t('candidate.card.badge.alreadySeen')}
              </div>
            </div>
          )}
          {candidate.property.gli_asked && (
            <div>
              <div className="image-badge warning">
                {Messages.t('candidate.card.badge.gli')}
              </div>
            </div>
          )}
          {
            candidate.property.original_price !== candidate.property.price
            && candidate.property.original_price && (
              <div>
                <div
                  className={
                    `image-badge ${
                      candidate.property.original_price > candidate.property.price
                        ? 'warning'
                        : 'danger'}`
                  }
                >
                  {candidate.property.original_price > candidate.property.price
                    ? Messages.t('candidate.card.badge.priceDrop')
                    : Messages.t('candidate.card.badge.priceGrow')}
                </div>
              </div>
            )
          }
          {
            candidate.property.available === false && (
              <div>
                <div className="image-badge danger">
                  {Messages.t('candidate.card.badge.expired')}
                </div>
              </div>
            )
          }
          {
            candidate.workflow_step === workflowStep.SENT && (
              <div>
                <div className="image-badge warning">
                  {Messages.t('candidate.card.badge.hmPick')}
                </div>
              </div>
            )
          }
          {
            candidate.property.is_scam && (
              <div>
                <div className="image-badge warning">
                  {Messages.t('candidate.card.badge.isScam')}
                </div>
              </div>
            )
          }
          {
            research.price_max_search && candidate.property.price > research.price_max_search && (
              <div>
                <div className="image-badge info">
                  {Messages.t('candidate.card.badge.overBudget')}
                </div>
              </div>
            )
          }
          {
            candidate.added_by_client && (
              <div>
                <div className="image-badge info">
                  {Messages.t('added_by_you')}
                </div>
              </div>
            )
          }
        </>
      </ResultCardContent>
    </Link>
  );
}
