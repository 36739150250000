import React,
{
  ReactNode,
} from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { Slider } from '@material-ui/core';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  label?: ReactNode
  requierd?: boolean,
  max?: number
  min?: number,
  marks?: { value: number, label: string }[]
};

export default function SliderWrapper(
  {
    control,
    label,
    min,
    max,
    marks,
    requierd,
  }: Props,
) {
  const { field } = control;

  let value = field.value && parseInt(field.value, 10);
  if (!Number.isInteger(value)) {
    value = undefined;
  }
  return (
    <div className="material-slider-wrapper">
      <div className="slider-label">{label} {requierd ? '*' : ''}</div>
      <Slider
        {...field}
        min={min}
        max={max}
        value={value}
        step={1}
        valueLabelDisplay="auto"
        marks={marks}
      />
    </div>
  );
}
