import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { workflowStepMetaData } from 'services/hunter/WorkflowStep';
import {
  BiTime,
} from 'react-icons/bi';
import { useComment } from 'network/api/CommentQueries';
import Messages from 'services/i18n/Messages';
import { Candidate } from 'types/candidate';
import { ShortDateTimeFormat } from 'services/utils';
import SpinButton from 'theme/hunter-utils/SpinButton';

type Props = {
  candidate: Candidate;
  author: string,
};

export default function AddCommentForm({ candidate, author }: Props) {
  const { createComment } = useComment();
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date());

  const handleSubmit = async () => {
    setSubmitting(true);
    await createComment.mutateAsync({
      candidateId: candidate.id,
      data: {
        author,
        message,
        workflow_step: candidate.workflow_step,
      },
    });
    setSubmitting(false);
    setMessage('');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ul className="timeline timeline_add">
      <li>
        <div className="timeline_badge primary">
          HM
        </div>
        <div className="timeline_panel">
          <div className="timeline_heading">
            <p>
              <small className="text-muted">
                <BiTime />
                &nbsp;
                {ShortDateTimeFormat(currentDate)}
                &nbsp;-&nbsp;
                {workflowStepMetaData(candidate.workflow_step).getDisplayName()}
                <br />
              </small>
            </p>
          </div>
          <div className="timeline_body">
            <Form.Row>
              <Col xs={12}>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={message || ''}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <div className="float-right">
              <SpinButton
                spin={submitting}
                title={Messages.t('formButton.send')}
                editing={false}
                type="button"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
}
