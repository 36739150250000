export interface SignedReviewType{
  done_well_review: string,
  improvement_review: string,
  move_in_notation: number,
}

export const moveInNotation = {
  1: '😟',
  2: '😐',
  3: '🙂',
  4: '😃',
  5: '🤩',
};
