import FetchAdapter from 'network/FetchAdapter';
import FakeTargomoIsochroneService from 'services/isochrone/targomo/FakeTargomoIsochroneService';
import TargomoIsochoneService from 'services/isochrone/targomo/TargomoIsochoneService';
import MapboxGeocodingService from 'services/isochrone/mapbox/MapboxGeocodingService';
import GeojsonOperationService from 'services/isochrone/GeojsonOperationService';
import DistrictService from 'services/isochrone/DistrictService';
import settings from 'services/hunterSettings';

const fetchAdapter = new FetchAdapter();

const services = {
  geocoding: new MapboxGeocodingService(),

  isochrone: settings.fakeIsochrone
    ? new FakeTargomoIsochroneService(fetchAdapter)
    : new TargomoIsochoneService(),

  geojson_operation: new GeojsonOperationService(),

  district: new DistrictService(fetchAdapter),
};

export default services;
