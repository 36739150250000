import React, { useState } from 'react';
import { useCandidates } from 'network/api/CandidateQueries';
import { Link, useLocation, useParams } from 'react-router-dom';
import { WorkflowStep, workflowStep } from 'types/WorkflowStep';
import TrackedWorkflowStep from 'features/client/pages/track/TrackedWorkflowStep';
import TrackCandidateCard from 'features/client/pages/track/TrackCandidateCard';
import { workFlowStepIcon, workflowStepMetaData } from 'services/hunter/WorkflowStep';
import MapButton from 'features/client/pages/map/MapButton';
import { Routes } from 'routes/Routes';
import { WORKFLOW_STEP_QUERY } from 'routes/ClientRoutes';
import Messages from 'services/i18n/Messages';
import candidateFilterService from 'services/CandidateFilterService';

type Params = {
  researchId: string,
};

type TrackWorkflowStep = {
  workflowStep: WorkflowStep,
  count: number | null,
};

const trackWorkflowSteps: TrackWorkflowStep[] = [
  {
    workflowStep: workflowStep.SIGNED,
    count: null,
  },
  {
    workflowStep: workflowStep.READY_TO_SIGN,
    count: null,
  },
  {
    workflowStep: workflowStep.AGREEMENT,
    count: null,
  },
  {
    workflowStep: workflowStep.APPLICATION_ACCEPTED,
    count: null,
  },
  {
    workflowStep: workflowStep.APPLIED,
    count: null,
  },
  {
    workflowStep: workflowStep.TO_APPLY,
    count: null,
  },
  {
    workflowStep: workflowStep.VIEWED,
    count: null,
  },
  {
    workflowStep: workflowStep.BOOKED,
    count: null,
  },
  {
    workflowStep: workflowStep.VIEWING_REQUESTED,
    count: null,
  },
];

export default function TrackList() {
  const { researchId } = useParams<Params>();
  const candidatesQueries = useCandidates();
  const location = useLocation();
  const [opendedStep, setOpenedStep] = useState<string | null>(null);
  const { data: countPerSteps } = candidatesQueries.getCountPerStep(researchId);
  const {
    isLoading,
    data,
  } = candidatesQueries.getCandidatesForStep(
    researchId,
    trackWorkflowSteps.map((trackWorkflowStep) => trackWorkflowStep.workflowStep).join(','),
    candidateFilterService.getSort(workflowStep.VIEWING_REQUESTED).ordering,
    true,
    1000, // We estimate that there should not be more than a 1000 candidate tracked
  );
  const candidates = data?.pages
    .map((result) => result.results)?.flat();

  const getCountWithStep = (step: WorkflowStep): number => {
    const workflowCountRes = countPerSteps?.filter(
      (workflowCount) => workflowCount.workflow_step === step,
    )[0];
    return workflowCountRes ? workflowCountRes.count : 0;
  };

  const givenUpCount = countPerSteps && getCountWithStep(workflowStep.TRASH);

  const trackWorkflowStepsWithCount = trackWorkflowSteps.map((trackWorkflowStep) => (
    {
      ...trackWorkflowStep,
      count: countPerSteps && getCountWithStep(trackWorkflowStep.workflowStep),
    })).filter((trackWorkflowStep) => !!trackWorkflowStep.count && trackWorkflowStep.count !== 0);

  if (!isLoading
    && trackWorkflowStepsWithCount.length === 0
    && givenUpCount
    && givenUpCount === 0) {
    return (
      <div className="error-message">
        {Messages.t('trackList.noTrack')}
      </div>
    );
  }
  return (
    <div className="track-list-container">
      <div className="workflow-step-menu">
        {
          trackWorkflowStepsWithCount.map((trackWorkflowStep) => (
            <TrackedWorkflowStep
              key={trackWorkflowStep.workflowStep}
              icon={workFlowStepIcon[trackWorkflowStep.workflowStep]}
              workflowStepProps={trackWorkflowStep.workflowStep}
              label={workflowStepMetaData(trackWorkflowStep.workflowStep).getClientDisplayName() || ''}
              candidates={candidates && candidates
                .filter((candidate) => candidate.workflow_step === trackWorkflowStep.workflowStep)}
              count={trackWorkflowStep.count}
              openStep={() => setOpenedStep(trackWorkflowStep.workflowStep)}
              isSelected={trackWorkflowStep.workflowStep === opendedStep}
            />
          ))
        }
        {
          countPerSteps && getCountWithStep(workflowStep.TRASH) > 0 && (
            <div className="given-up-link">
              <Link
                to={Routes.updateUrlWithQuery(
                  location.pathname,
                  [
                    { label: WORKFLOW_STEP_QUERY, value: workflowStep.TRASH },
                  ],
                )}
              >
                {Messages.t('trackList.givenUp', { count: getCountWithStep(workflowStep.TRASH) })}
              </Link>
            </div>
          )
        }
        <MapButton steps={trackWorkflowStepsWithCount.map((value) => value.workflowStep)} />
      </div>
      {
        candidates && opendedStep && (
          <div className="candidate-list-content">
            {
              candidates
                .filter((candidate) => candidate.workflow_step === opendedStep)
                .map((candidate) => (
                  <TrackCandidateCard
                    key={candidate.id}
                    candidate={candidate}
                    showViewing={opendedStep === workflowStep.BOOKED}
                  />
                ))
            }
          </div>
        )
      }
    </div>
  );
}
