import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FetchError } from 'network/Errors';
import { PostSearch, PostSearchForm } from 'types/postSearch/PostSearch';
import { usePostSearch } from 'network/api/PostSearchQueries';
import Collapsable from 'lib/Collapsable';
import UseFullInformationForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/signatureAndRental/UseFullInformationForm';
import Messages from 'services/i18n/Messages';
import { booleanToString } from 'lib/form/FormUtils';
import GarantorForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/signatureAndRental/GarantorForm';
import SignatureForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/signatureAndRental/SignatureForm';
import {
  meetingTypeEnum,
  signatureOrganisationTypeEnum,
  signatureTypeEnum,
} from 'types/postSearch/Signature';
import { Property } from 'types/property';
import { useProperty } from 'network/api/PropertyQueries';
import { Candidate } from 'types/candidate';
import RentalForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/signatureAndRental/RentalForm';
import { rentalTypeEnum } from 'types/postSearch/Rental';
import FinancialInformationForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/signatureAndRental/FinancialInformationForm';
import { useFinancialInformations } from 'network/api/FinancialQueries';
import PostSearchUtils from 'services/PostSearchUtils';

type Props = {
  postSearch: PostSearch,
  property: Property,
  candidate: Candidate
};

export default function SignatureAndRentalForm({ postSearch, property, candidate }: Props) {
  const { updatePostSearch } = usePostSearch();
  const { updateProperty } = useProperty();

  const [apiErrors, setApiError] = useState({});
  const {
    control,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = useForm<PostSearchForm>({
    defaultValues: {
      ...postSearch,
      proof_of_transfer_only: booleanToString(postSearch.proof_of_transfer_only),
      client_has_european_bank_account: booleanToString(
        postSearch.client_has_european_bank_account,
      ),
      signature_kind: postSearch.signature_kind || signatureTypeEnum.PHYSICAL,
      meeting_address_kind: postSearch.meeting_address_kind || meetingTypeEnum.AGENCY,
      inspection_meeting_address_kind: postSearch.inspection_meeting_address_kind
        || meetingTypeEnum.AGENCY,
      inspection_kind: postSearch.inspection_kind
        || rentalTypeEnum.PERSON,
      signature_organisation_kind: postSearch.signature_organisation_kind
        || signatureOrganisationTypeEnum.PERSON,
      property_address: property.address,
      use_papernest: booleanToString(postSearch.use_papernest),
      use_gas: booleanToString(postSearch.use_gas),
      use_voucher: booleanToString(postSearch.use_voucher),
    },
  });

  const financialQueries = useFinancialInformations();
  const { getFinancialInformations } = financialQueries;

  const { data: financialInformations } = getFinancialInformations(postSearch.id);

  const formField = watch();
  const handleSubmit = async (data: Partial<PostSearch>) => {
    setApiError({});
    await updatePostSearch.mutateAsync({
      postSearchId: postSearch.id,
      data,
    }).catch((error: FetchError) => {
      setApiError(error.json_response);
    });
  };

  const handleSubmitProperty = async (data: Partial<Property>) => {
    setApiError({});
    await updateProperty.mutateAsync({
      propertyId: property.id,
      data,
      candidate,
    }).catch((error: FetchError) => {
      setApiError(error.json_response);
    });
  };

  return (
    <div>
      <form>
        <Collapsable defaultOpen label={Messages.t('postSearch.section.usefullInfo')}>
          <UseFullInformationForm
            control={control}
            apiErrors={apiErrors}
            errors={errors}
            trigger={trigger}
            handleSubmit={handleSubmit}
          />
        </Collapsable>
        <Collapsable
          checked={PostSearchUtils.isGarantorChecked(formField)}
          label={Messages.t('postSearch.section.garantor')}
        >
          <GarantorForm
            control={control}
            formField={formField}
            apiErrors={apiErrors}
            errors={errors}
            trigger={trigger}
            handleSubmit={handleSubmit}
          />
        </Collapsable>
        <Collapsable
          checked={PostSearchUtils.isFinancialChecked(formField)}
          onRadioButtonClick={() => {
            setValue('financial_checked', !formField.financial_checked);
            handleSubmit({
              financial_checked: !formField.financial_checked,
            });
          }}
          listSize={financialInformations?.length}
          label={Messages.t('postSearch.section.financialInformation')}
        >
          <FinancialInformationForm
            financialInformations={financialInformations}
            postSearch={postSearch}
          />
        </Collapsable>
        <Collapsable
          checked={PostSearchUtils.isSignatureChecked(formField)}
          label={Messages.t('postSearch.section.signature')}
        >
          <SignatureForm
            candidateId={candidate.id}
            postSearch={postSearch}
            setValue={setValue}
            control={control}
            formField={formField}
            apiErrors={apiErrors}
            errors={errors}
            trigger={trigger}
            handleSubmit={handleSubmit}
            handleSubmitProperty={handleSubmitProperty}
          />
        </Collapsable>
        <Collapsable
          checked={PostSearchUtils.isRentalChecked(formField)}
          label={Messages.t('postSearch.section.rental')}
        >
          <RentalForm
            candidateId={candidate.id}
            postSearch={postSearch}
            setValue={setValue}
            control={control}
            formField={formField}
            apiErrors={apiErrors}
            errors={errors}
            trigger={trigger}
            handleSubmit={handleSubmit}
            handleSubmitProperty={handleSubmitProperty}
          />
        </Collapsable>
      </form>
    </div>
  );
}
