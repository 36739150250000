import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FetchError } from 'network/Errors';
import { PostSearch, PostSearchForm } from 'types/postSearch/PostSearch';
import { usePostSearch } from 'network/api/PostSearchQueries';
import Collapsable from 'lib/Collapsable';
import UseFullInformationForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/giftAndVoucher/UseFullInformationForm';
import Messages from 'services/i18n/Messages';
import { booleanToString } from 'lib/form/FormUtils';
import GiftForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/giftAndVoucher/GiftForm';
import VoucherForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/giftAndVoucher/VoucherForm';
import PostSearchUtils from 'services/PostSearchUtils';
import { Property } from 'types/property';
import { Candidate } from 'types/candidate';

type Props = {
  property: Property,
  postSearch: PostSearch,
  candidate: Candidate
};

export default function GiftAndVoucherForm({ postSearch, property, candidate }: Props) {
  const { updatePostSearch } = usePostSearch();

  const [apiErrors, setApiError] = useState({});
  const {
    control,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = useForm<PostSearchForm>({
    defaultValues: {
      ...postSearch,
      proof_of_transfer_only: booleanToString(postSearch.proof_of_transfer_only),
      client_has_european_bank_account: booleanToString(
        postSearch.client_has_european_bank_account,
      ),
      use_papernest: booleanToString(postSearch.use_papernest),
      use_gas: booleanToString(postSearch.use_gas),
      use_voucher: booleanToString(postSearch.use_voucher),
      main_contact: property.main_contact?.toString(),
    },
  });

  const formField = watch();
  const handleSubmit = async (data: Partial<PostSearch>) => {
    setApiError({});
    await updatePostSearch.mutateAsync({
      postSearchId: postSearch.id,
      data,
    }).catch((error: FetchError) => {
      setApiError(error.json_response);
    });
  };

  return (
    <div>
      <form>
        <Collapsable defaultOpen label={Messages.t('postSearch.section.usefullInfo')}>
          <UseFullInformationForm
            control={control}
            formField={formField}
            apiErrors={apiErrors}
            errors={errors}
            trigger={trigger}
            handleSubmit={handleSubmit}
          />
        </Collapsable>
        <Collapsable
          checked={PostSearchUtils.isGiftChecked(formField)}
          label={Messages.t('postSearch.section.clientGift')}
        >
          <GiftForm
            control={control}
            apiErrors={apiErrors}
            errors={errors}
            handleSubmit={handleSubmit}
          />
        </Collapsable>
        <Collapsable
          checked={PostSearchUtils.isVoucherChecked(formField)}
          label={Messages.t('postSearch.section.voucherCertificate')}
        >
          <VoucherForm
            propertyId={property.id}
            candidate={candidate}
            setValue={setValue}
            control={control}
            apiErrors={apiErrors}
            errors={errors}
            formField={formField}
            handleSubmit={handleSubmit}
            trigger={trigger}
          />
        </Collapsable>
      </form>
    </div>
  );
}
