import { observable, WritableObservable } from 'micro-observables';
import { ResearchFilter } from 'types/researchFilter';
import { FilterResearchStatus } from 'types/research';

export class ResearchFilterService {
  public static EVERYONE = 'everyone';

  public static NONE = 'empty';

  filterInitialState: ResearchFilter = {
    searchText: '',
    current_user_research: ResearchFilterService.EVERYONE,
    status: 'ALL',
  };

  private readonly filters: WritableObservable<ResearchFilter> =
  observable(this.filterInitialState);

  updateFilterText(text: string) {
    this.filters.update((filter) => ({ ...filter, searchText: text }));
  }

  updateStatus(text: FilterResearchStatus) {
    this.filters.update((filter) => ({ ...filter, status: text }));
  }

  setCurrentUser(currentUser: string) {
    this.filters.update((filter) => ({ ...filter, current_user_research: currentUser }));
  }

  getFilters() {
    return this.filters.readOnly();
  }
}

const researchFilterService = new ResearchFilterService();
export default researchFilterService;
