import { ReactNode } from 'react';

export type WorkflowStep =
  'REVIEW'
  | 'SHORTLIST'
  | 'SENT'
  | 'NOT_SURE'
  | 'VIEWING_REQUESTED'
  | 'BOOKED'
  | 'VIEWED'
  | 'TO_APPLY'
  | 'APPLIED'
  | 'APPLICATION_ACCEPTED'
  | 'SIGNED'
  | 'DISCARDED'
  | 'NOT_INTERESTED'
  | 'TRASH'
  | 'MATCH'
  | 'AGREEMENT'
  | 'READY_TO_SIGN';

export type WorkflowStepLower = Lowercase<WorkflowStep>;

type WorkflowStepEnum = {
  [key in WorkflowStep]: WorkflowStep;
};

export type WorkflowStepIcon = {
  [key in WorkflowStep]: ReactNode | undefined;
};

export type WorkflowStepColor = {
  [key in WorkflowStep]: string | undefined;
};

export const workflowStep: WorkflowStepEnum = {
  REVIEW: 'REVIEW',
  SHORTLIST: 'SHORTLIST',
  SENT: 'SENT',
  MATCH: 'MATCH',
  NOT_SURE: 'NOT_SURE',
  VIEWING_REQUESTED: 'VIEWING_REQUESTED',
  BOOKED: 'BOOKED',
  VIEWED: 'VIEWED',
  TO_APPLY: 'TO_APPLY',
  APPLIED: 'APPLIED',
  APPLICATION_ACCEPTED: 'APPLICATION_ACCEPTED',
  AGREEMENT: 'AGREEMENT',
  SIGNED: 'SIGNED',
  READY_TO_SIGN: 'READY_TO_SIGN',
  DISCARDED: 'DISCARDED',
  NOT_INTERESTED: 'NOT_INTERESTED',
  TRASH: 'TRASH',
};
