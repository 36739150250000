import { Enum } from 'lib/type/enum';

export type RentalKindType =
  'PERSON'
  | 'ACCOMPAGNIED'
  | 'ON_BEHALF';

export const rentalTypeEnum: Enum<RentalKindType> = {
  PERSON: 'PERSON',
  ACCOMPAGNIED: 'ACCOMPAGNIED',
  ON_BEHALF: 'ON_BEHALF',
};
