import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useCandidate,
  useCandidates,
} from 'network/api/CandidateQueries';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CANDIDATE_ID_AGREEMENT_REVIEW,
  CANDIDATE_ID_SIGNED_REVIEW,
  DETAIL_ID,
  RESEARCH_CANDIDATE_DETAIL,
  TAB_ID,
  TRANSITION_TO_QUERY_ID,
} from 'routes/HmRoutes';
import {
  CANDIDATE_ID,
  RESEARCH_ID,
  Routes,
} from 'routes/Routes';
import { CanditateTab } from 'features/hunter/pages/research/researchDetails/candidateDetails/CandidateTabs';
import WorkflowStepTransitionPanel
  from 'features/hunter/pages/research/researchDetails/workflowStepTransition/WorkflowStepTransitionPanel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/core';
import { WorkflowStep, workflowStep } from 'types/WorkflowStep';
import { Research } from 'types/research';
import ResearchLeftPanel from 'features/hunter/pages/research/researchDetails/layout/ResearchLeftPanel';
import { NotificationService } from 'services/notification';
import { workflowStepMetaData } from 'services/hunter/WorkflowStep';
import Messages from 'services/i18n/Messages';
import { useObservable } from 'micro-observables';
import candidateFilterService from 'services/CandidateFilterService';
import { Candidate } from 'types/candidate';
import CandidateActionBar
  from 'features/hunter/pages/research/researchDetails/candidateDetails/actionBar/CandidateActionBar';
import { FetchError } from 'network/Errors';
import CandidateDetailsRouter
  from 'features/hunter/pages/research/researchDetails/candidateDetails/CandidateDetailsRouter';
import ResultCard from 'features/hunter/common/ResultCard';

type Props = {
  researchId: string,
  tabId: string,
  candidateId: string,
  isActive: boolean,
  research: Research,
  tabWorkflowStep: WorkflowStep
};

const hasCandidate = (candidatePages, candidateId) => {
  for (let i = 0; i < candidatePages.length; i++) {
    for (let y = 0; y < candidatePages[i].results.length; y++) {
      if (candidatePages[i].results[y].id.toString() === candidateId) {
        return true;
      }
    }
  }
  return false;
};

export default function WorkflowStepTab(
  {
    tabWorkflowStep,
    researchId,
    tabId,
    candidateId,
    isActive,
    research,
  }: Props,
) {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const transitionToStep = queryParams.get(TRANSITION_TO_QUERY_ID) as WorkflowStep;
  const candidatesQueries = useCandidates();
  const { updateCandidateStep } = useCandidate();
  const sort = useObservable(candidateFilterService.getSortObservable(tabWorkflowStep));
  const [submittingCandidateStep, setSubmittingCandidateStep] = useState(false);
  const [candidateLeft, setCandidateLeft] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [findCandidate, setFindCandidate] = useState(!!candidateId);
  const [selectedCandidates, setSelectedCandidates] = useState<string[]>(
    candidateId ? [candidateId] : [],
  );
  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: dataAPI,
  } = candidatesQueries
    .getCandidatesForStep(
      researchId,
      tabWorkflowStep,
      sort.ordering,
      isActive,
      30,
      false,
      (datas) => {
        const hasNext = !!datas.pages[datas.pages.length - 1].next;
        if (selectedCandidates.length === 0 && datas.pages[0].results.length !== 0) {
          updateSelectedCandidate([datas.pages[0].results[0].id]);
        }
        if (findCandidate) {
          if (!hasNext) {
            setFindCandidate(false);
            return;
          }
          if (datas.pages.length > currentPageNumber) {
            if (hasCandidate(datas.pages, candidateId)) {
              setFindCandidate(false);
            } else {
              setCurrentPageNumber(datas.pages.length);
              fetchNextPage();
            }
          }
        }
      },
    );

  const data = dataAPI?.pages
    .map((result) => result.results)?.flat();

  useEffect(() => {
    if (isActive) {
      if ((selectedCandidates.length === 0 || !candidateId) && data && data.length !== 0) {
        updateSelectedCandidate([data[0].id]);
      }
    }
  }, [isActive]);

  const filteredCandidates: Candidate[] | null = useMemo(() => {
    if (!isLoading && data) {
      return data;
    }
    return null;
  }, [isLoading, data, tabWorkflowStep]);

  const candidateExist = useMemo(() => {
    if (!isLoading && data) {
      return !!data.find((c) => c.id.toString() === candidateId);
    }
    return null;
  }, [isLoading, data, candidateId]);

  const selectedCandidate = useMemo(() => {
    if (filteredCandidates) {
      return filteredCandidates.find((c) => c.id.toString() === candidateId);
    }
    return null;
  }, [filteredCandidates, candidateId]);

  const updateSelectedCandidate = useCallback((selectedCandidateIds: string[]) => {
    setSelectedCandidates(selectedCandidateIds);
    if (selectedCandidateIds.length === 1) {
      history.push(Routes.updateUrlWithQuery(Routes.withPath(
        RESEARCH_CANDIDATE_DETAIL,
        [
          { label: RESEARCH_ID, value: researchId },
          { label: CANDIDATE_ID, value: selectedCandidateIds[0] },
          { label: TAB_ID, value: tabId.toLowerCase() },
          { label: DETAIL_ID, value: CanditateTab.DETAILS },
        ],
      ), Array.from(queryParams.entries()).map((entry) => ({ label: entry[0], value: entry[1] }))));
    }
  }, [history, researchId, tabId, queryParams]);

  const setCandidateStep = useCallback(
    async (callBackData) => {
      setSubmittingCandidateStep(true);
      for (const id of selectedCandidates) {
        const candidate = filteredCandidates?.filter(
          (candidateFilter) => candidateFilter.id.toString() === id,
        )[0];
        if (candidate) {
          // This is a specific case. The update must be sequential,
          // otherwise it might trouble the identifier generation
          // eslint-disable-next-line no-await-in-loop
          await updateCandidateStep.mutateAsync({
            candidate,
            data: callBackData,
          }, {
            onSuccess: () => {
              if (callBackData.workflow_step === workflowStep.SIGNED) {
                history.push(Routes.updatePathWithQuery(location, [{
                  label: CANDIDATE_ID_SIGNED_REVIEW, value: candidate.id,
                }]));
              } else if (callBackData.workflow_step === workflowStep.AGREEMENT) {
                history.push(Routes.updatePathWithQuery(location, [{
                  label: CANDIDATE_ID_AGREEMENT_REVIEW, value: candidate.id,
                }]));
              } else {
                history.push(location.pathname);
              }

              NotificationService.notifySuccess(Messages.t('toast.hunter.movedListing', { stepDisplayName: workflowStepMetaData(callBackData.workflow_step).getDisplayName() }));
              setSubmittingCandidateStep(false);
              setCandidateLeft(true);
            },
            onError: (error: FetchError) => {
              setSubmittingCandidateStep(false);
              const { status } = error;
              if (status === 500) {
                NotificationService.notifyError(Messages.t('toast.hunter.error'));
              }
            },
          });
        }
      }
    },
    [updateCandidateStep],
  );

  function NoSelectedCandidates() {
    if (candidateId) {
      if (selectedCandidates.length > 1 && filteredCandidates) {
        return (
          <div className="candidate-list-short">
            {
              filteredCandidates
                .filter((candidate) => selectedCandidates.includes(candidate.id.toString()))
                .map((candidate) => (
                  <div
                    key={candidate.id}
                  >
                    <ResultCard
                      candidate={candidate}
                      research={research}
                      className=""
                      handleNavigation={() => {}}
                    />
                  </div>
                ))
            }
          </div>
        );
      }
      if (isLoading || isFetchingNextPage) {
        return (<CircularProgress sx={{ mt: 1 }} />);
      }
      if (candidateLeft) {
        return (
          <Alert severity="info" sx={{ maxWidth: 'fit-content', m: 'auto', mt: 5 }}>
            {Messages.t('workflowTab.movedCandidate')}
          </Alert>
        );
      }
      if (candidateExist === false) {
        return (
          <Alert severity="error" sx={{ maxWidth: 'fit-content', m: 'auto', mt: 5 }}>
            {Messages.t('workflowTab.notFound')}
          </Alert>
        );
      }
      if (filteredCandidates && candidateExist) {
        return (
          <Alert severity="info" sx={{ maxWidth: 'fit-content', m: 'auto', mt: 5 }}>
            {Messages.t('workflowTab.filterdHeader')}
            <br /> <br />
            {Messages.t('workflowTab.filterdBody')}
          </Alert>
        );
      }
      return (<CircularProgress sx={{ mt: 1 }} />);
    }
    return null;
  }

  return (
    <>
      <div className="workflow-tab-content">
        <ResearchLeftPanel
          research={research}
          currentWorkflowStep={tabWorkflowStep}
          candidateIds={selectedCandidates}
          candidates={filteredCandidates || []}
          isLoading={isLoading || isFetchingNextPage}
          editingActivity={transitionToStep}
          updateSelectedCandidate={updateSelectedCandidate}
          fetchNextPage={fetchNextPage}
          hasNextPage={!!hasNextPage}
        />
        <div
          className={`candidate-detail-container ${(transitionToStep === workflowStep.SIGNED || transitionToStep === workflowStep.READY_TO_SIGN) ? 'hide-details' : ''}`}
        >
          <div className="candidate-detail-content">
            {
              selectedCandidate && selectedCandidates.length === 1 ? (
                <CandidateDetailsRouter
                  research={research}
                  key={selectedCandidate.id}
                  candidate={selectedCandidate}
                />
              ) : (
                <NoSelectedCandidates />
              )
            }
          </div>
        </div>
        {transitionToStep && selectedCandidate && (
          <div className="full-height-container overflow-y-auto transition-panel">
            <WorkflowStepTransitionPanel
              candidateIds={selectedCandidates}
              candidate={selectedCandidate}
              editingActivity={transitionToStep}
              setCandidateStep={setCandidateStep}
              researchId={researchId}
            />
          </div>
        )}
        {selectedCandidate && !transitionToStep && (
          <div className="workflow_control_container">
            <CandidateActionBar
              research={research}
              candidateIds={selectedCandidates}
              candidate={selectedCandidate}
              submittingCandidateStep={submittingCandidateStep}
              setCandidateStep={setCandidateStep}
            />
          </div>
        )}
      </div>
    </>
  );
}
