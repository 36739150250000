import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  CANDIDATE_QUERY_ID,
  CLIENT_RESEARCH_TAB,
  CLIENT_TAB_ID,
  clientTab,
  clientTabDetail,
  ClientTabTypeLower, COLLECT_QUERY,
  FILTER_QUERY, NOTIFICATION_QUERY, SEARCH_QUERY, VIEWING_REPORT_QUERY, WORKFLOW_STEP_QUERY,
} from 'routes/ClientRoutes';
import { AdjustmentsIcon, DotsVerticalIcon } from '@heroicons/react/solid';
import {
  AcademicCapIcon,
  ChevronLeftIcon,
  DocumentDuplicateIcon,
  DocumentReportIcon,
  DocumentSearchIcon,
  TranslateIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { workflowStep, WorkflowStep, WorkflowStepLower } from 'types/WorkflowStep';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import { Research } from 'types/research';
import { useCandidates } from 'network/api/CandidateQueries';
import Filter from 'features/client/pages/discover/Filter';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { workflowStepMetaData } from 'services/hunter/WorkflowStep';
import Messages from 'services/i18n/Messages';
import LanguageSelector from 'theme/LanguageSelector';
import { MagicBellProvider } from '@magicbell/magicbell-react';
import { clientNotificationType } from 'types/Notifications';
import ClientMagicBell from 'features/client/layout/ClientMagicBell';

const API_KEY = '6640474927e1e3fd75d168de360b1e420be6f06c';

type Params = {
  clientTabId: ClientTabTypeLower
  researchId: string,
  workflowStep: WorkflowStepLower
};

type Props = {
  research?: Research
};

export default function Header({ research }: Props) {
  const { clientTabId, researchId, workflowStep: workflowStepParam } = useParams<Params>();
  const location = useLocation();
  const candidatesQueries = useCandidates();
  const { data: countPerSteps } = candidatesQueries.getCountPerStep(researchId);
  const queryParams = new URLSearchParams(location.search);
  const doFilter = queryParams.get(FILTER_QUERY);
  const notificationOpen = queryParams.get(NOTIFICATION_QUERY);
  const candidateId = queryParams.get(CANDIDATE_QUERY_ID);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openLanguageModal, setOpenLanguageModal] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectedDetails = clientTabDetail[clientTabId.toUpperCase()];

  const workflowStepLabel = workflowStepParam
    && workflowStepMetaData(workflowStepParam.toUpperCase() as WorkflowStep).getDisplayName();

  const getCountWithStep = (step: WorkflowStep) => {
    const workflowCountRes = countPerSteps?.filter(
      (workflowCount) => workflowCount.workflow_step === step,
    )[0];
    return workflowCountRes ? workflowCountRes.count : 0;
  };

  if (!selectedDetails) {
    return null;
  }

  return (
    <header>
      {
        workflowStepLabel && (
          <Link
            className="header-go-back"
            to={Routes.withPath(
              CLIENT_RESEARCH_TAB,
              [
                { label: RESEARCH_ID, value: researchId },
                { label: CLIENT_TAB_ID, value: clientTabId },
              ],
            )}
          >
            <ChevronLeftIcon />
          </Link>
        )
      }
      <h1>
        {workflowStepLabel || selectedDetails.getLabel()}
        {
          workflowStepLabel && (
            <span>({getCountWithStep(workflowStepParam.toUpperCase() as WorkflowStep)})</span>
          )
        }
      </h1>
      <div className="header-actions-container">
        {
          clientTabId.toUpperCase() === clientTab.DISCOVER && !candidateId && (
            <div className="filter-icon">
              <Link
                className="header-button"
                to={Routes.updatePathWithQuery(
                  location,
                  [
                    { label: FILTER_QUERY, value: 'true' },
                  ],
                )}
              >
                <AdjustmentsIcon />
              </Link>
              {
                doFilter && doFilter === 'true' && research && (
                  <Filter research={research} />
                )
              }
            </div>

          )
        }
        <div>
          {
            research && (
              <MagicBellProvider
                apiKey={API_KEY}
                userEmail={research.client_email.split(',')[0].trim()}
                stores={
                  [
                    ...Object.keys(clientNotificationType)
                      .map((key) => ({
                        id: key,
                        defaultQueryParams: { categories: [key] },
                      })),
                    { id: 'ALL', defaultQueryParams: { all: true } },
                  ]
                }
              >
                <ClientMagicBell open={!!notificationOpen && notificationOpen === 'true'} />
              </MagicBellProvider>
            )
          }
        </div>
        <div>
          <button
            type="button"
            className="header-button"
            onClick={handleClick}
          >
            <DotsVerticalIcon />
          </button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            className="menu-header"
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <AcademicCapIcon />
              </ListItemIcon>
              <a
                href="https://www.notion.so/settlesweet/FAQ-76c0471042a141b79714841f47de91f9"
                target="_blank"
                rel="noreferrer"
              >
                <ListItemText>{Messages.t('client.header.faq')}</ListItemText>
              </a>
            </MenuItem>
            {
              research?.collect_link && (
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <DocumentDuplicateIcon />
                  </ListItemIcon>
                  <Link
                    to={Routes.updateUrlWithQuery(
                      location.pathname,
                      [
                        { label: COLLECT_QUERY, value: 'true' },
                      ],
                    )}
                  >
                    <ListItemText>{Messages.t('collect.title')}</ListItemText>
                  </Link>
                </MenuItem>
              )
            }
            {
              research?.viewing_reports_link && (
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <DocumentReportIcon />
                  </ListItemIcon>
                  <Link
                    to={Routes.updateUrlWithQuery(
                      location.pathname,
                      [
                        { label: VIEWING_REPORT_QUERY, value: 'true' },
                      ],
                    )}
                  >
                    <ListItemText>{Messages.t('viewingReport.pageTitle')}</ListItemText>
                  </Link>
                </MenuItem>
              )
            }
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <DocumentSearchIcon />
              </ListItemIcon>
              <Link
                to={Routes.updateUrlWithQuery(
                  location.pathname,
                  [
                    { label: SEARCH_QUERY, value: 'true' },
                  ],
                )}
              >
                <ListItemText>{Messages.t('client.header.searchDetails')}</ListItemText>
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <TrashIcon />
              </ListItemIcon>
              <Link
                to={Routes.updateUrlWithQuery(
                  location.pathname,
                  [
                    { label: WORKFLOW_STEP_QUERY, value: workflowStep.NOT_INTERESTED },
                  ],
                )}
              >
                <ListItemText>{Messages.t('NOT_INTERESTED.clientDisplayName')}</ListItemText>
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenLanguageModal(true);
              }}
            >
              <ListItemIcon>
                <TranslateIcon />
              </ListItemIcon>
              <div className="header-action">
                {Messages.t('traduction.languages')}
              </div>
            </MenuItem>
          </Menu>
          <LanguageSelector
            isOpen={openLanguageModal}
            onClose={() => setOpenLanguageModal(false)}
          />
        </div>
      </div>
    </header>
  );
}
