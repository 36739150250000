import React, { ReactNode, useMemo } from 'react';
import Badge from 'react-bootstrap/Badge';
import { Range } from 'services/propertyAttribute/PropertyAttribute';
import { useResearch } from 'network/api/ResearchQueries';
import { PropertyPartial } from 'types/property';
import StringUtils from 'services/StringUtils';
import Messages from 'services/i18n/Messages';
import { Match } from 'services/propertyAttribute/matchResearch';

type Props = {
  attribute: any
  property: PropertyPartial
  researchId?: string,
  picto?: ReactNode,
  override_research?: any
  plainText?: boolean,
};

export default function MatchedPropertyAttribute(
  {
    attribute,
    property,
    researchId,
    picto,
    override_research = null,
    plainText,
  }: Props,
) {
  const researchQueries = useResearch();
  const { data } = researchQueries.getResearch(researchId, !override_research);

  const research = useMemo(() => {
    if (override_research) {
      return override_research;
    }
    return data;
  }, [data, override_research]);

  const match = useMemo(() => {
    if (research) {
      const matchPropertyWithResearch = (attributeParam, propertyParam, researchParam) => {
        const value = attributeParam.getPropertyValue(propertyParam);
        const researchValue = attributeParam.getReseachValue(researchParam);

        if (attributeParam.match_research === undefined) {
          throw Error();
        }
        return (researchValue === null || (typeof researchValue === 'string' && researchValue.toLowerCase() === 'all'))
          ? Match.INDIFFERENT
          : value === null
            ? Match.MISSING
            : attributeParam.match_research(value, researchValue)
              ? Match.MATCH
              : Match.NO_MATCH;
      };

      return matchPropertyWithResearch(attribute, property, research);
    }
    return null;
  }, [attribute, property, research]);

  let propertyValue = attribute.getPropertyValue(property);
  if (propertyValue === 'ALL') {
    propertyValue = 'Missing';
  }
  const researchValue = useMemo(() => {
    if (research) {
      return attribute.getReseachValue(research);
    }
    return null;
  }, [attribute, research]);

  return (
    <>
      {research && (
        <div
          className={`badge-property ${match?.classname}`}
        >
          {plainText ? (
            <div className="text-only">{`${propertyValue} ${attribute.unit}`}</div>
          ) : (
            <>
              <div className="picto">{picto}</div>
              <div>
                <Badge pill variant={match?.badge_variant}>
                  {propertyValue === null ? (
                    <small>{Messages.t('matched.missing')}</small>
                  ) : (
                    // @ts-ignore
                    <SearchValue attribute={attribute} value={propertyValue} />
                  )}
                </Badge>

                {(match === Match.MISSING || match === Match.NO_MATCH) && (
                  <>
                    <strong> {attribute.no_match_symbol} </strong>
                    <Badge variant="light">
                      {/* @ts-ignore */}
                      <SearchValue attribute={attribute} value={researchValue} />
                    </Badge>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

function SearchValue({ attribute, value }: { attribute: any, value: any }) {
  if (value === true) {
    return '✓';
  }
  if (value === false) {
    return '✖';
  }
  if (value instanceof Range) {
    const range = value;
    const valueUnit = (valueParam) => (
      <>
        {valueParam}
        {attribute.unit || ''}
      </>
    );

    return (
      <span>
        {range.min && <>&gt;{valueUnit(range.min)}</>}&nbsp;
        {range.max && <>&lt;{valueUnit(range.max)}</>}
      </span>
    );
  }
  if (value instanceof Array) {
    return '';
  }
  return (
    <span>
      {`${StringUtils.capitalizeFirstLetter(value)}${attribute.unit ? ` ${attribute.unit}` : ''}`}
    </span>
  );
}
