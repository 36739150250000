import React, { useState } from 'react';
import { Property } from 'types/property';
import ClientCarousel from 'features/client/common/ClientCarousel';
import Modal from 'lib/Modal';
import {
  XIcon,
} from '@heroicons/react/solid';

type Props = {
  property: Property,
  isOpen: boolean,
  onClose: () => void,
  intitialSelectedImage?: number,
};

export default function CarrouselModal(
  {
    property,
    onClose,
    isOpen,
    intitialSelectedImage,
  }: Props,
) {
  const [selectedIndex, setSelectedIndex] = useState<number>(intitialSelectedImage || 0);

  return (
    <Modal className="carrousel-modal" isOpen={isOpen} onClose={onClose}>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
        className="close-button"
      >
        <XIcon />
      </button>
      <ClientCarousel
        property={property}
        showArrows
        intitialSelectedImage={selectedIndex}
        selectIndex={setSelectedIndex}
        enableKeyBoard
      />
    </Modal>
  );
}
