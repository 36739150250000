import { observable, WritableObservable } from 'micro-observables';

export class PanelCollapseService {
  private readonly openCandidateList: WritableObservable<boolean> = observable(true);

  private readonly openSearchEdit: WritableObservable<boolean> = observable(false);

  private isPostSearch = false;

  updateIsPostSearch(isPostSearch: boolean) {
    this.openCandidateList.set(!isPostSearch);
    this.isPostSearch = isPostSearch;
  }

  updateOpenCandidateList(open: boolean) {
    if (open) {
      this.openSearchEdit.set(false);
    }
    this.openCandidateList.set(open);
  }

  updateOpenSearchEdit(open: boolean) {
    if (open) {
      this.openCandidateList.set(false);
    } else if (!this.isPostSearch) {
      this.openCandidateList.set(true);
    }
    this.openSearchEdit.set(open);
  }

  getOpenSearchEdit() {
    return this.openSearchEdit.readOnly();
  }

  getOpenCandidateList() {
    return this.openCandidateList.readOnly();
  }
}

const panelCollapseService = new PanelCollapseService();
export default panelCollapseService;
