import React, { useMemo, useState } from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, UseFormTrigger } from 'react-hook-form';
import {
  PostSearch,
  PostSearchForm,
} from 'types/postSearch/PostSearch';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { stringToBoolean } from 'lib/form/FormUtils';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { usePropertiesContact } from 'network/api/PropertyQueries';
import ContactInfoForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/forms/ContactInfoForm';
import { ContactType } from 'types/forms/ContactInfoForm';
import { Candidate } from 'types/candidate';
import ConfirmationModal from 'features/hunter/common/ConfirmationModal';
import { usePostSearch } from 'network/api/PostSearchQueries';

type Props = {
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  errors: FieldErrors
  formField: PostSearchForm,
  handleSubmit: (data: Partial<PostSearch>) => void,
  setValue: UseFormSetValue<PostSearchForm>,
  propertyId: string
  candidate: Candidate
  trigger: UseFormTrigger<PostSearchForm>
};

export default function VoucherForm(
  {
    trigger,
    control,
    errors,
    formField,
    apiErrors,
    handleSubmit,
    setValue,
    candidate,
    propertyId,
  }: Props,
) {
  const propertiesContactQueries = usePropertiesContact();
  const { createAgentPostSearch } = usePostSearch();

  const { data: contacts, refetch } = propertiesContactQueries
    .getPropertiesContact(propertyId);
  const [modalIsDirty, setModalIsDirty] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationCallback, setConfirmationCallback] = useState<() => void>(() => {
  });

  const contactsValues = useMemo(() => contacts?.map((obj) => (
    {
      key: obj.id.toString(),
      label: `${
        obj.contact_type === ContactType.AGENCY
          ? `${obj.agency_name} `
          : ''
      }${obj.first_name} ${obj.last_name}`,
    }
  )), [contacts]);

  return (
    <div>
      <ConfirmationModal
        isOpen={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onConfirm={() => {
          setConfirmationModal(false);
          confirmationCallback();
        }}
        title={Messages.t('search.warning')}
        text={Messages.t('search.loseChange')}
      />
      <Controller
        name="use_voucher"
        control={control}
        render={(controller) => (
          <SelectWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            onChange={(value) => handleSubmit({
              use_voucher: stringToBoolean(value),
            })}
            label={Messages.t('form.field.use_voucher')}
            values={[
              { key: 'true', label: Messages.t('generics.YES') },
              { key: 'false', label: Messages.t('generics.NO') },
            ]}
          />
        )}
      />
      {
        openModal && (
          <Dialog
            open={openModal}
            onClose={() => {
              if (modalIsDirty) {
                setConfirmationModal(true);
                setConfirmationCallback(() => (() => {
                  setOpenModal(false);
                  setModalIsDirty(false);
                }));
              } else {
                setOpenModal(false);
              }
              setValue('main_contact', undefined);
            }}
          >
            <DialogTitle>
              {
                formField.main_contact ? (
                  <h4 className="mt-4">{Messages.t('contacts.editOrConfirm')}</h4>
                ) : (
                  <h4 className="mt-4">{Messages.t('contacts.new')}</h4>
                )
              }
            </DialogTitle>
            <DialogContent>
              <div className="contact-info-modal">
                <ContactInfoForm
                  canConfirm
                  candidate={candidate}
                  property={candidate.property}
                  propertyId={candidate.property.id}
                  updateIsDirty={setModalIsDirty}
                  contact={
                    contacts?.filter((contact) => contact.id.toString()
                      === formField.main_contact)[0]
                  }
                  isFirstPropertyContact={contacts?.length === 0}
                  fieldRequierd
                  hideTitle
                  onValidate={(contactId) => {
                    if (refetch) {
                      refetch();
                    }
                    setValue('main_contact', contactId.toString());
                    setOpenModal(false);
                  }}
                />
              </div>
            </DialogContent>
          </Dialog>
        )
      }
      {
        formField.use_voucher === 'true' && (
          <>
            {
              contactsValues && (
                <Controller
                  name="main_contact"
                  control={control}
                  rules={{ required: true }}
                  render={(controller) => (
                    <SelectWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      label={Messages.t('form.field.contactInfo')}
                      values={contactsValues}
                      onClick={() => setOpenModal(true)}
                      action={() => {
                        setOpenModal(true);
                        setValue('main_contact', undefined);
                      }}
                      actionLabel={Messages.t('contacts.addNew')}
                    />
                  )}
                />
              )
            }
            <Controller
              name="voucher_sent"
              control={control}
              render={(controller) => (
                <CheckboxWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  onChange={(value) => handleSubmit({ voucher_sent: value })}
                  label={Messages.t('form.field.voucher_sent')}
                />
              )}
            />
            <Button onClick={async () => {
              const res = await trigger([
                'main_contact',
              ]);
              if (res) {
                setValue('voucher_sent', true);
                handleSubmit({ voucher_sent: true });
                createAgentPostSearch.mutateAsync({ candidateId: candidate.id });
              }
            }}
            >
              {Messages.t('formButton.sendVoucher')}
            </Button>
          </>

        )
      }
    </div>
  );
}
