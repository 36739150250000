import React from 'react';
import Highlighter from 'react-highlight-words';

type Props = {
  value: string,
  hideIfNoMatch?: boolean,
  searchValue?: string | null,
};

export function SearchResult({ value, hideIfNoMatch, searchValue }: Props) {
  const searchLabel = searchValue || '';
  if (hideIfNoMatch && !searchLabel?.split(' ')
    .some((search) => value.toLowerCase().includes(search.toLowerCase()))) {
    return <></>;
  }
  return (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={searchLabel?.split(' ')}
      autoEscape
      textToHighlight={value}
    />
  );
}
