import React, { ReactNode } from 'react';
import { Candidate } from 'types/candidate';
import { Property } from 'types/property';
import { Research } from 'types/research';
import {
  TicketIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/solid';
import Messages from 'services/i18n/Messages';

type Props = {
  candidate: Candidate
  research?: Research,
};

const nth = (number: number) => {
  switch (number) {
    case 1:
      return Messages.t('generic.number.first', { number });
    case 2:
      return Messages.t('generic.number.second', { number });
    case 3:
      return Messages.t('generic.number.third', { number });
    default:
      return Messages.t('generic.number.nth', { number });
  }
};

const getIcon = (property: Property, research: Research | undefined, key: string) => {
  if (!research) {
    return null;
  }
  if (!research[key]) {
    return <TicketIcon className="icon-info" />;
  }
  if (research[key] === property[key]) {
    return <CheckCircleIcon className="icon-success" />;
  }
  return <XCircleIcon className="icon-error" />;
};

type CharacteristicsElt = {
  candidate: Candidate,
  research?: Research,
  isImportant?: boolean,
  dataKey: string,
  children: ReactNode,
};

function CharacteristicData(
  {
    candidate,
    research,
    isImportant,
    dataKey,
    children,
  }: CharacteristicsElt,
) {
  if (!candidate.property[dataKey] || !research) {
    return null;
  }
  if (!isImportant === !!(research[dataKey])) {
    return null;
  }
  return (
    <div className="characteristic-data">
      {getIcon(candidate.property, research, dataKey)}
      {children}
    </div>
  );
}

type CharacteristicsDatas = {
  candidate: Candidate,
  research?: Research,
  isImportant?: boolean,
};

function CharacteristicDatas(
  {
    candidate,
    research,
    isImportant,
  }: CharacteristicsDatas,
) {
  return (
    <>
      <CharacteristicData
        candidate={candidate}
        research={research}
        isImportant={isImportant}
        dataKey="furnished"
      >
        {
          candidate.property.furnished
            ? <span>{Messages.t('property.furnished.YES')}</span>
            : <span>{Messages.t('property.furnished.NO')}</span>
        }
      </CharacteristicData>
      <CharacteristicData
        candidate={candidate}
        research={research}
        isImportant={isImportant}
        dataKey="elevator"
      >
        {
          candidate.property.elevator
            ? <span>{Messages.t('property.elevator.YES')}</span>
            : <span>{Messages.t('property.elevator.NO')}</span>
        }
      </CharacteristicData>
      <CharacteristicData
        candidate={candidate}
        research={research}
        isImportant={isImportant}
        dataKey="floor"
      >
        {nth(candidate.property.floor)} {Messages.t('form.field.floor')}
      </CharacteristicData>
      <CharacteristicData
        candidate={candidate}
        research={research}
        isImportant={isImportant}
        dataKey="rooms"
      >
        {candidate.property.rooms} {Messages.t('form.field.rooms')}
      </CharacteristicData>
      <CharacteristicData
        candidate={candidate}
        research={research}
        isImportant={isImportant}
        dataKey="bedrooms"
      >
        {candidate.property.bedrooms} {Messages.t('form.field.bedrooms')}
      </CharacteristicData>
    </>
  );
}

export default function Characteristics({ candidate, research }: Props) {
  return (
    <div className="candidate-section">
      <h4>{Messages.t('candidate.caracteristics.caracteristics')}</h4>
      <div className="characteristic-content">
        <CharacteristicDatas
          candidate={candidate}
          research={research}
          isImportant
        />
        <CharacteristicDatas
          candidate={candidate}
          research={research}
        />
      </div>
    </div>
  );
}
