import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { Button, TextField } from '@material-ui/core';
import { useMessageBackend } from 'network/api/MessageQueries';
import { NotificationService } from 'services/notification';

type Props = {
  traductionKey: string,
  defaultValue: string,
  language: string,
  value?: string
};

export default function TraductionInput({
  traductionKey, defaultValue, language, value,
}: Props) {
  const { updateMessage } = useMessageBackend();
  const [currentValue, setCurrentValue] = useState(value);
  const handleKeypress = (e, func) => {
    if (e.charCode === 13) {
      func();
    }
  };
  const save = () => {
    // @ts-ignore
    updateMessage.mutate({ key: traductionKey, language, traduction: currentValue }, {
      onSuccess: () => {
        NotificationService.notifySuccess(Messages.t('toast.hunter.messageUpdated'));
      },
      onError: () => {
        NotificationService.notifyError(Messages.t('toast.hunter.error'));
      },
    });
  };

  return (
    <div className="traduction-input-container">
      <span className="language">
        {language}
      </span>
      <TextField
        fullWidth
        variant="standard"
        value={currentValue}
        onChange={(e) => setCurrentValue(e.target.value)}
        onKeyPress={(e) => handleKeypress(e, save)}
        placeholder={defaultValue}
      />
      <Button onClick={save}>{Messages.t('formButton.save')}</Button>
    </div>
  );
}
