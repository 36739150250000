import React from 'react';
import { Candidate } from 'types/candidate';
import { PencilAltIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/solid';
import { Research } from 'types/research';
import { useOpinion } from 'network/api/CandidateQueries';
import { opinionType } from 'types/Opinion';
import Messages from 'services/i18n/Messages';

type Props = {
  candidate: Candidate,
  research?: Research,
};

export default function Opinion({ candidate, research }: Props) {
  const { getOpinion } = useOpinion();
  const { data: opinions } = getOpinion(candidate.id);
  if (!research) {
    return null;
  }
  if ((!opinions || opinions.length === 0)
    && !(research.price_max_search && candidate.property.price > research.price_max_search)) {
    return null;
  }

  return (
    <div className="candidate-section">
      <h4>{Messages.t('candidate.opinion.ourOpinion')}</h4>
      <div>
        {
          opinions
          && opinions
            .filter((opinion) => opinion.opinion_type === opinionType.PRO)
            .sort((a, b) => a.created_at.localeCompare(b.created_at))
            .map((opinion) => (
              <div className="opinion-row">
                <PlusCircleIcon className="icon-success" />
                {opinion.text}
              </div>
            ))
        }
        {
          opinions
          && opinions
            .filter((opinion) => opinion.opinion_type === opinionType.CONS)
            .sort((a, b) => a.created_at.localeCompare(b.created_at))
            .map((opinion) => (
              <div className="opinion-row">
                <MinusCircleIcon className="icon-error" />
                {opinion.text}
              </div>
            ))
        }
        {
          research.price_max_search && candidate.property.price > research.price_max_search && (
            <div className="opinion-row">
              <MinusCircleIcon className="icon-error" />
              {Messages.t('opinion.overBudget')}
            </div>
          )
        }
        {
          opinions
          && opinions
            .filter((opinion) => opinion.opinion_type === opinionType.OTHER)
            .sort((a, b) => a.created_at.localeCompare(b.created_at))
            .map((opinion) => (
              <div className="opinion-row">
                <PencilAltIcon className="icon-info" />
                {opinion.text}
              </div>
            ))
        }
      </div>
    </div>
  );
}
