/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropertyImage from 'theme/PropertyImage';
import { Candidate } from 'types/candidate';
import AddPropertyPhotoForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/forms/AddPropertyPhotoForm';
import { useProperty } from 'network/api/PropertyQueries';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import { CloudinaryUrl } from 'types/cloudinary';
import ConfirmationModal from 'features/hunter/common/ConfirmationModal';
import CarrouselModal from 'features/client/common/CarrouselModal';
import { FetchError } from 'network/Errors';

type Props = {
  candidate: Candidate,
};

export default function PropertyPhotosCarousel({ candidate }: Props) {
  const { updateProperty } = useProperty();
  const [submitting, setSubmitting] = useState(false);
  const [initialImage, setInitalImage] = useState<number | undefined>(undefined);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationCallback, setConfirmationCallback] = useState<() => void>();
  const { property } = candidate;

  const deleteImage = (data: { images_url: string[], uploaded_images: CloudinaryUrl[] }) => {
    setSubmitting(true);
    const formData = {
      images_url: data.images_url || [],
      uploaded_images: data.uploaded_images || [],
    };
    updateProperty.mutate({ propertyId: property.id, candidate, data: formData },
      {
        onSuccess: () => {
          setSubmitting(false);
          NotificationService.notifySuccess(Messages.t('toast.hunter.viewingUpdated'));
        },
        onError: (error: FetchError) => {
          const { status } = error;
          setSubmitting(false);
          if (status === 500) {
            NotificationService.notifyError(Messages.t('toast.hunter.error'));
          }
        },
      });
  };

  return (
    <div className="carousel_container">
      {
        initialImage !== undefined && (
          <CarrouselModal
            property={property}
            onClose={() => setInitalImage(undefined)}
            isOpen
            intitialSelectedImage={initialImage}
          />
        )
      }
      <ConfirmationModal
        isOpen={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onConfirm={() => {
          if (confirmationCallback) {
            confirmationCallback();
          }
          setConfirmationModal(false);
        }}
        title={Messages.t('imageConfirm.title')}
        text={Messages.t('imageConfirm.text')}
      />
      <div className="carousel">
        {property.images_url
        && property.images_url.map((image, index) => (
          <PropertyImage
            onError={() => {
            }}
            onclick={() => setInitalImage(index)}
            key={`images_url_${index}`}
            image={image}
            className="mr-2 style.carousel_image image-container hunter-image"
            enableDelete
            isCloudinaryImage={false}
            submitting={submitting}
            onDelete={() => {
              setConfirmationModal(true);
              setConfirmationCallback(() => (() => deleteImage(
                {
                  images_url: property.images_url.filter((imageUrl) => imageUrl !== image),
                  uploaded_images: property.uploaded_images,
                },
              )));
            }}
          />
        ))}
        {property.uploaded_images
        && property.uploaded_images.map((image, index) => (
          <PropertyImage
            onError={() => {
            }}
            key={`uploaded_images_${index}`}
            image={image.image_id}
            onclick={() => setInitalImage(property.images_url.length + index)}
            className="mr-2 style.carousel_image image-container hunter-image"
            isCloudinaryImage
            submitting={submitting}
            enableDelete
            onDelete={() => {
              setConfirmationModal(true);
              setConfirmationCallback(() => (() => deleteImage(
                {
                  images_url: property.images_url,
                  uploaded_images: property.uploaded_images
                    .filter((imageFilter) => imageFilter.image_id !== image.image_id),
                },
              )));
            }}
          />
        ))}
        <AddPropertyPhotoForm candidate={candidate} />
      </div>
    </div>
  );
}
