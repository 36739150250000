import { workflowStepMetaData } from 'services/hunter/WorkflowStep';
import { WorkflowStep, workflowStep } from 'types/WorkflowStep';

// TODO refacto
export function defaultWorkflowTabs() {
  const makeTab: (step: WorkflowStep, args: any | undefined) => any = (step, args) => ({
    step,
    displayName: workflowStepMetaData(step).getDisplayName(),
    type: workflowStepMetaData(step).type,
    ...args,
  });

  return [
    makeTab(workflowStep.REVIEW, {
      candidates_toreview: [],
      candidates_nomatch: [],
    }),
    makeTab(workflowStep.SHORTLIST, undefined),
    makeTab(workflowStep.SENT, undefined),
    makeTab(workflowStep.NOT_SURE, undefined),
    makeTab(workflowStep.VIEWING_REQUESTED, undefined),
    makeTab(workflowStep.BOOKED, undefined),
    makeTab(workflowStep.VIEWED, undefined),
    makeTab(workflowStep.TO_APPLY, undefined),
    makeTab(workflowStep.APPLIED, undefined),
    makeTab(workflowStep.APPLICATION_ACCEPTED, undefined),
    makeTab(workflowStep.AGREEMENT, undefined),
    makeTab(workflowStep.READY_TO_SIGN, undefined),
    makeTab(workflowStep.SIGNED, undefined),
    makeTab(workflowStep.DISCARDED, undefined),
    makeTab(workflowStep.NOT_INTERESTED, undefined),
    makeTab(workflowStep.TRASH, undefined),
  ];
}

export const postSearchTabs = [
  workflowStep.AGREEMENT,
  workflowStep.READY_TO_SIGN,
  workflowStep.SIGNED];
