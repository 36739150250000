import React from 'react';
import Messages from 'services/i18n/Messages';
import { Controller } from 'react-hook-form';
import {
  PostSearch,
  PostSearchForm,
} from 'types/postSearch/PostSearch';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import TimelineWrapper from 'lib/form/TimelineWrapper';
import RadioWrapper from 'lib/form/RadioWrapper';
import { giftStatusTypeEnum, giftTypeEnum } from 'types/postSearch/Gift';
import DateTimePickerWrapper from 'lib/form/DateTimePickerWrapper';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';

type Props = {
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  errors: FieldErrors
  handleSubmit: (data: Partial<PostSearch>) => void
};

export default function GiftForm(
  {
    control,
    errors,
    apiErrors,
    handleSubmit,
  }: Props,
) {
  return (
    <>
      <Controller
        name="client_gift"
        control={control}
        render={(controller) => (
          <RadioWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            values={
              Object.values(giftTypeEnum)
                .map((type) => ({ key: type, label: Messages.t(`gift.type.${type}`) }))
            }
            onChange={(value) => handleSubmit({ client_gift: value })}
            label={Messages.t('form.field.client_gift')}
          />
        )}
      />
      <Controller
        name="gift_date"
        control={control}
        render={(controller) => (
          <DateTimePickerWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            autoSaveSubmit={(value) => handleSubmit({ gift_date: value?.toISOString() })}
            label={Messages.t('form.field.gift_date')}
          />
        )}
      />
      <Controller
        name="gift_status"
        control={control}
        render={(controller) => (
          <TimelineWrapper
            control={controller}
            onChange={(value) => handleSubmit({ gift_status: value })}
            values={
              Object.values(giftStatusTypeEnum)
                .map((type) => ({ key: type, label: Messages.t(`gift.status.${type}`) }))
            }
          />
        )}
      />
      <Controller
        name="gift_notes"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            type="textarea"
            rows={5}
            autoSaveSubmit={(value) => handleSubmit({
              gift_notes: value,
            })}
            label={Messages.t('form.field.notes')}
          />
        )}
      />
    </>
  );
}
