import React, { useEffect, useState } from 'react';
import {
  Research,
  ResearchPartialType,
  ResearchStatus,
  researchStatus,
} from 'types/research';
import { Controller, useForm } from 'react-hook-form';
import Messages from 'services/i18n/Messages';
import DatePickerWrapper from 'lib/form/DatePickerWrapper';

import { NotificationService } from 'services/notification';
import { useResearch } from 'network/api/ResearchQueries';
import SelectWrapper from 'lib/form/SelectWrapper';
import { Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import DateUtils from 'services/DateUtils';

type Props = {
  research: Research
};

const getPrettyDate = (dateStr: string) => {
  const date = new Date(dateStr);
  return format(date, 'dd-MM-yyyy');
};

export default function HeaderSearchForm({ research }: Props) {
  const [apiErrors, setApiError] = useState({});
  const researchQueries = useResearch();

  const {
    control,
    trigger,
    reset,
    formState: { errors },
  } = useForm<ResearchPartialType>({
    defaultValues: { ...research },
  });

  useEffect(() => {
    reset({ ...research });
  }, [research.id]);

  const handleSubmit = async (formData: ResearchPartialType) => {
    setApiError({});
    if (research) {
      try {
        await researchQueries.updateResearch.mutateAsync({
          research_id: research.id,
          data: formData,
        });
        NotificationService.notifySuccess(Messages.t('toast.hunter.reasearchUpdated'));
      } catch (error) {
        // @ts-ignore
        const { status }: number = error;
        if (status === 500) {
          NotificationService.notifyError(Messages.t('toast.hunter.error'));
        }
        setApiError(error.json_response);
      }
    }
  };

  const readyDateDelta = DateUtils.getDaysDelta(
    new Date(),
    research.ready_move_in_date ? new Date(research.ready_move_in_date) : undefined,
  );
  const latestDateDelta = DateUtils.getDaysDelta(
    new Date(),
    research.latest_move_in_date ? new Date(research.latest_move_in_date) : undefined,
  );

  return (
    <form className="header-search-form">
      <Controller
        name="status"
        control={control}
        rules={{ required: true }}
        render={(controller) => (
          <SelectWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            label={Messages.t('research.list.status')}
            onChange={(value) => handleSubmit({ status: value as ResearchStatus })}
            values={Object.values(researchStatus).map((key) => ({ key, label: Messages.t(`research.status.${key}`) }))}
          />
        )}
      />
      {
        research.start_date && (
          <Tooltip
            title={Messages.t('research.startDateDelta', { days: DateUtils.getPositiveDaysDelta(new Date(research.start_date), new Date()) })}
          >
            <div>
              {Messages.t('research.startDate', { date: getPrettyDate(research.start_date) })}
            </div>
          </Tooltip>
        )
      }
      <Tooltip
        title={Messages.t(readyDateDelta > 0 ? 'research.readyDateDelta' : 'research.daysAgo', { days: Math.abs(readyDateDelta) })}
      >
        <div>
          <Controller
            name={
              research.status === researchStatus.POST_SEARCH
                ? 'move_in_date'
                : 'ready_move_in_date'
            }
            control={control}
            rules={{ required: true }}
            render={(controller) => (
              <DatePickerWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                trigger={trigger}
                autoSaveSubmit={(value) => handleSubmit({
                  [research.status === researchStatus.POST_SEARCH
                    ? 'move_in_date'
                    : 'ready_move_in_date']: value?.toISOString().split('T')[0],
                })}
                label={Messages.t(
                  research.status === researchStatus.POST_SEARCH
                    ? 'form.field.move_in_date'
                    : 'form.field.ready_move_in_date',
                )}
              />
            )}
          />
        </div>
      </Tooltip>
      <Tooltip
        title={Messages.t(readyDateDelta > 0 ? 'research.latestDateDelta' : 'research.daysAgo', { days: Math.abs(latestDateDelta) })}
      >
        <div>
          <Controller
            name={
              research.status === researchStatus.POST_SEARCH
                ? 'sign_in_date'
                : 'latest_move_in_date'
            }
            control={control}
            rules={{ required: true }}
            render={(controller) => (
              <DatePickerWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                trigger={trigger}
                autoSaveSubmit={(value) => handleSubmit({
                  [research.status === researchStatus.POST_SEARCH
                    ? 'sign_in_date'
                    : 'latest_move_in_date']: value?.toISOString().split('T')[0],
                })}
                label={Messages.t(
                  research.status === researchStatus.POST_SEARCH
                    ? 'form.field.sign_in_date'
                    : 'form.field.latest_move_in_date',
                )}
              />
            )}
          />
        </div>
      </Tooltip>
    </form>
  );
}
