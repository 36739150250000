import React from 'react';
import { Dialog } from '@material-ui/core';
import Button from 'features/client/common/Button';
import Messages from 'services/i18n/Messages';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onConfirm: () => void,
  title: string,
  text: string
};

export default function ConfirmationModal(
  {
    isOpen,
    onConfirm,
    onClose,
    text,
    title,
  }: Props,
) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="candidate-modal-content">
        <h3>
          {title}
        </h3>
        <div>
          {text}
        </div>
        <div>
          <Button preventDefault onClick={onClose} variant="secondary">{Messages.t('formButton.cancel')}</Button>
          <Button preventDefault onClick={onConfirm} variant="primary">{Messages.t('formButton.yes')}</Button>
        </div>
      </div>
    </Dialog>
  );
}
