import { Enum } from 'lib/type/enum';

export type GiftType =
  'CHOCOLATE'
  | 'PLANT'
  | 'NO_NEWS';

export const giftTypeEnum: Enum<GiftType> = {
  CHOCOLATE: 'CHOCOLATE',
  PLANT: 'PLANT',
  NO_NEWS: 'NO_NEWS',
};

export type GiftStatusType =
  'ORDERED'
  | 'DELEVERY'
  | 'DELIVERED';

export const giftStatusTypeEnum: Enum<GiftStatusType> = {
  ORDERED: 'ORDERED',
  DELEVERY: 'DELEVERY',
  DELIVERED: 'DELIVERED',
};
