import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import {
  Activity,
} from 'types/Activity';
import SpinButton from 'theme/hunter-utils/SpinButton';
import { Button } from '@material-ui/core';
import { Candidate } from 'types/candidate';
import { useUser } from 'reactfire';
import { NotificationService } from 'services/notification';
import { FetchError } from 'network/Errors';
import { useProperty } from 'network/api/PropertyQueries';

type Props = {
  candidate: Candidate,
  onClose: () => void,
  activity?: Activity,
};

export default function IssueForm(
  {
    candidate,
    onClose,
    activity,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiError] = useState({});
  const { updateProperty } = useProperty();
  const { data: user } = useUser();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ issue_report: string }>(
    {
      defaultValues: { issue_report: candidate.property.issue_report },
    },
  );

  const onSubmit: SubmitHandler<{ issue_report: string }> = (data: { issue_report: string }) => {
    setApiError({});
    setSubmitting(true);
    updateProperty.mutateAsync(
      {
        propertyId: candidate.property.id,
        candidate,
        data: { issue_report: `${data.issue_report}\n\n--\nUrl: ${window.location.href}\nBy: ${user?.email || ''}` },
      },
    ).then(() => {
      NotificationService.notifySuccess(Messages.t('toast.hunter.issueReported'));
      setSubmitting(false);
      onClose();
    })
      .catch((error: FetchError) => {
        const { status } = error;
        if (status === 500) {
          NotificationService.notifyError(Messages.t('toast.hunter.taskUpdated'));
        }
        setApiError(error.json_response);
        setSubmitting(false);
      });
  };

  return (
    <form className="issue-form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="issue_report"
        rules={{ required: true }}
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            type="textarea"
            rows={5}
            control={controller}
            label={Messages.t('form.field.comment')}
          />
        )}
      />
      <SpinButton
        editing={!!activity}
        spin={submitting}
        variant="primary"
      />
      <Button
        onClick={onClose}
        color="secondary"
        variant="contained"
        className="ml-2"
      >
        {Messages.t('formButton.cancel')}
      </Button>
    </form>
  );
}
