import React, { useEffect, useState } from 'react';
import { EMPTY_GEOJSON, makeGeojson } from 'services/isochrone/geometry/geojson_helpers';
import services from 'services/isochrone/services';
import { MapSource, MapLayer } from 'services/isochrone/MapSourceAndLayer';
import LoaderRing, { displayLoader } from 'theme/hunter-utils/LoaderRing';
import Card from 'react-bootstrap/Card';

export default function IntersectionLayer(
  {
    map,
    travelConstraints,
    districtsSelection,
  },
) {
  // // add layer and source
  useEffect(() => {
    //   map.addSource(MapSource.INTERSECTION_GRID, {
    //     type: "geojson",
    //     data: EMPTY_GEOJSON,
    //   });

    //   map.addLayer({
    //     id: MapLayer.INTERSECTION_GRID,
    //     type: "fill",
    //     source: MapSource.INTERSECTION_GRID,
    //     paint: {
    //       "fill-color": "rgba(100, 200, 140, 0.1)",
    //       "fill-outline-color": "rgba(50, 0, 0, 1)",
    //     },
    //     layout: {
    //       visibility: "none",
    //     },
    //   });

    map.addSource(MapSource.INTERSECTION_CONTOUR, {
      type: 'geojson',
      data: EMPTY_GEOJSON,
    });

    map.addLayer({
      id: MapLayer.INTERSECTION_CONTOUR,
      type: 'line',
      source: MapSource.INTERSECTION_CONTOUR,
      paint: {
        'line-width': 3,
        'line-color': 'rgba(40, 100, 40, 0.5)',
      },
    });

    // Cannot read property 'removeLayer' of undefined ???
    // return () => {
    //   map.removeLayer(MapLayer.INTERSECTION);
    //   map.removeSource(MapSource.INTERSECTION);
    // };
  }, [map]);

  const [loading, setLoading] = useState(0);
  const [intersection, setIntersection] = useState(null);

  useEffect(() => {
    displayLoader(
      setLoading,
      // @ts-ignore
      (function () {
        // features concerned by intersection.
        const travel_features = travelConstraints
          .filter((tc) => tc.isochrone_geojson != null)
          .map((tc) => {
            // keep feature which correspond to the current travel_duration
            const { duration } = tc.travel_options.max_travel_time;
            return feature_of_duration(tc.isochrone_geojson, duration);
          })
          .filter((feature) => feature != null);

        if (travel_features.length === 0) {
          return Promise.resolve(null);
        } if (travel_features.length === 1) {
          return Promise.resolve(makeGeojson(travel_features));
        } if (travel_features.length) {
          return services.geojson_operation.intersectGeojsons(
            travel_features.map((f) => f),
          );
        }

        // return services.geojson_operation.discretizeHegonalGridGeojson(
        //   intersection_geojson,
        //   //excluded_districts_geojson
        // );
      }())
        .then((intersection_geojson) => {
          if (intersection_geojson === null) {
            return Promise.resolve(null);
          }

          // exclude districts
          if (districtsSelection === null || districtsSelection.length === 0) {
            return Promise.resolve(intersection_geojson);
          }
          // union on district stabilize subtraction:
          return services.geojson_operation
            .unionGeojsons(
              districtsSelection.map((district) => district.feature),
            )
            .then((union_districts) => services.geojson_operation.differenceGeojsons([
              intersection_geojson,
              union_districts,
            ]));
        })
        .then((contour) => (contour === null
          ? null
          : Promise.resolve({
            grid: { features: [] },
            contour,
            area: 0,
          })))
        .then((intersection) => {
          if (intersection === null) {
            // map.getSource(MapSource.INTERSECTION_GRID).setData(EMPTY_GEOJSON);
            map
              .getSource(MapSource.INTERSECTION_CONTOUR)
              .setData(EMPTY_GEOJSON);
            setIntersection(null);
          } else {
            // map
            //   .getSource(MapSource.INTERSECTION_GRID)
            //   .setData(intersection.grid);
            map
              .getSource(MapSource.INTERSECTION_CONTOUR)
              .setData(intersection.contour);

            // annoying in practice
            // if (intersection.aabb != null) {
            //   map.fitBounds(intersection.aabb);
            // }

            setIntersection({
              // @ts-ignore
              area: intersection.area,
              cell_count: intersection.grid.features.length,
            });
          }
        }),
    );
  }, [map, travelConstraints, districtsSelection]);

  const format_area = (area) =>
    // @ts-ignore
    `Area: ${Math.round(area / 1e6)} km² (${intersection.cell_count} cells)`;
  return (
    <div className="intersection-container">
      <Card className="text-left mb-4">
        <Card.Header>Intersection</Card.Header>
        <Card.Body>
          {intersection && (
            // @ts-ignore
            <Card.Text>{format_area(intersection.area)} </Card.Text>
          )}
          <LoaderRing visible={!!loading} />
        </Card.Body>
      </Card>
    </div>
  );
}

function feature_of_duration(geojson, duration) {
  const feature_array = geojson.features.filter(
    (feature) => feature.properties.time === duration,
  );
  if (feature_array.length === 0) {
    return null;
  } if (feature_array.length === 1) {
    return feature_array[0];
  }
  throw Error('More than one feature for a specific duration');
}
