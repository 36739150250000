export default class District {
  private id;

  private feature;

  constructor(id, feature) {
    this.id = id;
    this.feature = feature;
  }
}

export function district_selection_toggle(selection, district) {
  const present = selection.includes(district);

  const new_selection = present
    ? selection.filter((d) => d !== district)
    : [...selection, district];

  return [new_selection, !present];
}
