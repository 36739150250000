import { Enum } from 'lib/type/enum';

export type InsuranceStatusType =
  'LINK_SENT_TO_CLIENT'
  | 'INSURANCE_ACTIVATED'
  | 'SENT_TO';

export const insuranceStatusTypeEnum: Enum<InsuranceStatusType> = {
  LINK_SENT_TO_CLIENT: 'LINK_SENT_TO_CLIENT',
  INSURANCE_ACTIVATED: 'INSURANCE_ACTIVATED',
  SENT_TO: 'SENT_TO',
};
