import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FetchError } from 'network/Errors';
import { PostSearch, PostSearchForm } from 'types/postSearch/PostSearch';
import { usePostSearch } from 'network/api/PostSearchQueries';
import Collapsable from 'lib/Collapsable';
import UseFullInformationForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/utilitiesAndInsurance/UseFullInformationForm';
import Messages from 'services/i18n/Messages';
import { booleanToString } from 'lib/form/FormUtils';
import { Property } from 'types/property';
import { useProperty } from 'network/api/PropertyQueries';
import { Candidate } from 'types/candidate';
import InsuranceForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/utilitiesAndInsurance/InsuranceForm';
import PapernestForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/utilitiesAndInsurance/PapernestForm';
import ElectricityForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/utilitiesAndInsurance/ElectricityForm';
import GasForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/utilitiesAndInsurance/GasForm';
import InternetForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/utilitiesAndInsurance/InternetForm';
import PostSearchUtils from 'services/PostSearchUtils';
import { useCandidate } from 'network/api/CandidateQueries';

type Props = {
  postSearch: PostSearch,
  property: Property,
  candidate: Candidate
};

export default function UtilitiesAndInsuranceForm(
  {
    postSearch,
    property,
    candidate,
  }: Props,
) {
  const { updatePostSearch } = usePostSearch();
  const { updateProperty } = useProperty();
  const { updateCandidate } = useCandidate();

  const [apiErrors, setApiError] = useState({});
  const {
    control,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = useForm<PostSearchForm>({
    defaultValues: {
      ...postSearch,
      proof_of_transfer_only: booleanToString(postSearch.proof_of_transfer_only),
      client_has_european_bank_account: booleanToString(
        postSearch.client_has_european_bank_account,
      ),
      use_papernest: booleanToString(postSearch.use_papernest),
      use_gas: booleanToString(postSearch.use_gas),
      use_voucher: booleanToString(postSearch.use_voucher),
      property_address: property.address,
      lease_start_date: candidate.lease_start_date,
    },
  });

  const formField = watch();
  const handleSubmit = async (data: Partial<PostSearch>) => {
    setApiError({});
    await updatePostSearch.mutateAsync({
      postSearchId: postSearch.id,
      data,
    }).catch((error: FetchError) => {
      setApiError(error.json_response);
    });
  };

  const handleSubmitProperty = async (data: Partial<Property>) => {
    setApiError({});
    await updateProperty.mutateAsync({
      propertyId: property.id,
      data,
      candidate,
    }).catch((error: FetchError) => {
      setApiError(error.json_response);
    });
  };

  const handleSubmitCandidate = async (data: Partial<Candidate>) => {
    setApiError({});
    await updateCandidate.mutateAsync({
      data,
      candidate,
    }).catch((error: FetchError) => {
      setApiError(error.json_response);
    });
  };

  return (
    <div>
      <form>
        <Collapsable defaultOpen label={Messages.t('postSearch.section.usefullInfo')}>
          <UseFullInformationForm
            control={control}
            formField={formField}
            apiErrors={apiErrors}
            errors={errors}
            trigger={trigger}
            handleSubmit={handleSubmit}
          />
        </Collapsable>
        <Collapsable
          checked={PostSearchUtils.isInsuranceChecked(formField)}
          label={Messages.t('postSearch.section.insurance')}
        >
          <InsuranceForm
            setValue={setValue}
            candidateId={candidate.id}
            postSearch={postSearch}
            control={control}
            apiErrors={apiErrors}
            errors={errors}
            trigger={trigger}
            handleSubmit={handleSubmit}
            handleSubmitProperty={handleSubmitProperty}
          />
        </Collapsable>
        <Collapsable
          checked={PostSearchUtils.isPapernestChecked(formField)}
          label={Messages.t('postSearch.section.papernest')}
        >
          <PapernestForm
            handleSubmitCandidate={handleSubmitCandidate}
            trigger={trigger}
            candidateId={candidate.id}
            control={control}
            apiErrors={apiErrors}
            errors={errors}
            formField={formField}
            handleSubmit={handleSubmit}
          />
        </Collapsable>
        <Collapsable
          checked={PostSearchUtils.isElectricityChecked(formField)}
          label={Messages.t('postSearch.section.electricity')}
        >
          <ElectricityForm
            control={control}
            apiErrors={apiErrors}
            errors={errors}
            trigger={trigger}
            handleSubmit={handleSubmit}
          />
        </Collapsable>
        <Collapsable
          checked={PostSearchUtils.isGasChecked(formField)}
          label={Messages.t('postSearch.section.gas')}
        >
          <GasForm
            control={control}
            apiErrors={apiErrors}
            errors={errors}
            trigger={trigger}
            formField={formField}
            handleSubmit={handleSubmit}
          />
        </Collapsable>
        <Collapsable
          checked={PostSearchUtils.isInternetChecked(formField)}
          label={Messages.t('postSearch.section.internet')}
        >
          <InternetForm
            control={control}
            apiErrors={apiErrors}
            errors={errors}
            trigger={trigger}
            handleSubmit={handleSubmit}
          />
        </Collapsable>
      </form>
    </div>
  );
}
