import React from 'react';
import { workflowStepMetaData } from 'services/hunter/WorkflowStep';
import { IconContext } from 'react-icons';
import {
  BiTime,
  BiCheckCircle,
  BiMessage,
  BiMailSend,
  BiHeart,
  BiTrash,
  BiFace,
} from 'react-icons/bi';
import DeleteActivityControl
  from 'features/hunter/pages/research/researchDetails/candidateDetails/activities/DeleteActivityControl';
import ActivitySent from 'features/hunter/pages/research/researchDetails/candidateDetails/activities/ActivitySent';
import ActivitySimpleComment
  from 'features/hunter/pages/research/researchDetails/candidateDetails/activities/ActivitySimpleComment';
import ActivityPickedByCustomer
  from 'features/hunter/pages/research/researchDetails/candidateDetails/activities/ActivityPickedByCustomer';
import ViewingBookFormWrapper
  from 'features/hunter/pages/research/researchDetails/workflowStepTransition/forms/ViewingBookFormWrapper';
import { getCurrentNotation } from 'types/forms/CommentForm';
import { workflowStep } from 'types/WorkflowStep';
import Messages from 'services/i18n/Messages';
import { Candidate } from 'types/candidate';
import { Activity, activityType } from 'types/Activity';
import ActivityShortlistForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/activities/ActivityShortlistForm';
import { ListAlt } from '@material-ui/icons';
import { ShortDateTimeFormat } from 'services/utils';

type Props = {
  candidate: Candidate,
  activity: Activity,
};

export default function ActivityCard({ candidate, activity }: Props) {
  const isWorkflow = activity.activity_type === activityType.WORKFLOW;
  const isMessage = activity.activity_type === activityType.MESSAGE;
  const isClient = activity.activity_type === activityType.CLIENT;
  const isShortlist = isWorkflow && activity.workflow_step === workflowStep.SHORTLIST;
  const isSent = isWorkflow && activity.workflow_step === workflowStep.SENT;
  const isMatch = isWorkflow && activity.workflow_step === workflowStep.MATCH;
  const isBooked = isWorkflow && activity.workflow_step === workflowStep.BOOKED;
  const isDiscarded = isWorkflow && activity.workflow_step === workflowStep.DISCARDED;

  const currentNotationObject = getCurrentNotation(activity.client_notation?.toString());

  const workflowIcon = isShortlist ? (
    <BiHeart />
  ) : isSent ? (
    <BiMailSend />
  ) : isDiscarded ? (
    <BiTrash />
  ) : (
    <BiCheckCircle />
  );
  return (
    <li>
      <div className="timeline_badge primary">
        {isMessage && <BiMessage />}
        {activity.activity_type === activityType.TASK && <ListAlt />}

        {isClient && (
          <IconContext.Provider value={{ color: 'red' }}>
            <span style={{ border: '3px solid black' }}>
              <BiFace />
            </span>
          </IconContext.Provider>
        )}

        {isWorkflow && workflowIcon}
      </div>
      <div className={`timeline_panel ${!activity.read ? 'unread' : ''}`}>
        <div className="timeline_heading">
          <h4 className="timeline_title">
            {isClient && (
              <>{workflowStepMetaData(activity.workflow_step).getDisplayName()}</>
            )}
            {
              activity.activity_type === activityType.TASK && (
                <>
                  {
                    activity.task_type
                  }
                </>
              )
            }
            {
              activity.activity_type === activityType.TASK && (
                <>
                  {activity.message_title}
                  <DeleteActivityControl
                    className="float-right"
                    activity={activity}
                  />
                </>
              )
            }
            {isWorkflow && `${workflowStepMetaData(activity.workflow_step).getDisplayName()}`}
          </h4>
          <p>
            <small className="text-muted">
              <BiTime />
              &nbsp;
              {ShortDateTimeFormat(activity.date)}
            </small>
          </p>
        </div>
        <div className="timeline_body">
          {(isMessage) && (
            <p style={{ whiteSpace: 'pre-wrap' }}>{activity.message_content}</p>
          )}
          {isShortlist && <ActivityShortlistForm candidate={candidate} />}
          {isSent && <ActivitySent candidate={candidate} />}
          {isMatch && (
            <ActivitySimpleComment
              candidate={candidate}
              commentField="match_comment"
            />
          )}
          {
            isClient
            && (activity.workflow_step === workflowStep.NOT_SURE
              || activity.workflow_step === workflowStep.VIEWING_REQUESTED)
            && (
              <ActivityPickedByCustomer candidate={candidate} />
            )
          }
          {
            !!activity.client_notation && activity.client_notation !== 0 && (
              <>
                <p>
                  <b>
                    {Messages.t('activity.like')}
                  </b>
                </p>
                <p>{`${currentNotationObject?.emoji} (${currentNotationObject?.text()})`}</p>
                <br />
              </>
            )
          }
          {
            !isMessage && activity.message_content && (
              <div>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                  {activity.message_content}
                </p>
              </div>
            )
          }
          {isBooked && <ViewingBookFormWrapper candidate={candidate} />}
        </div>
      </div>
    </li>
  );
}
