import React, { useState } from 'react';
import ClientCarousel from 'features/client/common/ClientCarousel';
import CarrouselModal from 'features/client/common/CarrouselModal';
import CardActionButtons from 'features/client/common/CardActionButtons';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import StringUtils from 'services/StringUtils';
import DateUtils from 'services/DateUtils';
import { Candidate } from 'types/candidate';
import { format } from 'date-fns';
import { detectMobile } from 'services/utils';
import { ViewingAppointment } from 'types/ViewingAppointment';
import PropertySummaryIcons from 'features/common/PropertySummaryIcons';
import ClientNotation from 'features/common/ClientNotation';

type Props = {
  candidate: Candidate,
  children: React.ReactChild,
  onArchive?: () => void,
  enableFullScreenCarousel?: boolean,
  displayActions?: boolean,
  displayViewing?: boolean,
  viewingAppointement?: ViewingAppointment[],
  showCarousel?: boolean,
  moreInfo?: string,
  showNotation?: boolean,
  notationEditable?: boolean,
  hideOtherPicture?: boolean,
};

const getPrettyDate = (dateStr: string) => {
  const date = new Date(dateStr);
  return format(date, 'dd/MM/yyyy - HH:mm');
};

export default function ResultCardContent(
  {
    hideOtherPicture,
    candidate,
    children,
    onArchive,
    enableFullScreenCarousel,
    displayActions,
    displayViewing,
    viewingAppointement,
    showCarousel,
    moreInfo,
    showNotation,
    notationEditable,
  }: Props,
) {
  const [initialImage, setInitalImage] = useState<number | undefined>(undefined);
  const isMobile = detectMobile();
  const [deployed, setDeployed] = useState(false);

  return (
    <>
      <div className="image-container">
        <ClientCarousel
          hideOtherPicture={hideOtherPicture}
          property={candidate.property}
          selectIndex={setInitalImage}
          showArrows={!isMobile && !deployed && showCarousel}
        />
        {
          enableFullScreenCarousel && initialImage !== undefined && (
            <CarrouselModal
              onClose={() => setInitalImage(undefined)}
              isOpen
              intitialSelectedImage={initialImage}
              property={candidate.property}
            />
          )
        }
        <div className="image-overlay">
          <div className="badge-container">
            {showNotation && (
              <ClientNotation
                candidate={candidate}
                editable={notationEditable}
                deployed={deployed}
                setDeployed={setDeployed}
              />
            )}
            {children}
          </div>
          {
            displayActions && !deployed && (
              <div className="actions-container">
                <CardActionButtons
                  onArchive={() => {
                    if (onArchive) {
                      onArchive();
                    }
                  }}
                  candidate={candidate}
                />
              </div>
            )
          }
        </div>
      </div>
      <PropertySummaryIcons candidate={candidate} />
      <div className="candidate-card-data-row">
        <div className="candidate-card-address">
          {
            !(displayViewing) && (
              <>
                <div>
                  <LocationMarkerIcon />
                </div>
                <span>
                  {
                    candidate.property.address
                      ? candidate.property.address
                      : `${StringUtils.getPrettyAddress(candidate.property.city)}, ${candidate.property.postal_code}`
                  }
                </span>
              </>
            )
          }
        </div>
        {
          !DateUtils.dateIsNullOtooOld(candidate.property.published_date) && (
            <div className="published-date">
              {DateUtils.getPrettyDateDeltaFromNow(candidate.property.published_date)}
            </div>
          )
        }
      </div>
      {
        viewingAppointement && displayViewing && (
          <div className="candidate-card-data-row">
            <div className="candidate-card-metting-address">
              <LocationMarkerIcon />
              <div>
                <div className="metting-address">{viewingAppointement[0]?.address || candidate.property.address}</div>
                <div>{getPrettyDate(viewingAppointement[0].date)}</div>
              </div>
            </div>
          </div>
        )
      }
      {moreInfo && <div>{moreInfo}</div>}
    </>
  );
}
