import React, { useState } from 'react';
import { Candidate } from 'types/candidate';
import Button from 'features/client/common/Button';
import { BookmarkIcon, TrashIcon } from '@heroicons/react/solid';
import { useCandidate } from 'network/api/CandidateQueries';
import ConfirmationModal from 'features/client/common/ConfirmationModal';
import { workflowStep } from 'types/WorkflowStep';
import { NotificationService } from 'services/notification';
import { workflowStepMetaData } from 'services/hunter/WorkflowStep';
import Messages from 'services/i18n/Messages';
import { activityType } from 'types/Activity';

type Props = {
  candidate: Candidate,
  onArchive: () => void
};

export default function CardActionButtons({ candidate, onArchive }: Props) {
  const { updateCandidateStep, updateHMPickCandidateStep } = useCandidate();

  const [trashModal, setTrashModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const updateIsFavortiteCandidate = () => {
    setSubmitting(true);
    if (candidate.workflow_step === workflowStep.NOT_SURE) {
      NotificationService.notifySuccess(Messages.t('toast.client.movedListing', { stepDisplayName: workflowStepMetaData(workflowStep.REVIEW).getClientDisplayName() }));
      updateCandidateStep.mutateAsync(
        {
          candidate,
          data: {
            is_client_update: true,
            workflow_step: workflowStep.REVIEW,
            workflow_step_changed_date: new Date(),
            activity_type: activityType.CLIENT,
            message_title: 'Client comment',
            message_content: 'The client moved this property to his review',
            client_notation: 0,
          },
        },
      ).then(() => {
        setSubmitting(false);
      });
    } else if (candidate.workflow_step === workflowStep.SENT) {
      NotificationService.notifySuccess(Messages.t('toast.client.movedListing', { stepDisplayName: workflowStepMetaData(workflowStep.NOT_SURE).getClientDisplayName() }));
      updateHMPickCandidateStep.mutateAsync(
        {
          candidate,
          data: {
            is_client_update: true,
            workflow_step: workflowStep.NOT_SURE,
            workflow_step_changed_date: new Date(),
            activity_type: activityType.CLIENT,
            message_title: 'Client comment',
            message_content: 'The client moved this property to his shortlist',
            client_notation: 0,
          },
        },
      ).then(() => {
        setSubmitting(false);
      });
    } else {
      NotificationService.notifySuccess(Messages.t('toast.client.movedListing', { stepDisplayName: workflowStepMetaData(workflowStep.NOT_SURE).getClientDisplayName() }));
      updateCandidateStep.mutateAsync(
        {
          candidate,
          data: {
            is_client_update: true,
            workflow_step: workflowStep.NOT_SURE,
            workflow_step_changed_date: new Date(),
            activity_type: activityType.CLIENT,
            message_title: 'Client comment',
            message_content: 'The client moved this property to his shortlist',
            client_notation: 0,
          },
        },
      ).then(() => {
        setSubmitting(false);
      });
    }
  };

  const confirmArchive = () => {
    NotificationService.notifySuccess(Messages.t('toast.client.movedListing', { stepDisplayName: workflowStepMetaData(workflowStep.NOT_INTERESTED).getClientDisplayName() }));
    updateCandidateStep.mutateAsync(
      {
        candidate,
        data: {
          is_client_update: true,
          workflow_step: workflowStep.NOT_INTERESTED,
          workflow_step_changed_date: new Date(),
          activity_type: activityType.CLIENT,
          message_title: 'Client comment',
          message_content: 'The property was trashed with the quick sort function. No comment can be left by the client',
          client_notation: 0,
        },
      },
      {
        onSuccess: () => {
          setTrashModal(false);
          onArchive();
        },
      },
    ).then(() => {
      setSubmitting(false);
    });
  };

  return (
    <>
      <ConfirmationModal
        isOpen={trashModal}
        onClose={() => setTrashModal(false)}
        onConfirm={confirmArchive}
        title={Messages.t('candidate.card.trash.title')}
        text={Messages.t('candidate.card.trash.text')}
      />
      <Button
        preventDefault
        disable={submitting}
        onClick={updateIsFavortiteCandidate}
        className={`heart-icon ${candidate.workflow_step === workflowStep.NOT_SURE ? 'favorite' : ''}`}
        variant="icon"
      >
        <BookmarkIcon />
      </Button>
      {
        candidate.workflow_step !== workflowStep.NOT_INTERESTED
        && candidate.workflow_step !== workflowStep.SENT && (
          <Button
            disable={submitting}
            preventDefault
            onClick={() => setTrashModal(true)}
            variant="icon"
          >
            <TrashIcon />
          </Button>
        )
      }
    </>
  );
}
