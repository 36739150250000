export const MapSource = {
  QUARTIER_PARIS: 'QUARTIER_PARIS',
  TRAIN_IDF: 'TRAIN_IDF',
  ISOCHRONE: (index) => `ISOCHRONE_${index}`,
  INTERSECTION_GRID: 'INTERSECTION_GRID',
  INTERSECTION_CONTOUR: 'INTERSECTION_CONTOUR',
};

export const MapLayer = {
  QUARTIER_PARIS: 'quartier_paris-layer',
  QUARTIER_PARIS_HIGHLIGHT: 'quartier_paris-highlight-layer',
  TRAIN_IDF: 'train-idf-layer',
  ISOCHRONE: (index) => `isochrone-layer_${index}`,
  INTERSECTION_GRID: 'intersection-grid-layer',
  INTERSECTION_CONTOUR: 'intersection-contour-layer',
};
