import React, { useState } from 'react';
import { usePropertiesContact } from 'network/api/PropertyQueries';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import Messages from 'services/i18n/Messages';
import { TextField } from '@material-ui/core';
import { fuzzysearch } from 'lib/FuzzySearch';
import { Routes } from 'routes/Routes';
import { CONTACT_ID, CONTACTS_DETAILS } from 'routes/HmRoutes';
import { useHistory } from 'react-router-dom';

export default function Contacts() {
  const { getAllContact } = usePropertiesContact();
  const history = useHistory();
  const [filter, setFilter] = useState<string>('');

  const { data: conctacts } = getAllContact();

  const columns: GridColDef[] = [
    {
      field: 'contact_type',
      headerName: Messages.t('form.field.contactType'),
      width: 150,
    },
    {
      field: 'agency_name',
      headerName: Messages.t('form.field.agency'),
      width: 150,
    },
    {
      field: 'fullName',
      headerName: Messages.t('research.list.fullName'),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => `${params.row.first_name || ''} ${
        params.row.last_name || ''}`,
    },
    {
      field: 'phone_number',
      headerName: Messages.t('form.field.phone'),
      width: 150,
    },
    {
      field: 'email',
      headerName: Messages.t('form.field.email'),
      width: 150,
    },
    {
      field: 'comment',
      headerName: Messages.t('form.field.comment'),
      width: 150,
    },
  ];
  return (
    <>
      <TextField
        className="search-contact"
        fullWidth
        type="text"
        variant="standard"
        placeholder="John Doe"
        label="Search"
        value={filter}
        onChange={(
          (event) => setFilter(event.target.value))}
      />
      <div className="data-grid-list">
        {
          conctacts && (
            <DataGrid
              onCellClick={(params) => {
                history.push(Routes.withPath(
                  CONTACTS_DETAILS,
                  [
                    { label: CONTACT_ID, value: params.id as string },
                  ],
                ));
              }}
              rows={conctacts.filter((contact) => {
                const name = `${contact.first_name} ${contact.last_name} ${contact.agency_name}`;
                return !filter || fuzzysearch(filter, name);
              })}
              getRowClassName={() => 'research-row'}
              columns={columns}
            />
          )
        }
        <div />
      </div>
    </>
  );
}
