import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { mapbox_access_token } from 'services/isochrone/mapbox/mapboxAccessToken';

mapboxgl.accessToken = mapbox_access_token;

export default function Map({ onMapReady }) {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      // @ts-ignore
      container: mapContainerRef.current,
      // See style options here: https://docs.mapbox.com/api/maps/#styles
      // style: "mapbox://styles/mapbox/streets-v11",
      style: 'mapbox://styles/vivien83/ckglmx7ux05fw19qt99tm3y0x',
      center: [2.3522, 48.8566],
      zoom: 12,
    });

    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    map.on('load', () => {
      onMapReady(map);
    });

    return () => map.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="mapboxgl-map map-container" ref={mapContainerRef} />;
}
