import React from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import {
  Checkbox,
} from '@material-ui/core';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  }, // TODO Better type
  values: { key: string, label: string }[],
  onChange?: (value: string) => void
};

export default function TimelineWrapper(
  {
    control,
    values,
    onChange,
  }: Props,
) {
  const { field } = control;

  const valuesWithData: { key: string, label: string, disabled: boolean, checked: boolean }[] = [];

  let checked = values.filter((val) => val.key === field.value).length > 0;

  let hasSeenTheValue = false;
  for (let i = 0; i < values.length; i++) {
    valuesWithData.push({ ...values[i], checked, disabled: hasSeenTheValue });
    if (!checked) {
      hasSeenTheValue = true;
    }
    if (values[i].key === field.value) {
      checked = false;
    }
  }

  return (
    <div>
      {
        valuesWithData.map((value) => (
          <div className="timeline-wrapper-element" key={value.key}>
            <div className="checkbox-container">
              <Checkbox
                disabled={value.disabled}
                checked={value.checked}
                onClick={() => {
                  field.onChange(value.key);
                  if (onChange) {
                    onChange(value.key);
                  }
                }}
              />
              <div className="checkbox-separator" />
            </div>
            <div className="timeline-label">
              {value.label}
            </div>
          </div>
        ))
      }
    </div>
  );
}
