import React, { useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/hunter-utils/SpinButton';

type Props<T> = {
  deleteConfirmation: T | null
  setDeleteConfirmation: (val: T | null) => void,
  onDeleteConfirmed: (val: T | null) => void,
  elementDisplayName: string,
};

export default function DeleteConfirmationDialog<T>(
  {
    deleteConfirmation,
    setDeleteConfirmation,
    onDeleteConfirmed,
    elementDisplayName,
  }: Props<T>,
) {
  const [submitting, setSubmitting] = useState(false);

  const handleClose = useCallback(() => {
    if (submitting) {
      return;
    }
    setDeleteConfirmation(null);
  }, [submitting, setDeleteConfirmation]);

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    await onDeleteConfirmed(deleteConfirmation);
    setSubmitting(false);
    setDeleteConfirmation(null);
  }, [deleteConfirmation, onDeleteConfirmed, setDeleteConfirmation]);

  return (
    <Modal show={!!deleteConfirmation} onHide={handleClose} centered>
      <Modal.Header closeButton={!submitting}>
        <Modal.Title>Delete confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {Messages.t('dialog.deleteConfirmation.body', { elementDisplayName })}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="info"
          disabled={submitting}
          onClick={handleClose}
        >
          {Messages.t('formButton.cancel')}
        </Button>
        <SpinButton
          editing
          variant="error"
          spin={submitting}
          onClick={handleDelete}
          title={Messages.t('formButton.delete')}
        />
      </Modal.Footer>
    </Modal>
  );
}
