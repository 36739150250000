import React, { useState } from 'react';
import { Candidate } from 'types/candidate';
import GoogleMap from 'features/googleMap/GoogleMap';
import GoogleCluster, { ClusterEvent } from 'features/googleMap/GoogleCluster';
import GoogleMarker from 'features/googleMap/GoogleMarker';
import GoogleOverlay from 'features/googleMap/GoogleOverlay';
import MapDesktopCard from 'features/client/pages/map/MapDesktopCard';
import { detectMobile } from 'services/utils';
import CardCarrousel from 'features/client/common/CardCarrousel';
import GoogleCircle from 'features/googleMap/GoogleCircle';
import { locationType } from 'types/property';
import Messages from 'services/i18n/Messages';

type Props = {
  candidates: Candidate[]
  selectedCandidateId?: string | null,
  setSelectedCandidateId?: (candidateId: string[]) => void,
  displayErrorMessage?: boolean,
};

const getCandidatePosition = (candidate: Candidate) => ({
  lng: candidate.property.longitude || candidate.property.fluximmo_raw_advert?.longitude || 0,
  lat: candidate.property.latitude || candidate.property.fluximmo_raw_advert?.latitude || 0,

});
export default function MapWrapper(
  {
    candidates,
    selectedCandidateId,
    setSelectedCandidateId,
    displayErrorMessage,
  }: Props,
) {
  const [
    clusterSelectedCandidateList,
    setClusterSelectedCandidateList,
  ] = useState<ClusterEvent | null>(null);
  const filteredCandidate = candidates.filter((candidate) => {
    const coordinates = getCandidatePosition(candidate);
    return !(coordinates.lng === 0 || coordinates.lat === 0);
  });

  if (filteredCandidate.length === 0) {
    if (displayErrorMessage) {
      return (
        <div className="error-message">
          {Messages.t('map.unableToLocateTracked')}
        </div>
      );
    }
    return null;
  }

  const filteredCandidatesTooltip = (clusterSelectedCandidateList
    && candidates.filter((candidate) => clusterSelectedCandidateList
      .markerIds.includes(candidate.id.toString()))) || [];

  const isMobile = detectMobile();

  return (
    <GoogleMap
      center={getCandidatePosition(filteredCandidate[0])}
      zoom={15}
    >
      <GoogleCluster
        onClick={(cluster) => {
          if (isMobile && setSelectedCandidateId) {
            setSelectedCandidateId(cluster.markerIds.map((str) => str));
          } else {
            setClusterSelectedCandidateList(cluster);
          }
        }}
      >
        {
          filteredCandidate.map((candidate) => (
            <React.Fragment key={candidate.id}>
              <GoogleMarker
                id={candidate.id.toString()}
                onClick={
                  setSelectedCandidateId
                    ? () => setSelectedCandidateId([candidate.id])
                    : undefined
                }
                coordinates={getCandidatePosition(candidate)}
                className={`map-marker ${selectedCandidateId === candidate.id ? 'selected' : ''}`}
                text={`${candidate.property.price} €`}
              />
              {
                selectedCandidateId === candidate.id
                && candidate.property.location_type !== locationType.ROOFTOP
                && (
                  <GoogleCircle
                    option={{
                      strokeWeight: 0,
                      fillColor: '#ED8936',
                      fillOpacity: 0.4,
                    }}
                    coordinates={{
                      lng: getCandidatePosition(candidate).lng,
                      lat: getCandidatePosition(candidate).lat,
                    }}
                    radius={100}
                  />
                )
              }
            </React.Fragment>
          ))
        }
        {
          !isMobile && (
            <GoogleOverlay
              onClose={() => setClusterSelectedCandidateList(null)}
              coordinates={clusterSelectedCandidateList?.center}
              isOpen={!!clusterSelectedCandidateList}
            >
              <div className="carrousel-container">
                <CardCarrousel listLength={filteredCandidatesTooltip.length}>
                  {
                    filteredCandidatesTooltip
                      .map((candidate) => (
                        <MapDesktopCard
                          key={candidate.id}
                          onClick={
                            setSelectedCandidateId
                              ? () => setSelectedCandidateId([candidate.id])
                              : undefined
                          }
                          candidate={candidate}
                        />
                      ))
                  }
                </CardCarrousel>
              </div>
            </GoogleOverlay>
          )
        }
      </GoogleCluster>
    </GoogleMap>
  );
}
