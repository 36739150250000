import React, { ReactNode, useCallback } from 'react';
import withMap from 'features/googleMap/Withmap';
import createHtmlMapOverlay from 'features/googleMap/HtmlMapOverlay';
import { Coordinates } from 'features/googleMap/GoogleMap';
/* global google */

type Props = {
  center: Coordinates,
  map: google.maps.Map,
  children: ReactNode,
};

function GoogleDrawOverlay(
  {
    map,
    center,
    children,
  }: Props,
) {
  const ref = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      createHtmlMapOverlay({ map, latlng: new google.maps.LatLng(center), div: node });
    }
  }, []);

  return (
    <div ref={ref}>
      {children}
    </div>
  );
}

export default withMap(GoogleDrawOverlay);
