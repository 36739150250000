import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useComment } from 'network/api/CommentQueries';
import Messages from 'services/i18n/Messages';
import { Candidate } from 'types/candidate';
import { CommentType } from 'types/Comment';
import SpinButton from 'theme/hunter-utils/SpinButton';

type Props = {
  candidate: Candidate,
  onCommentEdited: () => void,
  author: string,
  comment: CommentType,
};

export default function EditCommentForm(
  {
    candidate,
    onCommentEdited,
    author,
    comment,
  }: Props,
) {
  const { updateComment } = useComment();
  const [submitting, setSubmitting] = useState(false);
  const [editedMessage, setEditedMessage] = useState(comment.message);

  const handleSubmit = async () => {
    setSubmitting(true);
    await updateComment.mutateAsync({
      id: comment.id,
      author,
      message: editedMessage,
      workflow_step: candidate.workflow_step,
      edited: true,
    });
    setSubmitting(false);
    setEditedMessage('');
    onCommentEdited();
  };

  return (
    <>
      <Form.Row>
        <Col xs={12}>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={4}
              defaultValue={editedMessage}
              onChange={(event) => setEditedMessage(event.target.value)}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <div className="float-right">
        <SpinButton
          editing
          spin={submitting}
          title={Messages.t('formButton.send')}
          type="button"
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}
