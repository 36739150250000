import React, { useState } from 'react';
import RecursiveTraduction from 'features/hunter/pages/traductions/RecursiveTraduction';
import Messages, { DEFAULT_LANG } from 'services/i18n/Messages';
import { TextField } from '@material-ui/core';

export default function Traductions() {
  const [search, setSearch] = useState('');
  const traduction = Messages.getTranslations().get(DEFAULT_LANG);
  if (!traduction) {
    return null;
  }

  const getTree = () => {
    const rootPointer: any = {};
    const defaultMessage = Messages.getTranslations().get(DEFAULT_LANG)?.messages || {};
    Object.keys(defaultMessage).forEach((key) => {
      let currentTreePointer = rootPointer;
      if (search
        && !key.toLowerCase().includes(search.toLowerCase())
        && !defaultMessage[key].toLowerCase().includes(search.toLowerCase())) {
        return;
      }
      const keyList = key.split('.');
      for (let i = 0; i < keyList.length; i++) {
        if (i === keyList.length - 1) {
          if (!currentTreePointer.inline) {
            currentTreePointer.inline = [];
          }
          currentTreePointer.inline.push(keyList[i]);
          break;
        }
        if (!currentTreePointer[keyList[i]]) {
          currentTreePointer[keyList[i]] = {};
        }
        currentTreePointer = currentTreePointer[keyList[i]];
      }
    });
    return rootPointer;
  };

  return (
    <div className="traduction">
      <TextField
        fullWidth
        variant="standard"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        label="Search"
      />
      <RecursiveTraduction
        depth={1}
        higherKey=""
        traduction={getTree()}
        displayName={Messages.t('traduction.title')}
      />
    </div>
  );
}
