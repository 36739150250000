import React from 'react';
import { useUser } from 'reactfire';
import Messages from 'services/i18n/Messages';
import Chat from 'features/common/chat/Chat';
import { RESEARCH_TAB, TAB_ID } from 'routes/HmRoutes';
import { workflowStep } from 'types/WorkflowStep';

export default function ChatRouter() {
  const { data: user } = useUser();
  const currentUser = user && {
    id: user.email,
    name: user.email,
    email: user.email,
    photoUrl: user.photoURL,
    role: 'matcher_inbox',
  };

  return (
    <div className="chat-page">
      <div>
        <h2>
          {Messages.t('chat.title')}
        </h2>
      </div>
      <div className="chat-page__wrapper">
        <Chat
          hasCustomRedirect={{
            route: RESEARCH_TAB,
            type: 'withPath',
            params: [
              {
                label: TAB_ID,
                value: workflowStep.REVIEW.toLowerCase(),
              },
            ],
          }}
          currentUser={currentUser}
          isInbox
        />
      </div>
    </div>
  );
}
