import React from 'react';
import { Route, Switch } from 'react-router-dom';
import IsochroneMap from 'features/isochroneMap/IsochroneMap';
import ResearchList from 'features/hunter/pages/research/researchList/ResearchList';
import {
  DEFAULT,
  ISOCHRONE,
  RESEARCH,
  TRADUCTIONS,
  TASKS,
  CONTACTS_LIST,
  CHAT,
} from 'routes/HmRoutes';
import NotFound from 'features/NotFound';
import { onMount } from 'lib/alias/useEffectAlias';
import Tasks from 'features/hunter/pages/tasks/Tasks';
import { useUser } from 'reactfire';
import Traductions from 'features/hunter/pages/traductions/Traductions';
import ContactsRouter from 'features/hunter/pages/contacts/ContactsRouter';
import ResearchRouter from 'features/hunter/pages/research/ResearchRouter';
import ChatRouter from 'features/hunter/pages/chat/ChatRouter';

export default function MainRouter() {
  onMount(() => {
    document.title = 'Hunter - Home matcher';
  });
  const { data: user } = useUser();

  if (!user || (!user.email?.endsWith('settlesweet.com') && !user.email?.endsWith('nextories.com'))) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={DEFAULT} component={ResearchList} />
      <Route path={ISOCHRONE} component={IsochroneMap} />
      <Route path={RESEARCH} component={ResearchRouter} />
      <Route path={TRADUCTIONS} component={Traductions} />
      <Route path={TASKS} component={Tasks} />
      <Route path={CONTACTS_LIST} component={ContactsRouter} />
      <Route path={CHAT} component={ChatRouter} />
      <Route component={NotFound} />
    </Switch>
  );
}
