import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ActivityForm from 'features/hunter/pages/research/researchDetails/candidateDetails/actionBar/ActivityForm';
import { Candidate } from 'types/candidate';

type Props = {
  candidate: Candidate,
  onClose: () => void
};

export default function ActivityAction(
  {
    candidate,
    onClose,
  }: Props,
) {
  return (
    <div className="action-modal-container">
      <div className="action-modal-header">
        <h6>
          Add Activity
        </h6>
        <IconButton color="inherit" onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <ActivityForm candidate={candidate} onClose={onClose} />
    </div>
  );
}
