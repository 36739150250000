function checkFeature(feature) {
  if (feature.type !== 'Feature') {
    throw Error('Must be a geojson Feature or an Array of features');
  }
}

export const makeGeojson = (features) => {
  if (!Array.isArray(features)) {
    features = [features];
  }
  features.forEach(checkFeature);
  return {
    type: 'FeatureCollection',
    features,
  };
};

export const EMPTY_GEOJSON = makeGeojson([]);
