import { PostSearch, PostSearchForm } from 'types/postSearch/PostSearch';
import { garantorStatusByType } from 'types/postSearch/Garantor';
import { insuranceStatusTypeEnum } from 'types/postSearch/Insurance';
import { papernestStatusTypeEnum } from 'types/postSearch/Papernest';
import { electricityStatusTypeEnum } from 'types/postSearch/Electricity';
import { gasStatusTypeEnum } from 'types/postSearch/Gas';
import { internetStatusTypeEnum } from 'types/postSearch/Internet';
import { giftStatusTypeEnum, giftTypeEnum } from 'types/postSearch/Gift';
import {
  onlineStatusTypeEnum,
  signatureOrganisationTypeEnum,
  signatureTypeEnum,
} from 'types/postSearch/Signature';

export default class PostSearchUtils {
  static isGarantorChecked(postSearch: PostSearchForm | PostSearch): boolean {
    const statusList = garantorStatusByType[postSearch.garantor_kind || ''];
    if (!statusList) {
      return false;
    }
    return statusList.length === 0
      || statusList[statusList.length - 1] === postSearch.garantor_status;
  }

  static isFinancialChecked(postSearch: PostSearchForm | PostSearch): boolean {
    return !!postSearch.financial_checked;
  }

  static isSignatureChecked(postSearch: PostSearchForm | PostSearch): boolean {
    if (postSearch.signature_kind === signatureTypeEnum.PHYSICAL) {
      return !!postSearch.signature_checked;
    }
    if (postSearch.signature_organisation_kind
      === signatureOrganisationTypeEnum.ORGANISED_BY_AGENCY) {
      return true;
    }
    const statusList = Object.values(onlineStatusTypeEnum);
    return statusList.length === 0
      || statusList[statusList.length - 1] === postSearch.signature_status;
  }

  static isRentalChecked(postSearch: PostSearchForm | PostSearch): boolean {
    return !!postSearch.rental_checked;
  }

  static isInsuranceChecked(postSearch: PostSearchForm | PostSearch): boolean {
    const statusList = Object.values(insuranceStatusTypeEnum);
    return statusList.length === 0
      || statusList[statusList.length - 1] === postSearch.inssurance_status;
  }

  static isPapernestChecked(postSearch: PostSearchForm | PostSearch): boolean {
    if (typeof postSearch.use_papernest === 'boolean' && !postSearch.use_papernest) {
      return true;
    }
    if (typeof postSearch.use_papernest === 'string' && postSearch.use_papernest !== 'true') {
      return true;
    }
    const statusList = Object.values(papernestStatusTypeEnum);
    return statusList.length === 0
      || statusList[statusList.length - 1] === postSearch.papernest_status;
  }

  static isElectricityChecked(postSearch: PostSearchForm | PostSearch): boolean {
    const statusList = Object.values(electricityStatusTypeEnum);
    return statusList.length === 0
      || statusList[statusList.length - 1] === postSearch.eletricity_status;
  }

  static isGasChecked(postSearch: PostSearchForm | PostSearch): boolean {
    if (typeof postSearch.use_gas === 'boolean' && !postSearch.use_gas) {
      return true;
    }
    if (typeof postSearch.use_gas === 'string' && postSearch.use_gas !== 'true') {
      return true;
    }
    const statusList = Object.values(gasStatusTypeEnum);
    return statusList.length === 0
      || statusList[statusList.length - 1] === postSearch.gas_status;
  }

  static isInternetChecked(postSearch: PostSearchForm | PostSearch): boolean {
    const statusList = Object.values(internetStatusTypeEnum);
    return statusList.length === 0
      || statusList[statusList.length - 1] === postSearch.internet_status;
  }

  static isGiftChecked(postSearch: PostSearchForm | PostSearch): boolean {
    if (postSearch.client_gift === giftTypeEnum.NO_NEWS) {
      return true;
    }
    const statusList = Object.values(giftStatusTypeEnum);
    return statusList.length === 0
      || statusList[statusList.length - 1] === postSearch.gift_status;
  }

  static isVoucherChecked(postSearch: PostSearchForm | PostSearch): boolean {
    if (typeof postSearch.use_voucher === 'boolean' && !postSearch.use_voucher) {
      return true;
    }
    if (typeof postSearch.use_voucher === 'string' && postSearch.use_voucher !== 'true') {
      return true;
    }
    return !!postSearch.voucher_sent;
  }

  static getProgression(progressionTab: boolean[]): number {
    return (progressionTab.reduce((acc, val) => {
      if (val) {
        return acc + 1;
      }
      return acc;
    }, 0) / progressionTab.length) * 100;
  }
}
