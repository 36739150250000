import { TargomoClient } from '@targomo/core';
import { toWgs84 } from '@turf/projection';

export default class TargomoIsochroneService {
  isochrone_geojson({ arrival_point, travel_mode, travel_times }) {
    const sources = [{ id: 1, lat: arrival_point[1], lng: arrival_point[0] }];

    const client = new TargomoClient(
      'westcentraleurope',
      'JVCTM39YEM7C6972SNJZ370468911',
    );

    const options = {
      travelType: travel_mode.targomo,
      travelEdgeWeights: travel_times,
      serializer: 'geojson',
    };

    // @ts-ignore
    return client.polygons.fetch(sources, options).then((json) =>
      /*
        convert: ( source: https://epsg.io/transform#s_srs=3857&t_srs=4326 )
        EPSG:3857 WGS 84 / Pseudo-Mercator
        to
        EPSG:4326 WGS 84
        With Mapbox's mapping libraries like Mapbox GL JS and Mapbox GL Native,
        the maps are visually rendered in the Web Mercator Projection (EPSG:3857),
        however anytime you want to pass data to show on these maps,
        either as a Marker or GeoJSON layer then that data must be passed as WGS84 LL, ie. EPSG:4326.
        */
      toWgs84(json, {
        mutate: true,
      }));
  }
}
