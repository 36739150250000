import React, { useState } from 'react';
import { Candidate } from 'types/candidate';
import EditCommentForm from 'features/hunter/pages/research/researchDetails/candidateDetails/comments/EditCommentForm';
import { CommentType } from 'types/Comment';
import CommentMenu from 'features/client/pages/candidateDetails/comments/CommentMenu';
import { useComment } from 'network/api/CommentQueries';
import { CircularProgress } from '@material-ui/core';
import DateUtils from 'services/DateUtils';
import { Research } from 'types/research';

type Props = {
  candidate: Candidate,
  comment: CommentType,
  research: Research,
};

export default function CommentCard({ candidate, comment, research }: Props) {
  const { deleteComment } = useComment();
  const [editMode, setEditMode] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDelete = () => {
    setDeleting(true);
    deleteComment.mutate(comment, {
      onSuccess: () => {
        setDeleting(false);
      },
      onError: () => {
        setDeleting(false);
      },
    });
  };

  const authorName = comment.author === 'HOME_MATCHER' ? 'Home Matcher' : `${research.client_first_name} ${research.client_last_name}`;
  const authorInital = authorName.split(' ').map((name) => name.substr(0, 1).toUpperCase()).join('');
  return (
    <div className="comment-card">
      <div className="comment-initials">
        {authorInital}
      </div>
      <div className="comment-content">
        <div className="comment-name">
          <div>
            {authorName}
          </div>
          {
            comment.author !== 'HOME_MATCHER' && (
              <CommentMenu
                onEditClick={() => setEditMode(true)}
                onDeleteClick={handleDelete}
              />
            )
          }
        </div>
        <div>
          {
            editMode ? (
              <EditCommentForm
                candidate={candidate}
                onCommentEdited={() => {
                  setEditMode(false);
                }}
                author="CLIENT"
                comment={comment}
              />
            ) : deleting ? (
              <CircularProgress size={15} />
            ) : (
              <p className="comment-text">{comment.message}</p>
            )
          }
        </div>
        <div className="comment-date">
          {DateUtils.getPrettyDateDeltaFromNow(comment.updated_at)}
        </div>
      </div>
    </div>
  );
}
