import React from 'react';
import { Candidate } from 'types/candidate';
import { Research } from 'types/research';
import ResultCardContent from 'features/common/ResultCardContent';
import { useObservable } from 'micro-observables';
import candidateFilterService, {
  CandidateFilterService,
  possibleFilter,
  PossibleFilter,
} from 'services/CandidateFilterService';
import Messages from 'services/i18n/Messages';
import { activityType } from 'types/Activity';
import { AccessAlarm, Warning } from '@material-ui/icons';
import { showNotationDependingWorkflowStep } from 'services/hunter/WorkflowStep';

type Props = {
  candidate: Candidate,
  className: string,
  research: Research,
  handleNavigation: (e: React.MouseEvent<HTMLDivElement>) => void,
};

export default function ResultCard(
  {
    candidate,
    className,
    handleNavigation,
    research,
  }: Props,
) {
  const sort = useObservable(candidateFilterService.getSortObservable(candidate.workflow_step));
  const filter = useObservable(candidateFilterService.getFilterObservable(candidate.workflow_step));

  const field: PossibleFilter = {};
  field[sort.field] = possibleFilter[sort.field];
  filter.criterias.forEach((criteria) => {
    if (criteria.field && criteria.operand && criteria.value) {
      field[criteria.field] = possibleFilter[criteria.field];
    }
  });

  const filterdFieldHidden = [
    'property.title',
    'property.postal_code',
    'property.bedrooms',
    'property.published_date',
    'property.price',
    'property.available',
    'viewed',
    'property.source_website',
    'client_identifier',
    'property.area',
    'workflow_step_changed_date',
  ];

  const moreInfo = field && filterdFieldHidden && Object.values(field)
    .filter((filterItem) => !filterdFieldHidden.includes(filterItem.field))
    .map((filterItem) => (
      `${Messages.t(filterItem.label)}: ${CandidateFilterService.getValue(candidate, filterItem.field)}`
    ))
    .join(' | ');

  const showNotation = showNotationDependingWorkflowStep(candidate);

  return (
    <div
      role="presentation"
      data-candidateid={candidate.id}
      className={`candidate-card ${className}`}
      onClick={(event) => {
        handleNavigation(event);
      }}
    >
      <ResultCardContent
        hideOtherPicture
        candidate={candidate}
        moreInfo={moreInfo}
        showNotation={showNotation && !!candidate?.client_notation}
      >
        <>
          {candidate.client_identifier && (
            <div>
              <div className="image-badge number">
                #{candidate.client_identifier}
              </div>
            </div>
          )}
          {candidate.viewed && (
            <div>
              <div className="image-badge success">
                {Messages.t('candidate.card.badge.alreadySeen')}
              </div>
            </div>
          )}
          {candidate.property.gli_asked && (
            <div>
              <div className="image-badge warning">
                {Messages.t('candidate.card.badge.gli')}
              </div>
            </div>
          )}
          {candidate.property.source && (
            <div>
              <div className="image-badge info">
                {candidate.property.source}
              </div>
            </div>
          )}
          {candidate.property.is_scam && (
            <div>
              <div className="image-badge danger">
                {Messages.t('candidate.card.badge.isScam')}
              </div>
            </div>
          )}
          {
            candidate.property.original_price !== candidate.property.price
            && candidate.property.original_price && (
              <div>
                <div className="image-badge warning">
                  {
                    candidate.property.original_price > candidate.property.price
                      ? Messages.t('candidate.card.badge.priceDrop')
                      : Messages.t('candidate.card.badge.priceGrow')
                  }
                </div>
              </div>
            )
          }
          {!candidate.property.available && (
            <div>
              <div className="image-badge danger">
                {Messages.t('candidate.card.badge.expired')}
              </div>
            </div>
          )}
          {
            research.price_max_search && candidate.property.price > research.price_max_search && (
              <div>
                <div className="image-badge warning">
                  {Messages.t('candidate.card.badge.overBudget')}
                </div>
              </div>
            )
          }
          {
            candidate.added_by_client && (
              <div>
                <div className="image-badge warning">
                  {Messages.t('candidate.card.badge.added_by_client')}
                </div>
              </div>
            )
          }
          {
            candidate.activities
            && candidate.activities
              .filter(
                (activity) => activity.activity_type === activityType.TASK && !activity.task_done,
              )
              .map((activity) => (
                <div>
                  <div className={`image-badge ${new Date(activity.date).getTime() < Date.now() ? 'danger' : 'dark'}`}>
                    {new Date(activity.date).getTime() < Date.now() ? <Warning /> : <AccessAlarm />}
                    {activity.task_type}
                  </div>
                </div>
              ))
          }
        </>
      </ResultCardContent>
    </div>
  );
}
