import { Enum } from 'lib/type/enum';

export type SignatureType = 'ONLINE' | 'PHYSICAL';

export const signatureTypeEnum: Enum<SignatureType> = {
  ONLINE: 'ONLINE',
  PHYSICAL: 'PHYSICAL',
};

export type MeetingType = 'AGENCY' | 'PROPERTY';

export const meetingTypeEnum: Enum<MeetingType> = {
  AGENCY: 'AGENCY',
  PROPERTY: 'PROPERTY',
};

export type SignatureOrganisationType =
  'PERSON'
  | 'ACCOMPAGNIED'
  | 'ON_BEHALF'
  | 'ORGANISED_BY_SWT'
  | 'ORGANISED_BY_AGENCY';

export const signatureOrganisationTypeEnum: Enum<SignatureOrganisationType> = {
  PERSON: 'PERSON',
  ACCOMPAGNIED: 'ACCOMPAGNIED',
  ON_BEHALF: 'ON_BEHALF',
  ORGANISED_BY_SWT: 'ORGANISED_BY_SWT',
  ORGANISED_BY_AGENCY: 'ORGANISED_BY_AGENCY',
};

export const signatureOrganisationByType: {
  [key in SignatureType]: SignatureOrganisationType[]
} = {
  ONLINE: [
    signatureOrganisationTypeEnum.ORGANISED_BY_SWT,
    signatureOrganisationTypeEnum.ORGANISED_BY_AGENCY,
  ],
  PHYSICAL: [
    signatureOrganisationTypeEnum.PERSON,
    signatureOrganisationTypeEnum.ACCOMPAGNIED,
    signatureOrganisationTypeEnum.ON_BEHALF,
  ],
};

export type ProxyStatusType =
  'LETTER_PREPARED'
  | 'LETTER_SENT'
  | 'LETTER_SIGNED';

export const proxyStatusTypeEnum: Enum<ProxyStatusType> = {
  LETTER_PREPARED: 'LETTER_PREPARED',
  LETTER_SENT: 'LETTER_SENT',
  LETTER_SIGNED: 'LETTER_SIGNED',
};

export type OnlineStatusType =
  'ONLINE_SIGN_PREP'
  | 'SIGN_BY_CLIENT'
  | 'SIGN_BY_COUNTERPARTY';

export const onlineStatusTypeEnum: Enum<OnlineStatusType> = {
  ONLINE_SIGN_PREP: 'ONLINE_SIGN_PREP',
  SIGN_BY_CLIENT: 'SIGN_BY_CLIENT',
  SIGN_BY_COUNTERPARTY: 'SIGN_BY_COUNTERPARTY',
};
