import React from 'react';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Controller, useForm } from 'react-hook-form';
import { Button, Popover } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import SelectWrapper from 'lib/form/SelectWrapper';
import {
  CriteriaPartial,
  criteriaPossibleValues,
  criteriaType,
  criteriaTypeValue,
  editorType,
} from 'types/Criteria';
import SliderWrapper from 'lib/form/SliderWrapper';
import SpinButton from 'theme/hunter-utils/SpinButton';

type Props = {
  onAdd: (criteria: CriteriaPartial) => void
};

export default function AddCriteriaForm(
  { onAdd }: Props,
) {
  const {
    control,
    watch,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm<CriteriaPartial>({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const formField = watch();

  return (
    <>
      <Button
        onClick={handleClick}
      >
        ADD
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >

        <div className="criteria-container">
          <div>
            <div className="row-form">
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <SelectWrapper
                    error={errors}
                    control={controller}
                    onClick={() => {
                      setValue('value', undefined);
                      setValue('criteria', undefined);
                    }}
                    requierd
                    label={Messages.t('form.field.type')}
                    values={Object.values(criteriaType)
                      .map((key) => (
                        { key, label: Messages.t(`research.criterias.type.${key}`) }
                      ))}
                  />
                )}
              />

              <Controller
                name="criteria"
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <SelectWrapper
                    error={errors}
                    control={controller}
                    onChange={(value) => setValue('value', criteriaPossibleValues[value || '']?.defaultValue)}
                    requierd
                    label={Messages.t('form.field.criteria')}
                    values={(criteriaTypeValue[formField.type || ''] || [])
                      .map((key) => (
                        { key, label: Messages.t(`research.criterias.value.${key}`) }
                      ))}
                  />
                )}
              />
              <Controller
                name="value"
                control={control}
                rules={{ required: true }}
                render={(controller) => {
                  const possibleValues = criteriaPossibleValues[formField.criteria || ''];
                  if (!possibleValues) {
                    return (
                      <TextFieldWrapper
                        error={errors}
                        control={controller}
                        disabled
                        label={Messages.t('research.criterias.pleaseSelectValue')}
                      />
                    );
                  }
                  if (possibleValues.editor === editorType.TEXTFIELD) {
                    return (
                      <TextFieldWrapper
                        error={errors}
                        control={controller}
                        disabled={!possibleValues.editable}
                        requierd
                        suffix={possibleValues.postfix && Messages.t(`research.criterias.${possibleValues.postfix}`)}
                        type={possibleValues.onlyNumbers ? 'number' : 'text'}
                        label={Messages.t('form.field.value')}
                      />
                    );
                  }
                  return (
                    <SelectWrapper
                      error={errors}
                      control={controller}
                      requierd
                      label={Messages.t('form.field.value')}
                      values={possibleValues.posibleValue
                        .map((key) => (
                          { key, label: Messages.t(`research.criterias.enum.${key}`) }
                        ))}
                    />
                  );
                }}
              />
              <Controller
                name="weight"
                control={control}
                rules={{ required: true }}
                defaultValue="3"
                render={(controller) => (
                  <SliderWrapper
                    control={controller}
                    requierd
                    min={1}
                    max={5}
                    label={Messages.t('form.field.weight')}
                    marks={[{ value: 1, label: 'Not important' }, { value: 5, label: 'Important' }]}
                  />
                )}
              />
              <SpinButton
                onClick={async () => {
                  const res = await trigger();
                  if (res) {
                    onAdd(formField);
                    reset();
                    handleClose();
                  }
                }}
                editing={false}
                type="button"
                spin={false}
                variant="primary"
                className="mb-2"
              />
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}
