import React from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

export default function Firework() {
  const canvasStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  };
  const makeShot = (instance, particleRatio, opts) => {
    // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
    instance && instance({
      ...opts,
      origin: { y: 0.7 },
      particleCount: Math.floor(200 * particleRatio),
    });
  };

  const fire = (instance) => {
    makeShot(instance, 0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(instance, 0.2, {
      spread: 60,
    });

    makeShot(instance, 0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(instance, 0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(instance, 0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  return (
    // @ts-ignore*
    <ReactCanvasConfetti refConfetti={fire} style={canvasStyles} />
  );
}
