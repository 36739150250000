import React, { useState } from 'react';
import { useUser } from 'reactfire';

import { Link, useLocation } from 'react-router-dom';
import {
  RESEARCH_TAB,
  TAB_ID,
} from 'routes/HmRoutes';

import { useResearch } from 'network/api/ResearchQueries';
import { Research, researchStatus } from 'types/research';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import { workflowStep } from 'types/WorkflowStep';
import { useUserBackend } from 'network/api/UserQueries';
import { ReactSortable } from 'react-sortablejs';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';

export default function MySearchList() {
  const { data: user } = useUser();
  const location = useLocation();
  const { getHomeMatcherResearch, updateSearchSort } = useResearch();
  const [searchList, setSearchList] = useState<Research[] | null>(null);
  getHomeMatcherResearch(user?.email, (data: Research[]) => {
    setSearchList(data.sort((a, b) => (a.sort_order || 0) - (b.sort_order || 0)));
  }, !!user && !!user.email);
  const { getUser } = useUserBackend();
  const { data: users } = getUser(user.email, !!user.email);

  const updateSearchList = (list: Research[]) => {
    updateSearchSort.mutate(list.map((search, index) => ({ id: search.id, sort_order: index })), {
      onSuccess: () => {
        NotificationService.notifySuccess(Messages.t('toast.hunter.reasearchUpdated'));
      },
      onError: () => {
        NotificationService.notifyError(Messages.t('toast.hunter.error'));
      },
    });
  };

  return (
    <div className="search-list">
      {
        searchList && (
          <ReactSortable
            list={searchList}
            animation={200}
            setList={setSearchList}
            onEnd={() => updateSearchList(searchList)}
          >
            {
              searchList
                .map((research: Research) => {
                  const url = Routes.withPath(RESEARCH_TAB, [
                    { label: RESEARCH_ID, value: research.id },
                    {
                      label: TAB_ID,
                      value: research.status === researchStatus.POST_SEARCH
                        ? workflowStep.AGREEMENT.toLowerCase()
                        : workflowStep.REVIEW.toLowerCase(),
                    },
                  ]);
                  const diffCount = research.candidate_count
                    && (research.candidate_count - (
                      (users && users[research.id])
                        ? users[research.id].research_last_count
                        : 0));
                  return (
                    <Link
                      key={research.id}
                      to={url}
                      className={`nav-bar-item ${location.pathname.startsWith(url.split('/').slice(0, url.split('/').length - 1).join('/')) ? 'selected' : ''}`}
                    >
                      <div className="search-icon">
                        <div>
                          {research.client_first_name.substr(0, 1)}
                          {research.client_last_name.substr(0, 1)}
                        </div>
                      </div>
                      <span className="client-name">{research.client_first_name} {research.client_last_name}</span>
                      {
                        users
                        && research.status === researchStatus.IN_PROGRESS
                        && diffCount !== 0 && (
                          <div className="candidate-counter-container">
                            <span className={`new-candidate-counter ${diffCount === 0 ? 'no-new-candidate' : ''}`}>
                              +{diffCount}
                            </span>
                          </div>
                        )
                      }
                    </Link>
                  );
                })
            }
          </ReactSortable>
        )
      }
    </div>
  );
}
