import React, { ReactNode, useEffect, useState } from 'react';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import withMap from 'features/googleMap/Withmap';
import { Cluster } from '@googlemaps/markerclustererplus/dist/cluster';
/* global google */

export type ClusterEvent = {
  center: google.maps.LatLng
  markerIds: string[],
};

type Props = {
  children: ReactNode,
  map: google.maps.Map,
  onClick?: (clusterEvent: ClusterEvent) => void,
};

export const ClusterContext = React.createContext<MarkerClusterer | null>(null);

function GoogleCluster({ children, map, onClick }: Props) {
  const [cluster, setCluster] = useState<MarkerClusterer | null>(null);

  useEffect(() => {
    const markerCluster = new MarkerClusterer(map, [], {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    });
    setCluster(markerCluster);
    map.addListener('zoom_changed', () => {
      const zoom = map.getZoom();
      markerCluster.setZoomOnClick(zoom !== undefined && zoom <= 17);
    });
    google.maps.event.addListener(markerCluster, 'clusterclick', (eventCluster: Cluster) => {
      const zoom = eventCluster.getMap().getZoom();
      if (onClick && zoom !== undefined && zoom > 17) {
        onClick({
          center: eventCluster.getCenter(),
          markerIds: eventCluster
            .getMarkers().filter((marker) => !!marker.getTitle()).map((marker) => marker.getTitle() || ''),
        });
      }
    });
  }, []);

  return (
    <ClusterContext.Provider value={cluster}>
      {cluster && children}
    </ClusterContext.Provider>
  );
}

export default withMap(GoogleCluster);
