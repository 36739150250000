import qs from 'qs';
import { TravelMode, MaxTravelTime, defaultTravelOptions } from 'services/isochrone/TravelOptions';
import { defaultTravelConstraints } from 'services/isochrone/TravelConstraint';
import { defaultLocation } from 'services/isochrone/location';

export function filterUndefined(obj) {
  return Object.keys(obj || {}).reduce((acc, key) => {
    if (obj[key] !== undefined) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export function copyWithDefault() {
  const filtered_arguments = Array.from(arguments).map(filterUndefined);
  return Object.assign({}, ...filtered_arguments);
}

function parse_search(search) {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
    strictNullHandling: true,
    // arrayLimit: 0
  });
}

export function buildTravelConstraints(search) {
  const search_obj = parse_search(search);

  const search_tc_array = search_obj.tc || [];

  return defaultTravelConstraints.map((default_tc, index) => {
    const search_tc = search_tc_array[index] || {};
    // @ts-ignore
    return copyWithDefault(default_tc, {
      // @ts-ignore
      travel_options: copyWithDefault(defaultTravelOptions, {
        travel_mode: TravelMode[search_tc.travel_mode],
        max_travel_time: MaxTravelTime[search_tc.max_travel_time],
      }),
      // @ts-ignore
      location: copyWithDefault(defaultLocation, {
        address: search_tc.address,
        geocoded_coordinates: search_tc.geocoded_coordinates,
      }),
    });
  });
}

export function buildDistrictSelection(search, availableDistricts) {
  const search_obj = parse_search(search);
  const diff_districts = search_obj.diff_districts || '[]';
  const result = JSON.parse(diff_districts)
    .map((search_district_id) => parseInt(search_district_id))
    .map((search_district_id) => availableDistricts.find(
      (district) => district.id === search_district_id,
    ))
    .filter((d) => d !== undefined);
  return result;
}

export function stringifyState(travelConstraints, districtsSelection) {
  const tc = travelConstraints.map((tc) => ({
    travel_mode: tc.travel_options.travel_mode.name,
    max_travel_time: tc.travel_options.max_travel_time.name,
    address: tc.location.address === '' ? null : tc.location.address,
    geocoded_coordinates: tc.location.geocoded_coordinates,
  }));

  const diff_districts = districtsSelection.length === 0
    ? undefined
    : JSON.stringify(districtsSelection.map((district) => district.id));

  return qs.stringify(
    { tc, diff_districts },
    { addQueryPrefix: true, skipNulls: true },
  );
}
