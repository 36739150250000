import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  RESEARCH_CANDIDATE_DETAIL,
  RESEARCH_CANDIDATE_GIFT,
  RESEARCH_CANDIDATE_SIGN,
  RESEARCH_CANDIDATE_UTILITIES,
} from 'routes/HmRoutes';
import { Candidate } from 'types/candidate';
import { Research } from 'types/research';
import CandidateDetails from 'features/hunter/pages/research/researchDetails/candidateDetails/CandidateDetails';
import SignatureAndRental
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/SignatureAndRental';
import UtilitiesAndInsurance
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/UtilitiesAndInsurance';
import GiftAndVouchers
  from 'features/hunter/pages/research/researchDetails/candidateDetails/postSearch/GiftAndVouchers';

type Props = {
  research: Research,
  candidate: Candidate
};

export default function CandidateDetailsRouter({ candidate, research }: Props) {
  return (
    <Switch>
      <Route
        path={RESEARCH_CANDIDATE_SIGN}
        render={() => <SignatureAndRental candidate={candidate} research={research} />}
      />
      <Route
        path={RESEARCH_CANDIDATE_UTILITIES}
        render={() => <UtilitiesAndInsurance candidate={candidate} research={research} />}
      />
      <Route
        path={RESEARCH_CANDIDATE_GIFT}
        render={() => <GiftAndVouchers candidate={candidate} research={research} />}
      />
      <Route
        path={RESEARCH_CANDIDATE_DETAIL}
        render={() => <CandidateDetails research={research} candidate={candidate} />}
      />
    </Switch>
  );
}
