import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { ShortDateTimeFormat } from 'services/utils';
import { IconButton } from '@material-ui/core';
import { Close, DeleteForever, EditOutlined } from '@material-ui/icons';
import { Note } from 'types/Note';
import { useNote } from 'network/api/PropertyQueries';
import EditNoteForm from 'features/hunter/pages/research/researchDetails/candidateDetails/notes/EditNoteForm';

type Props = {
  note: Note,
  hmEmail: string | null,
//  TODO seems strange to have "|null"
};

export default function NoteCard({ note, hmEmail }: Props) {
  const [editMode, setEditMode] = useState(false);
  const noteQueries = useNote();
  const { deleteNote } = noteQueries;

  const deleteNoteHandler = async () => {
    await deleteNote.mutateAsync(note);
  };

  return (
    <div className="note-container" key={note.id}>
      {editMode ? (
        <div>
          <EditNoteForm note={note} onClose={() => setEditMode(false)} />
        </div>
      ) : (
        <p>{note.note_content}</p>
      )}
      <small>{note.author}, {`${Messages.t('note.lastUpdated')} ${ShortDateTimeFormat(note.updated_at)}`}</small>
      {hmEmail === note.author && (
        <div className="action-btns">
          <IconButton onClick={() => setEditMode((old) => !old)}>
            {editMode ? <Close /> : <EditOutlined />}
          </IconButton>
          <IconButton onClick={deleteNoteHandler}>
            <DeleteForever />
          </IconButton>
        </div>
      )}
    </div>
  );
}
