export interface Criteria {
  type: CriteriaType,
  criteria: CriteriaValue,
  value: string,
  weight: string,
  research: string
  id: number,
}

export interface CriteriaPartial {
  type?: CriteriaType,
  criteria?: CriteriaValue,
  value?: string,
  weight?: string,
  research?: string
  id?: number,
}

export const required = 'Required';

export type CriteriaType = 'LOCATION' | 'HOUSING';

type CriteriaTypeEnum = {
  [key in CriteriaType]: CriteriaType;
};

export const criteriaType: CriteriaTypeEnum = {
  LOCATION: 'LOCATION',
  HOUSING: 'HOUSING',
};

export type CriteriaValue = 'CITY'
| 'NEIGHBORHOOD'
| 'LOCOMOTION'
| 'TRANSPORT_TIME'
| 'WORK_LOCATION'
| 'WORK_PROXIMITY'
| 'TRANSPORT_PROXIMITY'
| 'SCHOOL_PROXIMITY'
| 'STORE_PROXIMITY'
| 'PEACEFUL_NEIGHBORHOOD'
| 'TRENDY_NEIGHBORHOOD'
| 'COLIVING'
| 'KITCHEN_TYPE'
| 'OPEN_KITCHEN'
| 'PEACEFUL'
| 'BRIGHT'
| 'DESIGN'
| 'BALCONY'
| 'PARKING'
| 'BIKE_GARAGE'
| 'ENERGY'
| 'GARDEN'
| 'WASHING_MACHINE'
| 'DISHWASHER'
| 'OVEN'
| 'ELEVATOR_MANDATORY_FLOOR'
| 'DRYING_MACHINE'
| 'GROUND_FLOOR';

type CriteriaValueEnum = {
  [key in CriteriaValue]: CriteriaValue;
};

export const criteriaValue: CriteriaValueEnum = {
  CITY: 'CITY',
  NEIGHBORHOOD: 'NEIGHBORHOOD',
  LOCOMOTION: 'LOCOMOTION',
  TRANSPORT_TIME: 'TRANSPORT_TIME',
  WORK_LOCATION: 'WORK_LOCATION',
  WORK_PROXIMITY: 'WORK_PROXIMITY',
  TRANSPORT_PROXIMITY: 'TRANSPORT_PROXIMITY',
  SCHOOL_PROXIMITY: 'SCHOOL_PROXIMITY',
  STORE_PROXIMITY: 'STORE_PROXIMITY',
  PEACEFUL_NEIGHBORHOOD: 'PEACEFUL_NEIGHBORHOOD',
  TRENDY_NEIGHBORHOOD: 'TRENDY_NEIGHBORHOOD',
  COLIVING: 'COLIVING',
  KITCHEN_TYPE: 'KITCHEN_TYPE',
  OPEN_KITCHEN: 'OPEN_KITCHEN',
  PEACEFUL: 'PEACEFUL',
  BRIGHT: 'BRIGHT',
  DESIGN: 'DESIGN',
  BALCONY: 'BALCONY',
  PARKING: 'PARKING',
  BIKE_GARAGE: 'BIKE_GARAGE',
  ENERGY: 'ENERGY',
  GARDEN: 'GARDEN',
  WASHING_MACHINE: 'WASHING_MACHINE',
  DISHWASHER: 'DISHWASHER',
  OVEN: 'OVEN',
  ELEVATOR_MANDATORY_FLOOR: 'ELEVATOR_MANDATORY_FLOOR',
  DRYING_MACHINE: 'DRYING_MACHINE',
  GROUND_FLOOR: 'GROUND_FLOOR',
};

type CriteriaTypeValueEnum = {
  [key in CriteriaType]: CriteriaValue[];
};

export const criteriaTypeValue: CriteriaTypeValueEnum = {
  LOCATION: [
    criteriaValue.CITY,
    criteriaValue.NEIGHBORHOOD,
    criteriaValue.LOCOMOTION,
    criteriaValue.TRANSPORT_TIME,
    criteriaValue.WORK_LOCATION,
    criteriaValue.WORK_PROXIMITY,
    criteriaValue.TRANSPORT_PROXIMITY,
    criteriaValue.SCHOOL_PROXIMITY,
    criteriaValue.STORE_PROXIMITY,
    criteriaValue.PEACEFUL_NEIGHBORHOOD,
    criteriaValue.TRENDY_NEIGHBORHOOD,
  ],
  HOUSING: [
    criteriaValue.COLIVING,
    criteriaValue.KITCHEN_TYPE,
    criteriaValue.OPEN_KITCHEN,
    criteriaValue.PEACEFUL,
    criteriaValue.BRIGHT,
    criteriaValue.DESIGN,
    criteriaValue.BALCONY,
    criteriaValue.PARKING,
    criteriaValue.BIKE_GARAGE,
    criteriaValue.ENERGY,
    criteriaValue.GARDEN,
    criteriaValue.WASHING_MACHINE,
    criteriaValue.DISHWASHER,
    criteriaValue.OVEN,
    criteriaValue.DRYING_MACHINE,
    criteriaValue.GROUND_FLOOR,
    criteriaValue.ELEVATOR_MANDATORY_FLOOR,
  ],
};

export type TransportType = 'FOOT' | 'BIKE' | 'COMMON' | 'CAR';

type TransportTypeEnum = {
  [key in TransportType]: TransportType;
};

export const transportType: TransportTypeEnum = {
  FOOT: 'FOOT',
  BIKE: 'BIKE',
  COMMON: 'COMMON',
  CAR: 'CAR',
};

export type ColivingType = 'POSSIBLE' | 'EXCLUDED';

type ColivingTypeEnum = {
  [key in ColivingType]: ColivingType;
};

export const colivingType: ColivingTypeEnum = {
  POSSIBLE: 'POSSIBLE',
  EXCLUDED: 'EXCLUDED',
};

export type KitchenOpenType = 'OPEN' | 'SEPARATED';

type KitchenOpenTypeEnum = {
  [key in KitchenOpenType]: KitchenOpenType;
};

export const kitchenOpenType: KitchenOpenTypeEnum = {
  OPEN: 'OPEN',
  SEPARATED: 'SEPARATED',
};

export type KitchenType = 'EQUIPPED' | 'PARTIALLY_EQUIPPED' | 'NOT_EQUIPPED';

type KitchenTypeEnum = {
  [key in KitchenType]: KitchenType;
};

export const kitchenType: KitchenTypeEnum = {
  EQUIPPED: 'EQUIPPED',
  PARTIALLY_EQUIPPED: 'PARTIALLY_EQUIPPED',
  NOT_EQUIPPED: 'NOT_EQUIPPED',
};

export type GroundFloorType = 'NO' | 'YES' | 'ONLY_YARD';

type GroundFloorTypeEnum = {
  [key in GroundFloorType]: GroundFloorType;
};

export const groundFloorType: GroundFloorTypeEnum = {
  NO: 'NO',
  YES: 'YES',
  ONLY_YARD: 'ONLY_YARD',
};

export type EditorType = 'TEXTFIELD' | 'SELECT' | 'DATE';

type EditorTypeEnum = {
  [key in EditorType]: EditorType;
};

export const editorType: EditorTypeEnum = {
  TEXTFIELD: 'TEXTFIELD',
  SELECT: 'SELECT',
  DATE: 'DATE',
};

type CriteriaPossibleValueEnum = {
  [key in CriteriaValue]: {
    editor: EditorType,
    defaultValue: string,
    editable: boolean,
    posibleValue: string[]
    onlyNumbers?: boolean,
    postfix?: string,
  };
};

export const criteriaPossibleValues: CriteriaPossibleValueEnum = {
  CITY: {
    editor: editorType.TEXTFIELD,
    defaultValue: '',
    editable: true,
    posibleValue: [],
  },
  NEIGHBORHOOD: {
    editor: editorType.TEXTFIELD,
    defaultValue: '',
    editable: true,
    posibleValue: [],
  },
  LOCOMOTION: {
    editor: editorType.SELECT,
    defaultValue: '',
    editable: true,
    posibleValue: Object.keys(transportType),
  },
  TRANSPORT_TIME: {
    editor: editorType.TEXTFIELD,
    defaultValue: '',
    editable: true,
    onlyNumbers: true,
    postfix: 'minutes',
    posibleValue: [],
  },
  WORK_LOCATION: {
    editor: editorType.TEXTFIELD,
    defaultValue: '',
    editable: true,
    posibleValue: [],
  },
  WORK_PROXIMITY: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  TRANSPORT_PROXIMITY: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  SCHOOL_PROXIMITY: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  STORE_PROXIMITY: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  PEACEFUL_NEIGHBORHOOD: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  TRENDY_NEIGHBORHOOD: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  COLIVING: {
    editor: editorType.SELECT,
    defaultValue: '',
    editable: true,
    posibleValue: Object.keys(colivingType),
  },
  KITCHEN_TYPE: {
    editor: editorType.SELECT,
    defaultValue: '',
    editable: true,
    posibleValue: Object.keys(kitchenType),
  },
  OPEN_KITCHEN: {
    editor: editorType.SELECT,
    defaultValue: '',
    editable: true,
    posibleValue: Object.keys(kitchenOpenType),
  },
  PEACEFUL: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  BRIGHT: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  DESIGN: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  BALCONY: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  PARKING: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  BIKE_GARAGE: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  ENERGY: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  GARDEN: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  WASHING_MACHINE: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  DISHWASHER: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  OVEN: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  ELEVATOR_MANDATORY_FLOOR: {
    editor: editorType.TEXTFIELD,
    defaultValue: '',
    editable: true,
    onlyNumbers: true,
    postfix: 'floor',
    posibleValue: [],
  },
  DRYING_MACHINE: {
    editor: editorType.TEXTFIELD,
    defaultValue: required,
    editable: false,
    posibleValue: [],
  },
  GROUND_FLOOR: {
    editor: editorType.SELECT,
    defaultValue: '',
    editable: true,
    posibleValue: Object.keys(groundFloorType),
  },
};
