import React, { useState, useCallback } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useCandidate } from 'network/api/CandidateQueries';
import Modal from 'lib/Modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { urlRegex } from 'lib/form/FormUtils';
import { NotificationService } from 'services/notification';
import { AddPropertyByUrlType } from 'types/forms/AddPropertyByUrl';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SpinButton from 'theme/hunter-utils/SpinButton';
import Messages from 'services/i18n/Messages';
import { Button } from '@material-ui/core';
import AddPropertyForm
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/forms/AddPropertyForm';
import { CANDIDATE_ID, RESEARCH_ID, Routes } from 'routes/Routes';
import {
  CANDIDATE_TAB_ID,
  DETAIL_ID,
  RESEARCH_CANDIDATE_DETAIL,
  TAB_ID, TRANSITION_TO_QUERY_ID,
} from 'routes/HmRoutes';
import { CanditateTab } from 'features/hunter/pages/research/researchDetails/candidateDetails/CandidateTabs';
import { useHistory } from 'react-router-dom';
import SelectWrapper from 'lib/form/SelectWrapper';
import { WorkflowStep, workflowStep } from 'types/WorkflowStep';
import { workflowStepMetaData, workFlowValidationStep } from 'services/hunter/WorkflowStep';
import { FetchError } from 'network/Errors';

type Props = {
  researchId: string,
};

export default function AddPropertyByUrlControl(
  {
    researchId,
  }: Props,
) {
  const { addCandidateByUrl } = useCandidate();
  const [show, setShow] = useState(false);
  const [showAddCandidateForm, setShowAddCandidateForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [apiErrors, setApiError] = useState({});
  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddPropertyByUrlType>({ defaultValues: { url: '' } });

  const handleShow = useCallback(() => setShow(true), []);
  const handleClose = useCallback(() => {
    if (submitting) {
      return;
    }
    setShow(false);
    setSubmitError(null);
  }, [submitting]);

  const updateSelectedCandidate = useCallback(
    (selectedCandidateId: string, targetWorkflowStep?: WorkflowStep) => {
      handleClose();
      history.push(Routes.updateUrlWithQuery(
        Routes.withPath(
          RESEARCH_CANDIDATE_DETAIL,
          [
            { label: RESEARCH_ID, value: researchId },
            { label: CANDIDATE_ID, value: selectedCandidateId },
            { label: TAB_ID, value: CANDIDATE_TAB_ID },
            { label: DETAIL_ID, value: CanditateTab.DETAILS },
          ],
        ),
        targetWorkflowStep && workFlowValidationStep.includes(targetWorkflowStep)
          ? [{ label: TRANSITION_TO_QUERY_ID, value: targetWorkflowStep }]
          : [],
      ));
    }, [history, researchId],
  );

  const onSubmit: SubmitHandler<AddPropertyByUrlType> = (formData: AddPropertyByUrlType) => {
    setSubmitting(true);
    setSubmitError(null);
    addCandidateByUrl.mutate(
      {
        url: formData.url,
        research: researchId,
        author: 'HOME_MATCHER',
        manually_added: true,
        added_by_client: formData.addedByClient === 'true',
        workflow_step: workFlowValidationStep.includes(formData.workflowStep)
          ? workflowStep.REVIEW
          : formData.workflowStep,
      },
      {
        onSuccess: (data) => {
          setSubmitting(false);
          if (data.status === 200 && data.response !== null) {
            setSubmitError(null);
            NotificationService.notifySuccess(Messages.t('toast.hunter.propertyAdded'));
            updateSelectedCandidate(
              data.response.id,
              data.response.workflow_step === workflowStep.REVIEW
                ? formData.workflowStep
                : undefined,
            );
            handleClose();
          } else if (data.status === 202) {
            setSubmitError(
              Messages.t('property.fluximmoError.notInDB'),
            );
          } else if (data.status === 404 || data.status === 400) {
            setSubmitError(
              Messages.t('property.fluximmoError.notImportable'),
            );
          } else {
            setSubmitError(
              Messages.t('property.fluximmoError.other'),
            );
          }
        },
        onError: (error: FetchError) => {
          setSubmitting(false);
          setApiError(error.json_response);
          setSubmitError(
            Messages.t('property.fluximmoError.other'),
          );
        },
      },
    );
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleShow}
      >
        {Messages.t('property.add')}
      </Button>
      <Modal
        isOpen={show}
        onClose={handleClose}
        header={Messages.t('property.add')}
        centered
      >
        <div className="add-property-modal">
          {
            showAddCandidateForm ? (
              <AddPropertyForm
                researchId={researchId}
                setShowAddCandidateForm={setShowAddCandidateForm}
                setSelectedCandidateId={(id, step) => updateSelectedCandidate(id.toString(), step)}
              />
            ) : (

              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="url"
                  control={control}
                  rules={
                    {
                      required: true,
                      pattern: {
                        value: urlRegex,
                        message: Messages.t('form.error.url'),
                      },
                    }
                  }
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      label={Messages.t('form.field.urlAdvert')}
                    />
                  )}
                />
                <Controller
                  name="workflowStep"
                  control={control}
                  rules={{ required: true }}
                  render={(controller) => (
                    <SelectWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      label={Messages.t('form.field.workflowStep')}
                      values={Object.values(workflowStep)
                        .filter((key) => key !== workflowStep.MATCH
                          && workFlowValidationStep.includes(key))
                        .map((key) => ({
                          key,
                          label: workflowStepMetaData(key as WorkflowStep).getDisplayName() || '',
                        }))}
                    />
                  )}
                />
                <Controller
                  name="addedByClient"
                  control={control}
                  rules={{ required: true }}
                  render={(controller) => (
                    <SelectWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      label={Messages.t('form.field.addedByClient')}
                      values={[{ key: 'true', label: 'Yes' }, { key: 'false', label: 'No' }]}
                    />
                  )}
                />
                {submitError && <Alert variant="danger">{submitError}</Alert>}
                <div className="buttons-container">
                  <Button
                    disabled={submitting}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setShowAddCandidateForm(true);
                    }}
                  >
                    {Messages.t('property.addManually')}
                  </Button>
                  <SpinButton
                    title={Messages.t('formButton.add')}
                    editing={false}
                    spin={submitting}
                    variant="primary"
                  />
                </div>
              </form>
            )
          }
        </div>
      </Modal>
    </>
  );
}
