import { Badge } from '@material-ui/core';
import React, { ReactNode, useEffect, useState } from 'react';
import Talk from 'talkjs';
import settings from 'services/hunterSettings';

type Props = {
  children?: ReactNode,
  conversationId?: string,
  currentUser: any,
  setCounter?: boolean,
};
export default function MainRouter({
  children, conversationId, currentUser, setCounter,
}: Props) {
  const [unreadCount, setUnreadCount] = useState(0);
  const [talkLoaded, markTalkLoaded] = useState(false);
  const createCounter = (count) => {
    const chatNotify = document.getElementsByClassName('chat__counter');
    if (chatNotify && chatNotify.length) {
      chatNotify[0].remove();
    }
    if (count) {
      const talkJsLauncher = document.getElementById('__talkjs_launcher');
      if (talkJsLauncher) {
        const counterEl = document.createElement('div');
        counterEl.classList.add('chat__counter');
        const newContent = document.createTextNode(count > 99 ? '99+' : count);
        counterEl.appendChild(newContent);
        talkJsLauncher.appendChild(counterEl);
      }
    }
  };
  useEffect(() => {
    Talk.ready.then(() => markTalkLoaded(true));
    if (talkLoaded) {
      const me = new Talk.User(currentUser.email);
      const session = new Talk.Session({
        appId: settings.talkJsId,
        me,
      });
      let unRead = 0;
      session.unreads.onChange((conversationIds) => {
        if (conversationId) {
          const findConversation: any = conversationIds.find(
            (chat) => (chat.conversation && chat.conversation.id === conversationId),
          );
          unRead = findConversation ? findConversation.unreadMessageCount : 0;
        } else {
          unRead = conversationIds.reduce((accumulator, currentValue: any) => accumulator
            + (currentValue.unreadMessageCount || 0), 0);
        }
        if (setCounter) {
          createCounter(unRead);
        }
        setUnreadCount(unRead);
      });
    }
  }, [talkLoaded, conversationId]);

  return (
    <Badge className="chat-counter" badgeContent={unreadCount} color="primary" max={99}>{children}</Badge>
  );
}
