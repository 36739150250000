import React, { useEffect, useState } from 'react';
import Messages from 'services/i18n/Messages';

const ANIMATION_DELAY = 3 * 1000; // 3 seconds

type Props = {
  children: React.ReactNode
  className?: string,
};

export default function ClipboardCopyButton({ children, className }: Props) {
  const [animate, updateAnimation] = useState<boolean>(false);

  useEffect(
    () => {
      const timer = setTimeout(() => updateAnimation(false), ANIMATION_DELAY);
      return () => {
        clearTimeout(timer);
      };
    },
    [animate],
  );

  return (
    <div className="clipboard-copy-button">
      <button
        type="button"
        className={className || ''}
        onClick={() => updateAnimation(true)}
      >
        {children}
      </button>
      <div className={`animated-tooltip ${animate ? 'show' : ''}`}>
        {Messages.t('clipBoardButton.linkCopied')}
      </div>
    </div>
  );
}
