import { onMount } from 'lib/alias/useEffectAlias';
import { ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ReactQueryDevtools } from 'react-query/devtools';
import React from 'react';
import ClientApp from 'features/client/ClientApp';
import { Route, Switch } from 'react-router-dom';
import {
  CLIENT_RESEARCH_BASE,
  CLIENT_RESEARCH_WITH_OPTIONAL_WORKFLOW_STEP,
} from 'routes/ClientRoutes';
import ClientSearchInput from 'features/client/ClientSearchInput';

export default function ClientRoot() {
  onMount(() => {
    document.title = 'SettleSweet Hunter';
  });

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#f39c12',
        main: '#fa7924',
        dark: '#e67e22',
      },
    },
  });

  return (
    <>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Switch>
            <Route
              path={CLIENT_RESEARCH_WITH_OPTIONAL_WORKFLOW_STEP}
              render={() => (
                <ClientApp />
              )}
            />
            <Route
              exact
              path={CLIENT_RESEARCH_BASE}
              render={() => (
                <ClientSearchInput />
              )}
            />
          </Switch>
        </ThemeProvider>
      </StyledEngineProvider>
      {process.env.NODE_ENV === 'development' ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : null}
    </>
  );
}
