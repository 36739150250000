import React, { useState, useCallback } from 'react';
import { useProperty } from 'network/api/PropertyQueries';
import { useCandidate } from 'network/api/CandidateQueries';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PropertyFormType } from 'types/forms/PropertyForm';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import AddressAutocompleteWrapper from 'lib/form/AddressAutocompleteWrapper';
import { urlRegex, zipCodeRegex } from 'lib/form/FormUtils';
import SpinButton from 'theme/hunter-utils/SpinButton';
import SelectWrapper from 'lib/form/SelectWrapper';
import { NotificationService } from 'services/notification';
import Messages from 'services/i18n/Messages';
import { WorkflowStep, workflowStep } from 'types/WorkflowStep';
import { workflowStepMetaData, workFlowValidationStep } from 'services/hunter/WorkflowStep';
import { Button } from '@material-ui/core';
import { domainFromUrl } from 'services/utils';
import { FetchError } from 'network/Errors';
import { propertyType } from 'types/research';

type Props = {
  researchId: string,
  setShowAddCandidateForm: (show: boolean) => void,
  setSelectedCandidateId: (candidateId: number, step: WorkflowStep) => void
};

export default function AddPropertyForm(
  {
    researchId,
    setShowAddCandidateForm,
    setSelectedCandidateId,
  }: Props,
) {
  const { createProperty } = useProperty();
  const { createCandidateByProperty } = useCandidate();

  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiError] = useState({});

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PropertyFormType>({
    defaultValues: {
      title: '',
      description: '',
      postal_code: '',
      source_url: '',
      source_website: '',
      address: '',
      property_type: '',
    },
  });

  const handleClose = useCallback(() => {
    setShowAddCandidateForm(false);
  }, [setShowAddCandidateForm]);

  const onSubmit: SubmitHandler<PropertyFormType> = (formData: PropertyFormType) => {
    setApiError({});
    setSubmitting(true);
    const dataFinal = {
      data: {
        ...formData,
        source_website: domainFromUrl(formData.source_url) || '',
        available: true,
        published_date: new Date(),
      },
    };
    // @ts-ignore
    createProperty.mutate(dataFinal,
      {
        onSuccess: (data) => {
          createCandidateByProperty.mutate({
            research: researchId,
            added_by_client: formData.addedByClient === 'true',
            manually_added: true,
            property: data.response.id,
            workflow_step: workFlowValidationStep.includes(formData.workflowStep)
              ? workflowStep.REVIEW
              : formData.workflowStep,
          }, {
            onSuccess: (candidate) => {
              NotificationService.notifySuccess(Messages.t('toast.hunter.propertyCreated'));
              setSelectedCandidateId(candidate.response.id, formData.workflowStep);
              setShowAddCandidateForm(false);
              setSubmitting(false);
            },
            onError: (error: FetchError) => {
              NotificationService.notifyError(Messages.t('toast.hunter.error'));
              setApiError(error.json_response);
              setSubmitting(false);
            },
          });
        },
        onError: (error: FetchError) => {
          NotificationService.notifyError(Messages.t('toast.hunter.error'));
          setApiError(error.json_response);
          setSubmitting(false);
        },
      });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="title"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.title')}
            />
          )}
        />
        <Controller
          name="address"
          control={control}
          render={(controller) => (
            <AddressAutocompleteWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              onCoordinateChanged={(value) => {
                setValue('latitude', value.latitude);
                setValue('longitude', value.longitude);
                setValue('location_type', value.location_type);
              }}
              label={Messages.t('form.field.address')}
              onPostalCodeChanged={(postalCode) => setValue('postal_code', postalCode)}
            />
          )}
        />
        <Controller
          name="postal_code"
          control={control}
          rules={
            {
              required: true,
              pattern: {
                value: zipCodeRegex,
                message: Messages.t('form.error.zipcode'),
              },
            }
          }
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              requierd
              label={Messages.t('form.field.zipcode')}
            />
          )}
        />
        <Controller
          name="property_type"
          control={control}
          rules={{ required: true }}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              requierd
              label={Messages.t('form.field.propertyType')}
              values={Object.values(propertyType)
                .map((key) => (
                  { key, label: Messages.t(`property.propertyType.${key}`) }
                ))}
            />
          )}
        />
        <Controller
          name="source_url"
          control={control}
          rules={
            {
              pattern: {
                value: urlRegex,
                message: Messages.t('form.error.url'),
              },
            }
          }
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.url')}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="textarea"
              rows={6}
              control={controller}
              label={Messages.t('form.field.description')}
            />
          )}
        />
        <Controller
          name="workflowStep"
          control={control}
          rules={{ required: true }}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.workflowStep')}
              values={Object.values(workflowStep)
                .filter((key) => key !== workflowStep.MATCH
                                 && workFlowValidationStep.includes(key))
                .map((key) => ({
                  key,
                  label: workflowStepMetaData(key as WorkflowStep).getDisplayName() || '',
                }))}
            />
          )}
        />
        <Controller
          name="addedByClient"
          control={control}
          rules={{ required: true }}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('form.field.addedByClient')}
              values={[{ key: 'true', label: 'Yes' }, { key: 'false', label: 'No' }]}
            />
          )}
        />
        <div className="buttons-container">
          <Button color="secondary" variant="contained" onClick={handleClose}>
            {Messages.t('property.addByUrl')}
          </Button>
          <SpinButton
            editing={false}
            spin={submitting}
            variant="primary"
          />
        </div>
      </form>
    </>
  );
}
