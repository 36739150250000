import React, { useCallback, useRef } from 'react';
import { useAuth, useUser } from 'reactfire';

import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  CONTACTS_LIST,
  DEFAULT,
  ISOCHRONE,
  TASKS,
  CHAT,
  TRADUCTIONS,
} from 'routes/HmRoutes';

import Messages from 'services/i18n/Messages';
import {
  ContactPhone,
  FirstPage, Home,
  LastPage,
  ListAlt,
  Map,
  Notifications,
  Person,
  Chat,
} from '@material-ui/icons';
import {
  useNotifications,
} from '@magicbell/magicbell-react';
import MagicBellContainer from 'features/magicbell/MagicBellContainer';
import { notificationType } from 'types/Notifications';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NotificationStore } from '@magicbell/react-headless/dist/hooks/useNotifications';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import StringUtils from 'services/StringUtils';
import settings from 'services/hunterSettings';
import ChatCounter from 'features/common/chat/ChatCounter';
import MySearchList from 'features/hunter/layout/MySearchList';

type Props = {
  showNavBar: boolean
  toogle: () => void
};

export default function NavBar({ showNavBar, toogle }: Props) {
  const auth = useAuth();
  const { data: user } = useUser();
  const location = useLocation();
  const history = useHistory();
  const [openNotifications, setOpenNotifications] = React.useState(false);
  const handleSignOut = useCallback(async () => {
    await auth.signOut();
  }, [auth]);
  const ref = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const allStore = useNotifications('ALL');
  const clientStore = useNotifications(notificationType.CLIENT_REQUEST);
  const commentStore = useNotifications(notificationType.COMMENT);
  const remindersStore = useNotifications(notificationType.REMINDERS);
  const otherStore = useNotifications(notificationType.OTHER);
  const storeList: [string, NotificationStore][] = [
    ['ALL', allStore],
    [notificationType.CLIENT_REQUEST, clientStore],
    [notificationType.COMMENT, commentStore],
    [notificationType.REMINDERS, remindersStore],
    [notificationType.OTHER, otherStore],
  ].filter((entry) => !!entry[1]) as [string, NotificationStore][];

  let position: {
    top?: number,
    bottom?: number,
    left?: number,
    right?: number
  } | null = null;

  if (ref && ref.current) {
    const viewportOffset = ref.current.getBoundingClientRect();
    const { top } = viewportOffset;
    const left = viewportOffset.right;
    position = {
      top,
      left,
    };
  }

  return (

    <nav className={`nav-bar ${showNavBar ? 'show' : ''}`}>
      <div className="nav-bar-control">
        <IconButton onClick={toogle}>
          {
            showNavBar
              ? <FirstPage />
              : <LastPage />
          }
        </IconButton>
      </div>
      <div className="nav-bar-content">
        <Link to={DEFAULT} className="nav-bar-item title">
          <img
            alt="Settlesweet logo"
            src={`${settings.publicUrl}/settlesweet-removebg.png`}
            className="d-inline-block align-top"
            style={{ height: '30px' }}
          />
          <span>{Messages.t('navbar.title')}</span>
        </Link>
        <div
          role="presentation"
          className="notification-button nav-bar-item notification-button-container"
          ref={ref}
          onClick={() => {
            setOpenNotifications(true);
          }}
        >
          <Notifications /><span>{Messages.t('navbar.notifications')}</span>
          {
            (allStore?.unseenCount || 0) > 0 && (
              <div className="notification-count notification-badge">
                <span>{allStore?.unseenCount}</span>
              </div>
            )
          }
          {
            openNotifications && ref && position && (
              <MagicBellContainer
                onClose={() => {
                  allStore.markAllAsSeen();
                  setOpenNotifications(false);
                }}
                position={position}
                notficationsList={storeList}
              />
            )
          }
        </div>
        <Link to={DEFAULT} className={`nav-bar-item ${location.pathname === DEFAULT ? 'selected' : ''}`}>
          <Home /><span>{Messages.t('navbar.search')}</span>
        </Link>
        <div className="nav-bar-item my-searches">
          <span>{Messages.t('navbar.mySearches')}</span>
        </div>
        <MySearchList />
        <Link to={CONTACTS_LIST} className={`nav-bar-item ${location.pathname.startsWith(CONTACTS_LIST) ? 'selected' : ''}`}>
          <ContactPhone /><span>{Messages.t('navbar.contacts')}</span>
        </Link>
        <Link to={TASKS} className={`nav-bar-item ${location.pathname.startsWith(TASKS) ? 'selected' : ''}`}>
          <ListAlt /><span>{Messages.t('navbar.tasks')}</span>
        </Link>
        <Link to={ISOCHRONE} className={`nav-bar-item ${location.pathname.startsWith(ISOCHRONE) ? 'selected' : ''}`}>
          <Map /><span>{Messages.t('navbar.mapping')}</span>
        </Link>
        <Link to={CHAT} className={`nav-bar-item ${location.pathname.startsWith(CHAT) ? 'selected' : ''}`}>
          <ChatCounter currentUser={user}><Chat /></ChatCounter><span>{Messages.t('navbar.chat')}</span>
        </Link>
        {
          user && user.email && (
            <div className="profile-container">
              <button type="button" onClick={handleClick} className="nav-bar-item profile-button">
                <Person />
                <span>{StringUtils.capitalizeFirstLetter(user.email.split('@')[0])}</span>
              </button>
            </div>
          )
        }
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className="menu-header"
        >
          {
            user && user.email && (
              user.email === 'guillaume@settlesweet.com'
              || user.email === 'quentin@settlesweet.com'
            ) && (
              <MenuItem onClick={() => history.push(TRADUCTIONS)}>
                {Messages.t('traduction.title')}
              </MenuItem>
            )
          }
          <MenuItem onClick={handleSignOut}>
            {Messages.t('navbar.signOut')}
          </MenuItem>
        </Menu>
      </div>
    </nav>
  );
}
