import React, { useCallback, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import {
  useCandidate,
} from 'network/api/CandidateQueries';
import VerticalCandidateList from 'features/hunter/pages/research/researchDetails/candidateList/VerticalCandidateList';
import { Candidate } from 'types/candidate';
import { ChevronRight } from '@material-ui/icons';
import { WorkflowStep } from 'types/WorkflowStep';
import { Research } from 'types/research';
import Messages from 'services/i18n/Messages';
import Modal from 'lib/Modal';
import MapWrapper from 'features/client/pages/map/MapWrapper';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import Filters from 'features/hunter/pages/research/researchDetails/candidateList/filter/Filters';
import candidateFilterService, { possibleFilter, sortType } from 'services/CandidateFilterService';

import { IconButton, Tooltip } from '@material-ui/core';
import Sort from 'features/hunter/pages/research/researchDetails/candidateList/filter/Sort';
import { useObservable } from 'micro-observables';
import panelCollapseService from 'services/PanelCollapseService';
import {
  AdjustmentsIcon,
  ChevronLeftIcon,
  SortAscendingIcon,
  SortDescendingIcon,
} from '@heroicons/react/solid';

type Props = {
  currentWorkflowStep: WorkflowStep,
  candidateIds: string[],
  candidates: Candidate[],
  isLoading: boolean,
  editingActivity: string | null,
  updateSelectedCandidate: (candidateIds: string[]) => void
  fetchNextPage: () => void,
  hasNextPage: boolean,
  research: Research,
};

export default function ResearchLeftPanel(
  {
    currentWorkflowStep,
    candidateIds,
    candidates,
    isLoading,
    editingActivity,
    updateSelectedCandidate,
    fetchNextPage,
    hasNextPage,
    research,
  }: Props,
) {
  const { updateCandidate } = useCandidate();
  const [modalOpen, setModalOpen] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const sort = useObservable(candidateFilterService.getSortObservable(currentWorkflowStep));
  const openCandidateList = useObservable(panelCollapseService.getOpenCandidateList());
  const handleSelectCandidateId = useCallback(
    (newSelectionIds: string[]) => {
      updateSelectedCandidate(newSelectionIds);

      if (newSelectionIds.length === 1) {
        const newSelectionId = newSelectionIds[0];

        const candidate = candidates.find(
          (c) => c.id.toString() === newSelectionId,
        );
        if (candidate?.viewed === false) {
          // @ts-ignore
          updateCandidate.mutate({
            candidate,
            data: { viewed: true },
          });
        }
      }
    },
    [candidates, updateCandidate, updateSelectedCandidate],
  );
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const filtered = candidateFilterService.filterCandidates(candidates, currentWorkflowStep);

  const filterActiveCount = candidateFilterService.getNumberActiveFilter(currentWorkflowStep);

  return (
    <Col
      xs="auto"
      className={`full-height-container left-panel  light-border-right ${
        editingActivity ? 'd-none' : ''
      }`}
      style={{
        width: openCandidateList ? '300px' : '50px',
      }}
    >
      {
        modalOpen && (
          <Modal isOpen={modalOpen} header={Messages.t('map.title')} onClose={() => setModalOpen(false)}>
            <div className="search-map-container">
              <MapWrapper
                displayErrorMessage
                candidates={filtered}
                setSelectedCandidateId={(list) => handleSelectCandidateId([list[0].toString()])}
                selectedCandidateId={(candidateIds[0] && candidateIds[0] !== '') ? candidateIds[0] : undefined}
              />
            </div>
          </Modal>
        )
      }
      {
        !openCandidateList && (
          <button
            type="button"
            className="collapse-button"
            onClick={() => panelCollapseService.updateOpenCandidateList(true)}
          >
            <ChevronRight />
          </button>
        )
      }
      {
        openCandidateList && (
          <div
            className="map-button-container"
          >
            <button
              type="button"
              className="map-button"
              onClick={() => setModalOpen(true)}
            >
              <LocationMarkerIcon />
              {Messages.t('map.title')}
            </button>
          </div>
        )
      }
      <div
        className="panel-header"
        style={{
          width: openCandidateList ? '300px' : '0px',
        }}
      >
        <div className="filter-button-container">
          <Tooltip title={Messages.t('filter.nbActive', { count: filterActiveCount })}>
            <IconButton
              className={filterActiveCount > 0 ? 'active-filter' : ''}
              onClick={() => setOpenFilters(true)}
            >
              <AdjustmentsIcon />
            </IconButton>
          </Tooltip>
          {
            openFilters && (
              <Filters
                research={research}
                onClose={() => setOpenFilters(false)}
                currentWorkflowStep={currentWorkflowStep}
              />
            )
          }
        </div>
        <div className="filter-button-container">
          <IconButton
            className={sort.field !== sort.default ? 'active-filter' : ''}
            onClick={() => setOpenSort(true)}
          >
            {
              sort.sort === sortType.DESC
                ? <SortDescendingIcon />
                : <SortAscendingIcon />
            }
          </IconButton>
          {
            openSort && (
              <Sort
                onClose={() => setOpenSort(false)}
                currentWorkflowStep={currentWorkflowStep}
              />
            )
          }
        </div>
        <div className="filter-button-container">
          <IconButton
            onClick={() => panelCollapseService.updateOpenCandidateList(false)}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
      </div>
      <div className="left-panel-content" ref={scrollableRef}>
        <p className="text-right">
          {Messages.t('hunter.candidateList.sortedBy', { name: Messages.t(possibleFilter[sort.field].label) })}
        </p>
        {candidates && scrollableRef.current && (
          <VerticalCandidateList
            research={research}
            scrollableRef={scrollableRef}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            candidates={filtered}
            selectedCandidateIds={candidateIds}
            setSelectedCandidateIds={handleSelectCandidateId}
            isLoading={isLoading}
          />
        )}
      </div>
    </Col>
  );
}
