import {
  workflowStep,
  WorkflowStep,
  WorkflowStepColor,
  WorkflowStepIcon,
} from 'types/WorkflowStep';
import {
  ArchiveIcon,
  BookmarkIcon,
  ClockIcon, FolderOpenIcon,
  MailOpenIcon, MinusCircleIcon, OfficeBuildingIcon,
  ShieldCheckIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import React, { ReactNode } from 'react';
import {
  BadgeCheckIcon,
  CameraIcon,
  PaperAirplaneIcon,
  ThumbUpIcon,
} from '@heroicons/react/outline';
import { CLIENT_DISCOVER, CLIENT_SHORTLIST, CLIENT_TRACK } from 'routes/ClientRoutes';
import Messages from 'services/i18n/Messages';

type ClientActionType = 'dialog' | 'direct';

export const CLIENT_ACTION_TYPE_DIALOG: ClientActionType = 'dialog';
export const CLIENT_ACTION_TYPE_DIRECT: ClientActionType = 'direct';

export type ClientAction = {
  getDisplayName: () => (ReactNode | null),
  targetWorkflowStep: WorkflowStep,
  type: ClientActionType,
  buttonType: 'primary' | 'secondary' | 'secondary-icon'
  showModal: boolean,
  clientRedirection: string,
};

type ClientDialog = {
  getTitle: () => string
  getText: () => string
};

export type WorkflowStepMetaDataType = {
  getDisplayName: () => (string | null),
  getClientDisplayName: () => (string | null),
  clientDialog?: ClientDialog
  clientActions: ClientAction[] | null,
  type: string,
};

export type WorkflowStepAction = {
  [key in WorkflowStep]: ClientAction | undefined;
};

export const workFlowValidationStep = [
  workflowStep.SHORTLIST,
  workflowStep.MATCH,
  workflowStep.NOT_SURE,
  workflowStep.SENT,
  workflowStep.BOOKED,
  workflowStep.AGREEMENT,
  workflowStep.SIGNED,
  workflowStep.READY_TO_SIGN,
  workflowStep.DISCARDED,
  workflowStep.TRASH,
  workflowStep.NOT_INTERESTED,
];

export const workFlowStepIcon: WorkflowStepIcon = {
  REVIEW: undefined,
  SHORTLIST: undefined,
  SENT: <BadgeCheckIcon />,
  MATCH: undefined,
  NOT_SURE: <BookmarkIcon />,
  VIEWING_REQUESTED: <PaperAirplaneIcon />,
  BOOKED: <ClockIcon />,
  VIEWED: <CameraIcon />,
  TO_APPLY: <FolderOpenIcon />,
  APPLIED: <MailOpenIcon />,
  APPLICATION_ACCEPTED: <ShieldCheckIcon />,
  AGREEMENT: <OfficeBuildingIcon />,
  SIGNED: <ThumbUpIcon />,
  READY_TO_SIGN: <ThumbUpIcon />,
  DISCARDED: <ArchiveIcon />,
  NOT_INTERESTED: <TrashIcon />,
  TRASH: <MinusCircleIcon />,
};

export const workflowStepColor: WorkflowStepColor = {
  REVIEW: 'grey',
  SHORTLIST: 'grey',
  SENT: 'orange',
  MATCH: 'grey',
  NOT_SURE: 'orange',
  VIEWING_REQUESTED: 'grey',
  BOOKED: 'green',
  VIEWED: 'grey',
  TO_APPLY: 'grey',
  APPLIED: 'grey',
  APPLICATION_ACCEPTED: 'green',
  AGREEMENT: 'grey',
  READY_TO_SIGN: 'green',
  SIGNED: 'green',
  DISCARDED: 'red',
  NOT_INTERESTED: 'red',
  TRASH: 'red',
};

const clientActionNotIntersted: ClientAction = {
  getDisplayName: () => workFlowStepIcon[workflowStep.NOT_INTERESTED],
  targetWorkflowStep: workflowStep.NOT_INTERESTED,
  type: CLIENT_ACTION_TYPE_DIALOG,
  buttonType: 'secondary',
  showModal: true,
  clientRedirection: CLIENT_DISCOVER,
};

const clientActionNotSure: ClientAction = {
  getDisplayName: () => <span>{workFlowStepIcon[workflowStep.NOT_SURE]}</span>,
  targetWorkflowStep: workflowStep.NOT_SURE,
  type: CLIENT_ACTION_TYPE_DIALOG,
  buttonType: 'secondary-icon',
  showModal: true,
  clientRedirection: CLIENT_SHORTLIST,
};

const clientActionRequestViewing: ClientAction = {
  getDisplayName: () => (
    <span>
      {workFlowStepIcon[workflowStep.VIEWING_REQUESTED]} {Messages.t('VIEWING_REQUESTED.clientAction')}
    </span>
  ),
  targetWorkflowStep: workflowStep.VIEWING_REQUESTED,
  type: CLIENT_ACTION_TYPE_DIALOG,
  buttonType: 'primary',
  showModal: true,
  clientRedirection: CLIENT_TRACK,
};

const clientActionTrash: ClientAction = {
  getDisplayName: () => Messages.t('TRASH.clientAction'),
  targetWorkflowStep: workflowStep.TRASH,
  type: CLIENT_ACTION_TYPE_DIALOG,
  buttonType: 'secondary',
  showModal: true,
  clientRedirection: CLIENT_TRACK,
};

const clientActionViewingDone: ClientAction = {
  getDisplayName: () => <span>{workFlowStepIcon[workflowStep.VIEWED]} {Messages.t('VIEWED.clientAction')}</span>,
  targetWorkflowStep: workflowStep.VIEWED,
  type: CLIENT_ACTION_TYPE_DIRECT,
  buttonType: 'primary',
  showModal: true,
  clientRedirection: CLIENT_TRACK,
};

const clientActionApply: ClientAction = {
  getDisplayName: () => Messages.t('TO_APPLY.clientAction'),
  targetWorkflowStep: workflowStep.TO_APPLY,
  type: CLIENT_ACTION_TYPE_DIALOG,
  buttonType: 'primary',
  showModal: true,
  clientRedirection: CLIENT_TRACK,
};

const clientActionMoveIn: ClientAction = {
  getDisplayName: () => <span>{workFlowStepIcon[workflowStep.AGREEMENT]} {Messages.t('AGREEMENT.clientAction')}</span>,
  targetWorkflowStep: workflowStep.AGREEMENT,
  type: CLIENT_ACTION_TYPE_DIALOG,
  buttonType: 'primary',
  showModal: true,
  clientRedirection: CLIENT_TRACK,
};

export const workflowStepClientAction: WorkflowStepAction = {
  REVIEW: undefined,
  SHORTLIST: undefined,
  SENT: undefined,
  MATCH: undefined,
  NOT_SURE: clientActionNotSure,
  VIEWING_REQUESTED: clientActionRequestViewing,
  BOOKED: undefined,
  VIEWED: clientActionViewingDone,
  TO_APPLY: clientActionApply,
  APPLIED: undefined,
  APPLICATION_ACCEPTED: undefined,
  AGREEMENT: clientActionMoveIn,
  SIGNED: undefined,
  READY_TO_SIGN: undefined,
  DISCARDED: undefined,
  NOT_INTERESTED: clientActionNotIntersted,
  TRASH: clientActionTrash,
};

const WorkflowStepMetaData: Map<string, WorkflowStepMetaDataType> = new Map([
  [
    workflowStep.REVIEW,
    {
      getDisplayName: () => Messages.t('REVIEW.displayName'),
      getClientDisplayName: () => Messages.t('REVIEW.clientDisplayName'),
      clientActions: [
        clientActionRequestViewing,
        clientActionNotIntersted,
        clientActionNotSure,
      ],
      type: 'matcher',
    },
  ],
  [
    workflowStep.SHORTLIST,
    {
      getDisplayName: () => Messages.t('SHORTLIST.displayName'),
      getClientDisplayName: () => Messages.t('SHORTLIST.clientDisplayName'),
      clientActions: null,
      type: 'matcher',
    },
  ],
  [
    workflowStep.SENT,
    {
      getDisplayName: () => Messages.t('SENT.displayName'),
      getClientDisplayName: () => Messages.t('SENT.clientDisplayName'),
      clientActions: [
        clientActionRequestViewing,
        clientActionNotIntersted,
        clientActionNotSure,
      ],
      type: 'matcher',
    },
  ],
  [
    workflowStep.NOT_SURE,
    {
      getDisplayName: () => Messages.t('NOT_SURE.displayName'),
      getClientDisplayName: () => Messages.t('NOT_SURE.clientDisplayName'),
      clientDialog: {
        getTitle: () => Messages.t('NOT_SURE.dialog.title'),
        getText: () => Messages.t('NOT_SURE.dialog.text'),
      },
      clientActions: [
        clientActionRequestViewing,
        clientActionNotIntersted,
      ],
      type: 'client',
    },
  ],
  [
    workflowStep.VIEWING_REQUESTED,
    {
      getDisplayName: () => Messages.t('VIEWING_REQUESTED.displayName'),
      getClientDisplayName: () => Messages.t('VIEWING_REQUESTED.clientDisplayName'),
      clientDialog: {
        getTitle: () => Messages.t('VIEWING_REQUESTED.dialog.title'),
        getText: () => Messages.t('VIEWING_REQUESTED.dialog.text'),
      },
      clientActions: [
        clientActionTrash,
      ],
      type: 'client',
    },
  ],
  [
    workflowStep.BOOKED,
    {
      getDisplayName: () => Messages.t('BOOKED.displayName'),
      getClientDisplayName: () => Messages.t('BOOKED.clientDisplayName'),
      clientActions: [
        clientActionViewingDone,
        clientActionTrash,
      ],
      type: 'matcher',
    },
  ],
  [
    workflowStep.VIEWED,
    {
      getDisplayName: () => Messages.t('VIEWED.displayName'),
      getClientDisplayName: () => Messages.t('VIEWED.clientDisplayName'),
      clientDialog: {
        getTitle: () => Messages.t('VIEWED.dialog.title'),
        getText: () => Messages.t('VIEWED.dialog.text'),
      },
      clientActions: [
        clientActionApply,
        clientActionTrash,
      ],
      type: 'client',
    },
  ],
  [
    workflowStep.TO_APPLY,
    {
      getDisplayName: () => Messages.t('TO_APPLY.displayName'),
      getClientDisplayName: () => Messages.t('TO_APPLY.clientDisplayName'),
      clientDialog: {
        getTitle: () => Messages.t('TO_APPLY.dialog.title'),
        getText: () => Messages.t('TO_APPLY.dialog.text'),
      },
      clientActions: [
        clientActionTrash,
      ],
      type: 'client',
    },
  ],
  [
    workflowStep.APPLIED,
    {
      getDisplayName: () => Messages.t('APPLIED.displayName'),
      getClientDisplayName: () => Messages.t('APPLIED.clientDisplayName'),
      clientActions: [
        clientActionTrash,
      ],
      type: 'matcher',
    },
  ],
  [
    workflowStep.APPLICATION_ACCEPTED,
    {
      getDisplayName: () => Messages.t('APPLICATION_ACCEPTED.displayName'),
      getClientDisplayName: () => Messages.t('APPLICATION_ACCEPTED.clientDisplayName'),
      clientActions: [
        clientActionMoveIn,
        clientActionTrash,
      ],
      type: 'matcher',
    },
  ],
  [
    workflowStep.AGREEMENT,
    {
      getDisplayName: () => Messages.t('AGREEMENT.displayName'),
      getClientDisplayName: () => Messages.t('AGREEMENT.clientDisplayName'),
      clientDialog: {
        getTitle: () => Messages.t('AGREEMENT.dialog.title'),
        getText: () => Messages.t('AGREEMENT.dialog.text'),
      },
      clientActions: [],
      type: 'matcher',
    },
  ],
  [
    workflowStep.SIGNED,
    {
      getDisplayName: () => Messages.t('SIGNED.displayName'),
      getClientDisplayName: () => Messages.t('SIGNED.clientDisplayName'),
      clientActions: [],
      type: 'matcher',
    },
  ],
  [
    workflowStep.READY_TO_SIGN,
    {
      getDisplayName: () => Messages.t('READY_TO_SIGN.displayName'),
      getClientDisplayName: () => Messages.t('READY_TO_SIGN.clientDisplayName'),
      clientDialog: {
        getTitle: () => Messages.t('READY_TO_SIGN.dialog.title'),
        getText: () => Messages.t('READY_TO_SIGN.dialog.text'),
      },
      clientActions: [],
      type: 'matcher',
    },
  ],
  [
    workflowStep.DISCARDED,
    {
      getDisplayName: () => Messages.t('DISCARDED.displayName'),
      getClientDisplayName: () => Messages.t('DISCARDED.clientDisplayName'),
      clientActions: [
        clientActionRequestViewing,
        clientActionNotIntersted,
        clientActionNotSure,
      ],
      type: 'matcher',
    },
  ],
  [
    workflowStep.NOT_INTERESTED,
    {
      getDisplayName: () => Messages.t('NOT_INTERESTED.displayName'),
      getClientDisplayName: () => Messages.t('NOT_INTERESTED.clientDisplayName'),
      clientDialog: {
        getTitle: () => Messages.t('NOT_INTERESTED.dialog.title'),
        getText: () => Messages.t('NOT_INTERESTED.dialog.text'),
      },
      clientActions: [
        clientActionRequestViewing,
        clientActionNotSure,
      ],
      type: 'client',
    },
  ],
  [
    workflowStep.TRASH,
    {
      getDisplayName: () => Messages.t('TRASH.displayName'),
      getClientDisplayName: () => Messages.t('TRASH.clientDisplayName'),
      clientDialog: {
        getTitle: () => Messages.t('TRASH.dialog.title'),
        getText: () => Messages.t('TRASH.dialog.text'),
      },
      clientActions: [
        clientActionRequestViewing,
        clientActionNotSure,
      ],
      type: 'client',
    },
  ],
]);

export function workflowStepMetaData(
  workflowStepParam: WorkflowStep | null,
): WorkflowStepMetaDataType {
  // Managing tensisition between MACTH and VIEWING_REQUESTED
  let finalWorkflowStep = workflowStepParam;
  if (finalWorkflowStep === workflowStep.MATCH) {
    finalWorkflowStep = workflowStep.VIEWING_REQUESTED;
  }
  if (workflowStepParam === null) {
    throw Error('Null workflow step');
  }
  const metadata = WorkflowStepMetaData.get(finalWorkflowStep || '');
  if (metadata === undefined) {
    throw Error(`Unknown workflow step : ${finalWorkflowStep}`);
  }
  return metadata;
}

export const showNotationDependingWorkflowStep = (candidate): boolean => (
  ![workflowStep.REVIEW, workflowStep.SHORTLIST]
    .includes(candidate.workflow_step)
);
