import { mapbox_access_token } from 'services/isochrone/mapbox/mapboxAccessToken';

import mbxClient from '@mapbox/mapbox-sdk';
import MbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import MapiError from '@mapbox/mapbox-sdk/lib/classes/mapi-error';
import { GeocodingError } from 'services/isochrone/geocoding';

const baseClient = new mbxClient({ accessToken: mapbox_access_token });
const geocodingClient = new MbxGeocoding(baseClient);

export default class MapboxGeocodingService {
  // doc: https://github.com/mapbox/mapbox-sdk-js/blob/master/docs/services.md#geocoding
  geocode_address(address) {
    return geocodingClient
      .forwardGeocode({
        query: address,
        countries: ['fr'],
        // language: ["fr"],
        // limit: 5,
        // proximity: "longitude,latitude",
        // types: ["country", "region", "postcode", "district", "place", "locality", "neighborhood", "address", "poi']
      })
      .send()
      .then((response) => {
        const feature = response.body.features[0];
        if (!feature) {
          throw GeocodingError.NoResult;
        }

        // return {
        //   accuracy: feature.properties.accuracy,
        //   place_name: feature.place_name,
        //   address_: feature.center
        // };
        return {
          address: feature.place_name,
          geocoded_coordinates: feature.center,
        };
      })
      .catch((error) => {
        if (error instanceof MapiError) {
          if (error.statusCode === 403) {
            throw GeocodingError.NoResult;
          }
        }
        throw error;
      });
  }
}
