import React, { useCallback } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { PropertyEditor } from 'services/propertyAttribute/PropertyAttribute';
import AutoSaveFormField from 'theme/hunter-utils/form-utils/AutoSaveFormField';
import { useProperty } from 'network/api/PropertyQueries';
import { Candidate } from 'types/candidate';
import Messages from 'services/i18n/Messages';
import { NotificationService } from 'services/notification';
import { FetchError } from 'network/Errors';

type Props = {
  attribute: any,
  autosave?: boolean,
  candidate?: Candidate,
  // classic form mode: set formValue and 'onChange'
  formValue?: any,
  onChange?: (value: any) => void,
};

// TODO refacto
export default function PropertyAttributeInput(
  {
    attribute,
    // autosave mode: set autosave and property
    autosave,
    candidate,
    // classic form mode: set formValue and 'onChange'
    formValue,
    onChange,
  }: Props,
) {
  const property = candidate ? candidate.property : null;
  const { updateProperty } = useProperty();

  const typeText = attribute.editor === PropertyEditor.TEXT;
  const typeOptionalInteger = attribute.editor === PropertyEditor.OPTIONAL_INTEGER;
  const typeOptionalBoolean = attribute.editor === PropertyEditor.OPTIONAL_BOOLEAN;

  const typeSelect = attribute.editor === PropertyEditor.SELECT;

  if (!typeText && !typeOptionalInteger && !typeOptionalBoolean && !typeSelect) {
    throw Error('Unknow property editor type');
  }

  const autosaveSubmit = useCallback(
    async (value) => {
      let finalValue = value;
      if (typeOptionalBoolean) {
        finalValue = stringToOptionalBool(finalValue);
      }

      if (candidate) {
        await updateProperty.mutateAsync({
          propertyId: candidate?.property.id,
          data: { [attribute.property_attr]: attribute.fromFormValue(finalValue) },
          candidate,
        }, {
          onError: (error: FetchError) => {
            const { status } = error;
            if (status === 500) {
              NotificationService.notifyError(Messages.t('toast.hunter.error'));
            }
          },
        });
      }
    },
    [typeOptionalBoolean, attribute, updateProperty, candidate],
  );

  const handleOnChange = useCallback(
    (event) => {
      if (onChange) {
        let { value } = event.target;
        if (typeOptionalBoolean) {
          value = stringToOptionalBool(value);
        }

        onChange(value);
      }
    },
    [onChange, typeOptionalBoolean],
  );

  const type = typeOptionalBoolean
    ? undefined
    : typeOptionalInteger
      ? 'number'
      : 'text';

  const formFieldChildren = (typeOptionalBoolean || typeSelect) ? (
    <>
      {
        attribute.editorParams.map((param) => (
          <option key={param.key} value={param.key}>
            {param.getLabel()}
          </option>
        ))
      }
    </>
  ) : false;

  let value = autosave && property ? attribute.getPropertyValue(property) : formValue;
  const isInvalid = autosave ? undefined : !attribute.isValid(value);
  if (typeOptionalBoolean) {
    value = optionalBoolToString(value);
  }

  const validator = useCallback(
    (formValueParam) => attribute.isValid(formValueParam),
    [attribute],
  );
  const invalidFeedback = (attribute.getEditor_feedback && attribute.getEditor_feedback()) || Messages.t('form.field.feedbackPositveNumber');
  return (
    <Form.Group>
      {attribute.getLongDisplayName && (
        <Form.Label>{attribute.getLongDisplayName()}</Form.Label>
      )}
      <InputGroup>
        {attribute.getDisplayName && (
          <InputGroup.Prepend>
            <InputGroup.Text>{attribute.getDisplayName()}</InputGroup.Text>
          </InputGroup.Prepend>
        )}

        {autosave ? (
          <>
            {typeOptionalBoolean ? (
              // @ts-ignore
              <AutoSaveFormField
                as="select"
                custom
                backendValue={value}
                submit={autosaveSubmit}
                validator={validator}
                invalidFeedback={invalidFeedback}
              >
                {formFieldChildren}
              </AutoSaveFormField>
            ) : (
              // @ts-ignore
              <AutoSaveFormField
                type={type}
                backendValue={value}
                submit={autosaveSubmit}
                validator={validator}
                invalidFeedback={invalidFeedback}
              />
            )}
          </>
        ) : (
          <>
            {(typeOptionalBoolean || typeSelect) ? (
              <Form.Control
                as="select"
                custom
                value={value}
                isInvalid={isInvalid}
                onChange={handleOnChange}
              >
                {formFieldChildren}
              </Form.Control>
            ) : (
              <Form.Control
                type={type}
                value={value}
                isInvalid={isInvalid}
                onChange={handleOnChange}
              />
            )}
            <Form.Control.Feedback type="invalid">
              {invalidFeedback}
            </Form.Control.Feedback>
          </>
        )}

        {attribute.unit && (
          <InputGroup.Append>
            <InputGroup.Text>{attribute.unit}</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
    </Form.Group>
  );
}

const optionalBoolToString = (value) => (value === null ? 'NONE' : value ? 'YES' : 'NO');

// @ts-ignore
const stringToOptionalBool = (value) => (value === 'YES' ? true : value === 'NO' ? false : null);
