import React, { useRef, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import TraductionInputs from 'features/hunter/pages/traductions/TraductionInputs';
import StringUtils from 'services/StringUtils';

type Props = {
  traduction: any,
  displayName: string,
  depth: number;
  higherKey: string;
};

const INLINE_KEY = 'inline';

export default function RecursiveTraduction({
  traduction, displayName, depth, higherKey,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const containerHeight = (ref.current && isOpen) ? ref.current.offsetHeight : 0;
  const extendStep = () => {
    setIsOpen(((prevState) => !prevState));
  };
  // eslint-disable-next-line no-undef
  const CustomTag = `h${depth}` as keyof JSX.IntrinsicElements;

  return (
    <div>
      <button type="button" className="traduction-title" onClick={extendStep}>
        <CustomTag>{StringUtils.prettyCamelCase(displayName)}</CustomTag>
        {
          traduction[INLINE_KEY] && (
            <ChevronRightIcon className={`extend-icon ${isOpen ? 'extended' : ''}`} />
          )
        }
      </button>
      <div className="traduction-recursive">
        <div style={{ height: containerHeight }} className="traduction-list-container">
          <div style={{ height: containerHeight }} className="delimiter" />
          <div ref={ref} className="traduction-list-content">
            {
              traduction[INLINE_KEY]
              && traduction[INLINE_KEY].sort((a, b) => a.localeCompare(b)).map((key) => (
                <TraductionInputs
                  key={key}
                  traductionKey={`${higherKey}${higherKey ? '.' : ''}${key}`}
                  label={key}
                />
              ))
            }
          </div>
        </div>
        {
          Object.keys(traduction).sort((a, b) => a.localeCompare(b)).map((key) => (
            key !== INLINE_KEY && (
              <RecursiveTraduction
                key={key}
                higherKey={`${higherKey}${higherKey ? '.' : ''}${key}`}
                depth={depth + 1}
                traduction={traduction[key]}
                displayName={key}
              />
            )
          ))
        }
      </div>
    </div>
  );
}
