import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useBackend } from 'network/BackendFetchAdapter';
import { CommentPartial, CommentType, MarkAsReadCommentType } from 'types/Comment';

export function useComments() {
  const backend = useBackend();

  const GetComments = (candidate_id, enabled = true) => useQuery<CommentType[]>(
    ['getComments', candidate_id],
    async () => {
      const response = await backend.fetchJson(
        `/api/comments/?candidate=${candidate_id}`,
      );
      return response;
    },
    { enabled },
  );

  const GetSearchComments = (search_id, enabled = true) => useQuery<CommentType[]>(
    ['getSearchComments', search_id],
    async () => {
      const response = await backend.fetchJson(
        `/api/comments/?candidate__research_id=${search_id}`,
      );
      return response;
    },
    { enabled },
  );

  return {
    getComments: GetComments,
    getSearchComments: GetSearchComments,
  };
}

export function useComment() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const CreateComment = useMutation<any, any, { candidateId: string, data: CommentPartial }>(
    async ({ candidateId, data }) => {
      data.candidate = candidateId;
      const response = await backend.post('/api/comments/', data);
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(['getComments', data.response.candidate]);
      },
    },
  );

  const UpdateComment = useMutation<any, any, CommentPartial>(
    async (data) => {
      const response = await backend.patch(`/api/comments/${data.id}/`, data);
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(['getComments', data.candidate]);
      },
    },
  );
  const MarkCommentsAsRead = useMutation<any, any, MarkAsReadCommentType>(
    async (data) => {
      const response = await backend.post('/api/comments/mark_as_read/', data);
      return response;
    },
    {
      onSuccess: (_, { candidate }) => {
        queryClient.refetchQueries(['getComments', candidate]);
      },
    },
  );

  const DeleteComment = useMutation<any, any, CommentType>(
    async (data) => {
      const response = await backend.delete(`/api/comments/${data.id}`);
      return response;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.refetchQueries(['getComments', variables.candidate]);
      },
    },
  );

  return {
    createComment: CreateComment,
    updateComment: UpdateComment,
    deleteComment: DeleteComment,
    markCommentsAsRead: MarkCommentsAsRead,
  };
}
