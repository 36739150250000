import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch, useLocation,
  useParams,
} from 'react-router-dom';
import {
  CANDIDATE_QUERY_ID,
  CLIENT_ADD,
  CLIENT_DISCOVER,
  CLIENT_SHORTLIST_OPTIONAL,
  CLIENT_TRACK_OPTIONAL,
  COLLECT_QUERY,
  MAP_QUERY_STEP,
  SEARCH_QUERY,
  VIEWING_REPORT_QUERY,
  WORKFLOW_STEP_QUERY,
} from 'routes/ClientRoutes';
import Messages from 'services/i18n/Messages';
import Discover from 'features/client/pages/discover/Discover';
import Track from 'features/client/pages/track/Track';
import Shortlist from 'features/client/pages/shortlist/Shortlist';
import Add from 'features/client/pages/add/Add';
import { RESEARCH_ID, Routes } from 'routes/Routes';
import Map from 'features/client/pages/map/Map';
import { WorkflowStep } from 'types/WorkflowStep';
import CandidateDetails from 'features/client/pages/candidateDetails/CandidateDetails';
import { Research } from 'types/research';
import { detectMobile } from 'services/utils';
import WorkflowStepList from 'features/client/pages/workflowStepList/WorkflowStepList';
import Collect from 'features/client/pages/collect/Collect';
import ViewingReport from 'features/client/pages/viewingReport/ViewingReport';
import Search from 'features/client/pages/search/Search';
import ChatCounter from "../common/chat/ChatCounter";
import Chat from "../common/chat/Chat";

type Params = {
  researchId: string,
};

type Props = {
  research?: Research
};

export default function ClientRouter({ research }: Props) {
  const { researchId } = useParams<Params>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const candidateId = queryParams.get(CANDIDATE_QUERY_ID);
  const mapStep = queryParams.get(MAP_QUERY_STEP);
  const workflowStepQuery = queryParams.get(WORKFLOW_STEP_QUERY);
  const collectQuery = queryParams.get(COLLECT_QUERY);
  const viewingQuery = queryParams.get(VIEWING_REPORT_QUERY);
  const searchQuery = queryParams.get(SEARCH_QUERY);

  const isMobile = detectMobile();
  const currentUser = research && {
    id: research.client_email,
    name: `${research.client_first_name} ${research.client_last_name}`,
    email: research.client_email,
    role: 'default',
  };
  const otherUser = research && {
    email: research.homematcher_email,
    id: research.homematcher_email,
    name: 'Service client',
    welcomeMessage: Messages.t('chat.matcher.welcomeMessage'),
    role: 'matcher',
  };
  useEffect(() => {
    let campaign;
    if (research) {
      let campainId = 'pyIlEk9lGbLG8nEMELz7BrpbHVk';
      if (research.language && research.language === 'fr') {
        campainId = '0WbODvbvassBaGQBPvjmVcdvM3Q';
      }
      // @ts-ignore
      // eslint-disable-next-line no-undef
      campaign = VL.load(campainId);
      campaign.identify({
        firstname: research.client_first_name,
        lastname: research.client_last_name,
        email: research.client_email,
        createdAt: Math.floor(new Date(research.created_at).getTime() / 1000),
      }, () => {
        campaign.widgets.load();
      });
    }
    return () => (campaign ? campaign.logout() : {});
  }, [research?.id]);

  return (
    <div className="client-container">
      {
        (isMobile || (!mapStep && !candidateId)) && (
          <div className="client-content">
            <Switch>
              <Route
                path={CLIENT_DISCOVER}
                render={() => (
                  <Discover />
                )}
              />
              <Route path={CLIENT_TRACK_OPTIONAL} component={Track} />
              <Route path={CLIENT_SHORTLIST_OPTIONAL} component={Shortlist} />
              <Route path={CLIENT_ADD} component={Add} />
              <Route render={() => (
                <Redirect to={Routes.withPath(
                  CLIENT_DISCOVER,
                  [{ label: RESEARCH_ID, value: researchId }],
                )}
                />
              )}
              />
            </Switch>
          </div>
        )
      }
      {
        workflowStepQuery && !candidateId && (
          <WorkflowStepList step={workflowStepQuery as WorkflowStep} />
        )
      }
      {
        searchQuery && research && (
          <Search research={research} />
        )
      }
      {
        viewingQuery && research && (
          <ViewingReport research={research} />
        )
      }
      {
        collectQuery && research && (
          <Collect research={research} />
        )
      }
      {
        mapStep && !candidateId && (
          <Map mapStep={mapStep as WorkflowStep} />
        )
      }
      {
        candidateId && (
          <CandidateDetails research={research} candidateId={candidateId} />
        )
      }
      { research
        && (
          <div>
            <ChatCounter setCounter conversationId={researchId} currentUser={currentUser}>
              <Chat showCloseInHeader currentUser={currentUser} research={otherUser} />
            </ChatCounter>
          </div>
          )}
    </div>
  );
}
