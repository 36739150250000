import React from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  label?: string,
  values: { key: string, label: (string | React.ReactNode) }[],
  error: FieldErrors,
  row?: boolean,
  apiErrors?: { [key: string]: string[] }
  hideButton?: boolean
  onChange?: (value: string) => void
};

export default function RadioWrapper(
  {
    control,
    label,
    values,
    error,
    row,
    hideButton,
    onChange,
    apiErrors,
  }: Props,
) {
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);

  return (
    <div className={`material-radio-wrapper ${hideButton ? 'hide-button' : ''}`}>
      <FormControl variant="standard" fullWidth error={!!errorMessage}>
        {
          label && (
            <FormLabel>{label}</FormLabel>
          )
        }
        <RadioGroup
          {...field}
          row={row}
        >
          {
            values.map((value) => (
              <FormControlLabel
                classes={{
                  root: field.value === value.key ? 'radio-selected' : '',
                }}
                key={value.key}
                value={value.key}
                onClick={() => {
                  if (onChange) {
                    onChange(value.key);
                  }
                }}
                label={<span>{value.label}</span>}
                control={<Radio />}
              />
            ))
          }
        </RadioGroup>
        {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
}
