import { Enum } from 'lib/type/enum';

export type ElectricityStatusType =
  'PROVIDER_SELECTED'
  | 'ACCOUNT_CREATED'
  | 'ENERGY_ON';

export const electricityStatusTypeEnum: Enum<ElectricityStatusType> = {
  PROVIDER_SELECTED: 'PROVIDER_SELECTED',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
  ENERGY_ON: 'ENERGY_ON',
};
