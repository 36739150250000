import React, { useState, useEffect } from 'react';
import { useProperty } from 'network/api/PropertyQueries';
import Messages from 'services/i18n/Messages';
import { NotificationService } from 'services/notification';
import { Candidate, CandidatePartial } from 'types/candidate';
import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { PropertyPartial } from 'types/property';
import AddressAutocompleteWrapper from 'lib/form/AddressAutocompleteWrapper';
import { useCandidate } from 'network/api/CandidateQueries';
import { FetchError } from 'network/Errors';
import PropertyAttributeInput
  from 'features/hunter/pages/research/researchDetails/candidateDetails/property/PropertyAttributeInput';
import { PropertyAttribute } from 'services/propertyAttribute/PropertyAttribute';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import CandidateMap from 'theme/CandidateMap';
import { urlRegex } from 'lib/form/FormUtils';
import { domainFromUrl } from 'services/utils';

type Props = {
  candidate: Candidate,
  onlyAddress?: boolean,
};

export default function PropertyAddressDescriptionUrlForm({ candidate, onlyAddress }: Props) {
  const [showMap, setShowMap] = useState(false);
  const { property } = candidate;
  const { updateCandidate } = useCandidate();

  const { updateProperty } = useProperty();
  const [apiErrors, setApiError] = useState({});

  const {
    control,
    trigger,
    formState: { errors },
  } = useForm<PropertyPartial>({
    defaultValues: {
      ...property,
      description: candidate.property.description?.replaceAll('\\n', '\n'),
    },
  });

  useEffect(() => {
    // loading the map in the same time with the address autocomplete can cause error in google API
    const timer = setTimeout(() => {
      setShowMap(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, [setShowMap]);

  const handleSubmit = async (data: PropertyPartial) => {
    setApiError({});
    await updateProperty.mutateAsync({
      propertyId: candidate.property.id,
      data,
      candidate,
    }, {
      onSuccess: () => {
        NotificationService.notifySuccess(Messages.t('toast.hunter.propertyUpdated'));
      },
      onError: (error: FetchError) => {
        setApiError(error.json_response);
      },
    });
  };
  const handleSubmitCandidate = async (data: CandidatePartial) => {
    setApiError({});
    await updateCandidate.mutateAsync({
      data,
      candidate,
    }, {
      onSuccess: () => {
        NotificationService.notifySuccess(Messages.t('toast.hunter.candidateUpdate'));
      },
      onError: (error: FetchError) => {
        setApiError(error.json_response);
      },
    });
  };

  return (
    <form className="feature-form">
      <div className="feature-checkboxes">
        <div>
          <FormControlLabel
            control={
              (
                <Checkbox
                  onChange={(e) => handleSubmit({ available: e.target.checked })}
                  checked={property.available}
                />
              )
            }
            label={Messages.t('form.field.available')}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              (
                <Checkbox
                  onChange={
                    (e) => handleSubmitCandidate({ added_by_client: e.target.checked })
                  }
                  checked={candidate.added_by_client}
                />
              )
            }
            label={Messages.t('candidate.card.badge.added_by_client')}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              (
                <Checkbox
                  onChange={(e) => handleSubmit({ gli_asked: e.target.checked })}
                  checked={property.gli_asked}
                />
              )
            }
            label={Messages.t('form.field.gliRequested')}
          />
        </div>
      </div>
      <div>
        <Controller
          name="address"
          control={control}
          render={(controller) => (
            <AddressAutocompleteWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              placeholder=""
              onCoordinateChanged={(value) => handleSubmit(value)}
              autoSaveSubmit={(value) => handleSubmit({ address: value })}
              onPostalCodeChanged={(postal_code) => handleSubmit({ postal_code })}
              label={Messages.t('form.field.address')}
            />
          )}
        />
        {!onlyAddress && (
          <PropertyAttributeInput
            attribute={PropertyAttribute.POSTAL_CODE}
            autosave
            candidate={candidate}
          />
        )}
      </div>
      {!onlyAddress && (
        <>
          <Controller
            name="description"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                type="textarea"
                rows={10}
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                trigger={trigger}
                autoSaveSubmit={(value) => handleSubmit({ description: value })}
                label={Messages.t('form.field.description')}
              />
            )}
          />
          <div className="candidate-map">
            {
              showMap && <CandidateMap candidate={candidate} />
            }
          </div>
          <Controller
            name="source_url"
            control={control}
            rules={
              {
                required: true,
                pattern: {
                  value: urlRegex,
                  message: Messages.t('form.error.url'),
                },
              }
            }
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                trigger={trigger}
                autoSaveSubmit={(value) => handleSubmit({
                  source_url: value,
                  source_website: domainFromUrl(value) || undefined,
                })}
                label={Messages.t('form.field.url')}
              />
            )}
          />
        </>
      )}
    </form>
  );
}
