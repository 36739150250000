import React, { MouseEventHandler, ReactNode } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  label: ReactNode
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  onClick?: MouseEventHandler
  onChange?: (value: boolean) => void
};

export default function CheckboxWrapper(
  {
    control,
    label,
    error,
    apiErrors,
    onClick,
    onChange,
  }: Props,
) {
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);

  return (
    <div className="material-textfield-wrapper">
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              {...field}
              value={!!field.value}
              checked={!!field.value}
              onClick={(e) => {
                if (onClick) {
                  onClick(e);
                }
                if (onChange) {
                  onChange(!field.value);
                }
              }}
            />
          )}
          label={label}
        />
        {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormGroup>
    </div>
  );
}
