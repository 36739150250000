import React, { useState, useMemo } from 'react';
import 'react-phone-number-input/style.css';
import ContactInfoForm from 'features/hunter/pages/research/researchDetails/candidateDetails/property/forms/ContactInfoForm';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { BiPlus } from 'react-icons/bi';
import CancelIcon from '@material-ui/icons/Cancel';
import { PropertyContact } from 'types/propertyContact';
import { Property } from 'types/property';
import { Candidate } from 'types/candidate';
import Messages from 'services/i18n/Messages';

type Props = {
  candidate: Candidate,
  contacts: PropertyContact[],
  property: Property,
  refetchContacts: () => void
};

export default function PropertyContactInfo(
  {
    candidate,
    contacts,
    property,
    refetchContacts,
  }: Props,
) {
  const [selectedContactId, setSelectedContactId] = useState<number | string>(property.main_contact || '');
  const [expandNewContact, setExpandNewContact] = useState(false);

  const selectedContact = useMemo(
    () => contacts.find((contact) => contact.id === selectedContactId),
    [contacts, selectedContactId],
  );

  const handleChange = (event) => {
    setExpandNewContact(false);
    setSelectedContactId(event.target.value !== 'action' ? event.target.value : '');
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl style={{ minWidth: 300 }}>
          <InputLabel>{Messages.t('contacts.selectToEdit')}</InputLabel>
          <Select
            value={selectedContactId}
            onChange={handleChange}
            displayEmpty
            label={Messages.t('contacts.selectToEdit')}
          >
            {contacts.map((contact) => (
              <MenuItem
                key={contact.id}
                value={contact.id}
              >
                {contact.agency_name ? `${contact.agency_name} ` : null}{contact.first_name} {contact.last_name}
              </MenuItem>
            ))}
            <MenuItem
              className="select-action"
              value="action"
              key="action"
              onClick={() => {
                setExpandNewContact(!expandNewContact);
              }}
            >
              {Messages.t('contacts.addNew')}
            </MenuItem>
          </Select>
        </FormControl>
        {selectedContactId && (
          <IconButton onClick={() => setSelectedContactId('')}>
            <CancelIcon />
          </IconButton>
        )}
        {
          selectedContact && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                setExpandNewContact(!expandNewContact);
                setSelectedContactId('');
              }}
            >
              <BiPlus />&nbsp; {Messages.t('contacts.addNew')}
            </Button>
          )
        }
      </div>
      {!selectedContactId && (
        <ContactInfoForm
          candidate={candidate}
          property={property}
          propertyId={property.id}
          isFirstPropertyContact={contacts.length === 0}
          onValidate={(contactId) => {
            refetchContacts();
            setSelectedContactId(contactId);
          }}
        />
      )}
      {selectedContact && (
        <ContactInfoForm
          candidate={candidate}
          key={selectedContactId}
          property={property}
          propertyId={property.id}
          isFirstPropertyContact={contacts.length === 0}
          contact={selectedContact}
          onValidate={(contactId) => {
            refetchContacts();
            setSelectedContactId(contactId);
          }}
        />
      )}
    </>
  );
}
