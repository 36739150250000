import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from 'react-query';
import { useBackend } from 'network/BackendFetchAdapter';
import { Candidate, CandidatePartial, CandidateResearch } from 'types/candidate';
import { PaginationWrapper } from 'types/pagination';
import { WorkflowStepCount } from 'types/WorkflowStepCount';
import { CandidateAddByUrl } from 'types/candidateAddByUrl';
import { ViewingBookFormType } from 'types/forms/ViewingBookForm';
import { ViewingAppointment } from 'types/ViewingAppointment';
import { InfiniteData } from 'react-query/types/core/types';
import { WorkflowStep, workflowStep } from 'types/WorkflowStep';
import { Opinion, OpinionForm } from 'types/Opinion';
import candidateFilterService from 'services/CandidateFilterService';
import clientFilterService from 'services/client/ClientFilterService';

export function useCandidates() {
  const backend = useBackend();

  const GetCandidatesForStep = (
    research_id,
    step: string,
    orderingBy,
    enabled = true,
    pageSize = 30,
    isClient = true,
    onSuccess?: (data: InfiniteData<PaginationWrapper<Candidate>>) => void,
  ) => useInfiniteQuery<PaginationWrapper<Candidate>>(
    ['getCandidatesForStep', { research_id, step, orderingBy }],
    async ({ pageParam = 1 }) => {
      const ordering = orderingBy;
      const steps = (step === workflowStep.REVIEW && isClient)
        ? [workflowStep.REVIEW, workflowStep.SENT]
        : [step];
      const response = await backend.fetchJson(
        `/api/candidates/?research=${research_id}&workflow_step__in=${steps.join(',')}&page=${pageParam}&ordering=${ordering}&page_size=${pageSize}`,
      );
      return response;
    },
    {
      onSuccess,
      enabled,
      getNextPageParam: (lastPage, allPages) => lastPage.next && allPages.length + 1,
    },
  );

  const SearchCandidates = (
    search,
    email,
    enabled = true,
  ) => useQuery<PaginationWrapper<CandidateResearch>>(
    ['searchCandidates', { search }],
    async () => {
      const response = await backend.fetchJson(
        `/api/candidates/?search=${search}&research__homematcher_mail=${email}&ordering=-workflow_step_changed_date&page_size=100&research__status=IN_PROGRESS`,
      );
      return response;
    },
    { enabled },
  );

  const GetCandidatesWithContact = (
    contactId,
    enabled = true,
  ) => useQuery<PaginationWrapper<CandidateResearch>>(
    ['getCandidatesWithContact', { contactId }],
    async () => {
      const response = await backend.fetchJson(
        `/api/candidates/?proprty__propertycontact=${contactId}&workflow_step__not=REVIEW`,
      );
      return response;
    },
    { enabled },
  );

  const GetCountPerStep = (research_id, enabled = true) => useQuery<WorkflowStepCount[]>(
    ['countCandidatesPerStep', { research_id }],
    async () => {
      const response = await backend.fetchJson(
        `/api/candidates/count_by_workflow_steps/?research=${research_id}`,
      );
      return response;
    },
    { enabled },
  );

  const NextClientIdentifier = (research_id, enabled = true) => useQuery<any>(
    ['nextClientIdentifier', { research_id }],
    async () => {
      const response = await backend.fetchJson(
        `/api/candidates/next_client_identifier/${research_id}`,
      );
      return response;
    },
    { enabled },
  );

  return {
    searchCandidates: SearchCandidates,
    getCandidatesForStep: GetCandidatesForStep,
    getCountPerStep: GetCountPerStep,
    nextClientIdentifier: NextClientIdentifier,
    getCandidatesWithContact: GetCandidatesWithContact,
  };
}

export function useCandidate() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const GetCandidate = (
    candidate_id: string,
    onSuccess?: (data: Candidate) => void,
    enabled = true,
  ) => useQuery<Candidate>(
    ['getCandidate', { candidate_id }],
    async () => {
      const response = await backend.fetchJson(
        `/api/candidates/${candidate_id}/`,
      );
      return response;
    },
    { enabled, onSuccess },
  );

  const AddCandidateByUrl = useMutation<any, any, CandidateAddByUrl>(
    async (data) => {
      const response = await backend.post('/api/candidates/', data);
      return response;
    },
    {
      onSuccess: (data) => {
        if (data.status !== 200) {
          return;
        }

        const candidate = data.response;
        queryClient.refetchQueries([
          'countCandidatesPerStep',
          { research_id: candidate.research },
        ]);
        queryClient.setQueryData<InfiniteData<PaginationWrapper<Candidate>> | undefined>(
          [
            'getCandidatesForStep',
            {
              research_id: candidate.research,
              step: candidate.workflow_step,
              orderingBy: candidateFilterService.getSort(candidate.workflow_step).ordering,
            },
          ],
          (oldData) => {
            if (oldData) {
              oldData.pages[0].results.push(data.response);
              return oldData;
            }
            return {
              pageParams: [],
              pages: [{
                count: 1,
                next: null,
                previous: null,
                results: [data.response],
              }],
            };
          },
        );
      },
    },
  );

  const UpdateCandidate = useMutation<any, any, { candidate: Candidate, data: CandidatePartial }>(
    async ({ candidate, data }) => {
      const response = await backend.patch(
        `/api/candidates/${candidate.id}/`,
        data,
      );
      return response;
    },
    {
      onSuccess: (data, variables) => {
        const { candidate } = variables;
        queryClient.setQueryData(
          ['getCandidate', { candidate_id: candidate.id.toString() }],
          data,
        );
        queryClient.setQueryData<InfiniteData<PaginationWrapper<Candidate>> | undefined>(
          [
            'getCandidatesForStep',
            {
              research_id: candidate.research,
              step: candidate.workflow_step,
              orderingBy: candidateFilterService.getSort(candidate.workflow_step).ordering,
            },
          ],
          (oldData) => oldData && {
            ...oldData,
            pages: oldData.pages
              .map((oldPage) => (
                {
                  ...oldPage,
                  results: oldPage.results
                    .map((old) => (old.id === candidate.id ? data : old)),
                })),
          },
        );
      },
    },
  );

  const CreateCandidatePostSearch = useMutation<any, any, { candidateId: string }>(
    async ({ candidateId }) => {
      const response = await backend.post(`/api/candidates/${candidateId}/post-searchs/`);
      return response;
    },
    {
      onSuccess: (_, { candidateId }) => {
        queryClient.refetchQueries(['getCandidate', { candidateId }]);
      },
    },
  );

  const UpdateCandidateStep = useMutation<any, any, any>(
    async ({ candidate, data }) => {
      const response = await backend.patch(
        `/api/candidates/${candidate.id}/`,
        data,
      );
      return response;
    },
    {
      onSuccess: (_, variables) => {
        const { candidate } = variables;
        queryClient.setQueryData<InfiniteData<PaginationWrapper<Candidate>> | undefined>(
          [
            'getCandidatesForStep',
            {
              research_id: candidate.research,
              step: candidate.workflow_step,
              orderingBy: candidateFilterService.getSort(candidate.workflow_step).ordering,
            },
          ],
          (oldData) => oldData && ({
            ...oldData,
            pages: oldData?.pages.map((oldpage) => ({
              ...oldpage,
              results: oldpage.results.filter((old) => old.id !== candidate.id),
            })),
          }),
        );
        queryClient.refetchQueries([
          'countCandidatesPerStep',
          { research_id: candidate.research },
        ]);
      },
    },
  );

  const UpdateHMPickClientCandidateStep = useMutation<any, any, any>(
    async ({ candidate, data }) => {
      const response = await backend.patch(
        `/api/candidates/${candidate.id}/`,
        data,
      );
      return response;
    },
    {
      onSuccess: (_, variables) => {
        const { candidate } = variables;
        const step = clientFilterService.getFilterValue().onlyHmPicks
          ? workflowStep.SENT
          : workflowStep.REVIEW;
        queryClient.setQueryData<InfiniteData<PaginationWrapper<Candidate>> | undefined>(
          [
            'getCandidatesForStep',
            {
              research_id: candidate.research,
              step,
              orderingBy: candidateFilterService.getSort(step).ordering,
            },
          ],
          (oldData) => oldData && ({
            ...oldData,
            pages: oldData?.pages.map((oldpage) => ({
              ...oldpage,
              results: oldpage.results.filter((old) => old.id !== candidate.id),
            })),
          }),
        );
        queryClient.refetchQueries([
          'countCandidatesPerStep',
          { research_id: candidate.research },
        ]);
      },
    },
  );

  const CreateCandidateByProperty = useMutation<any, any, any>(
    async (data) => {
      const response = await backend.post(
        '/api/candidates/create_by_property/',
        data,
      );
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries([
          'getCandidatesForStep',
          {
            research_id: data.response.research,
            step: data.response.workflow_step,
            orderingBy: candidateFilterService.getSort(data.response.workflow_step).ordering,
          },
        ]);
      },
    },
  );

  const ReportCandidates = useMutation<void, any, {
    candidate_id_list: string[],
    researchId: string,
    step: WorkflowStep,
  }>(
    async ({ candidate_id_list }) => {
      await backend.post(
        '/api/candidates/scams/',
        { candidate_id_list },
      );
    },
    {
      onSuccess: (_, params) => {
        queryClient.refetchQueries([
          'getCandidatesForStep',
          {
            research_id: params.researchId,
            step: params.step,
            orderingBy: candidateFilterService.getSort(params.step).ordering,
          },
        ]);
      },
    },
  );

  return {
    createCandidatePostSearch: CreateCandidatePostSearch,
    getCandidate: GetCandidate,
    addCandidateByUrl: AddCandidateByUrl,
    createCandidateByProperty: CreateCandidateByProperty,
    updateCandidate: UpdateCandidate,
    updateCandidateStep: UpdateCandidateStep,
    reportCandidates: ReportCandidates,
    updateHMPickCandidateStep: UpdateHMPickClientCandidateStep,
  };
}

export function useViewingAppointment() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const GetViewingAppointment = (candidateId, enabled = true) => useQuery<ViewingAppointment[]>(
    ['getViewingAppointment', candidateId],
    async () => {
      const response = await backend.fetchJson(
        `/api/viewing-appointments/?candidate=${candidateId}`,
      );
      return response;
    },
    { enabled },
  );

  const UpdateViewingAppointment = useMutation<any, any, {
    viewingAppointmentId: string,
    data: ViewingBookFormType
    reserachId: string,
  }>(
    async ({ viewingAppointmentId, data }) => {
      const response = await backend.patch(
        `/api/viewing-appointments/${viewingAppointmentId}/`,
        data,
      );
      return response;
    },
    {
      onSuccess: (data, { reserachId }) => {
        queryClient.refetchQueries(['getViewingAppointment', data.candidate]);
        queryClient.refetchQueries([
          'getResearch',
          reserachId,
        ]);
      },
    },
  );

  const CreateViewingAppointment = useMutation<any, any, {
    candidateId: string,
    data: ViewingBookFormType
    reserachId: string,
  }>(
    async ({ candidateId, data }) => {
      data.candidate = candidateId;
      const response = await backend.post('/api/viewing-appointments/', data);
      return response;
    },
    {
      onSuccess: (data, { reserachId }) => {
        queryClient.refetchQueries([
          'getViewingAppointment',
          data.response.candidate,
        ]);
        queryClient.refetchQueries([
          'getResearch',
          reserachId,
        ]);
      },
    },
  );

  return {
    getViewingAppointment: GetViewingAppointment,
    createViewingAppointment: CreateViewingAppointment,
    updateViewingAppointment: UpdateViewingAppointment,
  };
}

export function useOpinion() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const GetOpinion = (candidateId, enabled = true) => useQuery<Opinion[]>(
    ['getOpinion', candidateId],
    async () => {
      const response = await backend.fetchJson(
        `/api/opinions/?candidate=${candidateId}`,
      );
      return response;
    },
    { enabled },
  );

  const UpdateOpinion = useMutation<any, any, {
    opinionId: number, data: Opinion
  }>(
    async ({ opinionId, data }) => {
      const response = await backend.patch(
        `/api/opinions/${opinionId}/`,
        data,
      );
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(['getOpinion', data.candidate]);
      },
    },
  );

  const DeleteOpinion = useMutation<any, any, {
    opinionId: number
  }>(
    async ({ opinionId }) => {
      const response = await backend.delete(
        `/api/opinions/${opinionId}/`,
      );
      return response;
    },
  );

  const CreateOpinion = useMutation<any, any, {
    candidateId: string, data: OpinionForm
  }>(
    async ({ candidateId, data }) => {
      data.candidate = candidateId;
      const response = await backend.post('/api/opinions/', data);
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries([
          'getOpinion',
          data.response.candidate,
        ]);
      },
    },
  );

  return {
    getOpinion: GetOpinion,
    createOpinion: CreateOpinion,
    updateOpinion: UpdateOpinion,
    deleteOpinion: DeleteOpinion,
  };
}
