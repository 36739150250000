import React from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, UseFormTrigger } from 'react-hook-form';
import {
  PostSearch,
  PostSearchForm,
} from 'types/postSearch/PostSearch';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import TimelineWrapper from 'lib/form/TimelineWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import { stringToBoolean } from 'lib/form/FormUtils';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import { papernestStatusTypeEnum } from 'types/postSearch/Papernest';
import { Candidate } from 'types/candidate';
import DateTimePickerWrapper from 'lib/form/DateTimePickerWrapper';
import { Button } from '@material-ui/core';
import { usePostSearch } from 'network/api/PostSearchQueries';

type Props = {
  control: Control<PostSearchForm>,
  apiErrors: { [key: string]: string[] }
  errors: FieldErrors
  formField: PostSearchForm,
  trigger: UseFormTrigger<PostSearchForm>
  handleSubmit: (data: Partial<PostSearch>) => void
  handleSubmitCandidate: (data: Partial<Candidate>) => void
  candidateId: string,

};

export default function PapernestForm(
  {
    control,
    errors,
    candidateId,
    apiErrors,
    handleSubmit,
    trigger,
    formField,
    handleSubmitCandidate,
  }: Props,
) {
  const { createPapernestContact } = usePostSearch();

  return (
    <div>
      <Controller
        name="use_papernest"
        control={control}
        render={(controller) => (
          <SelectWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            onChange={(value) => handleSubmit({
              use_papernest: stringToBoolean(value),
            })}
            label={Messages.t('form.field.use_papernest')}
            values={[
              { key: 'true', label: Messages.t('generics.YES') },
              { key: 'false', label: Messages.t('generics.NO') },
            ]}
          />
        )}
      />
      {
        formField.use_papernest === 'true' && (
          <>
            <Controller
              name="lease_start_date"
              control={control}
              rules={{ required: true }}
              render={(controller) => (
                <DateTimePickerWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  autoSaveSubmit={(value) => handleSubmitCandidate({
                    lease_start_date: value?.toISOString(),
                  })}
                  label={Messages.t('form.field.lease_start_date')}
                />
              )}
            />
            <Button onClick={async () => {
              const res = await trigger([
                'lease_start_date',
              ]);
              if (res) {
                createPapernestContact.mutateAsync({ candidateId });
              }
            }}
            >
              {Messages.t('formButton.createPapernest')}
            </Button>
            <h5>
              {Messages.t('papernest.useFor')}
            </h5>
            <Controller
              name="papernest_electricity"
              control={control}
              render={(controller) => (
                <CheckboxWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  onChange={(value) => handleSubmit({ papernest_electricity: value })}
                  label={Messages.t('form.field.electricity')}
                />
              )}
            />
            <Controller
              name="papernest_internet"
              control={control}
              render={(controller) => (
                <CheckboxWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  onChange={(value) => handleSubmit({ papernest_internet: value })}
                  label={Messages.t('form.field.internet')}
                />
              )}
            />
            <Controller
              name="papernest_gas"
              control={control}
              render={(controller) => (
                <CheckboxWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  onChange={(value) => handleSubmit({ papernest_gas: value })}
                  label={Messages.t('form.field.gas')}
                />
              )}
            />
            <Controller
              name="papernest_status"
              control={control}
              render={(controller) => (
                <TimelineWrapper
                  control={controller}
                  onChange={(value) => handleSubmit({ papernest_status: value })}
                  values={
                    Object.values(papernestStatusTypeEnum)
                      .map((type) => ({ key: type, label: Messages.t(`papernest.status.${type}`) }))
                  }
                />
              )}
            />
          </>
        )
      }
    </div>
  );
}
