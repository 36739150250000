import React from 'react';
import { Image as CloudinaryImage, Transformation } from 'cloudinary-react';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Messages from 'services/i18n/Messages';
import Button from 'features/client/common/Button';
import { TrashIcon } from '@heroicons/react/solid';

type Props = {
  image: string,
  className?: string,
  isCloudinaryImage: boolean,
  onError: () => void,
  submitting?: boolean,
  onDelete?: () => void,
  enableDelete?: boolean,
  onclick?: () => void,
};

export default function PropertyImage(
  {
    image,
    className,
    isCloudinaryImage,
    onError,
    submitting,
    onDelete,
    enableDelete,
    onclick,
  }: Props,
) {
  if (!image && image !== '') {
    return (
      <div>
        <br />
        <p className="no-image">
          {Messages.t('property.image.noImage')} <PhotoCameraIcon />
        </p>
        <hr className="mb-3" />
      </div>
    );
  }
  return (
    <div role="presentation" onClick={onclick} className={className}>
      {
        isCloudinaryImage ? (
          <CloudinaryImage publicId={image}>
            <Transformation quality="auto" width="3000" height="600" crop="limit" />
          </CloudinaryImage>
        ) : (
          <img onError={onError} alt="property" src={image} />
        )
      }
      {
        enableDelete && (
          <Button
            disable={submitting}
            preventDefault
            onClick={(e) => {
              e.stopPropagation();
              if (onDelete) {
                onDelete();
              }
            }}
            variant="icon"
          >
            <TrashIcon />
          </Button>
        )
      }
    </div>
  );
}
