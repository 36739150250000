import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useBackend } from 'network/BackendFetchAdapter';
import { PostSearch } from 'types/postSearch/PostSearch';

export function usePostSearch() {
  const backend = useBackend();
  const queryClient = useQueryClient();

  const GetPostSearch = (postSearchId: string, enabled = true) => useQuery<PostSearch>(
    ['getPostSearch', postSearchId], async () => {
      const response = await backend.fetchJson(
        `/api/post-searchs/${postSearchId}/`,
      );
      return response;
    }, { enabled },
  );

  const UpdatePostSearch = useMutation<void, any, {
    postSearchId: string,
    data: Partial<PostSearch>,
  }>(
    async ({ postSearchId, data }) => {
      await backend.patch(
        `/api/post-searchs/${postSearchId}/`,
        data,
      );
    },
    {
      onSuccess: (_, { postSearchId }) => {
        queryClient.refetchQueries(['getPostSearch', postSearchId]);
      },
    },
  );

  const CreateSignaturePostSearch = useMutation<void, any, {
    candidateId: string,
  }>(
    async ({ candidateId }) => {
      await backend.post(
        `/api/candidates/${candidateId}/signature/`,
      );
    },
  );

  const CreatePapernestContact = useMutation<void, any, {
    candidateId: string,
  }>(
    async ({ candidateId }) => {
      await backend.post(
        `/api/candidates/${candidateId}/papernest/`,
      );
    },
  );

  const CreateRentalPostSearch = useMutation<void, any, {
    candidateId: string,
  }>(
    async ({ candidateId }) => {
      await backend.post(
        `/api/candidates/${candidateId}/rental/`,
      );
    },
  );

  const CreateAgentPostSearch = useMutation<void, any, {
    candidateId: string,
  }>(
    async ({ candidateId }) => {
      await backend.post(
        `/api/candidates/${candidateId}/agent-contact/`,
      );
    },
  );

  const CreateLukoLink = useMutation<{ link: string }, any, {
    candidateId: string,
    postSearchId: string,
  }>(
    async ({ candidateId }) => {
      const response = await backend.post(
        `/api/candidates/${candidateId}/luko/`,
      );
      return response.response as { link: string };
    },
    {
      onSuccess: (_, { postSearchId }) => {
        queryClient.refetchQueries(['getPostSearch', postSearchId]);
      },
    },
  );

  const UploadPostSearchFile = useMutation<void, any, {
    postSearchId: string,
    field: string
    data: {
      filename: string,
      fileBase64: string,
    },
  }>(
    async ({ postSearchId, data, field }) => {
      const uploadData = {
        id: null,
        post_search_id: postSearchId,
        field,
        filename: data.filename,
        file_base_64: data.fileBase64,
      };
      await backend.post(
        '/api/post-searchs/upload/',
        uploadData,
      );
    },
    {
      onSuccess: (_, { postSearchId }) => {
        queryClient.refetchQueries(['getPostSearch', postSearchId]);
      },
    },
  );

  return {
    createAgentPostSearch: CreateAgentPostSearch,
    createPapernestContact: CreatePapernestContact,
    createLukoLink: CreateLukoLink,
    createSignaturePostSearch: CreateSignaturePostSearch,
    createRentalPostSearch: CreateRentalPostSearch,
    uploadPostSearchFile: UploadPostSearchFile,
    getPostSearch: GetPostSearch,
    updatePostSearch: UpdatePostSearch,
  };
}
