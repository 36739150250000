import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useComment } from 'network/api/CommentQueries';
import { Candidate } from 'types/candidate';
import Button from 'features/client/common/Button';
import Messages from 'services/i18n/Messages';
import { RouterPrompt } from 'features/common/RouterPrompt';

type Props = {
  candidate: Candidate,
  author: string,
};

export default function AddCommentForm({ candidate, author }: Props) {
  const { createComment } = useComment();
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [dirty, setDirty] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    createComment.mutate({
      candidateId: candidate.id,
      data: {
        author,
        message,
        workflow_step: candidate.workflow_step,
      },
    }, {
      onSuccess: () => {
        setSubmitting(false);
        setMessage('');
      },
      onError: () => {
        setSubmitting(false);
      },
    });
  };

  return (
    <div className="add-comment">
      <RouterPrompt
        whenPageClose={dirty}
        when={() => dirty}
      />
      <TextField
        type="textarea"
        fullWidth
        placeholder={Messages.t('candidate.comment.placeholder')}
        rows={2}
        multiline
        value={message}
        onKeyDown={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
        onChange={(event) => {
          setMessage(event.target.value);
          setDirty(event.target.value.length > 0);
        }}
      />
      <Button
        variant="primary"
        spin={submitting}
        onClick={handleSubmit}
      >
        {Messages.t('candidate.comments.comment')}
      </Button>
    </div>
  );
}
