import {
  PathParamString,
  QueryParamString,
  RESEARCH_ID,
  Route,
} from 'routes/Routes';
import { ReactNode } from 'react';
import Messages from 'services/i18n/Messages';

export const CANDIDATE_QUERY_ID: QueryParamString = 'candidateId';
export const MAP_QUERY_STEP: QueryParamString = 'mapStep';
export const FILTER_QUERY: QueryParamString = 'filter';
export const NOTIFICATION_QUERY: QueryParamString = 'notifications';

export const WORKFLOW_STEP_QUERY: QueryParamString = 'workflowStep';
export const COLLECT_QUERY: QueryParamString = 'collect';
export const SEARCH_QUERY: QueryParamString = 'search';
export const VIEWING_REPORT_QUERY: QueryParamString = 'viewingReport';

export const CLIENT_TAB_ID: PathParamString = ':clientTabId';

export const WORKFLOW_STEP: PathParamString = ':workflowStep';

export const CLIENT_BASE_PATH: Route = '/client';
export const CLIENT_RESEARCH_BASE: Route = `${CLIENT_BASE_PATH}/research`;
export const CLIENT_RESEARCH: Route = `${CLIENT_RESEARCH_BASE}/${RESEARCH_ID}`;
export const CLIENT_RESEARCH_TAB: Route = `${CLIENT_BASE_PATH}/research/${RESEARCH_ID}/${CLIENT_TAB_ID}`;

export const CLIENT_RESEARCH_WITH_OPTIONAL_WORKFLOW_STEP: Route = `${CLIENT_RESEARCH_TAB}/${WORKFLOW_STEP}?`;

export type ClientTabType = 'DISCOVER' | 'TRACK' | 'SHORTLIST' | 'ADD';
export type ClientTabTypeLower = Lowercase<ClientTabType>;

type ClientTabEnum = {
  [key in ClientTabType]: ClientTabType;
};

export type ClientTabIcons = {
  [key in ClientTabType]: { icon: ReactNode };
};

export const clientTab: ClientTabEnum = {
  DISCOVER: 'DISCOVER',
  SHORTLIST: 'SHORTLIST',
  TRACK: 'TRACK',
  ADD: 'ADD',
};

export type ClientTabDetail = {
  [key in ClientTabType]: { getLabel: () => string };
};

export const clientTabDetail: ClientTabDetail = {
  DISCOVER: {
    getLabel: () => Messages.t('client.navbar.discover'),
  },
  SHORTLIST: {
    getLabel: () => Messages.t('client.navbar.shortlist'),
  },
  TRACK: {
    getLabel: () => Messages.t('client.navbar.track'),
  },
  ADD: {
    getLabel: () => Messages.t('client.navbar.add'),
  },
};

export const CLIENT_DISCOVER: Route = `${CLIENT_RESEARCH}/${clientTab.DISCOVER.toLowerCase()}`;
export const CLIENT_TRACK: Route = `${CLIENT_RESEARCH}/${clientTab.TRACK.toLowerCase()}`;
export const CLIENT_TRACK_OPTIONAL: Route = `${CLIENT_TRACK}/${WORKFLOW_STEP}?`;

export const CLIENT_SHORTLIST: Route = `${CLIENT_RESEARCH}/${clientTab.SHORTLIST.toLowerCase()}`;
export const CLIENT_SHORTLIST_OPTIONAL: Route = `${CLIENT_SHORTLIST}/${WORKFLOW_STEP}?`;

export const CLIENT_ADD: Route = `${CLIENT_RESEARCH}/${clientTab.ADD.toLowerCase()}`;
