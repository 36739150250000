import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ChevronRight } from '@material-ui/icons';
import { Radio } from '@material-ui/core';

type Props = {
  label: string,
  children: ReactNode
  defaultOpen?: boolean
  listSize?: number,
  checked?: boolean,
  onRadioButtonClick?: () => void,
};

export default function Collapsable(
  {
    label,
    children,
    defaultOpen,
    listSize,
    checked,
    onRadioButtonClick,
  }: Props,
) {
  const [isOpen, setIsOpen] = useState(!!defaultOpen);
  const ref = useRef<HTMLDivElement>(null);
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    forceUpdate();
  }, [listSize]);

  const containerHeight = (isOpen) ? ref.current?.offsetHeight : 0;

  const extendStep = () => {
    setIsOpen(((prevState) => !prevState));
  };
  return (
    <div className="collapsable-container">
      <div className="collapsable-header">
        <button type="button" onClick={extendStep} className="collapsable-title">
          <div>
            <ChevronRight className={`extend-icon ${isOpen ? 'extended' : ''}`} />
          </div>
          <h5>{label}</h5>
        </button>
        {
          checked !== undefined && (
            <Radio
              disabled={!onRadioButtonClick}
              checked={checked}
              onClick={onRadioButtonClick}
            />
          )
        }
      </div>
      <div style={containerHeight !== undefined ? { height: containerHeight } : {}} className="collapsable-content">
        <div ref={ref} className="collapsable-body">
          {
            children
          }
        </div>
      </div>
    </div>
  );
}
