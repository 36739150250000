import React from 'react';
import { Candidate } from 'types/candidate';

type ShortDescProps = {
  candidate: Candidate,
  className: string
};

export default function CandidateShortDescription({ candidate, className }: ShortDescProps) {
  return (
    <h6 className={`m-0 ${className}`}>
      {candidateDescription(candidate, { exclude_address: true })}
    </h6>
  );
}

export function candidateDescription(candidate: Candidate, params?: { exclude_address: boolean }) {
  // eslint-disable-next-line no-irregular-whitespace
  const plural = (value, singular, pluralParam) => `${value} ${value !== 1 ? pluralParam : singular}`;

  const { property } = candidate;

  const paramFinal = params || { exclude_address: false };

  return Array.from(
    [
      // eslint-disable-next-line no-irregular-whitespace
      candidate.client_identifier === null || candidate.client_identifier === 0 ? null : `#${candidate.client_identifier}`,
      paramFinal.exclude_address ? null : property.address,
      property.rooms === null ? null : plural(property.rooms, 'room', 'rooms'),
      property.bedrooms === null
        ? null
        : plural(property.bedrooms, 'bedroom', 'bedrooms'),
      // eslint-disable-next-line no-irregular-whitespace
      property.area === null ? null : `${property.area} ㎡`,
      // eslint-disable-next-line no-irregular-whitespace
      property.price === null ? null : `${property.price} €`,
    ].filter((item) => item !== null && item !== ''),
  ).join(' | ');
}
