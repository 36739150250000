import React from 'react';
import { Candidate } from 'types/candidate';
import { ViewingAppointment, viewingType } from 'types/ViewingAppointment';
import { LocationMarkerIcon, PhoneIcon } from '@heroicons/react/solid';
import { ClockIcon } from '@heroicons/react/outline';
import { format } from 'date-fns';
import { usePropertiesContact } from 'network/api/PropertyQueries';
import { ContactType } from 'types/forms/ContactInfoForm';
import StringUtils from 'services/StringUtils';
import Messages from 'services/i18n/Messages';

type Props = {
  candidate: Candidate
  viewingAppointement: ViewingAppointment
};

const getPrettyDate = (dateStr: string) => {
  const date = new Date(dateStr);
  return `${format(date, 'dd MMMM')} ${Messages.t('generics.at')} ${format(date, 'HH:mm')}`;
};

export default function ViewingDetails({ candidate, viewingAppointement }: Props) {
  const date = new Date(viewingAppointement.date);

  const meetingAddress = viewingAppointement.address || candidate.property.address;
  const propertiesContactQueries = usePropertiesContact();
  const { data: propertyContacts } = propertiesContactQueries
    .getPropertiesContact(candidate.property.id);

  const contact = propertyContacts && propertyContacts
    .filter((propertyContact) => propertyContact.id === viewingAppointement.selected_contact)[0];

  return (
    <div className="candidate-section viewing-details-containter">
      <div>
        <h4>{date > new Date() ? Messages.t('candidate.viewing.detailsUpcomming') : Messages.t('candidate.viewing.detailsPast')}</h4>
        <div className="viewing-details">
          <div className="text-icon">
            <LocationMarkerIcon />
            <a className="address-link" href={`https://www.google.com/maps/place/${meetingAddress}`}>
              {meetingAddress}
            </a>
          </div>
          <div className="text-icon">
            <ClockIcon />
            {getPrettyDate(viewingAppointement.date)}
          </div>
          <h5>{Messages.t('candidate.viewing.whoIsHere')}</h5>
          <div className="contact-list">
            {
              viewingAppointement.viewing_type === viewingType.ACCOMPANIED && (
                <div className="contact-container">
                  <div className="contact-initals">
                    {`${viewingAppointement.ground_agent_first_name.substr(0, 1).toUpperCase()}${viewingAppointement.ground_agent_last_name.substr(0, 1).toUpperCase()}`}
                  </div>
                  <div className="contact-content">
                    <div className="contact-name">
                      {`${viewingAppointement.ground_agent_first_name} ${viewingAppointement.ground_agent_last_name}`}
                    </div>
                    <div className="contact-agency">
                      {Messages.t('candidate.viewing.settlesweetGroundAgent')}
                    </div>
                    <div className="contact-phone text-icon">
                      <PhoneIcon />
                      {viewingAppointement.ground_agent_phone_number}
                    </div>
                  </div>
                </div>
              )
            }
            {
              contact && (
                <div className="contact-container">
                  <div className="contact-initals">
                    {`${contact.first_name.substr(0, 1).toUpperCase()}${contact.last_name.substr(0, 1).toUpperCase()}`}
                  </div>
                  <div className="contact-content">
                    <div className="contact-name">
                      {`${contact.first_name} ${contact.last_name}`}
                    </div>
                    <div className="contact-agency">
                      {
                        contact.contact_type === ContactType.AGENCY ? (
                          <>{Messages.t('candidate.viewing.agency', { agency: StringUtils.capitalizeFirstLetter(contact.agency_name) })}</>
                        ) : (
                          <>{Messages.t('candidate.viewing.landlord')}</>
                        )
                      }
                    </div>
                    <div className="contact-phone text-icon">
                      <PhoneIcon />
                      <a href={`tel:${contact.phone_number}`}>
                        {contact.phone_number}
                      </a>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
