import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import LoaderRing from 'theme/hunter-utils/LoaderRing';
import { useResearch } from 'network/api/ResearchQueries';
import ResearchForm from 'features/hunter/pages/research/form/ResearchForm';

type Param = {
  researchId: string,
};

export default function ResearchEdit() {
  const { researchId } = useParams<Param>();
  const researchQueries = useResearch();
  const { isLoading, data } = researchQueries.getResearch(
    researchId,
    !!researchId && researchId !== 'add',
  );

  const research = useMemo(() => {
    if (!researchId) {
      return {
        research_id: null,
        name: '',
        client_email: '',
        homematcher_email: '',
        notes: '',
        internal_notes: '',
        zip_codes: [],
        area_min: null,
        area_max: null,
        price_min: null,
        price_max: null,
        rooms: null,
        bedrooms: null,
        floor_min: null,
        floor_max: null,
        elevator: null,
        furnished: null,
        client_first_name: '',
        client_last_name: '',
      };
    }
    if (!isLoading && data) {
      return data;
    }
    return null;
  }, [researchId, isLoading, data]);

  return (
    <div className="page-container">
      <div className="search-page-container">
        <LoaderRing visible={isLoading} />
        {!isLoading && research && (
          <ResearchForm
            key={researchId || 'new research'}
            research={research}
          />
        )}
      </div>
    </div>
  );
}
