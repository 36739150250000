import React from 'react';
import { TemplateIcon } from '@heroicons/react/solid';
import { Elevator, Hotel } from '@material-ui/icons';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
import { Candidate } from 'types/candidate';

type Props = {
  candidate: Candidate,
};

export default function PropertySummaryIcons({ candidate }: Props) {
  return (
    <div className="property-summary">
      <div className="candidate-card-price">
        {candidate.property.price} &euro;
      </div>
      <div className="pictos">
        <TemplateIcon />
        {candidate.property.rooms}
        {candidate.property.bedrooms && (
          <>
            <Hotel />
            {candidate.property.bedrooms}
          </>
        )}
        <ArrowsExpandIcon />
        {candidate.property.area} m2
        {candidate.property.elevator && <Elevator />}
        {candidate.property.elevator === false && (
          <div className="slashed-elevator">
            <Elevator />
            <div className="slash" />
          </div>
        )}
      </div>
    </div>
  );
}
