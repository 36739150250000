import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Messages from 'services/i18n/Messages';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onConfirm: () => void,
  title: string,
  text: string
};

export default function ConfirmationModal(
  {
    isOpen,
    onConfirm,
    onClose,
    text,
    title,
  }: Props,
) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {text}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">{Messages.t('formButton.cancel')}</Button>
        <Button onClick={onConfirm} variant="contained">{Messages.t('formButton.yes')}</Button>
      </DialogActions>
    </Dialog>
  );
}
