import React, { useCallback, useMemo } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { candidateDescription } from 'features/hunter/pages/research/researchDetails/candidateDetails/CandidateShortDescription';
import { Candidate } from 'types/candidate';
import Messages from 'services/i18n/Messages';
import { copyToClipboard } from 'services/utils';

type Props = {
  candidate: Candidate
};

export default function ActivityPickedByCustomer({ candidate }: Props) {
  const alertId = useMemo(
    () => `candidate-mail-${candidate.id}`,
    [candidate.id],
  );

  const handleCopyToClipboard = useCallback(() => {
    try {
      const alert = document.getElementById(alertId);
      const link = alert?.getElementsByTagName('a')[0];
      if (link) {
        link.href = candidate.client_url;
        copyToClipboard(alertId);
        link.href = candidate.client_url;
      }
    } catch {
      // eslint-disable-next-line no-console
      console.error('Error getting link');
    }
  }, [alertId, candidate.research, candidate.id, candidate.client_url]);

  return (
    <div style={{ position: 'relative' }}>
      <Alert variant="secondary" id={alertId}>
        <a
          href={candidate.client_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {candidateDescription(candidate)}
        </a>
      </Alert>
      <Button
        onClick={handleCopyToClipboard}
        variant="secondary"
        size="sm"
        className="top-right-corner"
      >
        {Messages.t('clipBoardButton.toClipboard')}
      </Button>
    </div>
  );
}
